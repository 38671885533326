// import { useState } from 'react'
import { TextField, FormControl } from '@mui/material'
import DatePicker from '@mui/lab/DatePicker'
import PropTypes from 'prop-types'
import useWindowDimensions from '../../hooks/useWindowDimensions'
import MobileDatePicker from '@mui/lab/MobileDatePicker'
// import { height, width } from '@mui/system'

const FormikDatePicker = ({
  variableName,
  labelName,
  formik,
  disabled,
  placeholder,
  breakpointHeight = 665,
  breakpointWidth = 530,
  variant = 'outlined',
}) => {
  const { height, width } = useWindowDimensions()
  // const [open, setOpen] = useState(false)

  const handleDateChange = (value) => {
    formik.setFieldValue(variableName, value && value.format('YYYY-MM-DD'))
  }

  return (
    <>
      {Number(height) >= breakpointHeight &&
      Number(width) >= breakpointWidth ? (
        <FormControl
          fullWidth={true}
          error={
            formik.touched[variableName] && Boolean(formik.errors[variableName])
          }
        >
          <DatePicker
            label={labelName}
            value={formik.values[variableName]}
            onChange={handleDateChange}
            disabled={disabled}
            // open={open}
            // onOpen={() => setOpen(true)}
            // onClose={() => setOpen(false)}
            mask="__.__.____"
            renderInput={(params) => (
              <TextField
                size="small"
                {...params}
                // InputProps={{ onFocus: () => setOpen(true) }}
                // value={
                //   formik.values[variableName] == null
                //     ? null
                //     : formik.values[variableName]
                // }
                placeholder={placeholder}
                id={variableName}
                name={variableName}
                helperText={
                  formik.touched[variableName] && formik.errors[variableName]
                }
                mask=""
                error={
                  formik.touched[variableName] &&
                  Boolean(formik.errors[variableName])
                }
                variant={variant}
              />
            )}
          />
        </FormControl>
      ) : (
        <FormControl
          fullWidth={true}
          error={
            formik.touched[variableName] && Boolean(formik.errors[variableName])
          }
        >
          <MobileDatePicker
            label={labelName}
            value={
              formik.values[variableName] == undefined
                ? null
                : formik.values[variableName]
            }
            onChange={handleDateChange}
            mask="__.__.____"
            disabled={disabled}
            renderInput={(params) => (
              <TextField
                size="small"
                {...params}
                placeholder={placeholder}
                id={variableName}
                value={
                  formik.values[variableName] == undefined
                    ? null
                    : formik.values[variableName]
                }
                name={variableName}
                helperText={
                  formik.touched[variableName] && formik.errors[variableName]
                }
                error={
                  formik.touched[variableName] &&
                  Boolean(formik.errors[variableName])
                }
                variant={variant}
              />
            )}
          />
        </FormControl>
      )}
    </>
  )
}

FormikDatePicker.propTypes = {
  formik: PropTypes.object,
  variableName: PropTypes.string,
  labelName: PropTypes.string,
  variant: PropTypes.string,
  placeholder: PropTypes.string,
  disabled: PropTypes.bool,
  breakpointHeight: PropTypes.number,
  breakpointWidth: PropTypes.number,
}

export default FormikDatePicker
