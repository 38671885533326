import { useContext } from 'react'
import { useTranslation } from 'react-i18next'
import { ValuationCalculatorContext } from '../../../../../contexts/ValuationCalculatorContext'
import { Grid, Typography } from '@mui/material'
import ReactQuill from 'react-quill'

const ValuationCalculatorDescriptionInput = () => {
  const { component, setComponent, readOnly } = useContext(
    ValuationCalculatorContext
  )
  const { t } = useTranslation()

  const changeValue = (value, source) => {
    if (source === 'user') {
      component.description = value
      setComponent(component)
    }
  }

  return (
    <>
      <Grid container sx={{ maxWidth: 'inherit' }}>
        <Grid item xs={12}>
          <Typography
            variant="h6"
            component="div"
            className="mb-5 mt-5 !important"
          >
            {t('valuation_popup.data.title.comment')}
          </Typography>
        </Grid>
        <Grid sx={{ overfloWrap: 'anywhere' }} item xs={12}>
          <ReactQuill
            class="textarea"
            value={component.description || null}
            readOnly={readOnly}
            onChange={(value, _, source) => {
              changeValue(value !== '<p><br></p>' ? value : '', source)
            }}
          />
        </Grid>
      </Grid>
    </>
  )
}
ValuationCalculatorDescriptionInput.propTypes = {}
export default ValuationCalculatorDescriptionInput
