import { useContext, useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import useSaveToLS from '../../hooks/useSaveToLS'
import StoreKeyContext from '../../contexts/StoreKeyContext'
import {
  loadFilterByKey,
  buildInitFilter,
  loadData,
} from '../../store/filtersSlice'
import PropTypes from 'prop-types'

const WithFilters = ({ children, config, endpoint }) => {
  const dispatch = useDispatch()
  const [timeoutValue, setTimeoutValue] = useState(null)

  const storeKey = useContext(StoreKeyContext)
  const filters = useSelector((state) => state.filters.filters[storeKey])
  const saveToLS = useSaveToLS()

  const filter = () => {
    saveToLS(`filters/${storeKey}`, filters)
    dispatch(loadData({ endpoint, key: storeKey }))
  }

  useEffect(() => {
    if (storeKey && config) {
      dispatch(buildInitFilter({ key: storeKey, config: config }))
    }
  }, [config])

  useEffect(() => {
    const filter = localStorage.getItem(`filters/${storeKey}`)
    if (filter) {
      dispatch(loadFilterByKey({ key: storeKey, filter: filter }))
    }
  }, [dispatch, storeKey])

  useEffect(() => {
    if (Object.keys(filters) && Object.keys(filters).length !== 0) {
      if (timeoutValue) {
        clearTimeout(timeoutValue)
      }
      setTimeoutValue(setTimeout(filter, 700))
    }
  }, [filters])

  return (
    <>
      {/* { JSON.stringify(filters) } */}
      {children}
    </>
  )
}

WithFilters.propTypes = {
  children: PropTypes.any,
  config: PropTypes.object,
  endpoint: PropTypes.string,
}

export default WithFilters
