import { useContext } from 'react'
import { useTranslation } from 'react-i18next'
import { ValuationCalculatorContext } from '../../../../../../contexts/ValuationCalculatorContext'
import FormInput from '../../../../inputs/FormInput'

const ValuationCalculatorNameInput = () => {
  const { component, setComponent, validate, readOnly } = useContext(
    ValuationCalculatorContext
  )
  const { t } = useTranslation()

  const changeValue = (value) => {
    component.name = value
    setComponent(component)
  }

  return (
    <>
      <FormInput
        name="name"
        disabled={readOnly}
        labelName={t('valuation_popup.data.input.name')}
        value={component.name}
        handleChange={(v) => {
          changeValue(v)
        }}
        helperText={
          validate && !component.name && t('main.validation.required')
        }
      />
    </>
  )
}
ValuationCalculatorNameInput.propTypes = {}
export default ValuationCalculatorNameInput
