import { useContext } from 'react'
import { useTranslation } from 'react-i18next'
import { ValuationCalculatorContext } from '../../../../../../contexts/ValuationCalculatorContext'
import FormInput from '../../../../inputs/FormInput'

const ValuationCalculatorQuantityInput = () => {
  const { component, setComponent, validate, readOnly } = useContext(
    ValuationCalculatorContext
  )
  const { t } = useTranslation()

  const changeValue = (value) => {
    component.quantity = value
    setComponent(component)
  }

  return (
    <>
      <FormInput
        name="quantity"
        value={component.quantity}
        handleChange={(v) => {
          changeValue(v)
        }}
        disabled={readOnly}
        max="10000"
        labelName={t('Quantity')}
        min="1"
        helperText={
          validate &&
          ((!component.quantity && t('main.validation.required')) ||
            (component.quantity < 1 &&
              t('main.validation.min_value', { min: 1 })))
        }
        type="number"
      />
    </>
  )
}
ValuationCalculatorQuantityInput.propTypes = {}
export default ValuationCalculatorQuantityInput
