const downloader = ({ data, type, name }) => {
  return new Promise((resolve) => {
    function triggerDownload(blob, name) {
      let link = document.createElement('a')
      let url = window.URL.createObjectURL(blob)

      link.download = name
      link.href = window.URL.createObjectURL(blob)
      link.click()

      window.URL.revokeObjectURL(url)
    }

    function buildBlob(image) {
      return new Promise((resolve) => {
        let canvas = document.createElement('canvas')

        canvas.width = image.naturalWidth
        canvas.height = image.naturalHeight

        let ctx = canvas.getContext('2d')

        ctx.imageSmoothingEnabled = false
        ctx.drawImage(image, 0, 0)

        canvas.toBlob((blob) => {
          resolve(blob)
        })
      })
    }

    if (type != 'image/png') {
      triggerDownload(new Blob([data], { type }), name)

      resolve(true)
    } else {
      let image = new Image()

      image.src = 'data:image/png;base64,' + data
      image.onload = () => {
        buildBlob(image).then((blob) => {
          triggerDownload(blob, name)

          resolve(true)
        })
      }
    }
  })
}

export default downloader
