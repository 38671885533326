import { toFixedNumber } from '../../lib/numberFormatter'

export function calculatePriceNet(wholesalePriceNet, increasePriceNet) {
  return toFixedNumber({
    value: +wholesalePriceNet * ((100 + +increasePriceNet) / 100),
  })
}

export function calculateIncreasePriceNet(priceNet, wholesalePriceNet) {
  return Math.round((+priceNet / +wholesalePriceNet) * 100 - 100)
}

export function calculateWholesalePriceNet(priceNet, increasePriceNet) {
  return toFixedNumber({
    value: +priceNet / ((100 + +increasePriceNet) / 100),
  })
}

export function onPriceNetChange(value, item) {
  const priceNet = toFixedNumber({ value })
  const increasePriceNet = calculateIncreasePriceNet(
    priceNet,
    item.wholesale_price_net
  )
  return { price_net: priceNet, increase_price_net: increasePriceNet }
}

export function onWholesalePriceNetChange(value, item) {
  const wholesalePriceNet = toFixedNumber({ value })
  const priceNet = calculatePriceNet(wholesalePriceNet, item.increase_price_net)
  return { wholesale_price_net: wholesalePriceNet, price_net: priceNet }
}

export function onIncreasePriceNetChange(value, item) {
  const increasePriceNet = Math.round(value)
  if (item.wholesale_price_net) {
    const priceNet = calculatePriceNet(
      item.wholesale_price_net,
      increasePriceNet
    )
    return { increase_price_net: increasePriceNet, price_net: priceNet }
  } else {
    const wholesalePriceNet = calculateWholesalePriceNet(
      item.price_net,
      increasePriceNet
    )
    return {
      increase_price_net: increasePriceNet,
      wholesale_price_net: wholesalePriceNet,
    }
  }
}
