// import PropTypes from 'prop-types'
import { useContext, useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { ValuationCalculatorContext } from '../../../../../../contexts/ValuationCalculatorContext'
import FormAutocomplete from '../../../../inputs/FormAutocomplete'
import { getColors } from '../../../../../../store/valuationSlice'
import { useDispatch } from 'react-redux'

const ValuationCalculatorColorInput = () => {
  const {
    component,
    setComponent,
    validate,
    readOnly,
    setImage,
    setColorImage,
    getExtrasItems,
    handleLoadingStack,
  } = useContext(ValuationCalculatorContext)
  const { t } = useTranslation()
  const [colors, setColors] = useState([])
  const dispatch = useDispatch()
  const firstRender = useRef(true)
  useEffect(() => {
    firstRender.current = false
  })
  useEffect(() => {
    firstRender.current = true
  }, [component.id])

  useEffect(async () => {
    if (component.color_id && colors) {
      const color = colors.find(
        (element) => element.color_id == component.color_id
      )
      if (color) {
        component.color_name = color.name
        component.color_group = color.color_group
        setImage(color.image || null)
        setColorImage(color.image)
      }
    } else {
      component.color_name = ''
      component.color_group = false
    }
    setComponent(component)
  }, [component.color_id, colors])

  useEffect(() => {
    if (!firstRender.current) {
      getExtrasItems()
    }
  }, [component.color_id])

  useEffect(() => {
    if (colors && !component.color_id) {
      for (const color of colors) {
        if (color.default) {
          changeValue(color.color_id)
        }
      }
    }
  }, [colors])

  useEffect(() => {
    if (component.material_id) {
      loadColors()
    } else {
      setColors([])
    }
    if (!firstRender.current) {
      changeValue('')
    }
  }, [component.material_id])

  const loadColors = () => {
    setColors(null)
    handleLoadingStack(true)
    dispatch(getColors({ material_id: component.material_id })).then(
      (colors) => {
        setColors(colors.payload)
        handleLoadingStack(false)
      }
    )
  }

  const changeValue = (value) => {
    component.color_id = value
    setComponent(component)
  }

  return (
    <>
      <FormAutocomplete
        variableName="color_id"
        value={component.color_id}
        disabled={readOnly || !component.material_id}
        handleChange={(l, v) => {
          changeValue(v)
        }}
        config={{
          key: 'color_id',
          label: 'name',
          separator: ' ',
        }}
        helperText={
          validate && !component.color_id && t('main.validation.required')
        }
        labelName={t('valuation_popup.data.input.color')}
        options={colors}
      />
    </>
  )
}
// ValuationCalculatorColorInput.propTypes = {}
export default ValuationCalculatorColorInput
