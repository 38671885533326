import React from 'react'
import Confirmation from '../Confirmation'
import { useTranslation } from 'react-i18next'
import PropTypes from 'prop-types'

const PWAPrompt = ({ accept, reject }) => {
  const { t } = useTranslation()

  return (
    <>
      <Confirmation
        maxWidth="xs"
        icon="Help"
        showDialogTitle={false}
        title={t('Would you like to install application?')}
        subtitle={t(
          'After confirmation, click the "Install" button in the upper right corner of the screen. The application shortcut will appear on your desktop.'
        )}
        reject={reject}
        success={accept}
      />
    </>
  )
}

PWAPrompt.propTypes = {
  accept: PropTypes.func,
  reject: PropTypes.func,
}
export default PWAPrompt
