import { Grid, Typography } from '@mui/material'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import ValuationCalculatorDescriptionInput from '../inputs/ValuationCalculatorDescriptionInput'
import ValuationCalculatorAttachments from '../inputs/ValuationCalculatorAttachments'
import ValuationCalculatorLocationInput from '../windowsill/Inputs/ValuationCalculatorLocationInput'
import ValuationCalculatorMaterialInput from '../windowsill/Inputs/ValuationCalculatorMaterialInput'
import ValuationCalculatorColorInput from '../windowsill/Inputs/ValuationCalculatorColorInput'
import ValuationCalculatorThicknessInput from '../windowsill/Inputs/ValuationCalculatorThicknessInput'
import ValuationCalculatorPriceForProductInput from '../windowsill/Inputs/ValuationCalculatorPriceForProductInput'
import ValuationCalculatorNameInput from '../windowsill/Inputs/ValuationCalculatorNameInput'
import ValuationCalculatorWidthInput from '../windowsill/Inputs/ValuationCalculatorWidthInput'
import ValuationCalculatorCustomLengthInput from '../windowsill/Inputs/ValuationCalculatorCustomLengthInput'

const ValuationCalculatorCustom = ({ className }) => {
  const user = useSelector((state) => state.user.user)
  const { t } = useTranslation()

  return (
    <>
      <Grid
        className={className}
        style={{ marginBottom: '28px' }}
        id="valuation-calculator-custom"
      >
        <Grid item xs={12}>
          <Typography variant="h6" component="div" className="mb-5">
            {t('valuation_popup.data.title.basic_informations')}
          </Typography>
        </Grid>
        <Grid className="relative" item xs={12}>
          <Grid
            className="fundamental-info-custom"
            item
            style={{
              display: 'grid',
              justifySelf: 'stretch',
              justifyItems: 'stretch',
              alignItems: 'stretch',
              gridTemplateRows: 'auto',
              gridTemplateColumns: '1fr 1fr 1fr',
              gap: '0.5rem',
            }}
          >
            <ValuationCalculatorNameInput />
            <ValuationCalculatorLocationInput />
            <ValuationCalculatorMaterialInput />
            <ValuationCalculatorColorInput />
            <ValuationCalculatorThicknessInput />
            {user.role_id === 1 && (
              <>
                <ValuationCalculatorWidthInput />
                <ValuationCalculatorCustomLengthInput />
                <ValuationCalculatorPriceForProductInput />
              </>
            )}
          </Grid>
          <ValuationCalculatorDescriptionInput />
          <ValuationCalculatorAttachments />
        </Grid>
      </Grid>
    </>
  )
}

ValuationCalculatorCustom.propTypes = {
  className: PropTypes.string,
}

export default ValuationCalculatorCustom
