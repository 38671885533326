import PropTypes from 'prop-types'
import React, { useEffect } from 'react'
import { useFormik } from 'formik'
import { refreshFilter } from '../../../../lib/handleFilters'
import { Grid, DialogActions, DialogContent } from '@mui/material'
import FormikControl from '../../../molecules/FormikControl'
import SaveIcon from '@mui/icons-material/Save'
import * as Yup from 'yup'
import axios from 'axios'
import { handleOpenAlert } from '../../../../lib/handleAlert'
import { closePopup } from '../../../../store/popupsSlice'
import { useDispatch } from 'react-redux'
import { useState } from 'react'
import LoadingButton from '@mui/lab/LoadingButton'
import FormikSelect from '../../../molecules/FormikSelect'

const PriceListFactory = () => {
  const dispatch = useDispatch()
  const formik = useFormik({
    initialValues: {
      name: '',
      currency_id: '',
      price_list_id: '',
    },
    enableReinitialize: false,
    validationSchema: Yup.object({
      name: Yup.string().required('Pole wymagane'),
      currency_id: Yup.string().required('Pole wymagane'),
      price_list_id: Yup.string().nullable(),
    }),
    onSubmit: async (values) => {
      setLoading(true)
      store(values)
    },
  })

  const [loading, setLoading] = useState(false)
  const [currencies, setCurrencies] = useState([])
  const [priceLists, setPriceLists] = useState([])

  const loadCurrencies = () => {
    return new Promise(function (resolve) {
      axios.post(`/lookups/currencies`).then((res) => {
        if (res.status == 200) {
          resolve(res.data)
        }
      })
    })
  }
  const loadPriceLists = () => {
    return new Promise(function (resolve) {
      axios
        .post(`/price_lists/filter`, { filters: { WHERE: { active: 1 } } })
        .then((res) => {
          if (res.status == 200) {
            resolve(res.data)
          }
        })
    })
  }

  useEffect(async () => {
    setCurrencies(await loadCurrencies())
    setPriceLists(await loadPriceLists())
  }, [])

  const store = (values) => {
    axios
      .post('/price_lists', {
        ...values,
      })
      .then((res) => {
        if (res.status == 200 || res.status == 201) {
          dispatch(closePopup('factory-price-list-popup'))

          refreshFilter('PriceListsKey')
        }
        handleOpenAlert({
          title: 'Sukces!',
          subtitle: 'Dodano nowy cennik',
        })
      })
      .finally(() => {
        setLoading(false)
      })
  }

  // function handleClick() {
  //   setLoading(true)
  // }

  // const classes = styles()

  return (
    <>
      <form onSubmit={formik.handleSubmit} onReset={formik.handleReset}>
        <>
          <DialogContent dividers={true}>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <Grid container spacing={2}>
                  <Grid item md={6} xs={12}>
                    <FormikControl
                      variableName="name"
                      handleChange={formik.handleChange}
                      labelName="Nazwa"
                      formik={formik}
                    />
                  </Grid>
                  <Grid item md={6} xs={12}>
                    <FormikSelect
                      variableName="currency_id"
                      value={formik.values['currency_id']}
                      labelName="Waluta"
                      formik={formik}
                      options={currencies}
                    />
                  </Grid>
                  <Grid item md={6} xs={12}>
                    <FormikSelect
                      variableName="price_list_id"
                      value={formik.values['price_list_id']}
                      labelName="Kopiuj cennik z:"
                      formik={formik}
                      options={priceLists}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <LoadingButton
              startIcon={<SaveIcon />}
              variant="contained"
              color="primary"
              type="submit"
              // onClick={setLoading}
              loading={loading}
              loadingPosition="start"
            >
              <span className="mt-1">Zapisz</span>
            </LoadingButton>
          </DialogActions>
        </>
      </form>
    </>
  )
}

PriceListFactory.propTypes = {
  values: PropTypes.object,
}

export default PriceListFactory
