import { Card, CardContent, CircularProgress } from '@mui/material'
import { makeStyles } from '@mui/styles'
import PropTypes from 'prop-types'
const styles = makeStyles(() => ({
  content: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    position: 'relative',
  },
  loading: {
    fontSize: '50px',
  },
}))
const LoadingCard = ({ fullHeight, className }) => {
  const classes = styles()

  return (
    <>
      <Card
        className={`${className} ${classes.content}`}
        elevation={0}
        style={{
          height: fullHeight ? '100% ' : '300px',
        }}
      >
        <CardContent>
          <>
            <CircularProgress
              className={classes.loading}
              color="primary"
              thickness={2}
              size="9rem"
            />
          </>
        </CardContent>
      </Card>
    </>
  )
}

LoadingCard.propTypes = {
  loading: PropTypes.bool,
  fullHeight: PropTypes.bool,
  className: PropTypes.string,
}

export default LoadingCard
