import PropTypes from 'prop-types'
import { TableCell, TableRow } from '@mui/material'
import buildValuationComponentName from 'features/Valuation/functions/buildValuationComponentName'
import { useTranslation } from 'react-i18next'

const ValuationOrderDetailsBlockRow = ({ block, lp }) => {
  const { t } = useTranslation()
  return (
    <TableRow>
      <TableCell>{lp}</TableCell>
      <TableCell>{buildValuationComponentName(block)}</TableCell>
      <TableCell>{block.quantity}</TableCell>
      <TableCell>{block.windowsill_discount_price_net.toFixed(2)}</TableCell>
      <TableCell>
        {block.windowsill_summary_discount_price_net.toFixed(2)}
      </TableCell>
    </TableRow>
  )
}

ValuationOrderDetailsBlockRow.propTypes = {
  block: PropTypes.object,
  lp: PropTypes.number,
}
export default ValuationOrderDetailsBlockRow
