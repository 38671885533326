import { TextField, FormControl } from '@mui/material'
import PropTypes from 'prop-types'
import { useEffect, useState } from 'react'
import { stringPathToArray, getDeepValueByArray } from '../../lib/deepUtils'

const FormikControlDeep = ({
  variableName = '',
  labelName,
  handleChange,
  formik,
  helperText = '',
  max = null,
  min = null,
  InputProps = null,
  type = 'text',
  variant = 'outlined',
  forceError = false,
  disabled = false,
  ...rest
}) => {
  const [path, setPath] = useState(null)
  const [timeoutValue, setTimeoutValue] = useState(null)

  const getValueFromObject = (object) => {
    if (path) {
      return getDeepValueByArray(object, path)
    }
    return null
  }
  useEffect(() => {
    setPath(stringPathToArray(variableName))
  }, [variableName])

  const validAndSet = (value) => {
    if (!disabled) {
      if (min !== null && parseInt(min) > parseInt(value)) {
        value = min
      } else if (max !== null && parseInt(max) < parseInt(value)) {
        value = max
      } else {
        return
      }
      if (handleChange) {
        handleChange(value)
      } else {
        formik.setFieldValue(variableName, value)
      }
    }
  }

  const localHandleChange = (e) => {
    let value = e.target.value
    if (type == 'number') {
      clearTimeout(timeoutValue)
      setTimeoutValue(
        setTimeout(() => {
          validAndSet(value)
        }, 300)
      )
    }
    if (handleChange) {
      handleChange(value)
    } else {
      formik.setFieldValue(variableName, value)
    }
  }

  return (
    <FormControl fullWidth>
      <TextField
        error={
          (getValueFromObject(formik.touched) &&
            Boolean(getValueFromObject(formik.errors))) ||
          forceError
        }
        disabled={disabled}
        label={labelName}
        InputProps={InputProps}
        type={type}
        autoComplete="off"
        value={getValueFromObject(formik.values) || ''}
        onChange={localHandleChange}
        id={variableName}
        name={variableName}
        helperText={
          forceError
            ? helperText
            : getValueFromObject(formik.touched)
            ? getValueFromObject(formik.errors)
            : ''
        }
        variant={variant}
        {...rest}
        size="small"
      />
    </FormControl>
  )
}

FormikControlDeep.propTypes = {
  formik: PropTypes.object,
  variableName: PropTypes.string,
  labelName: PropTypes.string,
  handleChange: PropTypes.func,
  type: PropTypes.string,
  variant: PropTypes.string,
  disabled: PropTypes.bool,
  forceError: PropTypes.bool,
  InputProps: PropTypes.object,
  max: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  min: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  helperText: PropTypes.string,
}

export default FormikControlDeep
