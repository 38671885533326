import { useContext, useEffect, useRef, useState } from 'react'
import { ValuationCalculatorContext } from '../../../../../../contexts/ValuationCalculatorContext'
import { Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material'
import { makeStyles } from '@mui/styles'
import ValuationCalculatorExtrasInput from '../../windowsill/Inputs/ValuationCalculatorExtrasInput'
import axios from 'axios'
import { useTranslation } from 'react-i18next'

const useStyles = makeStyles(() => ({
  void: {
    fontSize: '1.5rem',
    display: 'flex',
    padding: '2rem',
    justifyContent: 'center',
    alignItems: 'center',
    color: '#D8D8D8',
  },
}))

const ValuationCalculatorExtrasInputs = () => {
  const classes = useStyles()
  const {
    component,
    fetchExtrasItemsTrigger,
    blocks,
    withoutBlocks,
    extras,
    setExtras,
    handleLoadingStack,
  } = useContext(ValuationCalculatorContext)
  const [extrasTimeout, setExtrasTimeout] = useState(null)
  const [isAnyCompleted, setIsAnyCompleted] = useState(false)
  const [extrasItems, setExtrasItems] = useState({})
  const firstRender = useRef(true)
  const { t } = useTranslation()
  useEffect(() => {
    firstRender.current = false
  })
  useEffect(() => {
    firstRender.current = true
    getExtrasItems(true)
  }, [component.id])

  const _setExtras = (extras) => {
    let isAnyCompleted = false
    for (const key in extras) {
      if (extras[key]?.id) {
        isAnyCompleted = true
      }
    }
    setIsAnyCompleted(isAnyCompleted)
    setExtras(extras)
  }

  const loadExtrasItems = (id = null) => {
    //eslint-disable-next-line
    return new Promise((resolve) => {
      let materialId = id ? id : component.material_id
      let colorId = component.color_id
      let thicknessId = component.thickness_id
      let width = component.width

      if (materialId && colorId && thicknessId && (withoutBlocks || width)) {
        axios
          .post(`/materials/${materialId}/calculator_extras`, {
            color_id: colorId,
            thickness_id: thicknessId,
            width: withoutBlocks ? null : width,
            blocks: ((!withoutBlocks && blocks) || []).map((block) => {
              return { ...block, length: block.length / 100 }
            }),
          })
          .then((res) => {
            if (res.status == 200) {
              resolve(res.data)
            }
          })
          .catch(() => {
            resolve([])
          })
      } else {
        resolve([])
      }
    })
  }

  const getExtrasItems = (skipTimeout = false) => {
    if (!skipTimeout && extrasTimeout) {
      clearTimeout(extrasTimeout)
      handleLoadingStack(false)
    }
    if (
      component.material_id &&
      component.color_id &&
      component.thickness_id &&
      (component.width || withoutBlocks)
    ) {
      handleLoadingStack(true)
      setExtrasTimeout(
        setTimeout(async () => {
          loadExtrasItems().then((data) => {
            setExtrasItems(data)
            handleLoadingStack(false)
          })
        }, 300)
      )
    } else {
      setExtrasItems([])
    }
  }

  useEffect(() => {
    getExtrasItems()
  }, [fetchExtrasItemsTrigger])

  const checkExtras = () => {
    let types = {}
    for (let key in extrasItems) {
      types[extrasItems[key][0].type_id] = true
      for (let i = 0; i < extrasItems[key].length; i++) {
        if (
          extrasItems[key][i].default == 1 &&
          (!extras[extrasItems[key][0].type_id] ||
            !extras[extrasItems[key][0].type_id].id)
        ) {
          extraChange(key, null, extrasItems[key][i])
        }
      }
    }
    // let extrasDefault = {}
    let deletedExtras = false
    for (const key in extras) {
      if (!types[key]) {
        delete extras[key]
        deletedExtras = true
      }
    }
    if (deletedExtras) {
      _setExtras(extras)
    }
  }

  useEffect(() => {
    if (!firstRender.current) {
      checkExtras()
    }
  }, [extrasItems])

  const extraChange = async (key, extra) => {
    let e = extrasItems[key]
    let typeId = extra ? extra.type_id : e[0].type_id
    extras[typeId] = extra
      ? {
          id: extra.id,
          quantity: extra.service
            ? 1
            : extra.quantity || extra.default_quantity,
          price_net: extra.price_net,
          name: extra.name,
          type_id: extra.type_id,
          service: extra.service,
        }
      : {}
    _setExtras(extras)
  }

  return (
    <>
      {extrasItems && Object.keys(extrasItems).length ? (
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell
                style={{
                  width: '50%',
                }}
              >
                {t('Windowsill part')}
              </TableCell>
              <TableCell
                style={{
                  width: '25%',
                }}
              >
                {t('Quantity')}
              </TableCell>
              <TableCell
                style={{
                  width: '25%',
                }}
              >
                {t('Net price')}
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {Object.keys(extrasItems).map((key) => (
              <ValuationCalculatorExtrasInput
                options={extrasItems[key]}
                labelName={key}
                key={key}
                extra={extras[extrasItems[key][0].type_id] || {}}
                isAnyCompleted={isAnyCompleted}
                setValue={(extra) => {
                  extraChange(key, extra)
                }}
              />
            ))}
          </TableBody>
        </Table>
      ) : (
        <div className={classes.void}>Brak</div>
      )}
    </>
  )
}
ValuationCalculatorExtrasInputs.propTypes = {}
export default ValuationCalculatorExtrasInputs
