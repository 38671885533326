import React, { useState } from 'react'
import PropTypes from 'prop-types'
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown'
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp'

const ReadMore = ({ text, maxLength }) => {
  const [isClicked, setIsClicked] = useState(true)

  if (text.length <= maxLength) {
    return <span className="flex font-semibold text-custom_grey">{text}</span>
  }

  return (
    <div>
      <span className="font-semibold flex items-start break-words text-custom_grey">
        {isClicked
          ? `${
              typeof text == 'string'
                ? text.substr(0, maxLength).trim() + '...'
                : text
            }`
          : text}
        {isClicked ? (
          <a
            className="font-bold cursor-pointer flex"
            onClick={() => setIsClicked(false)}
          >
            <ArrowDropDownIcon />
          </a>
        ) : (
          <a
            className="font-bold cursor-pointer flex"
            onClick={() => setIsClicked(true)}
          >
            <ArrowDropUpIcon />
          </a>
        )}
      </span>
    </div>
  )
}

ReadMore.propTypes = {
  text: PropTypes.any,
  maxLength: PropTypes.any,
}

export default ReadMore
