import {
  Box,
  FormControl,
  FormHelperText,
  Autocomplete as MuiAutocomplete,
  TextField,
} from '@mui/material'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import React, { useMemo, useEffect, useState } from 'react'
import { cloneDeep } from 'lodash'

const Autocomplete = ({
  options,
  labelName,
  value,
  className = '',
  onChange,
  message = null,
  itemText = (item) => item.name,
  itemValue = 'id',
  optionComponent = null,
  disabled = false,
  freeSolo = false,
  returnObject = false,
  disableClearable = false,

  variant = 'outlined',
  textFieldProps,
}) => {
  const [inputValue, setInputValue] = useState(null)

  const { t } = useTranslation()

  const mappedOptions = useMemo(
    () =>
      options && options.length
        ? options.map((option) => ({
            ...option,
            id: option[itemValue],
            label: itemText(option),
          }))
        : [],
    [options]
  )

  useEffect(() => {
    if (freeSolo && value) {
      setInputValue({
        id: value,
        label: value,
      })
    } else if (!freeSolo && (value || value === 0) && options) {
      for (const option of mappedOptions) {
        if (option.id == value) {
          setInputValue(option)
        }
      }
    } else {
      setInputValue(null)
    }
  }, [value, options])

  const handleSearchChange = (value) => {
    if (freeSolo) {
      onChange(value)
    }
  }

  const localHandleChange = (e, v) => {
    let value = ''
    if (returnObject) {
      const option = mappedOptions.filter((option) => option.id == v.id)
      if (option) {
        value = cloneDeep(option)
      }
    } else {
      value = v?.id
    }
    // }
    onChange(value)
  }

  return (
    <FormControl fullWidth error={!!message} className={className}>
      {typeof options == 'object' && (
        <MuiAutocomplete
          size="small"
          value={inputValue}
          onChange={(e, v) => localHandleChange(e, v)}
          freeSolo={freeSolo}
          variant={variant}
          disabled={disabled}
          disableClearable={disableClearable}
          loading={!disabled && !options}
          getOptionLabel={(option) => option.label}
          isOptionEqualToValue={(option, value) => option.id === value.id}
          openOnFocus={true}
          renderOption={(props, option) => (
            <Box component="li" {...props}>
              {optionComponent
                ? React.createElement(optionComponent, { option })
                : option.label}
            </Box>
          )}
          loadingText={t('main.loading')}
          noOptionsText={t('main.no_data')}
          options={mappedOptions}
          renderInput={(props) => (
            <>
              <TextField
                size="small"
                {...props}
                autoComplete="off"
                onChange={(e) => handleSearchChange(e.target.value)}
                error={!!message}
                {...textFieldProps}
                label={labelName}
              />
            </>
          )}
        />
      )}
      <FormHelperText>{message}</FormHelperText>
    </FormControl>
  )
}

Autocomplete.propTypes = {
  value: PropTypes.any,
  labelName: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  itemText: PropTypes.func,
  rules: PropTypes.array,
  type: PropTypes.string,
  variant: PropTypes.string,
  message: PropTypes.string,
  itemValue: PropTypes.string,
  className: PropTypes.string,
  options: PropTypes.array,
  config: PropTypes.object,
  validate: PropTypes.object,
  disabled: PropTypes.bool,
  optionComponent: PropTypes.element,
  freeSolo: PropTypes.bool,
  disableClearable: PropTypes.bool,
  returnObject: PropTypes.bool,
  textFieldProps: PropTypes.any,
  InputProps: PropTypes.object,
}

export default Autocomplete
