import React, { useState } from 'react'
import { Formik } from 'formik'
import * as Yup from 'yup'
import { Paper } from '@mui/material'
import FormikControl from '../../molecules/FormikControl'
import { Card, FormControlLabel, Checkbox, Typography } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import LogoAmbit from '../../../images/logo-ambit.png'
import { NavLink } from 'react-router-dom'
import InputAdornment from '@mui/material/InputAdornment'
import LoginIcon from '@mui/icons-material/Login'
import KeyIcon from '@mui/icons-material/Key'
import LoadingButton from '@mui/lab/LoadingButton'
import axios from 'axios'
// import { useHistory } from 'react-router-dom'
import PropTypes from 'prop-types'
import loginSetData from '../../../lib/loginSetData'
// import { loginUser } from '../../../store/userSlice'
import { useTranslation } from 'react-i18next'
import LanguagePicker from '../translate/LanguagePicker'

const useStyles = makeStyles((theme) => ({
  loginMain: {
    background: 'unset !important',
    boxShadow: 'unset !important',
  },
  nested: {
    padding: theme.spacing(10),
  },
  listItem: {
    color: theme.palette.white.main,
  },
  logo: {
    maxWidth: '160px',
    width: 'fit-content',
    margin: '0 auto',
    [theme.breakpoints.down('md')]: {
      width: 'auto',
    },
  },
  link: {
    color: theme.palette.primary.main,
    textDecoration: 'underline',
  },

  formBg: {
    height: '100vh',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    gap: '16px',
    backgroundColor: theme.palette.secondary.mainNd,
    padding: theme.spacing(4),
    [theme.breakpoints.down('md')]: {
      padding: 'unset',
    },
  },
  paperFront: {
    position: 'relative',
    width: '440px',
    minWidth: '100%',
    height: '100%',
    boxShadow: '-55px 40px 2px 0px rgb(218, 218, 218)',
    [theme.breakpoints.down('md')]: {
      width: '100%',
      boxShadow: 'unset',
    },
  },
  form: {
    height: '100%',
    width: '100%',
    maxWidth: '600px',
    display: 'flex',
    gap: theme.spacing(3),
    flexDirection: 'column',
    justifyContent: 'center',
    padding: theme.spacing(4),
    [theme.breakpoints.down('md')]: {
      maxWidth: 'unset',
      // padding: '16px',
    },
  },
  languagePicker: {
    display: 'flex',
    width: '100%',
    justifyContent: 'flex-end',
    margin: '-1.5rem 0 0 1.5rem',
  },
  btn: {
    background: theme.palette.primary.main,
    width: '100%',
    marginTop: '8px',
  },
}))

const Login = () => {
  const classes = useStyles()
  const [loading, setLoading] = useState(false)
  const { t } = useTranslation()
  // let history = useHistory()

  return (
    <Formik
      initialValues={{ email: '', password: '', remember_me: false }}
      validationSchema={Yup.object({
        email: Yup.string()
          .email(t('main.validation.email'))
          .required(t('main.validation.required')),
        password: Yup.string()
          .min(8, t('main.validation.min', { length: 8 }))
          .required(t('main.validation.required')),
      })}
      onSubmit={async (values) => {
        setLoading(true)
        axios.defaults.baseURL = `${
          // eslint-disable-next-line
          process.env.REACT_APP_BASE_API_URL
        }`
        axios
          .post(`/auth/login`, {
            ...values,
          })
          .then(async (res) => {
            if (res.status == 200) {
              loginSetData(res.data, t)
              // history.push('/patients')
            }
          })
          .finally(() => {
            setLoading(false)
          })
      }}
    >
      {(formik) => (
        <form className={classes.formBg} onSubmit={formik.handleSubmit}>
          <Paper className={classes.loginMain}>
            <Card className={classes.paperFront} sx={{ minWidth: 'unset' }}>
              <div className={classes.form}>
                <LanguagePicker className={classes.languagePicker} />
                <img className={classes.logo} src={LogoAmbit} alt="logo" />
                <div>
                  <Typography
                    variant="h2"
                    className="text-custom_red_main text-center"
                  >
                    {t('login.header')}
                  </Typography>
                  <Typography variant="subtitle2" className="text-center">
                    PRZEDSIĘBIORSTWO BUDOWLANE
                  </Typography>
                  <div className="flex gap-4 flex-col justify-center my-3 mx-auto">
                    <FormikControl
                      variableName="email"
                      handleChange={formik.handleChange}
                      labelName={t('login.inputs.login')}
                      formik={formik}
                      type="email"
                      variant="outlined"
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="start">
                            <LoginIcon />
                          </InputAdornment>
                        ),
                      }}
                    />
                    <FormikControl
                      variableName="password"
                      handleChange={formik.handleChange}
                      labelName={t('login.inputs.password')}
                      formik={formik}
                      variant="outlined"
                      type="password"
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="start">
                            <KeyIcon />
                          </InputAdornment>
                        ),
                      }}
                    />
                  </div>
                  <FormControlLabel
                    className="text-custom_blue_dark"
                    control={
                      <Checkbox
                        color="primary"
                        name="remember_me"
                        value={formik.values.remember_me}
                      />
                    }
                    onChange={formik.handleChange}
                    label={t('login.inputs.remember_me')}
                  />
                </div>
                <div>
                  <Typography className=" flex gap-2 flex-wrap justify-between sm:gap-2">
                    {t('login.forgot_password_question')}
                    <NavLink className={classes.link} to="password/reset">
                      {t('login.forgot_password_link')}
                    </NavLink>
                  </Typography>
                  <LoadingButton
                    className={classes.btn}
                    color="primary"
                    variant="contained"
                    type="submit"
                    size="large"
                    loading={loading}
                  >
                    <span className="mt-1">{t('login.submit_button')}</span>
                  </LoadingButton>
                </div>
              </div>
            </Card>
          </Paper>
        </form>
      )}
    </Formik>
  )
}

Login.propTypes = {
  role: PropTypes.string,
}

export default Login
