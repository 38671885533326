import PageHeader from 'components/organisms/layout/PageHeader'
import {
  Card,
  CardContent,
  Typography,
  Grid,
  Tabs,
  Tab,
  AccordionSummary,
  Accordion,
  AccordionDetails,
  IconButton,
} from '@mui/material'
import { useParams } from 'react-router'
import makeStyles from '@mui/styles/makeStyles'
import PageContainer from 'components/organisms/layout/PageContainer'
import PartnerValuesPreview from './tabs/PartnerValuesPreview'
import PartnerOrdersPreview from './tabs/PartnerOrdersPreview'
import PartnerAddressesPreview from './tabs/PartnerAddressesPreview'
import PartnerEmployeesPreview from './tabs/PartnerEmployeesPreview'
import axios from 'axios'
import { useEffect, useState } from 'react'
import { Link, useRouteMatch, useHistory } from 'react-router-dom'
import LoadingCard from '../../../../../atoms/LoadingCard'
import eventBus from 'lib/eventBus'
// import ValuationTable from '../../molecules/valuations/ValuationTable'
import { Search, ExpandMore, ExpandLess } from '@mui/icons-material'
import PartnerPreviewHeader from './PartnerPreviewHeader'
import DOMPurify from 'dompurify'

const useStyles = makeStyles((theme) => ({
  fontAwesome: {
    position: 'absolute',
    fontSize: '16rem',
    right: '20%',
    margin: 'auto 0',
    top: '50%',
    transform: 'translateY(-50%);',
    height: '80%',
    color: theme.palette.primary.light,
    overflow: 'hidden',
    opacity: '0.2',
    zIndex: '0',
  },
  warehousePartner: {
    color: theme.palette.primary.light,
  },
  customCard: {
    position: 'relative',
  },
  custom: {
    width: '100%',
    maxWidth: '25% !important',
  },
  header: {
    width: '100%',
  },
  accordionContent: {
    width: '100%',
    position: 'relative',
  },
  icon: {
    width: '24px !important',
    height: '24px',
  },
  expandButton: {
    position: 'absolute',
    right: 0,
    bottom: 0,
  },
}))

const tabNameToIndex = {
  0: 'orders',
  1: 'valuations',
  2: 'addresses',
  3: 'employees',
}

const indexToTabName = {
  employees: 3,
  valuations: 1,
  orders: 0,
  addresses: 2,
}

function LinkTab(props) {
  return <Tab component={Link} {...props} />
}

const PartnerPreview = () => {
  const { id } = useParams([])
  const [partner, setPartner] = useState({})
  const match = useRouteMatch()
  const history = useHistory([])
  const [tab, setTab] = useState(indexToTabName[match.params.page])
  const [isExpanded, setIsExpanded] = useState(false)

  useEffect(() => {
    if (typeof indexToTabName[match.params.page] != 'number') {
      history.goBack()
    }
  }, [])

  useEffect(() => {
    if (id) {
      loadPartner(id)
    }
  }, [id])

  useEffect(() => {
    eventBus.on('loadPartner', () => {
      loadPartner(id)
    })

    return eventBus.remove('loadPartner')
  }, [])

  const loadPartner = (id) => {
    axios
      .post(`/partners/${id}`)
      .then((res) => {
        if (res.status == 200) {
          setPartner(res.data)
        }
      })
      .catch(() => {
        // handleOpenAlert({
        //   title: 'Błąd!',
        //   subtitle: `Nie znaleziono partnera`,
        //   type: 'warning',
        // })
        history.goBack()
      })
  }

  const accordionChange = (_, isExpanded) => {
    setIsExpanded(isExpanded)
  }

  const classes = useStyles()

  const handleTabChange = (event, newValue) => {
    history.push(`${tabNameToIndex[newValue]}`)
    setTab(newValue)
  }

  return (
    <>
      <PageHeader
        icon={<Search fontSize="medium" />}
        title="Podgląd partnera"
      />
      <PageContainer>
        {partner && id ? (
          <>
            <div className={classes.accordionContent}>
              <PartnerPreviewHeader
                className={classes.header}
                partner={partner}
              />
            </div>
            <Grid item xs={12}>
              <Card className="bg-transparent overflow-auto shadow-none">
                <Tabs
                  className="bg-white"
                  value={tab}
                  onChange={handleTabChange}
                  aria-label="nav tabs"
                >
                  <LinkTab
                    label="Lista zamówień"
                    to="orders"
                    className={classes.custom}
                  />
                  <LinkTab
                    label="Lista wycen"
                    to="valuations"
                    className={classes.custom}
                  />
                  <LinkTab
                    label="Lista adresów"
                    to="addresses"
                    className={classes.custom}
                  />
                  <LinkTab
                    label="Lista pracowników"
                    to="employees"
                    className={classes.custom}
                  />
                </Tabs>
                <CardContent className="no-padding">
                  {tab === 1 && (
                    <PartnerValuesPreview partnerId={parseInt(id)} />
                  )}
                  {tab === 3 && (
                    <PartnerEmployeesPreview
                      partnerId={parseInt(id)}
                      disabledAdd={!!partner.warehouse_partner}
                    />
                  )}
                  {tab === 0 && (
                    <PartnerOrdersPreview partnerId={parseInt(id)} />
                  )}
                  {tab === 2 && (
                    <PartnerAddressesPreview partnerId={parseInt(id)} />
                  )}
                </CardContent>
              </Card>
            </Grid>
          </>
        ) : (
          <LoadingCard />
        )}
      </PageContainer>
    </>
  )
}

export default PartnerPreview
