import PropTypes from 'prop-types'
import { TableCell } from '@mui/material'
import React, { memo } from 'react'
import Autocomplete from 'components/modules/Input/atoms/Autocomplete'
import ClearableInput from 'components/modules/Input/atoms/ClearableInput'

const MaterialPricingListFinishingItemForm = ({
  item,
  index,
  action,
  names,
  updateItem,
  colors = [],
  extras = {},
  errors = {},
}) => {
  const onFieldValueChange = (field, value) => {
    item[field] = value
    updateItem(item)
  }

  return (
    <>
      <TableCell>{index + 1}</TableCell>
      <TableCell>
        <Autocomplete
          labelName={'Kolor'}
          value={item.color_id}
          itemValue="color_id"
          onChange={(v) => onFieldValueChange('color_id', v)}
          options={colors}
        />
      </TableCell>
      <TableCell>
        <Autocomplete
          value={item.name}
          labelName={'Typ wykończenia'}
          itemValue="name"
          message={errors.name}
          onChange={(v) => onFieldValueChange('name', v)}
          options={names}
        />
      </TableCell>
      <TableCell>
        <Autocomplete
          labelName={'Nazwa wykończenia'}
          value={item.extra_id}
          message={errors.extra_id}
          onChange={(v) => onFieldValueChange('extra_id', v)}
          disabled={!item.name}
          options={extras[item.name] || []}
        />
      </TableCell>
      <TableCell>---</TableCell>
      <TableCell>
        <ClearableInput
          InputProps={{ id: `price_net-${index}` }}
          label="Cena"
          type="number"
          value={item.price_net}
          errors={errors.price_net}
          suffix="zł"
          changeHandler={(value) => onFieldValueChange('price_net', value)}
        />
      </TableCell>
      <TableCell>{action}</TableCell>
    </>
  )
}

MaterialPricingListFinishingItemForm.propTypes = {
  item: PropTypes.object.isRequired,
  index: PropTypes.number.isRequired,
  material: PropTypes.object.isRequired,
  action: PropTypes.element,
  colors: PropTypes.array,
  names: PropTypes.array,
  thickness: PropTypes.array,
  updateItem: PropTypes.func,
  errors: PropTypes.object,
  extras: PropTypes.object,
  finishing: PropTypes.bool,
}

export default memo(MaterialPricingListFinishingItemForm)
