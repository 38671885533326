export function toFixedNumber({ value, decimals = 2, parseToString = false }) {
  if (typeof decimals === 'undefined' || +decimals === 0)
    return Math.round(value)

  value = +value
  decimals = +decimals

  if (isNaN(value) || !(typeof decimals === 'number' && decimals % 1 === 0))
    return NaN

  // Shift
  value = value.toString().split('e')
  value = Math.round(
    +(value[0] + 'e' + (value[1] ? +value[1] + decimals : decimals))
  )

  // Shift back
  value = value.toString().split('e')
  const returnValue = +(
    value[0] +
    'e' +
    (value[1] ? +value[1] - decimals : -decimals)
  )
  if (parseToString) {
    return returnValue.toFixed(decimals)
  } else {
    return returnValue
  }
}

export function toFixedNumberIfNotNullOrEmptyString({
  value,
  decimals = 2,
  parseToString = true,
}) {
  if (value === null || value === '') {
    return value
  } else {
    return toFixedNumber({ value, decimals, parseToString })
  }
}
export function toPositiveNumber(value) {
  if (value < 0) {
    return 0.0
  }
  return value
}

export function toPositiveFixedNumber({ value, decimals = 2 }) {
  value = toPositiveNumber(value)
  return toFixedNumber({ value, decimals })
}

export function toFixedNumberIfItIsNot({ value, decimals = 2 }) {
  value = toPositiveNumber(value)
  if (value.toString().split('.')[1]?.length >= decimals) {
    return toFixedNumber({ value, decimals })
  }
  return value
}

export function toPositiveInteger(value) {
  value = toPositiveNumber(value)
  if (value) {
    return Math.floor(value)
  }
  return value
}
