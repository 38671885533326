// import PropTypes from 'prop-types'
import { useContext, useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { ValuationCalculatorContext } from '../../../../../../contexts/ValuationCalculatorContext'
import { getColorItems } from '../../../../../../store/valuationSlice'
import { useDispatch } from 'react-redux'
import ValuationColorItemOption from 'components/modules/Valuation/atoms/ValuationColorItemOption'
import Autocomplete from 'components/modules/Input/atoms/Autocomplete'

const ValuationCalculatorColorItemInput = () => {
  const {
    component,
    setComponent,
    validate,
    readOnly,
    handleLoadingStack,
    setImage,
  } = useContext(ValuationCalculatorContext)
  const { t } = useTranslation()
  const [colorItems, setColorItems] = useState([])
  const dispatch = useDispatch()
  const firstRender = useRef(true)

  useEffect(() => {
    firstRender.current = false
  })
  useEffect(() => {
    firstRender.current = true
  }, [component.id])

  useEffect(async () => {
    if (component.color_item_id && colorItems) {
      const colorItem = colorItems.find(
        (element) => element.id == component.color_item_id
      )
      if (colorItem) {
        component.color_item_name = colorItem.name
        setImage(colorItem.hex_color, 'hex')
      }
    } else {
      component.color_item_name = ''
      setImage(null, 'hex')
    }
    setComponent(component)
  }, [component.color_item_id, colorItems])

  useEffect(() => {
    if (colorItems && !component.color_item_id) {
      for (const colorItem of colorItems) {
        if (colorItem.default) {
          changeValue(colorItem.color_id)
        }
      }
    }
  }, [colorItems])

  useEffect(() => {
    if (component.color_id) {
      loadColorItems(component.color_id)
    } else {
      setColorItems([])
    }
    if (!firstRender.current) {
      changeValue('')
    }
  }, [component.material_id])

  const loadColorItems = (colorId) => {
    setColorItems(null)
    handleLoadingStack(true)
    dispatch(getColorItems({ color_id: colorId })).then((colorsItems) => {
      setColorItems(colorsItems.payload)
      handleLoadingStack(false)
    })
  }

  const changeValue = (value) => {
    component.color_item_id = value
    setComponent(component)
  }

  return (
    <>
      <Autocomplete
        value={component.color_item_id}
        disabled={readOnly || !component.color_id}
        optionComponent={ValuationColorItemOption}
        onChange={changeValue}
        message={
          validate && !component.color_item_id && t('main.validation.required')
        }
        labelName={t('Hue')}
        options={colorItems}
      />
    </>
  )
}
// ValuationCalculatorColorItemInput.propTypes = {}
export default ValuationCalculatorColorItemInput
