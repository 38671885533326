// import PropTypes from 'prop-types'
import { useContext, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { ValuationCalculatorContext } from '../../../../../../contexts/ValuationCalculatorContext'
import FormAutocomplete from '../../../../inputs/FormAutocomplete'
import { useDispatch } from 'react-redux'
import { getLocations } from '../../../../../../store/valuationSlice'
import LocationEnum from '../../../../../../lib/LocationEnum'

const ValuationCalculatorLocationInput = () => {
  const {
    component,
    setComponent,
    validate,
    readOnly,
    partnerId,
    withoutBlocks,
    universal,
    handleLoadingStack,
    selectedGroupNum,
  } = useContext(ValuationCalculatorContext)
  const [locations, setLocations] = useState([])
  const { t } = useTranslation()
  const dispatch = useDispatch()

  useEffect(() => {
    loadLocations()
  }, [universal])

  useEffect(() => {
    if (
      selectedGroupNum &&
      !LocationEnum.groups[selectedGroupNum].includes(component.location_id)
    ) {
      changeValue('')
    }
  }, [selectedGroupNum])

  const changeValue = async (value) => {
    component.location_id = value
    setComponent(component)
  }

  const filterLocations = (locations) => {
    if (withoutBlocks) {
      locations = locations.filter((location) => {
        return LocationEnum.groups[2].includes(location.id)
      })
    }
    if (selectedGroupNum) {
      locations = locations.filter((location) =>
        LocationEnum.groups[selectedGroupNum].includes(location.id)
      )
    }
    return locations
  }

  const loadLocations = () => {
    handleLoadingStack(true)
    dispatch(
      getLocations({
        partner_id: partnerId,
        universal,
      })
    ).then((locations) => {
      locations = locations.payload
      setLocations(filterLocations(locations))
      if (
        locations.findIndex(
          (location) => location.id === component.location_id
        ) === -1
      ) {
        changeValue('')
      }
      handleLoadingStack(false)
    })
  }

  useEffect(() => {
    if (!component.location_id && locations?.length == 1) {
      changeValue(locations[0].id)
    }

    if (component.location_id && locations) {
      component.location_name = locations.find(
        (element) => element.id == component.location_id
      )?.name
    } else {
      component.location_name = ''
    }
    setComponent(component)
  }, [component.location_id, locations])

  return (
    <>
      <FormAutocomplete
        variableName="location_id"
        value={component.location_id}
        disabled={
          readOnly || (!!component.location_id && locations.length === 1)
        }
        labelName={t('valuation_popup.data.input.windowsill_type')}
        handleChange={(l, v) => {
          changeValue(v)
        }}
        helperText={
          validate && !component.location_id && t('main.validation.required')
        }
        options={locations}
      />
    </>
  )
}
ValuationCalculatorLocationInput.propTypes = {}
export default ValuationCalculatorLocationInput
