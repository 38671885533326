import PropTypes from 'prop-types'
import { TableCell } from '@mui/material'
import React, { memo, useState } from 'react'
import UnitEnum from '../../../../features/Unit/Enum/UnitEnum'
import ClearableInput from 'components/modules/Input/atoms/ClearableInput'
import {
  onIncreasePriceNetChange,
  onPriceNetChange,
  onWholesalePriceNetChange,
} from '../../../../features/MaterialPricing/materialPricesCalculation'

const MaterialPricingListItemEditForm = ({
  item,
  index,
  action,
  material,
  updateItem,
  errors = {},
  finishing = false,
}) => {
  const [handlerTimeout, setHandlerTimeout] = useState(null)
  const onFieldValueChange = (field, value) => {
    item[field] = value
    updateItem(item)
  }

  const getPercentOfMainPrice = () => {
    return Math.round((item.price_net / item.main_price_net) * 100, 2)
  }
  const changeHandler = (handler, value, field) => {
    if (handlerTimeout) {
      clearTimeout(handlerTimeout)
    }
    setHandlerTimeout(
      setTimeout(() => {
        updateValues(value, handler)
      }, 500)
    )
    onFieldValueChange(field, value)
  }
  const updateValues = (value, handler) => {
    const valuesToUpdate = handler(value, item)
    for (const field in valuesToUpdate) {
      onFieldValueChange(field, valuesToUpdate[field])
    }
  }

  return (
    <>
      <TableCell> {index + 1}</TableCell>
      <TableCell>
        <span>{item.color_name}</span>
      </TableCell>
      <TableCell>
        {finishing
          ? item.type_name
          : material.unit_id === UnitEnum.SQUARE_METERS
          ? item.thickness_name
          : item.width}
      </TableCell>
      {finishing && <TableCell>{item.name}</TableCell>}
      <TableCell>
        {+item.main_price_net ? `${getPercentOfMainPrice()} %` : '---'}
      </TableCell>
      {!finishing && material && material.unit_id === UnitEnum.LINEAR_METERS && (
        <>
          <TableCell>
            <ClearableInput
              InputProps={{ id: `wholesale_price_net-${index}` }}
              label="Cena hurtowa"
              type="number"
              value={item.wholesale_price_net}
              errors={errors.wholesale_price_net}
              suffix="zł"
              changeHandler={(value) =>
                changeHandler(
                  onWholesalePriceNetChange,
                  value,
                  'wholesale_price_net'
                )
              }
            />
          </TableCell>
          <TableCell>
            <ClearableInput
              InputProps={{ id: `increase_price-${index}` }}
              label="Zwiększ cenę detaliczną o"
              type="number"
              value={item.increase_price_net}
              errors={errors.increase_price_net}
              suffix="%"
              changeHandler={(value) =>
                changeHandler(
                  onIncreasePriceNetChange,
                  value,
                  'increase_price_net'
                )
              }
            />
          </TableCell>
        </>
      )}
      <TableCell>
        <ClearableInput
          InputProps={{ id: `price_net-${index}` }}
          label={`Cena ${finishing ? '' : 'detaliczna'}`}
          type="number"
          value={item.price_net}
          errors={errors.price_net}
          suffix="zł"
          changeHandler={(value) =>
            changeHandler(onPriceNetChange, value, 'price_net')
          }
        />
      </TableCell>
      <TableCell>{action}</TableCell>
    </>
  )
}

MaterialPricingListItemEditForm.propTypes = {
  item: PropTypes.object.isRequired,
  index: PropTypes.number.isRequired,
  material: PropTypes.object.isRequired,
  action: PropTypes.element,
  updateItem: PropTypes.func,
  errors: PropTypes.object,
  finishing: PropTypes.bool,
}

export default memo(MaterialPricingListItemEditForm)
