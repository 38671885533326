import { setToken, loadUser } from '../store/userSlice'
import { handleOpenAlert } from '../lib/handleAlert'
import axios from 'axios'
import store from '../store/store'
import roleIdToPrefix from './roleIdToPrefix'

const loginSetData = async (data, t) => {
  let prefix = roleIdToPrefix[Number(data.data.role_id)]
  if (axios.defaults.baseURL.search(prefix) === -1) {
    axios.defaults.baseURL = await `${
      // eslint-disable-next-line
      process.env.REACT_APP_BASE_API_URL
    }${prefix}`
  }
  await store.dispatch(setToken(data.access_token))
  await store.dispatch(loadUser())
  handleOpenAlert({
    title: t('main.alert.success_title'),
    subtitle: t('login.alert.after_submit_subtitle'),
    type: 'success',
  })
}

export default loginSetData
