import React from 'react'
import PropTypes from 'prop-types'
import {
  Grid,
  DialogActions,
  Typography,
  Dialog,
  DialogContent,
} from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import { Block, Warning, Help, Factory, Logout } from '@mui/icons-material'
import LoadingButton from '@mui/lab/LoadingButton'

const useStyles = makeStyles((theme) => ({
  root: {
    textAlign: 'center',
  },
  icon: {
    background: theme.palette.background.default,
    borderRadius: '1em',
    padding: theme.spacing(2),
    fontSize: '5em',
  },
  dialogWrapper: {
    padding: theme.spacing(2),
  },
  dialogActions: {
    justifyContent: 'space-between',
  },
  dialogContent: {
    overflowY: 'unset',
  },
}))

const icons = {
  Warning: Warning,
  Help: Help,
}

const Confirmation = ({ result, loading = false, showProduction = true }) => {
  const classes = useStyles()

  return (
    <Dialog
      open={true}
      classes={{ paper: classes.dialogWrapper }}
      style={{ zIndex: 1301 }}
      maxWidth="sm"
      fullWidth={true}
      disableEscapeKeyDown={true}
    >
      <DialogContent className={classes.dialogContent}>
        <Grid container spacing={3} className={classes.root}>
          <Grid item xs={12}>
            {React.createElement(icons.Warning, {
              className: classes.icon,
            })}
          </Grid>
          <Grid item xs={12}>
            <Typography variant="h5">
              {'Czy na pewno oznaczyć zamówienie jako "W realizacji"?'}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography>Wybierz formę obsługi zamówienia</Typography>
          </Grid>
          <Grid item xs={12}>
            <DialogActions className={classes.dialogActions}>
              <LoadingButton
                startIcon={<Block />}
                variant="contained"
                color="white"
                loading={loading}
                onClick={() => result('cancel')}
              >
                <span className="mt-1">Anuluj</span>
              </LoadingButton>
              <LoadingButton
                startIcon={<Logout />}
                variant="contained"
                color={showProduction ? 'white' : 'primary'}
                onClick={() => result('manual')}
                loading={loading}
                loadingPosition="start"
              >
                <span className="mt-1">Obsługa ręczna</span>
              </LoadingButton>
              {showProduction && (
                <LoadingButton
                  startIcon={<Factory />}
                  variant="contained"
                  color="primary"
                  onClick={() => result('production')}
                  loading={loading}
                  loadingPosition="start"
                >
                  <span className="mt-1">Produkcja</span>
                </LoadingButton>
              )}
            </DialogActions>
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  )
}

Confirmation.propTypes = {
  loading: PropTypes.bool,
  showProduction: PropTypes.bool,
  result: PropTypes.func,
}

export default Confirmation
