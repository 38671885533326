import React from 'react'
import {
  ThemeProvider,
  StyledEngineProvider,
  createTheme,
} from '@mui/material/styles'
// import { colors } from '@mui/material'
import PropTypes from 'prop-types'

// const font = 'Open Sans, sans-serif'
// const font = 'Roboto, sans-serif'
const font = 'Montserrat, sans-serif'
// const font = 'Lato, sans-serif'

const theme = createTheme({
  breakpoints: {
    values: {
      custom_valuations_st: 560,
      custom_valuations_nd: 470,
      custom_valuations_th: 478,
      custom_extras: 499,
      custom_xs: 426,
      xs: 0,
      sm: 767,
      md: 1024,
      lg: 1200,
      xl: 1559,
      xxl: 1958,
    },
  },
  typography: {
    fontFamily: font,
  },
  palette: {
    primary: {
      dark: '#ab2322',
      main: '#bc2b2a',
      white: '#fff',
    },
    secondary: {
      main: '#D0312D',
      mainNd: '#E5E5E5',
      black: '#000',
      lightBlack: '#323232',
    },
    white: {
      light: '#fff',
      main: '#E6E6E6',
    },
    red: {
      main: '#e81809',
    },
    blue: {
      main: '#16375a',
    },
    background: {
      default: '#E5E5E5',
    },
  },
  props: {
    MuiTextField: {
      variant: 'outlined',
      autocomplete: 'off',
    },
    MuiInput: {
      variant: 'outlined',
    },
    MuiFormControl: {
      variant: 'outlined',
    },
    MuiButton: {
      variant: 'contained',
    },
  },
  components: {
    MuiListItemButton: {
      styleOverrides: {
        root: {
          '&.Mui-selected': {
            paddingLeft: `20px`,
            color: `rgba(0, 0, 0, 1) !important`,
            background: 'rgba(188, 43, 42, 0.3)',
          },
        },
      },
    },
  },
})

const CustomThemeVariables = (props) => {
  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={theme}>{props.children}</ThemeProvider>
    </StyledEngineProvider>
  )
}

CustomThemeVariables.propTypes = {
  children: PropTypes.any,
}

export default CustomThemeVariables
