import '../../assets/loadingText.scss'
import PropTypes from 'prop-types'

const LoadingText = ({ text }) => {
  return (
    <>
      <span className="loading-text">{text}</span>
    </>
  )
}

LoadingText.propTypes = {
  text: PropTypes.string,
}

export default LoadingText
