import {
  Card,
  CardHeader,
  CardContent,
  Typography,
  Grid,
  Button,
} from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import PropTypes from 'prop-types'
import { faHandshake } from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useDispatch } from 'react-redux'
import { openPopup } from 'store/popupsSlice'
// import ValuationTable from '../../molecules/valuations/ValuationTable'
import { Edit } from '@mui/icons-material'

const useStyles = makeStyles((theme) => ({
  fontAwesome: {
    position: 'absolute',
    fontSize: '16rem',
    right: '20%',
    margin: 'auto 0',
    top: '50%',
    transform: 'translateY(-50%);',
    height: '80%',
    color: theme.palette.primary.light,
    overflow: 'hidden',
    opacity: '0.2',
    zIndex: '0',
  },
  warehousePartner: {
    color: theme.palette.primary.light,
  },
  customCard: {
    position: 'relative',
  },
  custom: {
    width: '100%',
    maxWidth: '25% !important',
  },
  icon: {
    width: '24px !important',
    height: '24px',
  },
  header: {
    color: 'rgba(0, 0, 0, 0.6) !important',
    display: 'flex',
    flex: '1 1 100%',
    flexWrap: 'wrap',
  },
  headerMd: {
    display: 'flex',
    flex: '1 1 100%',
    flexWrap: 'wrap',
    gap: '16px',
    alignItems: 'center',
  },
  [theme.breakpoints.between('xs', 'custom_xs')]: {
    headerMd: {
      display: 'grid',
    },
  },
}))

const PartnerPreviewHeader = ({ partner }) => {
  const dispatch = useDispatch()
  const classes = useStyles()

  return (
    <Card className={classes.customCard} elevation={2} square>
      <FontAwesomeIcon className={classes.fontAwesome} icon={faHandshake} />
      <CardHeader
        className={classes.header}
        title={
          <span>
            {partner.name}{' '}
            {partner.warehouse_partner && (
              <span className={classes.warehousePartner}>
                Dostawca (Magazyn)
              </span>
            )}
          </span>
        }
        action={
          <Button
            startIcon={<Edit />}
            variant="contained"
            color="primary"
            onClick={() =>
              dispatch(
                openPopup({
                  component: 'PartnerFactory',
                  title: 'Edytuj partnera',
                  level: 1,
                  key: 'partner-factory-popup',
                  maxWidth: 'sm',
                  values: {
                    id: partner.id,
                  },
                })
              )
            }
          >
            <span className="mt-1">Edytuj partnera</span>
          </Button>
        }
      />
      <CardContent>
        <div>
          <Card
            elevation={0}
            className="flex flex-col pt-0 text-black lg:w-full md:w-full p-4 whitespace-nowrap"
          >
            <Grid
              className="z-10"
              container
              rowSpacing={1}
              columnSpacing={{ xs: 1, sm: 2, md: 3 }}
            >
              <Grid item xs={12} md={6} lg={4}>
                <Typography
                  className="flex items-center gap-1 text-custom_grey"
                  variant="subtitle1"
                >
                  <p className="flex font-semibold text-custom_grey">Nip:</p>{' '}
                  {partner.nip || 'Brak'}
                </Typography>
              </Grid>
              <Grid item xs={12} md={6} lg={4}>
                <Typography
                  className="flex items-center gap-1 text-custom_grey"
                  variant="subtitle1"
                >
                  <p className="flex font-semibold text-custom_grey">Miasto:</p>{' '}
                  {partner.city}
                </Typography>
              </Grid>
              <Grid item xs={12} md={6} lg={4}>
                <Typography
                  className="flex items-center gap-1 text-custom_grey"
                  variant="subtitle1"
                >
                  <p className="flex font-semibold text-custom_grey">Adres:</p>
                  {partner.street}
                </Typography>
              </Grid>
              <Grid item xs={12} md={6} lg={4}>
                <Typography
                  className="flex items-center gap-1 text-custom_grey"
                  variant="subtitle1"
                >
                  <p className="flex font-semibold text-custom_grey">
                    Kod pocztowy:
                  </p>{' '}
                  {partner.postcode}
                </Typography>
              </Grid>
              <Grid item xs={12} md={6} lg={4}>
                <Typography
                  className="flex items-center gap-1 text-custom_grey"
                  variant="subtitle1"
                >
                  <p className="flex font-semibold text-custom_grey">
                    Województwo:
                  </p>{' '}
                  {partner.state_name}
                </Typography>
              </Grid>
              <Grid item xs={12} md={6} lg={4}>
                <Typography
                  className="flex items-center gap-1 text-custom_grey"
                  variant="subtitle1"
                >
                  <p className="flex font-semibold text-custom_grey">Rabat:</p>{' '}
                  {partner.discount}%
                </Typography>
              </Grid>
            </Grid>
          </Card>
        </div>
      </CardContent>
    </Card>
  )
}
PartnerPreviewHeader.propTypes = {
  partner: PropTypes.object,
}

export default PartnerPreviewHeader
