import { openAlert, closeAlert } from '../store/alertsSlice'
import store from '../store/store'

export const handleOpenAlert = (content) => {
  const key = generateKey(content.title, content.subtitle, content.type)
  store.dispatch(openAlert({ ...content, key }))
}

export const handleCloseAlert = (key) => {
  store.dispatch(closeAlert(key))
}

const generateKey = (title, subtitle, type) => {
  return `${title}${subtitle}${type}`
}
