import { TextField, FormControl } from '@mui/material'
import PropTypes from 'prop-types'
import { useState } from 'react'
// import { useEffect, useState } from 'react'

const NumberInput = ({
  variableName,
  labelName,
  handleChange,
  value,
  helperText,
  max = null,
  min = null,
  variant = 'outlined',
  error = false,
  disabled = false,
  InputProps = null,
  ...rest
}) => {
  const [timeoutValue, setTimeoutValue] = useState(null)

  const validAndSet = (value) => {
    if (!disabled) {
      if (min !== null && parseInt(min) > parseInt(value)) {
        value = min
      } else if (max !== null && parseInt(max) < parseInt(value)) {
        value = max
      } else {
        return
      }
      handleChange(value)
    }
  }

  const localHandleChange = (e) => {
    let value = e.target.value
    clearTimeout(timeoutValue)
    setTimeoutValue(
      setTimeout(() => {
        validAndSet(value)
      }, 500)
    )
    handleChange(value)
  }

  return (
    <FormControl fullWidth>
      <TextField
        size="small"
        error={error}
        InputProps={InputProps}
        disabled={disabled}
        label={labelName}
        type="number"
        autoComplete="off"
        value={value || value === 0 ? value : ''}
        onChange={localHandleChange}
        id={variableName}
        name={variableName}
        helperText={error && helperText}
        variant={variant}
        {...rest}
      />
    </FormControl>
  )
}

NumberInput.propTypes = {
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  variableName: PropTypes.string,
  labelName: PropTypes.string,
  handleChange: PropTypes.func,
  variant: PropTypes.string,
  disabled: PropTypes.bool,
  error: PropTypes.bool,
  helperText: PropTypes.string,
  InputProps: PropTypes.object,
  max: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  min: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
}

export default NumberInput
