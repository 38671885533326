import PropTypes from 'prop-types'

const ValuationColorItemOption = ({ option }) => {
  return (
    <div
      style={{
        width: '100%',
        height: '100%',
        display: 'flex',
        gap: '1rem',
      }}
    >
      <div
        style={{
          width: '1rem',
          height: '1rem',
          backgroundColor: option.hex_color,
        }}
      ></div>
      <div>{option.label}</div>
    </div>
  )
}
ValuationColorItemOption.propTypes = {
  option: PropTypes.object,
}
export default ValuationColorItemOption
