import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
// import setAuthToken from '../lib/setAuthToken'
import axios from 'axios'

export const getLocations = createAsyncThunk(
  'valuation/getLocations',
  async (payload, { dispatch }) => {
    return new Promise((resolve) => {
      axios
        .post(`/locations/available`, {
          partner_id: payload.partner_id,
          universal: payload.universal,
        })
        .then((res) => {
          if (res.status == 200) {
            resolve(res.data)
          }
        })
    })
  }
)
export const getMaterials = createAsyncThunk(
  'valuation/getMaterials',
  async (payload, { getState, dispatch }) => {
    return new Promise((resolve) => {
      const materials = getState().valuation.materials[payload.location_id]
      if (!materials) {
        axios
          .post(`/locations/${payload.location_id}/materials`, {
            partner_id: payload.partner_id,
          })
          .then((res) => {
            if (res.status == 200) {
              dispatch(
                setValues({
                  var: 'materials',
                  key: payload.location_id,
                  data: res.data,
                })
              )
              resolve(res.data)
            }
          })
      } else {
        resolve(materials)
      }
    })
  }
)
export const getColors = createAsyncThunk(
  'valuation/getColors',
  async (payload, { getState, dispatch }) => {
    return new Promise((resolve) => {
      const colors = getState().valuation.colors[payload.material_id]
      if (!colors) {
        axios
          .post(`/materials/${payload.material_id}/colors/filter`)
          .then((res) => {
            if (res.status == 200) {
              dispatch(
                setValues({
                  var: 'colors',
                  key: payload.material_id,
                  data: res.data,
                })
              )
              resolve(res.data)
            }
          })
      } else {
        resolve(colors)
      }
    })
  }
)
export const getThickness = createAsyncThunk(
  'valuation/getThickness',
  async (payload, { getState, dispatch }) => {
    return new Promise((resolve) => {
      const key = `${payload.material_id}_${payload.color_id}`
      const thickness = getState().valuation.thickness[key]
      if (!thickness) {
        axios
          .post(
            `/materials/${payload.material_id}/colors/${payload.color_id}/thickness`
          )
          .then((res) => {
            if (res.status == 200) {
              dispatch(
                setValues({
                  var: 'thickness',
                  key,
                  data: res.data,
                })
              )
              resolve(res.data)
            }
          })
      } else {
        resolve(thickness)
      }
    })
  }
)
export const getWidths = createAsyncThunk(
  'valuation/getThickness',
  async (payload, { getState, dispatch }) => {
    return new Promise((resolve) => {
      const key = `${payload.material_id}_${payload.color_id}_${payload.thickness_id}`
      const widths = getState().valuation.widths[key]
      if (!widths) {
        axios
          .get(
            `/materials/${payload.material_id}/colors/${payload.color_id}/thickness/${payload.thickness_id}/widths`
          )
          .then((res) => {
            if (res.status == 200) {
              dispatch(
                setValues({
                  var: 'widths',
                  key,
                  data: res.data,
                })
              )
              resolve(res.data)
            }
          })
      } else {
        resolve(widths)
      }
    })
  }
)

export const getColorItems = createAsyncThunk(
  'valuation/getColorItems',
  async (payload, { getState, dispatch }) => {
    return new Promise((resolve) => {
      const key = `${payload.color_id}`
      const colorItems = getState().valuation.colorItems[key]
      if (!colorItems) {
        axios.get(`/colors/${payload.color_id}/items`).then((res) => {
          if (res.status == 200) {
            dispatch(
              setValues({
                var: 'colorItems',
                key,
                data: res.data,
              })
            )
            resolve(res.data)
          }
        })
      } else {
        resolve(colorItems)
      }
    })
  }
)

export const valuationSlice = createSlice({
  name: 'valuation',
  initialState: {
    locations: null,
    thickness: {},
    materials: {},
    widths: {},
    colors: {},
    colorItems: {},
  },
  reducers: {
    setValues: (state, action) => {
      state[action.payload.var][action.payload.key] = action.payload.data
    },
    setLocations: (state, action) => {
      state.locations = action.payload
    },
    clear: (state) => {
      state.locations = null
      state.colors = {}
      state.colorItems = {}
      state.materials = {}
      state.thickness = {}
      state.widths = {}
    },
  },
})

export const { setValues, setLocations, clear } = valuationSlice.actions

export default valuationSlice.reducer
