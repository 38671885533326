import PageHeader from 'components/organisms/layout/PageHeader'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUsers } from '@fortawesome/pro-solid-svg-icons'
import makeStyles from '@mui/styles/makeStyles'
import DataTable from 'components/organisms/dataTable/DataTable'
import { useSelector } from 'react-redux'
import { Card, CardContent, Button } from '@mui/material'
import { useDispatch } from 'react-redux'
import AddIcon from '@mui/icons-material/Add'
import PageContainer from 'components/organisms/layout/PageContainer'
import StoreKeyProvider from 'components/providers/StoreKeyProvider'
import WithFilters from 'components/hocs/WithFilters'
import DataInputs from 'components/organisms/dataTable/DataInputs'
import { openPopup } from 'store/popupsSlice'
import { useEffect, useState } from 'react'
import axios from 'axios'

const useStyles = makeStyles(() => ({
  icon: {
    width: '24px !important',
    height: '24px',
  },
  bg: {
    background: 'transparent',
    overflow: 'unset',
  },
  content: {
    padding: '24px',
    background: '#fff',
    marginBottom: '24px',
    borderRadius: '2px',
    boxShadow:
      '0px 3px 1px -2px rgba(0,0,0,0.2),0px 2px 2px 0px rgba(0,0,0,0.14),0px 1px 5px 0px rgba(0,0,0,0.12)',
  },
  contentSt: {
    background: '#fff',
    marginBottom: '24px',
    borderRadius: '2px',
    boxShadow:
      '0px 3px 1px -2px rgba(0,0,0,0.2),0px 2px 2px 0px rgba(0,0,0,0.14),0px 1px 5px 0px rgba(0,0,0,0.12)',
    position: 'relative',
  },
}))

const Users = () => {
  const dispatch = useDispatch()
  const [roles, SetRoles] = useState([])
  const user = useSelector((state) => state.user.user)

  const classes = useStyles()

  useEffect(() => {
    axios.post('/lookups/roles', null).then((res) => {
      SetRoles(res.data)
    })
  }, [])

  return (
    <div>
      <PageHeader
        icon={<FontAwesomeIcon icon={faUsers} className={classes.icon} />}
        title="Lista użytkowników"
      />
      <PageContainer>
        <StoreKeyProvider storeKey="UsersKey">
          <WithFilters
            config={{
              page: 1,
              paginate: 25,
              orderType: 'asc',
              order: 'firstname',
              inputs: {
                firstname: {
                  value: '',
                  relation: 'LIKE',
                },
                lastname: {
                  value: '',
                  relation: 'LIKE',
                },
                phone_number: {
                  value: null,
                  relation: 'LIKE',
                },
                email: {
                  value: '',
                  relation: 'LIKE',
                },
                active: {
                  value: 1,
                  relation: 'WHERE',
                },
                role_id: {
                  value: '',
                  relation: 'WHERE',
                },
              },
            }}
            endpoint="/users"
          >
            <Card elevation={0} className={classes.bg}>
              {/* <CardHeader
                // title="Lista handlowców"
                action={
                  user && user.role_id == 1 ? (
                    <Button
                      startIcon={<AddIcon />}
                      variant="contained"
                      color="primary"
                      onClick={() =>
                        dispatch(
                          openPopup({
                            component: 'UserFactory',
                            title: 'Dodaj handlowca',
                            level: 1,
                            key: 'user-factory-popup',
                            maxWidth: 'sm',
                          })
                        )
                      }
                    >
                      Dodaj handlowca
                    </Button>
                  ) : null
                }
              /> */}
              <CardContent className={classes.contentSt}>
                {user.permissions &&
                  user.permissions.includes('storeAdmin User') && (
                    <Button
                      className="absolute right-0 mr-4"
                      startIcon={<AddIcon />}
                      variant="contained"
                      color="primary"
                      onClick={() =>
                        dispatch(
                          openPopup({
                            component: 'UserFactory',
                            title: 'Dodaj użytkownika',
                            level: 1,
                            key: 'user-factory-popup',
                            maxWidth: 'sm',
                          })
                        )
                      }
                    >
                      <span className="mt-1">Dodaj użytkownika</span>
                    </Button>
                  )}
                {roles && (
                  <DataInputs
                    inputs={[
                      {
                        id: 'firstname',
                        label: 'Imię',
                        placeholder: '',
                        type: 'text',
                      },
                      {
                        id: 'lastname',
                        label: 'Nazwisko',
                        placeholder: '',
                        type: 'text',
                      },
                      {
                        id: 'phone_number',
                        label: 'Nr. Telefonu',
                        placeholder: '',
                        type: 'number',
                      },
                      {
                        id: 'email',
                        label: 'Email',
                        placeholder: '',
                        type: 'text',
                      },
                      {
                        id: 'role_id',
                        label: 'Rola',
                        placeholder: '',
                        type: 'select',
                        options: roles,
                      },
                      {
                        id: 'active',
                        label: 'Aktywne',
                        placeholder: '',
                        type: 'select',
                        options: [
                          {
                            id: 1,
                            name: 'Tak',
                          },
                          {
                            id: 0,
                            name: 'Nie',
                          },
                        ],
                      },
                    ]}
                  />
                )}
              </CardContent>

              <CardContent className={classes.content}>
                <DataTable
                  breakpoint={1484}
                  actions="UsersTableActions"
                  labels={['Imię', 'Nazwisko', 'Nr. Telefonu', 'Email', 'Rola']}
                  keys={[
                    'firstname',
                    'lastname',
                    'phone_number',
                    'email',
                    'role_name',
                  ]}
                />
              </CardContent>
            </Card>
          </WithFilters>
        </StoreKeyProvider>
      </PageContainer>
    </div>
  )
}

export default Users
