import { Grid, DialogContent } from '@mui/material'
import DOMPurify from 'dompurify'
import PropTypes from 'prop-types'

const NotesPreview = ({ comment }) => {
  return (
    <>
      <DialogContent dividers={true}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <div
              dangerouslySetInnerHTML={{
                __html: DOMPurify.sanitize(comment),
              }}
            ></div>
          </Grid>
        </Grid>
      </DialogContent>
    </>
  )
}

NotesPreview.propTypes = {
  comment: PropTypes.string,
}

export default NotesPreview
