import PropTypes from 'prop-types'
import { useContext, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { ValuationCalculatorContext } from '../../../../../../contexts/ValuationCalculatorContext'
import FormInput from '../../../../inputs/FormInput'
import { TableCell, TableRow } from '@mui/material'
import FormSelectWithImg from 'components/molecules/inputs/FormSelectWithImg'

const ValuationCalculatorExtrasInput = ({
  options,
  setValue,
  extra,
  isAnyCompleted,
  labelName,
}) => {
  const { validate, readOnly, currencyAcronym, withoutBlocks, blocks } =
    useContext(ValuationCalculatorContext)
  const { t } = useTranslation()

  const changeExtra = (value) => {
    if (value) {
      const extra = options.find((element) => element.id == value)
      setValue(extra)
    } else {
      setValue(null)
    }
  }
  const isRequired = (withoutBlocks && !isAnyCompleted) || blocks?.length > 1

  const setQuantity = (value) => {
    setValue({
      ...extra,
      quantity: parseInt(value),
    })
  }

  const onlyEvenNumbers = !extra.service && extra.type_id == 2

  useEffect(() => {
    if (onlyEvenNumbers && (!withoutBlocks || !extra.quantity)) {
      setQuantity(2)
    }
  }, [withoutBlocks])

  return (
    <>
      {extra && (
        <TableRow>
          <TableCell
            style={{
              widht: '50%',
            }}
          >
            <FormSelectWithImg
              variableName={`extra_id`}
              labelName={labelName}
              helperText={
                isRequired &&
                validate &&
                !extra.id &&
                t('main.validation.required')
              }
              disabled={readOnly}
              config={{
                label: `name`,
                separator: ' ',
                key: 'id',
              }}
              value={extra.id}
              handleChange={(l, v) => {
                changeExtra(v)
              }}
              options={options}
            />
          </TableCell>
          <TableCell
            style={{
              widht: '25%',
            }}
          >
            {readOnly ||
            extra.service === 1 ||
            (!withoutBlocks && onlyEvenNumbers) ? (
              <span>{extra.quantity}</span>
            ) : (
              <FormInput
                name="quantity"
                value={extra.quantity}
                handleChange={(v) => {
                  setQuantity(v)
                }}
                helperText={
                  (validate &&
                    (!isNaN(extra.quantity) || isRequired || extra?.id) &&
                    (extra.quantity || 0) < 1 &&
                    t('main.validation.required')) ||
                  (onlyEvenNumbers &&
                    withoutBlocks &&
                    extra.quantity % 2 !== 0 &&
                    t('main.validation.even'))
                }
                disabled={!extra.id}
                max="10000"
                labelName={t('valuation_popup.data.input.quantity')}
                min="1"
                type="number"
              />
            )}
          </TableCell>
          <TableCell
            style={{
              widht: '25%',
            }}
          >
            {(extra.price_net * extra.quantity || 0).toFixed(2)}{' '}
            {currencyAcronym}
          </TableCell>
        </TableRow>
      )}
    </>
  )
}
ValuationCalculatorExtrasInput.propTypes = {
  options: PropTypes.array,
  setValue: PropTypes.func,
  labelName: PropTypes.string,
  isAnyCompleted: PropTypes.bool,
  extra: PropTypes.oneOfType([PropTypes.object]),
}
export default ValuationCalculatorExtrasInput
