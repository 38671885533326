import PropTypes from 'prop-types'
import React, { useEffect } from 'react'
import { useFormik } from 'formik'
import { refreshFilter } from '../../../../lib/handleFilters'
// import eventBus from '../../../../lib/eventBus'
import {
  //   Typography,
  Grid,
  DialogActions,
  DialogContent,
  FormControlLabel,
  Checkbox,
  // CircularProgress,
} from '@mui/material'
import SaveIcon from '@mui/icons-material/Save'
import * as Yup from 'yup'
import axios from 'axios'
import { handleOpenAlert } from '../../../../lib/handleAlert'
import { closePopup } from '../../../../store/popupsSlice'
import { useDispatch } from 'react-redux'
import { useState } from 'react'
import LoadingButton from '@mui/lab/LoadingButton'
import FileInput from '../../../molecules/FileInput'
import createFormData from '../../../../lib/createFormData'
import FormikSelect from '../../../molecules/FormikSelect'

const MaterialColorFactory = ({ ...values }) => {
  const dispatch = useDispatch()
  const [fileEdit, setFileEdit] = useState(null)
  const [colors, setColors] = useState([])

  const colorId = values.color_id
  const materialId = values.material_id

  const formik = useFormik({
    initialValues: {
      color_id: '',
      default: false,
    },
    enableReinitialize: false,
    validationSchema: Yup.object({
      color_id: Yup.string().required('Pole wymagane'),
    }),
    onSubmit: async (values) => {
      setLoading(true)
      save(values)
    },
  })

  useEffect(async () => {
    if (!colorId) {
      setColors(await loadColors())
    }
  }, [materialId])

  useEffect(() => {
    if (colorId) {
      axios
        .get(`/materials/${materialId}/colors/${colorId}`)
        .then((response) => {
          formik.setValues({
            default: response.data.default,
            color_id: response.data.color_id,
          })
          if (response.data.file) {
            setFileEdit({
              file_id: response.data.file.id,
              file_name: response.data.file.name,
              file_hash: response.data.file.hash,
            })
          }
        })
    }
  }, [colorId])

  const loadColors = () => {
    return new Promise((resolve) => {
      axios.get(`/materials/${materialId}/colors/to_add`).then((response) => {
        resolve(response.data)
      })
    })
  }

  const save = (values) => {
    if (typeof values.image == 'string') {
      delete values.image
    }
    const form = createFormData({
      ...values,
    })
    axios
      .post(
        `/materials/${materialId}/colors${colorId ? `/${colorId}/edit` : ''}`,
        form,
        {
          'Content-Type': 'multipart/form-data',
        }
      )
      .then((res) => {
        if (res.status == 200 || res.status == 201) {
          dispatch(closePopup('material-color-factory-popup'))

          refreshFilter('MaterialColorsPreviewKey')
        }
        handleOpenAlert({
          title: 'Sukces!',
          subtitle: `${colorId ? 'Zaktualizowano' : 'Dodano'} kolor`,
          type: 'success',
        })
      })
      .finally(() => {
        setLoading(false)
      })
  }

  const [loading, setLoading] = useState(false)

  return (
    <>
      <form onSubmit={formik.handleSubmit} onReset={formik.handleReset}>
        <>
          <DialogContent dividers={true}>
            <Grid container spacing={3}>
              {!colorId && (
                <Grid item md={6} xs={12}>
                  <FormikSelect
                    variableName="color_id"
                    value={formik.values['color_id']}
                    labelName="Kolor"
                    formik={formik}
                    options={colors}
                  />
                </Grid>
              )}
              <Grid item md={6} xs={12}>
                <FormControlLabel
                  control={
                    <Checkbox
                      color="primary"
                      checked={Boolean(formik.values.default)}
                      onChange={(e) => {
                        formik.setFieldValue('default', e.target.checked)
                      }}
                    />
                  }
                  label="Domyślny kolor"
                />
              </Grid>
              <Grid item xs={12}>
                {
                  <FileInput
                    formik={formik}
                    edit={fileEdit}
                    availableExtensions={['jpg', 'bmp', 'png', 'gif', 'svg']}
                    delEdit={(id) => {
                      setFileEdit(null)
                      formik.setFieldValue('file_to_del', id)
                    }}
                    downloadRoute={(file) => {
                      return colorId
                        ? `/files/${file.file_hash}/download`
                        : null
                    }}
                    labelName="image"
                    label="Kliknij aby dodać zdjęcie domyślnego koloru"
                  />
                }
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <LoadingButton
              startIcon={<SaveIcon />}
              variant="contained"
              color="primary"
              type="submit"
              // onClick={setLoading}
              loading={loading}
              loadingPosition="start"
            >
              <span className="mt-1">Zapisz</span>
            </LoadingButton>
          </DialogActions>
        </>
      </form>
    </>
  )
}

MaterialColorFactory.propTypes = {
  values: PropTypes.object,
}

export default MaterialColorFactory
