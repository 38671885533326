import React from 'react'
import { useSelector } from 'react-redux'
import makeStyles from '@mui/styles/makeStyles'
import Download from './Download'

const useStyles = makeStyles((theme) => ({
  root: {
    zIndex: '160000',
    position: 'absolute',
    display: 'flex',
    flexDirection: 'column',
    bottom: theme.spacing(4),
    right: theme.spacing(2),
  },
  alert: {
    width: '100%',
    '& > * + *': {
      marginBottom: theme.spacing(2),
    },
  },
}))

const DownloadsHub = () => {
  const classes = useStyles()

  const downloads = useSelector((state) => state.downloads.downloads)
  return (
    <div className={classes.root}>
      {Object.keys(downloads).map((key) => (
        <Download
          key={key}
          className={classes.alert}
          title={downloads[key].title}
          progress={downloads[key].progress}
        />
      ))}
    </div>
  )
}

export default DownloadsHub
