import PropTypes from 'prop-types'
import SideMenu from '../SideMenu'
import Header from '../Header/Header'
import { useSelector } from 'react-redux'

import { makeStyles } from '@mui/styles'

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.default,
  },
}))

const DefaultLayout = ({ children }) => {
  const classes = useStyles()
  const user = useSelector((state) => state.user.user)

  return (
    <>
      {(user.role_id == 1 || user.role_id == 2) && <SideMenu />}
      <div id="wrapper">
        <Header />
        <main className={classes.root}>{children}</main>
      </div>
    </>
  )
}

DefaultLayout.propTypes = {
  children: PropTypes.any,
}

export default DefaultLayout
