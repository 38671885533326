import { TextField, FormControl } from '@mui/material'
import PropTypes from 'prop-types'
import { useState } from 'react'
// import { useEffect, useState } from 'react'

const FormInput = ({
  labelName,
  handleChange,
  value = null,
  type = 'text',
  max = null,
  min = null,
  variant = 'outlined',
  helperText = null,
  error = true,
  disabled = false,
  InputProps = {},
}) => {
  const [timeoutValue, setTimeoutValue] = useState(null)

  const validAndSet = (value) => {
    if (!disabled) {
      if (min !== null && parseInt(min) > parseInt(value)) {
        value = min
      } else if (max !== null && parseInt(max) < parseInt(value)) {
        value = max
      } else {
        return
      }
      handleChange(value)
    }
  }

  const localHandleChange = (e) => {
    let value = e.target.value
    if (type == 'number') {
      clearTimeout(timeoutValue)
      setTimeoutValue(
        setTimeout(() => {
          validAndSet(value)
        }, 300)
      )
    }
    handleChange(value)
  }

  return (
    <FormControl fullWidth error={helperText && error}>
      <TextField
        size="small"
        error={helperText && error}
        disabled={disabled}
        label={labelName}
        type={type}
        autoComplete="off"
        value={value || ''}
        onChange={localHandleChange}
        helperText={helperText}
        variant={variant}
        InputProps={InputProps}
      />
    </FormControl>
  )
}

FormInput.propTypes = {
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  labelName: PropTypes.string,
  handleChange: PropTypes.func,
  type: PropTypes.string,
  variant: PropTypes.string,
  disabled: PropTypes.bool,
  error: PropTypes.bool,
  helperText: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  max: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  min: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  InputProps: PropTypes.object,
}

export default FormInput
