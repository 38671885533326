import { Grid, IconButton } from '@mui/material'
import { Delete, Edit } from '@mui/icons-material'
import { useDispatch, useSelector } from 'react-redux'
import PropTypes from 'prop-types'
import { openPopup } from '../../../../store/popupsSlice'
import Confirmation from '../../popups/Confirmation'
import axios from 'axios'
import { handleOpenAlert } from '../../../../lib/handleAlert'
import { refreshFilter } from '../../../../lib/handleFilters'
import { useState } from 'react'
import RestoreFromTrashIcon from '@mui/icons-material/RestoreFromTrash'

const MaterialFinishingPreviewActions = ({ row }) => {
  const dispatch = useDispatch()
  const user = useSelector((state) => state.user.user)
  const [confirmation, setConfirmation] = useState(false)

  return (
    <Grid
      container
      alignItems="center"
      justifyContent="center"
      direction="row"
      spacing={1}
      className="table-actions"
    >
      {confirmation && (
        <Confirmation
          level={5}
          maxWidth="xs"
          icon="Warning"
          title={`Czy na pewno chcesz usunąć wykończenie: ${row.name}?`}
          subtitle={`Tej operacji nie można cofnąć.`}
          reject={() => {
            setConfirmation(false)
          }}
          success={() => {
            axios[row.active ? 'delete' : 'patch'](
              `/materials/${row.material_id}/extras/${row.id}`
            )
              .then(() => {
                handleOpenAlert({
                  title: 'Sukces!',
                  subtitle: `${
                    row.active ? 'Usunięto' : 'Przywrócono'
                  } wykończenie ${row.name}`,
                  type: 'success',
                })
                refreshFilter('MaterialFinishingPreviewKey')
              })
              .finally(() => {
                setConfirmation(false)
              })
          }}
        />
      )}
      {user.permissions && user.permissions.includes('edit Extra') && (
        <IconButton
          title="Edytuj wykończenie"
          size="small"
          onClick={() =>
            dispatch(
              openPopup({
                component: 'MaterialFinishingPricingFactory',
                title: 'Edytuj wykończenie',
                level: 1,
                maxWidth: 'sm',
                values: {
                  id: row.id,
                  material_id: row.material_id,
                  popupKey: 'factory-finishing-popup',
                },
                key: 'factory-finishing-popup',
              })
            )
          }
        >
          <Edit fontSize="small" />
        </IconButton>
      )}
      {user.permissions &&
        ((row.active && user.permissions.includes('remove Extra')) ||
          (!row.active && user.permissions.includes('restore Extra'))) && (
          <IconButton
            className="p-0"
            title={`${row.active ? 'Przywróć' : 'Usuń'} materiał`}
            size="small"
            onClick={() => setConfirmation(true)}
          >
            {row.active ? (
              <Delete fontSize="small" color="error" />
            ) : (
              <RestoreFromTrashIcon fontSize="small" color="success" />
            )}
          </IconButton>
        )}
    </Grid>
  )
}

MaterialFinishingPreviewActions.propTypes = {
  row: PropTypes.object,
}

export default MaterialFinishingPreviewActions
