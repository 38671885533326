import { TextField, FormControl } from '@mui/material'
import PropTypes from 'prop-types'

const Input = ({
  labelName,
  onChange,
  value = '',
  type = 'text',
  variant = 'outlined',
  message = null,
  disabled = false,
  InputProps = {},
}) => {
  const localHandleChange = (e) => {
    let value = e.target.value
    onChange(value)
  }

  return (
    <FormControl fullWidth error={!!message}>
      <TextField
        size="small"
        error={!!message}
        disabled={disabled}
        label={labelName}
        type={type}
        autoComplete="off"
        value={value || ''}
        onChange={localHandleChange}
        helperText={message}
        variant={variant}
        InputProps={InputProps}
      />
    </FormControl>
  )
}

Input.propTypes = {
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  labelName: PropTypes.string,
  onChange: PropTypes.func,
  type: PropTypes.string,
  variant: PropTypes.string,
  disabled: PropTypes.bool,
  message: PropTypes.oneOfType([PropTypes.string]),
  InputProps: PropTypes.object,
}

export default Input
