import { useContext } from 'react'
import { useDispatch } from 'react-redux'
import StoreKeyContext from '../../../contexts/StoreKeyContext'
import { TableCell, TableHead, TableRow } from '@mui/material'
import { ArrowDropDown, ArrowDropUp } from '@mui/icons-material'
import { changeOrder } from '../../../store/filtersSlice'
import PropTypes from 'prop-types'
import makeStyles from '@mui/styles/makeStyles'
import { useTranslation } from 'react-i18next'

const useStyles = makeStyles((theme) => ({
  cell: {
    padding: `${theme.spacing(2)} ${theme.spacing(1)}`,
    cursor: 'pointer',
    background: '#bc2b2a',
    color: '#fff',
  },
  thead: {
    background: '#bc2b2a',
    color: '#fff',
  },
}))

const DataTableHead = ({ labels, filters, keys, actions, rowStyles }) => {
  const dispatch = useDispatch()
  const classes = useStyles()
  const { t } = useTranslation()

  const storeKey = useContext(StoreKeyContext)

  const handleOrderChange = (index) => {
    const key = keys[index]
    dispatch(changeOrder({ key: storeKey, orderKey: key }))
  }
  return (
    <TableHead className={classes.thead}>
      <TableRow className={classes.thead}>
        {labels &&
          labels.map((label, index) => (
            <TableCell
              className={classes.cell}
              key={label + 'header'}
              onClick={() => handleOrderChange(index)}
              // className={`${style.tableCell} ${
              //   keys[index] === filters.order ? style.active : ''
              // }`}
              style={{
                width: `${100 / (keys.length + (actions ? 1 : 0))}%`,
                ...rowStyles[keys[index]],
                whiteSpace: 'nowrap',
              }}
            >
              <div>
                <span className="uppercase font-medium">{label}</span>{' '}
                {keys[index] === filters.order ? (
                  filters.orderType === 'asc' ? (
                    <ArrowDropDown />
                  ) : (
                    <ArrowDropUp />
                  )
                ) : null}
              </div>
            </TableCell>
          ))}
        {actions && (
          <TableCell className={classes.thead}>
            <div>
              <span>{t('main.filter.actions')}</span>
            </div>
          </TableCell>
        )}
      </TableRow>
    </TableHead>
  )
}

DataTableHead.propTypes = {
  labels: PropTypes.array,
  filters: PropTypes.object,
  rowStyles: PropTypes.object,
  keys: PropTypes.array,
  actions: PropTypes.string,
}

export default DataTableHead
