export default {
  STONE: 1,
  STEEL: 2,
  ALUMINIUM: 3,
  PCV: 4,
  groups: {
    1: [1],
    2: [2, 3, 4],
  },
}
