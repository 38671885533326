import React from 'react'
import { useSelector } from 'react-redux'
import PopupsBase from './PopupsBase'
import PatientFactory from './popupsComponents/PatientFactory'
import UserFactory from './popupsComponents/UserFactory'
import PartnerFactory from './popupsComponents/PartnerFactory'
import PricingPreviewFactory from './popupsComponents/PricingPreviewFactory'
import MaterialFinishingPricingFactory from './popupsComponents/MaterialFinishingPricingFactory'
import MaterialPricingFactory from './popupsComponents/MaterialPricingFactory'
import ColorFactory from './popupsComponents/ColorFactory'
import ValuationFactory from './popupsComponents/ValuationFactory'
import ValuationMarkupFactory from './popupsComponents/ValuationMarkupFactory'
import ValuationMarkupFactoryPreview from './popupsComponents/ValuationMarkupFactoryPreview'
import OrderFactory from './popupsComponents/OrderFactory'
import DoctorFactory from './popupsComponents/DoctorFactory'
import EmployeeFactory from './popupsComponents/EmployeeFactory'
import ContractFactory from './popupsComponents/EmployeeFactory'
import AdministratorsFactory from './popupsComponents/AdministratorsFactory'
import EventsFactory from './popupsComponents/EventsFactory'
import NotesFactory from './popupsComponents/NotesFactory'
import NotesPreview from './popupsComponents/NotesPreview'
import PartnerAddressFactory from './popupsComponents/PartnerAddressFactory'
import MaterialColorFactory from './popupsComponents/MaterialColorFactory'
import PriceListFactory from './popupsComponents/PriceListFactory'
import ColorItemsFactory from './popupsComponents/ColorItemsFactory'

const components = {
  PatientFactory,
  PartnerFactory,
  PricingPreviewFactory,
  MaterialFinishingPricingFactory,
  MaterialPricingFactory,
  UserFactory,
  ColorFactory,
  ColorItemsFactory,
  ValuationFactory,
  ValuationMarkupFactory,
  ValuationMarkupFactoryPreview,
  OrderFactory,
  DoctorFactory,
  EmployeeFactory,
  ContractFactory,
  AdministratorsFactory,
  EventsFactory,
  NotesFactory,
  NotesPreview,
  PartnerAddressFactory,
  MaterialColorFactory,
  PriceListFactory,
}

const PopupsHub = () => {
  const popups = useSelector((state) => state.popups.popups)
  return (
    <>
      {Object.keys(popups).map((key) => (
        <PopupsBase
          key={key}
          title={popups[key].title}
          popupKey={key}
          maxWidth={popups[key].maxWidth}
          level={popups[key].level}
        >
          {React.createElement(
            components[popups[key].component],
            popups[key].values
          )}
        </PopupsBase>
      ))}
    </>
  )
}

export default PopupsHub
