import { BrowserRouter as Router, Switch, Route } from 'react-router-dom'
import { useSelector } from 'react-redux'
import Login from '../pages/Login'
import LoginLayout from '../layout/layouts/LoginLayout'
import Register from '../pages/Register'
import PasswordReset from '../pages/PasswordReset'
import PasswordResetCode from '../pages/PasswordResetCode'
import B2BRoutes from './B2BRoutes'
import MESRoutes from './MESRoutes'
// import OutsideViewBox from './dataTable/boxes/OutsideViewBox'

const Routes = () => {
  const loaded = useSelector((state) => state.user.loaded)

  return loaded ? (
    <Router>
      <Switch>
        <Route path="/login">
          <LoginLayout>
            <Login />
          </LoginLayout>
        </Route>
        <Route path="/password/reset/:code">
          <LoginLayout>
            <PasswordResetCode />
          </LoginLayout>
        </Route>
        <Route exact path="/password/reset">
          <LoginLayout>
            <PasswordReset />
          </LoginLayout>
        </Route>
        <Route path="/register/:code">
          <LoginLayout>
            <Register />
          </LoginLayout>
        </Route>
        <Route path="/mes" component={MESRoutes} />
        <Route path="/" component={B2BRoutes} />
      </Switch>
    </Router>
  ) : (
    <>test</>
  )
}

export default Routes
