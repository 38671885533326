// import PropTypes from 'prop-types'
import { useFormik } from 'formik'
import {
  Typography,
  Grid,
  DialogActions,
  Button,
  DialogContent,
} from '@mui/material'
import regex from '../../../../regex'
import FormikControl from '../../../molecules/FormikControl'
// import FormikSelect from '../../../molecules/FormikSelect'
import SaveIcon from '@mui/icons-material/Save'
import * as Yup from 'yup'
import axios from 'axios'
import { handleOpenAlert } from '../../../../lib/handleAlert'
import { closePopup } from '../../../../store/popupsSlice'
import { refreshFilter } from '../../../../lib/handleFilters'
import { useDispatch } from 'react-redux'
// import { useEffect, useState } from 'react'

const phone_numberRegExp = /^(\+[0-9]{1,})?[ ]?([0-9]{3}[- ]?){3}$/

let lastCheckedEmail = {
  email: null,
  status: false,
}

async function checkEmail(email) {
  if (lastCheckedEmail['email'] != email && regex.email.test(email)) {
    lastCheckedEmail['email'] = email
    let res = await axios.post('/users/email/check', { email })
    lastCheckedEmail['status'] = !res.data.exist
    return !res.data.exist
  } else {
    return lastCheckedEmail['status']
  }
}

const AdministratorsFactory = ({ ...values }) => {
  const userId = values && values.id

  const initEmail = values.id && values.email

  const dispatch = useDispatch()

  const edit = async (values) => {
    await axios
      .post(`/admins/${userId}/edit`, {
        ...values,
        password: values.password !== '' ? values.password : null,
      })
      .then((res) => {
        if (res.status == 200) {
          dispatch(closePopup('admin-factory-popup'))
          refreshFilter('AdministratorsKey')
        }
        handleOpenAlert({
          title: 'Sukces!',
          subtitle: 'Edytowano administratora',
          type: 'success',
        })
      })
      .catch((err) => {
        handleOpenAlert(err.request)
      })
  }

  const store = async (values) => {
    await axios
      .post('/admins/store', {
        ...values,
      })
      .then((res) => {
        if (res.status == 200 || res.status == 201) {
          dispatch(closePopup('admin-factory-popup'))
          refreshFilter('AdministratorsKey')
        }
        handleOpenAlert({
          title: 'Sukces!',
          subtitle: 'Dodano administratora',
          type: 'success',
        })
      })
      .catch((err) => {
        handleOpenAlert(err.request)
      })
  }

  const formik = useFormik({
    initialValues: {
      phone_number: (values && values.phone_number) || '',
      firstname: values && values.firstname,
      lastname: values && values.lastname,
      email: values && values.email,
      password: '',
      passwordConfirmation: '',
      pwz: values && values.pwz,
    },
    enableReinitialize: false,
    validationSchema: Yup.object({
      email: Yup.string()
        .email('E-mail niepoprawny')
        .required('Pole wymagane')
        .test('email-unique', 'Podany email jest zajęty', (value) =>
          value && value.length > 2 && value != initEmail
            ? checkEmail(value)
            : true
        ),
      firstname: Yup.string().required('Pole wymagane'),
      lastname: Yup.string().required('Pole wymagane'),
      phone_number: Yup.string().matches(
        phone_numberRegExp,
        'Numer telefonu jest niepoprawny'
      ),
      password: userId
        ? Yup.string().min('8', 'Hasło musi zawierać minimum 8 znaków')
        : Yup.string()
            .required('Pole wymagane')
            .min('8', 'Hasło musi zawierać minimum 8 znaków'),
      passwordConfirmation: userId
        ? Yup.string()
            .oneOf(
              [Yup.ref('password'), null],
              'Hasło musi być takie samo w obu polach'
            )
            .min('8', 'Hasło musi zawierać minimum 8 znaków')
        : Yup.string()
            .required('Pole wymagane')
            .oneOf(
              [Yup.ref('password'), null],
              'Hasło musi być takie samo w obu polach'
            )
            .min('8', 'Hasło musi zawierać minimum 8 znaków'),
      pwz: Yup.number()
        .required('Pole wymagane')
        .test(
          'len',
          'Numer PWZ składa się maksymalnie z 7 cyfr',
          (val) => val && val.toString().length <= 7
        ),
    }),
    onSubmit: async (values) => {
      if (userId) {
        edit(values)
      } else {
        store(values)
      }
    },
  })

  return (
    <>
      <form onSubmit={formik.handleSubmit} onReset={formik.handleReset}>
        <DialogContent dividers={true}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Typography variant="subtitle1" component="div">
                    Dane osobowe
                  </Typography>
                </Grid>
                <Grid item md={6} xs={12}>
                  <FormikControl
                    variableName="firstname"
                    handleChange={formik.handleChange}
                    labelName="Imię"
                    formik={formik}
                  />
                </Grid>
                <Grid item md={6} xs={12}>
                  <FormikControl
                    variableName="lastname"
                    handleChange={formik.handleChange}
                    labelName="Nazwisko"
                    formik={formik}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Typography variant="subtitle1" component="div">
                    Dane kontaktowe
                  </Typography>
                </Grid>
                <Grid item md={6} xs={12}>
                  <FormikControl
                    type="tel"
                    variableName="phone_number"
                    handleChange={formik.handleChange}
                    labelName="Nr. Tel."
                    formik={formik}
                  />
                </Grid>
                <Grid item md={6} xs={12}>
                  <FormikControl
                    type="email"
                    variableName="email"
                    handleChange={formik.handleChange}
                    labelName="E-mail"
                    formik={formik}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Typography variant="subtitle1" component="div">
                    Dane administracyjne
                  </Typography>
                </Grid>
                <Grid item md={6} xs={12}>
                  <FormikControl
                    variableName="pwz"
                    handleChange={formik.handleChange}
                    labelName="Nr. PWZ"
                    formik={formik}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Typography variant="subtitle1" component="div">
                    Autoryzacja
                  </Typography>
                </Grid>
                <Grid item md={6} xs={12}>
                  <FormikControl
                    type="password"
                    variableName="password"
                    handleChange={formik.handleChange}
                    labelName="Hasło"
                    formik={formik}
                    inputProps={{
                      autoComplete: 'new-password',
                    }}
                  />
                </Grid>
                <Grid item md={6} xs={12}>
                  <FormikControl
                    inputProps={{
                      autoComplete: 'new-password',
                    }}
                    type="password"
                    variableName="passwordConfirmation"
                    handleChange={formik.handleChange}
                    labelName="Powtórz hasło"
                    formik={formik}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button
            startIcon={<SaveIcon />}
            variant="contained"
            color="primary"
            type="submit"
          >
            <span className="mt-1">Zapisz</span>
          </Button>
        </DialogActions>
      </form>
    </>
  )
}

// AdministratorsFactory.propTypes = {
//   AdministratorsFactoryProp: PropTypes.string,
// }

export default AdministratorsFactory
