import { InputAdornment, TableCell } from '@mui/material'
import Input from 'components/modules/Input/atoms/Input'
import PropTypes from 'prop-types'

const MaterialPricingListItemLinearMetersCellsForm = ({
  item,
  onFieldValueChange,
  errors = {},
}) => {
  return (
    <>
      <TableCell>
        <Input
          value={item.width}
          labelName={'Szerokość'}
          message={errors.width}
          onChange={(v) => onFieldValueChange('width', v)}
          InputProps={{
            endAdornment: <InputAdornment position="start">mm</InputAdornment>,
          }}
        />
      </TableCell>
    </>
  )
}
MaterialPricingListItemLinearMetersCellsForm.propTypes = {
  item: PropTypes.object.isRequired,
  onFieldValueChange: PropTypes.func.isRequired,
  errors: PropTypes.object,
}

export default MaterialPricingListItemLinearMetersCellsForm
