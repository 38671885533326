import PropTypes from 'prop-types'
import { Grid, Typography } from '@mui/material'
import { useContext, useEffect } from 'react'
import ValuationCalculatorLengthList from './Inputs/ValuationCalculatorLengthList'
// import { getDeepValue } from '../../../../lib/deepUtils'
import noImage from '../../../../../images/no_image.png'
import makeStyles from '@mui/styles/makeStyles'
import { useTranslation } from 'react-i18next'
import { ValuationCalculatorContext } from '../../../../../contexts/ValuationCalculatorContext'
import ValuationCalculatorLocationInput from './Inputs/ValuationCalculatorLocationInput'
import ValuationCalculatorMaterialInput from './Inputs/ValuationCalculatorMaterialInput'
import ValuationCalculatorColorInput from './Inputs/ValuationCalculatorColorInput'
import ValuationCalculatorThicknessInput from './Inputs/ValuationCalculatorThicknessInput'
import ValuationCalculatorExtrasInputs from './Inputs/ValuationCalculatorExtrasInputs'
import ValuationCalculatorQuantityInput from './Inputs/ValuationCalculatorQuantityInput'
import ValuationCalculatorWidthInput from './Inputs/ValuationCalculatorWidthInput'
import ValuationCalculatorColorItemInput from './Inputs/ValuationCalculatorColorItemInput'
import ValuationCalculatorDescriptionInput from '../inputs/ValuationCalculatorDescriptionInput'
import ValuationCalculatorAttachments from '../inputs/ValuationCalculatorAttachments'
const useStyles = makeStyles(() => ({
  overflowHiddenPretty: {
    overflowY: 'hidden',
    transition: 'all 0.5s ease-in-out',
  },
  imgWidth: {
    width: '300px',
    ['@media (max-width:1500px)']: {
      maxWidth: '200px',
    },
  },
}))

const ValuationCalculatorWindowsill = ({
  className,
  // breakpointWidth = 757,
}) => {
  const classes = useStyles()
  const { component, image, readOnly, withoutBlocks, getExtrasItems } =
    useContext(ValuationCalculatorContext)
  const { t } = useTranslation()

  useEffect(() => {
    getExtrasItems()
  }, [withoutBlocks])

  return (
    <>
      <Grid
        className={className}
        style={{ marginBottom: '28px' }}
        id="valuation-calculator-windowsill"
      >
        <>
          <Grid item xs={12}>
            <Typography variant="h6" component="div" className="mb-2">
              {withoutBlocks ? t('Finishes') : t('Windowsill')}
            </Typography>
          </Grid>
          <Grid className="relative" item xs={12}>
            <Grid
              className="fundamental-info"
              item
              style={{
                display: 'grid',
                justifySelf: 'stretch',
                justifyItems: 'stretch',
                alignItems: 'stretch',
                gridTemplateRows: 'auto',
                gridTemplateColumns: '2fr auto',
              }}
            >
              <div className="flex flex-wrap gap-2">
                <Grid
                  item
                  xs={12}
                  md={12}
                  lg={12}
                  className="flex gap-2 flex-wrap"
                >
                  <Grid item xs={12} md={12} lg={12} className="flex gap-2">
                    <Grid item xs={6} md={12} lg={12}>
                      <ValuationCalculatorLocationInput />{' '}
                    </Grid>
                    <Grid item xs={6} md={12} lg={12}>
                      <ValuationCalculatorMaterialInput />
                    </Grid>
                  </Grid>
                  <Grid item xs={12} md={12} lg={12} className="flex gap-2">
                    <Grid item xs={6} md={12} lg={12}>
                      <ValuationCalculatorColorInput />
                    </Grid>
                    <Grid item xs={6} md={12} lg={12}>
                      <ValuationCalculatorThicknessInput />
                    </Grid>
                  </Grid>
                  {!!component.color_group && (
                    <Grid item xs={12} md={12} lg={12} className="flex gap-2">
                      <Grid item md={12} lg={12}>
                        <ValuationCalculatorColorItemInput />
                      </Grid>
                    </Grid>
                  )}
                  {!withoutBlocks && (
                    <Grid item xs={12} md={12} lg={12} className="flex gap-2">
                      <Grid item xs={12} md={12} lg={12}>
                        <ValuationCalculatorWidthInput />
                      </Grid>
                      <Grid item xs={12} md={12} lg={12}>
                        <ValuationCalculatorQuantityInput />
                      </Grid>
                    </Grid>
                  )}
                </Grid>
              </div>
              <div
                className={`w-full max-h-36 flex justify-end img_custom:hidden pl-3 ${classes.overflowHiddenPretty}`}
                style={{
                  maxWidth: withoutBlocks ? '0' : '500px',
                }}
              >
                {image.value && image.type == 'hex' ? (
                  <div
                    style={{
                      width: '300px',
                      backgroundColor: image.value,
                    }}
                  ></div>
                ) : (
                  <img
                    style={{
                      border: '3px solid #bc2b2a',
                      maxHeight: '100%',
                      objectFit: 'none',
                    }}
                    className={classes.imgWidth}
                    src={
                      image
                        ? `${
                            //eslint-disable-next-line
                            process.env.REACT_APP_BASE_BACKEND_URL
                          }${image.value}`
                        : noImage
                    }
                    alt="Zdjęcie poglądowe"
                  />
                )}
              </div>
            </Grid>

            <Grid
              className={classes.overflowHiddenPretty}
              style={{
                maxHeight: withoutBlocks ? '0' : '1000px',
              }}
            >
              <Grid item xs={12}>
                <Typography variant="h6" component="div" className="mb-2 mt-2">
                  {t('valuation_popup.data.title.windowsill_parts')}
                </Typography>
              </Grid>
              <ValuationCalculatorLengthList
                component={component}
                disabled={
                  readOnly ||
                  !component.material_id ||
                  !component.color_id ||
                  !component.thickness_id ||
                  !component.width
                }
              />
            </Grid>
          </Grid>
          <Grid item xs={12}>
            {!withoutBlocks && (
              <Typography variant="h6" component="div" className="mb-2 mt-2">
                {t('Finishing for windowsill')}
              </Typography>
            )}
          </Grid>
          <ValuationCalculatorExtrasInputs />
          <div
            className={`${classes.overflowHiddenPretty}`}
            style={{
              maxHeight: withoutBlocks ? '0' : '500px',
            }}
          >
            <ValuationCalculatorDescriptionInput />
            <ValuationCalculatorAttachments />
          </div>
        </>
      </Grid>
    </>
  )
}

ValuationCalculatorWindowsill.propTypes = {
  className: PropTypes.string,
}

export default ValuationCalculatorWindowsill
