import React from 'react'
import { Card, CardContent, Grid, IconButton } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import PropTypes from 'prop-types'
import { useEffect, useState } from 'react'
import axios from 'axios'
import NoContent from '../../pages/NoContent'
import noImage from '../../../../images/brak-zdjecia.png'
import { useDispatch } from 'react-redux'
import { openPopup } from '../../../../store/popupsSlice'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCalculator } from '@fortawesome/pro-solid-svg-icons'
import { useTranslation } from 'react-i18next'
// import PropTypes from 'prop-types'
// import eventBus from '../../../../lib/eventBus'
// import { useHistory } from 'react-router-dom'

const useStyles = makeStyles((theme) => ({
  text: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'nowrap',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
  },
  [theme.breakpoints.between('xs', 'sm')]: {
    text: {
      display: 'flex',
      flexDirection: 'row',
      flexWrap: 'wrap',
      whiteSpace: 'normal',
      textOverflow: 'unset',
    },
    img: {
      height: 'auto',
    },
  },
  bold: {
    fontWeight: '700',
  },
  defaultBox: {
    display: 'flex',
    alignItems: 'center',
    flex: '0 0 50%',
    maxWidth: '50%',
    padding: '0.4rem',
    height: 'auto',
  },

  h100: {
    height: 'auto',
    display: 'flex',
    flexWrap: 'wrap',
    margin: '32px',
    background: '#fff',
    boxShadow:
      '0px 3px 1px -2px rgba(0,0,0,0.2),0px 2px 2px 0px rgba(0,0,0,0.14),0px 1px 5px 0px rgba(0,0,0,0.12)',
  },

  icon: {
    color: 'white',
  },

  [theme.breakpoints.down('xxl')]: {
    defaultBox: {
      flex: '0 0 50%',
      maxWidth: '50%',
      padding: '0.4rem',
    },
  },

  [theme.breakpoints.down('xl')]: {
    defaultBox: {
      flex: '0 0 50%',
      maxWidth: '50%',
      padding: '0.4rem',
    },
  },

  [theme.breakpoints.between('lg', 'xl')]: {
    defaultBox: {
      flex: '0 0 50%',
      maxWidth: '50%',
      padding: '0.4rem',
    },
  },

  [theme.breakpoints.down('lg')]: {
    defaultBox: {
      flex: '0 0 100%',
      maxWidth: '100%',
      padding: '0.4rem',
    },
  },

  [theme.breakpoints.down('md')]: {
    defaultBox: {
      flex: '0 0 100%',
      maxWidth: '100%',
      padding: '0.4rem',
    },
    h100: {
      margin: 'unset',
    },
    img: {
      height: 'auto !important',
    },
  },

  img: {
    minWidth: '50%',
    maxWidth: '50%',
    maxHeight: '250px',
    minHeight: '250px',
    objectFit: 'cover',
  },
}))

const OutsideViewBox = () => {
  const dispatch = useDispatch()
  const classes = useStyles()
  const { t } = useTranslation()

  const [materials, setMaterials] = useState(null)

  useEffect(() => {
    axios.post('/materials', null).then((res) => {
      setMaterials(res.data)
    })
  }, [])

  return (
    <>
      {materials && materials.length > 0 ? (
        <CardContent className={classes.defaultBoxContent + ' ' + classes.h100}>
          {materials.map((material, i) => (
            <div key={i} className={classes.defaultBox}>
              <div className="flex lg:flex-row md:flex-row sm:w-full sm:flex-col lg:flex-1 md:flex-1 lg_custom:h-full side_menu_nd_w:h-auto">
                <Card
                  elevation={5}
                  className="rounded-xl flex flex-row text-black lg:w-full md:w-full p-0 sm:whitespace-normal custom_card hover:opacity-50 transition-all cursor-pointer"
                  onClick={() =>
                    dispatch(
                      openPopup({
                        component: 'ValuationFactory',
                        title: t('valuation_popup.title_partner'),
                        level: 1,
                        maxWidth: 'xl',
                        key: 'valuation-factory-popup',
                        values: {
                          location_id: material.location_id,
                          material_id: material.id,
                        },
                      })
                    )
                  }
                >
                  <img
                    className={classes.img}
                    src={`${
                      material.image
                        ? `${
                            // eslint-disable-next-line
                          process.env.REACT_APP_BASE_BACKEND_URL
                          }${material.image}`
                        : noImage
                    }`}
                    alt="logo"
                  />
                  <div className="w-full flex side_menu_nd_w:flex-wrap relative">
                    <div
                      className="w-full pb-4"
                      style={{
                        fontSize: '1.5rem',
                      }}
                    >
                      <p
                        className="flex flex-column flex-shrink-0 m-4 flex-grow-0 text-custom_grey font-medium mb-2 !important"
                        style={{
                          fontSize: '2rem',
                        }}
                      >
                        {`${material.name}`}&nbsp;
                      </p>
                      <p className="flex flex-row flex-shrink-0 ml-4 flex-grow-0 text-custom_grey">
                        <p className="font-medium mr-2">
                          {t('home.tile.type')}:
                        </p>
                        {`${material.location_name}`}&nbsp;
                      </p>
                      <p className="flex flex-row flex-shrink-0 ml-4 flex-grow-0 text-custom_grey">
                        <p className="font-medium mr-2">
                          {t('home.tile.unit')}:
                        </p>
                        {`${material.unit_name}`}&nbsp;
                      </p>
                      <p className="flex flex-row flex-shrink-0 ml-4 flex-grow-0 text-custom_grey">
                        <p className="font-medium mr-2">
                          {t('home.tile.max_length')}:
                        </p>
                        {`${material.max_length}`}&nbsp;
                      </p>
                    </div>
                  </div>
                </Card>
              </div>
            </div>
          ))}
        </CardContent>
      ) : (
        <NoContent loading={!materials} />
      )}
    </>
  )
}

OutsideViewBox.propTypes = {
  labels: PropTypes.array,
  cells: PropTypes.object,
  keys: PropTypes.array,
  actions: PropTypes.string,
  row: PropTypes.object,
}

export default OutsideViewBox
