const useSaveToLS = () => {
  const saveToLS = (key, value) => {
    const prev = localStorage.getItem(key)
    const actual = JSON.stringify(value)
    if (actual && prev !== actual) {
      localStorage.setItem(key, JSON.stringify(value))
    }
  }
  return saveToLS
}

export default useSaveToLS
