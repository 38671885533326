import { Grid, IconButton } from '@mui/material'
import { Delete, Edit } from '@mui/icons-material'
import { useDispatch, useSelector } from 'react-redux'
import PropTypes from 'prop-types'
import { openPopup } from '../../../../store/popupsSlice'
import SwitchAccessShortcutIcon from '@mui/icons-material/SwitchAccessShortcut'
import axios from 'axios'
import { useState } from 'react'
import { handleOpenAlert } from '../../../../lib/handleAlert'
import { refreshFilter } from '../../../../lib/handleFilters'
import Confirmation from '../../popups/Confirmation'
import RestoreFromTrashIcon from '@mui/icons-material/RestoreFromTrash'
import eventBus from '../../../../lib/eventBus'

const PartnerAddressPreviewActions = ({ row }) => {
  const dispatch = useDispatch()
  const user = useSelector((state) => state.user.user)
  const [confirmation, setConfirmation] = useState(false)

  const setMain = () => {
    axios
      .patch(`/partners/${row.partner_id}/addresses/${row.id}/main`)
      .then(() => {
        handleOpenAlert({
          title: 'Sukces!',
          subtitle: `Zmieniono główny adres`,
          type: 'success',
        })
        eventBus.dispatch('loadPartner', { partnerId: row.partner_id })
        refreshFilter('PartnerAddressesPreview')
      })
  }

  return (
    <Grid
      container
      alignItems="center"
      justifyContent="center"
      direction="row"
      spacing={1}
      className="table-actions"
    >
      {confirmation && (
        <Confirmation
          level={5}
          maxWidth="xs"
          icon="Warning"
          title={`Czy na pewno chcesz ${
            row.active ? 'usunąć' : 'przywrócić'
          } adres?`}
          subtitle={`Tej operacji nie można cofnąć.`}
          reject={() => {
            setConfirmation(false)
          }}
          success={() => {
            axios[row.active ? 'delete' : 'patch'](
              `/partners/${row.partner_id}/addresses/${row.id}`
            )
              .then(() => {
                handleOpenAlert({
                  title: 'Sukces!',
                  subtitle: `${row.active ? 'Usunięto' : 'Przywrócono'} adres`,
                  type: 'success',
                })
                refreshFilter('PartnerAddressesPreview')
                setConfirmation(false)
              })
              .catch((err) => {
                handleOpenAlert(err.request)
              })
          }}
        />
      )}
      {!!row.active &&
        user.permissions &&
        user.permissions.includes('edit User') && (
          <>
            {!row.main && (
              <Grid item>
                <IconButton
                  title={'Ustaw jako główny address'}
                  size="small"
                  onClick={() => setMain()}
                >
                  <SwitchAccessShortcutIcon />
                </IconButton>
              </Grid>
            )}
            <Grid item>
              <IconButton
                title="Edytuj address"
                size="small"
                onClick={() =>
                  dispatch(
                    openPopup({
                      component: 'PartnerAddressFactory',
                      title: 'Edytuj adres',
                      level: 1,
                      maxWidth: 'sm',
                      key: 'partner-adddress-popup',
                      values: {
                        id: row.id,
                        partner_id: row.partner_id,
                      },
                    })
                  )
                }
              >
                <Edit fontSize="small" />
              </IconButton>
            </Grid>
          </>
        )}
      {!row.main &&
        user.permissions &&
        user.permissions.includes('remove User') && (
          <Grid item>
            <IconButton
              title={`${row.active ? 'Przywróć' : 'Usuń'}`}
              size="small"
              onClick={() => setConfirmation(true)}
            >
              {row.active ? (
                <Delete fontSize="small" color="error" />
              ) : (
                <RestoreFromTrashIcon fontSize="small" color="success" />
              )}
            </IconButton>
          </Grid>
        )}
    </Grid>
  )
}

PartnerAddressPreviewActions.propTypes = {
  row: PropTypes.object,
}

export default PartnerAddressPreviewActions
