import React from 'react'
import { useSelector } from 'react-redux'
import makeStyles from '@mui/styles/makeStyles'
import Alert from './Alert'

const useStyles = makeStyles((theme) => ({
  root: {
    zIndex: '160000',
    position: 'absolute',
    display: 'flex',
    flexDirection: 'column',
    top: theme.spacing(4),
    left: '50%',
    transform: 'translateX(-50%)',
  },
  alert: {
    width: '100%',
    '& > * + *': {
      marginTop: theme.spacing(2),
    },
  },
}))

const AlertsHub = () => {
  const classes = useStyles()

  const alerts = useSelector((state) => state.alerts.alerts)
  return (
    <div className={classes.root}>
      {Object.keys(alerts).map((key) => (
        <Alert
          key={key}
          className={classes.alert}
          title={alerts[key].title}
          subtitle={alerts[key].subtitle}
          type={alerts[key].type}
        />
      ))}
    </div>
  )
}

export default AlertsHub
