import React from 'react'
import { Card, CardContent } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import PropTypes from 'prop-types'
import PartnersTableActions from '../actions/PartnersTableActions'
import UsersTableActions from '../actions/UsersTableActions'
import MaterialsTableActions from '../actions/MaterialsTableActions'
import ValuationsTableActions from '../actions/ValuationsTableActions'
import OrderTableActions from '../actions/OrderTableActions'
import AdminsTableActions from '../actions/AdminsTableActions'
import PartnerValuesPreviewActions from '../actions/PartnerValuesPreviewActions'
import PatientsListActions from '../actions/PatientsListActions'
import PatientsDataActions from '../actions/PatientsDataActions'
import ContractsTableActions from '../actions/ContractsTableActions'
import ExaminationsTableActions from '../actions/ExaminationsTableActions'
import ReadMore from '../boxes/ReadMore'
import EventsTableActions from '../actions/EventsTableActions'
import PartnerEmployeePreviewActions from '../actions/PartnerEmployeePreviewActions'
import PartnerAddressPreviewActions from '../actions/PartnerAddressPreviewActions'
import MaterialColorsPreviewActions from '../actions/MaterialColorsPreviewActions'
import MaterialFinishingPreviewActions from '../actions/MaterialFinishingPreviewActions'
import ColorsTableActions from '../actions/ColorsTableActions'
import PriceListsActions from '../actions/PriceListsActions'

const useStyles = makeStyles((theme) => ({
  text: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'nowrap',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
  },
  [theme.breakpoints.between('xs', 'sm')]: {
    text: {
      display: 'flex',
      flexDirection: 'row',
      flexWrap: 'wrap',
      whiteSpace: 'normal',
      textOverflow: 'unset',
    },
  },
  bold: {
    fontWeight: '700',
  },
  defaultBox: {
    display: 'inline-block',
    width: '33.333%',
  },

  h100: {
    height: '100%',
  },

  [theme.breakpoints.down('xl')]: {
    defaultBox: {
      width: '33.333%',
    },
  },

  [theme.breakpoints.down('xl')]: {
    defaultBox: {
      width: '50%',
    },
  },

  [theme.breakpoints.down('lg')]: {
    defaultBox: {
      width: '50%',
    },
  },

  [theme.breakpoints.down('md')]: {
    defaultBox: {
      width: '100%',
    },
  },
}))

const components = {
  PartnersTableActions: PartnersTableActions,
  UsersTableActions: UsersTableActions,
  MaterialsTableActions: MaterialsTableActions,
  ValuationsTableActions: ValuationsTableActions,
  OrderTableActions: OrderTableActions,
  AdminsTableActions: AdminsTableActions,
  PartnerValuesPreviewActions: PartnerValuesPreviewActions,
  PatientsDataActions: PatientsDataActions,
  PatientsListActions: PatientsListActions,
  ContractsTableActions: ContractsTableActions,
  ExaminationsTableActions: ExaminationsTableActions,
  EventsTableActions: EventsTableActions,
  PartnerEmployeePreviewActions: PartnerEmployeePreviewActions,
  PartnerAddressPreviewActions: PartnerAddressPreviewActions,
  MaterialColorsPreviewActions: MaterialColorsPreviewActions,
  MaterialFinishingPreviewActions: MaterialFinishingPreviewActions,
  ColorsTableActions: ColorsTableActions,
  PriceListsActions: PriceListsActions,
  // DefaultBox: DefaultBox,
}

const DefaultBox = ({ cells, keys, labels, actions, rowComponents = {} }) => {
  const classes = useStyles()

  return (
    <div className={classes.defaultBox}>
      <Card
        elevation={0}
        square
        className={'overflow-visible !important ' + classes.h100}
      >
        {/* <div className={classes.defaultBoxHeaderButton}></div> */}
        <CardContent className={classes.defaultBoxContent + ' ' + classes.h100}>
          <div className="flex lg:flex-row md:flex-row sm:flex-col lg:flex-1 md:flex-1 gap-4 h-full">
            <Card
              elevation={5}
              square
              className="flex flex-col h-full text-black lg:w-full md:w-full p-4 sm:whitespace-normal custom_card"
            >
              <>
                <div className="flex items-end gap-1">
                  {components[actions] &&
                    actions &&
                    React.createElement(components[actions], { row: cells })}
                </div>
                <div className="w-full border-b-2 border-custom_grey_bg mt-2 mb-2 flex-initial"></div>
                {labels.map((label, i) => (
                  <div key={i}>
                    <div className="flex flex-row flex-wrap justify-self-stretch justify-center text-center">
                      {rowComponents[keys[i]] ? (
                        React.createElement(rowComponents[keys[i]], {
                          row: cells,
                          keyName: keys[i],
                        })
                      ) : (
                        <>
                          <p className="flex flex-row flex-shrink-0 flex-grow-0 text-custom_grey">
                            {label}:&nbsp;
                          </p>
                          <ReadMore
                            maxLength={15}
                            text={cells[keys[i]] ? cells[keys[i]] : ''}
                            style={{ fontWeight: 'bold' }}
                          />
                        </>
                      )}
                    </div>
                  </div>
                ))}
              </>
            </Card>
          </div>
        </CardContent>
      </Card>
    </div>
  )
}

DefaultBox.propTypes = {
  labels: PropTypes.array,
  cells: PropTypes.object,
  rowComponents: PropTypes.object,
  keys: PropTypes.array,
  actions: PropTypes.string,
}

export default DefaultBox
