import { useRef } from 'react'
import { makeStyles, useTheme } from '@mui/styles'
import { Box, TextField, ButtonBase, IconButton, Grid } from '@mui/material'
import PropTypes from 'prop-types'
import { Delete, Download } from '@mui/icons-material'
import { handleOpenAlert } from '../../lib/handleAlert'
import downloadFile from '../../lib/downloadFile'

const useStyles = makeStyles(() => ({
  field: {
    '& .MuiFormLabel-root.Mui-disabled': {
      color: 'black',
    },
  },
  button: {
    width: '100%',
    height: '100%',
    overflow: 'hidden',
    borderWidth: '1px',
    borderRadius: '4px',
    borderColor: 'rgba(0, 0, 0, 0.23)',
    borderStyle: 'solid',
  },
  file: {
    display: 'flex',
    alignItems: 'center',
    flex: '1 1 100%',
    width: 'fit-content',
  },
}))
const FileInput = ({
  labelName,
  label,
  formik,
  error,
  disabled = false,
  maxMBSize = 10,
  handleChange,
  edit,
  downloadRoute,
  delEdit,
  availableExtensions = [],
}) => {
  const ref = useRef()
  const theme = useTheme()
  const classes = useStyles()

  const download = () => {
    downloadFile({
      route: downloadRoute(edit),
      fileName: edit.file_name,
    })
  }

  const validExt = (ext) => {
    ext = ext.toLowerCase()
    for (const availableExtension of availableExtensions) {
      if (availableExtension.toLowerCase() == ext) {
        return true
      }
    }
    return false
  }

  const localHandleChange = (event) => {
    const files = Array.from(event.target.files)
    const [file] = files
    if (file) {
      let ext = file.name.split('.').pop()
      if (maxMBSize && maxMBSize * 1048576 < file.size) {
        handleOpenAlert({
          title: 'Za duży plik!',
          subtitle: `Maksymalny rozmiar pliku: ${maxMBSize}MB`,
          type: 'warning',
        })
      } else if (availableExtensions.length && !validExt(ext)) {
        handleOpenAlert({
          title: 'Błędne rozszerzenie!',
          subtitle: `Możliwe rozszerzenia: ${availableExtensions.join(',')}`,
          type: 'warning',
        })
      } else {
        if (handleChange) {
          handleChange(file)
        } else {
          formik.setFieldValue(labelName, file)
        }
      }
    } else {
      if (handleChange) {
        handleChange(null)
      } else {
        formik.setFieldValue(labelName, null)
      }
    }
  }

  return (
    <Box
      position="relative"
      width={'100%'}
      height={60}
      color={error ? theme.palette.error.main : theme.palette.background.paper}
      borderBottom={4}
    >
      {edit ? (
        <Box position="absolute" top={8} bottom={0} left={0} right={0} mx={2}>
          <Grid item className={classes.file}>
            <TextField
              variant="standard"
              className={classes.field}
              InputProps={{ disableUnderline: true }}
              margin="normal"
              fullWidth
              disabled
              label={label}
              value={edit.file_name}
            />
            {downloadRoute && (
              <IconButton
                component="span"
                size="large"
                onClick={() => {
                  download()
                }}
              >
                <Download />
              </IconButton>
            )}
            {!disabled && (
              <IconButton
                color="error"
                component="span"
                size="large"
                onClick={() => {
                  delEdit(edit.file_id)
                }}
              >
                <Delete />
              </IconButton>
            )}
          </Grid>
        </Box>
      ) : (
        <>
          <Box position="absolute" top={8} bottom={0} left={0} right={0} mx={2}>
            <TextField
              variant="standard"
              className={classes.field}
              InputProps={{ disableUnderline: true }}
              margin="normal"
              fullWidth
              disabled
              label={label}
              value={formik.values[labelName]?.name || ''}
              error={!!error}
              helperText={error?.message || ' '}
            />
          </Box>
          <ButtonBase
            className={classes.button}
            disabled={disabled}
            component="label"
            onKeyDown={(e) => e.keyCode === 32 && ref.current?.click()}
          >
            <input
              ref={ref}
              type="file"
              accept="image/*"
              hidden
              onChange={localHandleChange}
            />
          </ButtonBase>
        </>
      )}
    </Box>
  )
}

FileInput.propTypes = {
  labelName: PropTypes.string,
  label: PropTypes.string,
  formik: PropTypes.object,
  maxMBSize: PropTypes.number,
  error: PropTypes.bool,
  edit: PropTypes.object,
  delEdit: PropTypes.func,
  downloadRoute: PropTypes.func,
  disabled: PropTypes.bool,
  handleChange: PropTypes.func,
  availableExtensions: PropTypes.array,
}

export default FileInput
