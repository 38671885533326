import PageHeader from 'components/organisms/layout/PageHeader'
import ConstructionIcon from '@mui/icons-material/Construction'
import DataTable from 'components/organisms/dataTable/DataTable'
import HomeIcon from '@mui/icons-material/Home'
// import { useSelector } from 'react-redux'
import { Card, CardContent, Button } from '@mui/material'
import AddIcon from '@mui/icons-material/Add'
import PageContainer from 'components/organisms/layout/PageContainer'
import StoreKeyProvider from 'components/providers/StoreKeyProvider'
import WithFilters from 'components/hocs/WithFilters'
import DataInputs from 'components/organisms/dataTable/DataInputs'
import { useDispatch, useSelector } from 'react-redux'
import { openPopup } from 'store/popupsSlice'
import { useEffect, useState } from 'react'
import axios from 'axios'
import makeStyles from '@mui/styles/makeStyles'

const useStyles = makeStyles(() => ({
  bg: {
    background: 'transparent',
    overflow: 'unset',
  },
  content: {
    padding: '24px',
    background: '#fff',
    marginBottom: '24px',
    borderRadius: '2px',
    boxShadow:
      '0px 3px 1px -2px rgba(0,0,0,0.2),0px 2px 2px 0px rgba(0,0,0,0.14),0px 1px 5px 0px rgba(0,0,0,0.12)',
  },
  contentSt: {
    background: '#fff',
    marginBottom: '24px',
    borderRadius: '2px',
    boxShadow:
      '0px 3px 1px -2px rgba(0,0,0,0.2),0px 2px 2px 0px rgba(0,0,0,0.14),0px 1px 5px 0px rgba(0,0,0,0.12)',
    position: 'relative',
  },
}))

const Materials = () => {
  const user = useSelector((state) => state.user.user)
  const dispatch = useDispatch()
  const [types, setType] = useState([])
  const [units, setUnit] = useState([])
  const classes = useStyles()

  useEffect(() => {
    axios.post('/lookups/locations', null).then((res) => {
      setType(res.data)
    }),
      axios.post('/lookups/units', null).then((res) => {
        setUnit(res.data)
      })
  }, [])

  return (
    <div>
      <>
        {user.role_id == 1 ? (
          <PageHeader
            icon={<ConstructionIcon fontSize="medium" />}
            title="Lista materiałów"
          />
        ) : (
          <PageHeader icon={<HomeIcon fontSize="medium" />} title="Home" />
        )}
      </>
      <PageContainer>
        <StoreKeyProvider storeKey="MaterialsKey">
          <WithFilters
            config={{
              page: 1,
              paginate: 25,
              orderType: 'desc',
              order: 'name',
              inputs: {
                name: {
                  value: '',
                  relation: 'LIKE',
                },
                location_id: {
                  value: '',
                  relation: 'WHERE',
                },
                unit_id: {
                  value: '',
                  relation: 'WHERE',
                },
                min_max_length: {
                  value: null,
                  relation: 'MIN',
                },
                max_max_length: {
                  value: null,
                  relation: 'MAX',
                },
                active: {
                  value: 1,
                  relation: 'WHERE',
                },
              },
            }}
            endpoint="/materials"
          >
            <Card elevation={0} className={classes.bg}>
              <CardContent className={classes.contentSt}>
                {types && units && (
                  <DataInputs
                    inputs={[
                      {
                        id: 'name',
                        label: 'Nazwa',
                        placeholder: '',
                        type: 'text',
                      },
                      {
                        id: 'location_id',
                        label: 'Typ',
                        placeholder: '',
                        type: 'select',
                        options: types,
                      },
                      {
                        id: 'unit_id',
                        label: 'Jednostka',
                        placeholder: '',
                        type: 'select',
                        options: units,
                        config: {
                          key: 'id',
                          label: 'name',
                        },
                      },
                      {
                        id: 'max_length',
                        label: 'Długość',
                        placeholder: '',
                        type: 'number-between',
                      },
                      {
                        id: 'active',
                        label: 'Aktywne',
                        placeholder: '',
                        type: 'select',
                        options: [
                          {
                            id: 1,
                            name: 'Tak',
                          },
                          {
                            id: 0,
                            name: 'Nie',
                          },
                        ],
                      },
                    ]}
                  />
                )}
              </CardContent>
              <CardContent className={classes.content}>
                <DataTable
                  breakpoint={1484}
                  actions="MaterialsTableActions"
                  labels={['Nazwa', 'Typ', 'Jednostka', 'Maksymalna długość']}
                  keys={['name', 'location_name', 'unit_name', 'max_length']}
                />
              </CardContent>
            </Card>
          </WithFilters>
        </StoreKeyProvider>
      </PageContainer>
    </div>
  )
}

export default Materials
