import PageHeader from 'components/organisms/layout/PageHeader'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBoxOpen } from '@fortawesome/pro-solid-svg-icons'
import makeStyles from '@mui/styles/makeStyles'
import PageContainer from 'components/organisms/layout/PageContainer'
import ValuationTable from 'components/molecules/valuations/ValuationTable'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
// import AddIcon from '@mui/icons-material/Add'
// import { useDispatch, useSelector } from 'react-redux'
// import { openPopup } from '../../../store/popupsSlice'

const useStyles = makeStyles(() => ({
  icon: {
    width: '24px !important',
    height: '24px',
  },
  bg: {
    background: 'transparent',
    overflow: 'unset',
  },
  content: {
    padding: '24px',
    background: '#fff',
    marginBottom: '24px',
    borderRadius: '2px',
    boxShadow:
      '0px 3px 1px -2px rgba(0,0,0,0.2),0px 2px 2px 0px rgba(0,0,0,0.14),0px 1px 5px 0px rgba(0,0,0,0.12)',
  },
  contentSt: {
    background: '#fff',
    marginBottom: '24px',
    borderRadius: '2px',
    boxShadow:
      '0px 3px 1px -2px rgba(0,0,0,0.2),0px 2px 2px 0px rgba(0,0,0,0.14),0px 1px 5px 0px rgba(0,0,0,0.12)',
    position: 'relative',
  },
}))

const Orders = () => {
  // const dispatch = useDispatch()
  // const user = useSelector((state) => state.user.user)
  const classes = useStyles()
  const user = useSelector((state) => state.user.user)
  const { t } = useTranslation()

  const getToExclude = () => {
    let toExclude = []
    if (user.role_id == 2) {
      toExclude.push('custom')
      if (user.partner_id) {
        toExclude.push('partner_name')
        toExclude.push('user_name')
        toExclude.push('is_custom')
      }
      if (user.user_id) {
        toExclude.push('user_name')
      }
    } else {
      toExclude.push('name')
    }
    return toExclude
  }

  return (
    <div>
      <PageHeader
        icon={<FontAwesomeIcon icon={faBoxOpen} className={classes.icon} />}
        title="Lista zamówień"
      />
      <PageContainer>
        <ValuationTable
          customKey="OrdersKey"
          endpoint="/orders"
          showAdd={user.role_id == 1 ? t('valuation.actions.add_order') : null}
          actions="OrderTableActions"
          excludeFilters={getToExclude()}
          isOrders
        />
      </PageContainer>
    </div>
  )
}

export default Orders
