import PropTypes from 'prop-types'
import React from 'react'
import { useFormik } from 'formik'
import { refreshFilter } from '../../../../lib/handleFilters'
import eventBus from '../../../../lib/eventBus'
import {
  Typography,
  Grid,
  DialogActions,
  DialogContent,
  InputAdornment,
  // CircularProgress,
} from '@mui/material'
import FormikControl from '../../../molecules/FormikControl'
import FormikSelect from '../../../molecules/FormikSelect'
import SaveIcon from '@mui/icons-material/Save'
import * as Yup from 'yup'
import axios from 'axios'
import { handleOpenAlert } from '../../../../lib/handleAlert'
import { closePopup } from '../../../../store/popupsSlice'
import { useDispatch } from 'react-redux'
import { useState, useEffect } from 'react'
import LoadingButton from '@mui/lab/LoadingButton'

const MaterialFactory = ({ ...values }) => {
  const dispatch = useDispatch()

  const material_id = values.id

  const [types, setType] = useState([])
  const [units, setUnit] = useState([])

  useEffect(() => {
    axios.post('/lookups/locations', null).then((res) => {
      setType(res.data)
    }),
      axios.post('/lookups/units', null).then((res) => {
        setUnit(res.data)
      })
  }, [])

  const formik = useFormik({
    initialValues: {
      name: values.name,
      location_id: values.location_id,
      unit_id: values.unit_id,
      max_length: values.max_length,
    },
    enableReinitialize: false,
    validationSchema: Yup.object({
      name: Yup.string().required('Pole wymagane'),
      location_id: Yup.string().required('Pole wymagane'),
      unit_id: Yup.string().required('Pole wymagane'),
      max_length: Yup.number()
        .min(0, 'Maksymalna długość musi być dodatnie.')
        .required('Pole wymagane'),
    }),
    onSubmit: async (values) => {
      edit(values)
      setLoading(true)
    },
  })

  const edit = (values) => {
    axios
      .post(`/materials/${material_id}/edit`, {
        ...values,
      })
      .then((res) => {
        if (res.status == 200 || res.status == 201) {
          dispatch(closePopup('material-factory-popup'))
          refreshFilter('MaterialsKey')
          eventBus.dispatch('refreshPatient', { material_id })
        }
        handleOpenAlert({
          title: 'Sukces!',
          subtitle: 'Edytowano materiał',
        })
      })
      .catch((err) => {
        handleOpenAlert(err.request.status)
        setLoading(false)
      })
  }

  const [loading, setLoading] = useState(false)

  return (
    <>
      <form onSubmit={formik.handleSubmit} onReset={formik.handleReset}>
        <>
          <DialogContent dividers={true}>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <Typography variant="subtitle1" component="div">
                      Materiał
                    </Typography>
                  </Grid>
                  <Grid item md={6} xs={12}>
                    <FormikControl
                      variableName="name"
                      handleChange={formik.handleChange}
                      labelName="Nazwa"
                      formik={formik}
                    />
                  </Grid>
                  {types && (
                    <Grid item md={6} xs={12}>
                      <FormikSelect
                        variableName="location_id"
                        value={formik.values['location_id']}
                        labelName="Typ"
                        formik={formik}
                        options={types}
                      />
                    </Grid>
                  )}
                  {units && (
                    <Grid item md={6} xs={12}>
                      <FormikSelect
                        variableName="unit_id"
                        value={formik.values['unit_id']}
                        labelName="Jednostka"
                        formik={formik}
                        options={units}
                      />
                    </Grid>
                  )}
                  <Grid item md={6} xs={12}>
                    <FormikControl
                      variableName="max_length"
                      handleChange={formik.handleChange}
                      labelName="Maksymalna długość"
                      type="number"
                      formik={formik}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="start">mb</InputAdornment>
                        ),
                      }}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <LoadingButton
              startIcon={<SaveIcon />}
              variant="contained"
              color="primary"
              type="submit"
              // onClick={setLoading}
              loading={loading}
              loadingPosition="start"
            >
              <span className="mt-1">Zapisz</span>
            </LoadingButton>
          </DialogActions>
        </>
      </form>
    </>
  )
}

MaterialFactory.propTypes = {
  values: PropTypes.object,
}

export default MaterialFactory
