import { useContext } from 'react'
import { useTranslation } from 'react-i18next'
import { ValuationCalculatorContext } from '../../../../../../contexts/ValuationCalculatorContext'
import FormInput from '../../../../inputs/FormInput'
import { InputAdornment } from '@mui/material'

const ValuationCalculatorCustomLengthInput = () => {
  const { blocks, setBlocks, readOnly, validate } = useContext(
    ValuationCalculatorContext
  )

  const { t } = useTranslation()

  const changeValue = (value) => {
    blocks[0].length = value
    setBlocks(blocks)
  }

  const block = blocks ? blocks[0] : null

  return (
    <>
      {block && (
        <FormInput
          type="number"
          disabled={readOnly}
          handleChange={(v) => {
            changeValue(v)
          }}
          min="0"
          name="length"
          value={block.length}
          helperText={
            validate &&
            ((!block.length && t('main.validation.required')) ||
              (block.length < 100 &&
                t('main.validation.min_value', { min: 100 })))
          }
          error={true}
          labelName={t('valuation_popup.data.input.length')}
          InputProps={{
            endAdornment: <InputAdornment position="start">mm</InputAdornment>,
          }}
        />
      )}
    </>
  )
}
ValuationCalculatorCustomLengthInput.propTypes = {}
export default ValuationCalculatorCustomLengthInput
