import { TableCell } from '@mui/material'
import Autocomplete from 'components/modules/Input/atoms/Autocomplete'
import PropTypes from 'prop-types'

const MaterialPricingListItemSquareMetersCellsForm = ({
  item,
  onFieldValueChange,
  thickness = [],
  errors = {},
}) => {
  return (
    <>
      <TableCell>
        <Autocomplete
          value={item.thickness_id}
          labelName={'Grubość'}
          itemValue="id"
          message={errors.thickness_id}
          onChange={(v) => onFieldValueChange('thickness_id', v)}
          options={thickness}
        />
      </TableCell>
    </>
  )
}
MaterialPricingListItemSquareMetersCellsForm.propTypes = {
  item: PropTypes.object.isRequired,
  onFieldValueChange: PropTypes.func.isRequired,
  thickness: PropTypes.array,
  errors: PropTypes.object,
}

export default MaterialPricingListItemSquareMetersCellsForm
