import { configureStore, getDefaultMiddleware } from '@reduxjs/toolkit'
import userSlice from './userSlice'
import filtersSlice from './filtersSlice'
import layoutSlice from './layoutSlice'
import popupsSlice from './popupsSlice'
import languageSlice from './languageSlice'
import valuationSlice from './valuationSlice'
import alertsSlice from './alertsSlice'
import downloadsSlice from './downloadsSlice'
import mainSlice from './mainSlice'

export default configureStore({
  reducer: {
    filters: filtersSlice,
    layout: layoutSlice,
    popups: popupsSlice,
    language: languageSlice,
    valuation: valuationSlice,
    alerts: alertsSlice,
    user: userSlice,
    main: mainSlice,
    downloads: downloadsSlice,
  },
  middleware: getDefaultMiddleware({
    serializableCheck: false,
  }),
})
