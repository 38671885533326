import PropTypes from 'prop-types'
import makeStyles from '@mui/styles/makeStyles'

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(4),
    display: 'flex',
    gap: theme.spacing(4),
    flexDirection: 'column',
  },

  [theme.breakpoints.down('md')]: {
    root: {
      padding: 'unset',
    },
  },
}))

const PageContainer = ({ children }) => {
  const classes = useStyles()

  return <section className={classes.root}>{children}</section>
}

PageContainer.propTypes = {
  children: PropTypes.any,
}

export default PageContainer
