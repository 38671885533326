import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router'
import { Formik } from 'formik'
import * as Yup from 'yup'
import { Typography } from '@mui/material'
import FormikControl from '../../molecules/FormikControl'
import { Card } from '@mui/material'
import { Link } from 'react-router-dom'
import makeStyles from '@mui/styles/makeStyles'
import LogoAmbit from '../../../images/logo-ambit.png'
import { handleOpenAlert } from '../../../lib/handleAlert'
import axios from 'axios'
import { useHistory } from 'react-router-dom'
import LoadingButton from '@mui/lab/LoadingButton'
import { useTranslation } from 'react-i18next'
import LanguagePicker from '../translate/LanguagePicker'

const useStyles = makeStyles((theme) => ({
  nested: {
    padding: theme.spacing(10),
  },
  listItem: {
    color: theme.palette.white.main,
  },
  logo: {
    maxWidth: '123px',
  },

  error: {
    color: theme.palette.error.main,
  },

  paperFront: {
    position: 'relative',
    maxWidth: '540px',
    maxHeight: '450px',
    width: '100',
    height: '100%',
    boxShadow: '-55px 40px 2px 0px rgb(218, 218, 218)',
    [theme.breakpoints.down('md')]: {
      width: '100%',
      boxShadow: 'unset',
      maxHeight: '474px',
    },
  },

  formBg: {
    height: '100vh',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    gap: '24px',
    backgroundColor: theme.palette.secondary.mainNd,
    padding: theme.spacing(4),
    [theme.breakpoints.down('md')]: {
      padding: theme.spacing(2),
    },
  },
  form: {
    height: '100%',
    width: '100%',
    maxWidth: '600px',
    maxHeight: '490px',
    display: 'flex',
    gap: '18px',
    flexDirection: 'column',
    justifyContent: 'center',
    padding: theme.spacing(4),
    [theme.breakpoints.down('md')]: {
      maxWidth: 'unset',
    },
  },
  languagePicker: {
    position: 'absolute',
    top: '5px',
    right: '5px',
  },
  btn: {
    background: theme.palette.primary.main,
    width: '100%',
    marginTop: '8px',
    [theme.breakpoints.down('md')]: {
      marginTop: 'unset',
    },
  },
}))

const PasswordResetCode = () => {
  const classes = useStyles()
  const { code } = useParams()
  const history = useHistory()
  const { t } = useTranslation()

  const [validCode, setValidCode] = useState(false)
  const [loaded, setLoaded] = useState(false)
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    if (code) {
      checkCode(code)
    }
  }, [code])

  const checkCode = (code) => {
    axios
      .post(
        '/auth/password/reset/check',
        { token: code },
        { headers: { ignoreAlerts: true } }
      )
      .then(() => {
        setValidCode(true)
        setLoaded(true)
      })
      .catch(() => {
        handleOpenAlert({
          title: 'Uwaga!',
          subtitle: t('main.alert.link_not_valid'),
          type: 'error',
        })
        history.push('/login')
      })
  }

  return (
    <>
      <Formik
        initialValues={{ password: '', passwordConfirmation: '' }}
        validationSchema={Yup.object({
          password: Yup.string()
            .min(8, t('main.validation.password_min', { min: 8 }))
            .required(t('main.validation.required')),
          passwordConfirmation: Yup.string()
            .min(8, t('main.validation.password_min', { min: 8 }))
            .oneOf(
              [Yup.ref('password'), null],
              t('main.validation.password_exact')
            )
            .required(t('main.validation.required')),
        })}
        onSubmit={async (values) => {
          setLoading(true)
          axios
            .post('/auth/password/reset/change', {
              ...values,
              token: code,
            })
            .then(async (res) => {
              if (res.status == 200) {
                handleOpenAlert({
                  title: t('main.alert.success_title'),
                  subtitle: t('password_reset_code.second_step.info'),
                })
                setLoading(false)
                history.push('/login')
              }
            })
            .catch(() => {
              setLoading(false)
              history.push('/login')
            })
        }}
      >
        {(formik) => (
          <form className={classes.formBg} onSubmit={formik.handleSubmit}>
            <Card className={classes.paperFront} sx={{ minWidth: 'unset' }}>
              <LanguagePicker className={classes.languagePicker} />
              <div className={classes.form}>
                <div className="flex flex-wrap items-center gap-6 sm:justify-center">
                  <Link to="/login">
                    <img className={classes.logo} src={LogoAmbit} alt="logo" />
                  </Link>
                  <h1 className="text-left text-custom_blue_dark text-2xl">
                    {t('password_reset_code.second_step.title')}
                  </h1>
                </div>
                <Typography>
                  {t('password_reset_code.second_step.content')}
                </Typography>
                <div className=" flex gap-4 flex-col justify-center w-9/12 mx-auto">
                  <FormikControl
                    variableName="password"
                    handleChange={formik.handleChange}
                    labelName={t(
                      'password_reset_code.second_step.new_password'
                    )}
                    formik={formik}
                    type="password"
                    inputProps={{
                      autoComplete: 'new-password',
                    }}
                  />
                  <FormikControl
                    variableName="passwordConfirmation"
                    handleChange={formik.handleChange}
                    labelName={t(
                      'password_reset_code.second_step.repeat_password'
                    )}
                    formik={formik}
                    type="password"
                    inputProps={{
                      autoComplete: 'new-password',
                    }}
                  />
                </div>
                <div className=" flex justify-between items-center gap-8 flex-wrap sm:justify-center sm:gap-2">
                  <LoadingButton
                    className={classes.btn}
                    color="primary"
                    variant="contained"
                    disabled={!validCode || !loaded}
                    type="submit"
                    loading={loading}
                    size="large"
                  >
                    <span className="mt-1">
                      {t('password_reset_code.second_step.send')}
                    </span>
                  </LoadingButton>
                </div>
              </div>
            </Card>
          </form>
        )}
      </Formik>
    </>
  )
}

export default PasswordResetCode
