import PropTypes from 'prop-types'
import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { loadUser } from '../../store/userSlice'
import { loadLanguages } from '../../store/languageSlice'
import i18next from 'i18next'
import { changePanel } from '../../store/mainSlice'
import store from '../../store/store'

const RefreshToken = ({ children }) => {
  const loaded = useSelector((state) => state.user.loaded)
  const dispatch = useDispatch()

  useEffect(async () => {
    await dispatch(loadLanguages())
    store.dispatch(changePanel(localStorage.getItem('panelId') || 1))
    const lang = localStorage.getItem('lang')
    if (lang) {
      i18next.changeLanguage(lang)
    }
    await dispatch(loadUser())
  }, [])
  return loaded ? <>{children}</> : <></>
}

RefreshToken.propTypes = {
  children: PropTypes.any,
}

export default RefreshToken
