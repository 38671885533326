import React from 'react'
import { useContext } from 'react'
import { useSelector } from 'react-redux'
import StoreKeyContext from '../../../contexts/StoreKeyContext'
import { TableBody, Table } from '@mui/material'
import Row from './Row'
import DataTableHead from './DataTableHead'
import DataTablePagination from './DataTablePagination'
import DefaultBox from './boxes/DefaultBox'
import PropTypes from 'prop-types'
import useWindowDimensions from '../../../hooks/useWindowDimensions'
import DefaultBoxSorter from './boxes/DefaultBoxSorter'
import makeStyles from '@mui/styles/makeStyles'
import NoContent from '../pages/NoContent'

const styles = makeStyles(() => ({
  Table: {
    whiteSpace: 'nowrap',
    top: '16px',
  },
  boxesContainer: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  thead: {
    background: '#bc2b2a',
    color: '#fff',
  },
}))

const DataTable = ({
  keys,
  labels,
  actions,
  breakpoint,
  rowStyles = {},
  rowComponents = {},
}) => {
  const storeKey = useContext(StoreKeyContext)
  const data = useSelector((state) => state.filters.data[storeKey])
  const filters = useSelector((state) => state.filters.filters[storeKey])
  const { width } = useWindowDimensions()
  const classes = styles()

  return (
    <>
      {data && data.data && data.data.length > 0 ? (
        <>
          {Number(width) >= breakpoint ? (
            <Table stickyHeader className={classes.Table}>
              <DataTableHead
                filters={filters}
                keys={keys}
                labels={labels}
                rowStyles={rowStyles}
                actions={actions}
                className={classes.thead}
              />
              <TableBody>
                {keys &&
                  data.data.map((row, i2) => (
                    <Row
                      rowStyles={rowStyles}
                      rowComponents={rowComponents}
                      key={i2 + 'row'}
                      cells={{ ...row }}
                      keys={keys}
                      actions={actions}
                    />
                  ))}
              </TableBody>
            </Table>
          ) : (
            <>
              <div className="flex justify-end pr-4">
                <div>
                  <DefaultBoxSorter
                    keys={keys}
                    labels={labels}
                    filters={filters}
                  />
                </div>
              </div>
              <div className={classes.boxesContainer}>
                {keys &&
                  // data &&
                  // data.data &&
                  data.data.map((row, i2) => (
                    <DefaultBox
                      key={i2 + 'row'}
                      cells={{ ...row }}
                      keys={keys}
                      labels={labels}
                      rowComponents={rowComponents}
                      actions={actions}
                    />
                  ))}
              </div>
            </>
          )}
          <DataTablePagination data={data} filters={filters} />
        </>
      ) : (
        <NoContent loading={!data.current_page} />
      )}
    </>
  )
}

DataTable.propTypes = {
  keys: PropTypes.array,
  labels: PropTypes.array,
  actions: PropTypes.string,
  breakpoint: PropTypes.number,
  rowStyles: PropTypes.object,
  rowComponents: PropTypes.object,
}

export default DataTable
