import PropTypes from 'prop-types'
import { useDispatch } from 'react-redux'
import { closePopup } from '../../../store/popupsSlice'
import { Dialog, DialogTitle, Typography } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import { Clear } from '@mui/icons-material'

const useStyles = makeStyles(() => ({
  dialogWrapper: {
    padding: 0,
  },
}))

const PopupsBase = ({ children, title, popupKey, level, maxWidth = 'md' }) => {
  const classes = useStyles()
  const dispatch = useDispatch()

  return (
    <>
      <Dialog
        open={true}
        maxWidth={maxWidth}
        classes={{ paper: classes.dialogWrapper }}
        style={{ zIndex: 1300 + level }}
        fullWidth={true}
        // scroll={scroll}
        disableEscapeKeyDown={true}
      >
        <DialogTitle>
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}
          >
            <Typography variant="h6" component="div">
              {title}
            </Typography>
            <Clear
              className="cursor-pointer"
              onClick={() => dispatch(closePopup(popupKey))}
            />
          </div>
        </DialogTitle>
        {children}
      </Dialog>
    </>
  )
}

PopupsBase.propTypes = {
  children: PropTypes.any,
  title: PropTypes.string,
  popupKey: PropTypes.string,
  level: PropTypes.number,
  maxWidth: PropTypes.string,
}

export default PopupsBase
