import { Edit, Search } from '@mui/icons-material'
import {
  Card,
  CardHeader,
  CardContent,
  Typography,
  Grid,
  Tabs,
  Tab,
  Button,
} from '@mui/material'
import ConstructionIcon from '@mui/icons-material/Construction'
import makeStyles from '@mui/styles/makeStyles'
import PageContainer from 'components/organisms/layout/PageContainer'
import { Link, useRouteMatch, useHistory } from 'react-router-dom'
// import { useDispatch } from 'react-redux'
import { useEffect, useState } from 'react'
import { useParams } from 'react-router'
import axios from 'axios'
import PropTypes from 'prop-types'
import MaterialPricingPreview from './tabs/MaterialPricingPreview'
import MaterialFinishingPreview from './tabs/MaterialFinishingPreview'
import AddIcon from '@mui/icons-material/Add'
import { useDispatch, useSelector } from 'react-redux'
import { openPopup } from 'store/popupsSlice'
import LoadingCard from 'components/atoms/LoadingCard'
import eventBus from 'lib/eventBus'
import MaterialFinishingPricingPreview from './tabs/MaterialFinishingPricingPreview'
import MaterialColorsPreview from './tabs/MaterialColorsPreview'
// import { handleOpenAlert } from '../../../lib/handleAlert'

const useStyles = makeStyles((theme) => ({
  fontAwesome: {
    position: 'absolute',
    fontSize: '16rem',
    right: '20%',
    margin: 'auto 0',
    top: '50%',
    transform: 'translateY(-50%);',
    height: '80%',
    color: theme.palette.primary.light,
    overflow: 'hidden',
    opacity: '0.2',
  },
  customCard: {
    position: 'relative',
  },
  custom: {
    width: '100%',
    maxWidth: '25% !important',
  },
}))

const tabNameToIndex = {
  0: 'prices',
  1: 'extras-prices',
  2: 'extras',
  3: 'colors',
}

const indexToTabName = {
  prices: 0,
  'extras-prices': 1,
  extras: 2,
  colors: 3,
}

function LinkTab(props) {
  return <Tab component={Link} {...props} />
}

const MaterialPreview = ({ priceListId }) => {
  const dispatch = useDispatch()
  const user = useSelector((state) => state.user.user)

  const { id } = useParams([])
  const [materials, setMaterial] = useState(null)
  const match = useRouteMatch()
  const history = useHistory([])
  const [tab, setTab] = useState(indexToTabName[match.params.page] || 0)

  useEffect(() => {
    if (typeof indexToTabName[match.params.page] != 'number') {
      history.goBack()
    }
  }, [])

  useEffect(() => {
    if (id) {
      loadMaterial(id)
    }
  }, [id])

  useEffect(() => {
    eventBus.on('loadMaterial', () => {
      loadMaterial(id)
    })

    return eventBus.remove('loadMaterial')
  }, [])

  const loadMaterial = (id) => {
    axios
      .post(`/materials/${id}`)
      .then((res) => {
        if (res.status == 200) {
          setMaterial(res.data)
        }
      })
      .catch(() => {
        // handleOpenAlert({
        //   title: 'Błąd!',
        //   subtitle: `Nie znaleziono materiału`,
        //   type: 'warning',
        // })
        history.goBack()
      })
  }

  const classes = useStyles()

  const handleTabChange = (event, newValue) => {
    history.push(`${tabNameToIndex[newValue]}`)
    setTab(newValue)
  }

  return (
    <>
      <PageContainer>
        {materials && id ? (
          <>
            <Card className={classes.customCard} elevation={2} square>
              <ConstructionIcon className={classes.fontAwesome} />
              <CardHeader
                className="text-custom_grey flex flex-wrap"
                title={`${materials.name}`}
                action={
                  <div className="flex flex-wrap gap-4 side_menu_nd_w:max-w-min">
                    {user.permissions &&
                      user.permissions.includes('store Color') && (
                        <Button
                          startIcon={<AddIcon />}
                          className="side_menu_nd_w:w-max"
                          variant="contained"
                          color="primary"
                          onClick={() =>
                            dispatch(
                              openPopup({
                                component: 'ColorFactory',
                                title: 'Dodaj kolor',
                                level: 1,
                                key: 'color-factory-popup',
                                maxWidth: 'sm',
                                values: {
                                  material_id: id,
                                },
                              })
                            )
                          }
                        >
                          <span className="mt-1">Dodaj kolor</span>
                        </Button>
                      )}
                  </div>
                }
              />
              <CardContent>
                <div>
                  <Card
                    elevation={0}
                    className="flex flex-col pt-0 text-black lg:w-full md:w-full p-4 whitespace-nowrap"
                  >
                    <Grid
                      className="z-10"
                      container
                      rowSpacing={1}
                      columnSpacing={{ xs: 1, sm: 2, md: 3 }}
                    >
                      <Grid item xs={12} md={3} lg={3}>
                        <Typography
                          className="flex items-center gap-1 text-custom_grey"
                          variant="subtitle1"
                        >
                          <p className="flex font-semibold text-custom_grey">
                            Typ:
                          </p>
                          {`${materials.location_name}`}
                        </Typography>
                      </Grid>
                      <Grid item xs={12} md={3} lg={3}>
                        <Typography
                          className="flex items-center gap-1 text-custom_grey"
                          variant="subtitle1"
                        >
                          <p className="flex font-semibold text-custom_grey">
                            Jednostka:
                          </p>
                          {`${materials.unit_name}`}
                        </Typography>
                      </Grid>
                      <Grid item xs={12} md={3} lg={3}>
                        <Typography
                          className="flex items-center gap-1 text-custom_grey"
                          variant="subtitle1"
                        >
                          <p className="flex font-semibold text-custom_grey">
                            Maksymalna długość:
                          </p>
                          {`${materials.max_length}`}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Card>
                </div>
              </CardContent>
            </Card>
            <Grid item xs={12}>
              <Card className="bg-transparent overflow-auto shadow-none">
                <Tabs
                  className="bg-white"
                  value={tab}
                  onChange={handleTabChange}
                  aria-label="nav tabs"
                >
                  <LinkTab
                    label="Cennik"
                    to="pricing"
                    className={classes.custom}
                  />
                  <LinkTab
                    label="Cennik Wykończeń"
                    to="extras-pricing"
                    className={classes.custom}
                  />
                  <LinkTab
                    label="Wykończenia"
                    to="extras"
                    className={classes.custom}
                  />
                  <LinkTab
                    label="Kolory"
                    to="colors"
                    className={classes.custom}
                  />
                </Tabs>
                <CardContent className="no-padding">
                  {tab === 0 && (
                    <MaterialPricingPreview
                      priceListId={priceListId}
                      materialId={id}
                    />
                  )}
                  {tab === 1 && (
                    <MaterialFinishingPricingPreview
                      priceListId={priceListId}
                      materialId={id}
                    />
                  )}
                  {tab === 2 && <MaterialFinishingPreview materialId={id} />}
                  {tab === 3 && <MaterialColorsPreview materialId={id} />}
                </CardContent>
              </Card>
            </Grid>
          </>
        ) : (
          <LoadingCard />
        )}
      </PageContainer>
    </>
  )
}

MaterialPreview.propTypes = {
  priceListId: PropTypes.oneOfType(PropTypes.string, PropTypes.number),
}

export default MaterialPreview
