import { Grid, IconButton } from '@mui/material'
import { Edit, Delete } from '@mui/icons-material'
import Confirmation from '../../popups/Confirmation'
import PropTypes from 'prop-types'
import { useDispatch, useSelector } from 'react-redux'
import { useState } from 'react'
import { handleOpenAlert } from '../../../../lib/handleAlert'
import { refreshFilter } from '../../../../lib/handleFilters'
import { openPopup } from '../../../../store/popupsSlice'
import axios from 'axios'

const AdminsTableActions = ({ row }) => {
  const dispatch = useDispatch()
  const [confirmation, setConfirmation] = useState(false)
  const user = useSelector((state) => state.user.user)

  return (
    <Grid
      container
      alignItems="center"
      justifyContent="center"
      direction="row"
      spacing={1}
      className="table-actions"
    >
      {confirmation && (
        <Confirmation
          level={5}
          maxWidth="xs"
          icon="Warning"
          title={`Czy na pewno chcesz usunąć administratora ${row.firstname} ${row.lastname}?`}
          subtitle={`Tej operacji nie można cofnąć.`}
          reject={() => {
            setConfirmation(false)
          }}
          success={() => {
            axios
              .post(`/admins/${row.id}/delete`)
              .then(() => {
                handleOpenAlert({
                  title: 'Sukces!',
                  subtitle: `Usunięto administratora ${row.firstname} ${row.lastname}`,
                  type: 'success',
                })
                refreshFilter('AdministratorsKey')
                setConfirmation(false)
              })
              .catch((err) => {
                handleOpenAlert(err.request)
              })
          }}
        />
      )}
      {user.role_id == 1 && user.permissions.includes('edit User') && (
        <Grid item>
          <IconButton
            title="Edytuj administratora"
            size="small"
            onClick={() =>
              dispatch(
                openPopup({
                  component: 'AdministratorsFactory',
                  title: 'Edytuj administratora',
                  level: 1,
                  maxWidth: 'sm',
                  values: {
                    ...row,
                  },
                  key: 'admin-factory-popup',
                })
              )
            }
          >
            <Edit fontSize="small" />
          </IconButton>
        </Grid>
      )}
      {user.role_id == 1 && user.permissions.includes('remove User') && (
        <Grid item>
          <IconButton
            title="Usuń administratora"
            size="small"
            onClick={() => setConfirmation(true)}
          >
            <Delete fontSize="small" color="error" />
          </IconButton>
        </Grid>
      )}
    </Grid>
  )
}

AdminsTableActions.propTypes = {
  row: PropTypes.object,
}

export default AdminsTableActions
