import PageHeader from 'components/organisms/layout/PageHeader'
import PageContainer from 'components/organisms/layout/PageContainer'
import WithFilters from 'components/hocs/WithFilters'
import DataInputs from 'components/organisms/dataTable/DataInputs'
// import { useSelector } from 'react-redux'
import {
  Card,
  CardContent,
  Button,
  // CardHeader,
  // Typography,
} from '@mui/material'
import AddIcon from '@mui/icons-material/Add'
import DataTable from 'components/organisms/dataTable/DataTable'
import StoreKeyProvider from 'components/providers/StoreKeyProvider'
import makeStyles from '@mui/styles/makeStyles'
import { useDispatch } from 'react-redux'
import { openPopup } from 'store/popupsSlice'
import { useEffect, useState } from 'react'
// import axios from 'axios'
import { faHandshake } from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import axios from 'axios'

const useStyles = makeStyles(() => ({
  bg: {
    background: 'transparent',
    overflow: 'unset',
  },
  content: {
    padding: '24px',
    background: '#fff',
    marginBottom: '24px',
    borderRadius: '2px',
    boxShadow:
      '0px 3px 1px -2px rgba(0,0,0,0.2),0px 2px 2px 0px rgba(0,0,0,0.14),0px 1px 5px 0px rgba(0,0,0,0.12)',
  },
  contentSt: {
    background: '#fff',
    marginBottom: '24px',
    borderRadius: '2px',
    boxShadow:
      '0px 3px 1px -2px rgba(0,0,0,0.2),0px 2px 2px 0px rgba(0,0,0,0.14),0px 1px 5px 0px rgba(0,0,0,0.12)',
    position: 'relative',
  },
  icon: {
    width: '24px !important',
    height: '24px',
  },
}))

const Home = () => {
  const dispatch = useDispatch()
  const [states, setStates] = useState([])
  const [priceLists, setPriceLists] = useState([])

  useEffect(() => {
    axios.post('/lookups/states', null).then((res) => {
      setStates(res.data)
    })
    axios.post('/lookups/price_lists', null).then((res) => {
      setPriceLists(res.data)
    })
  }, [])
  const classes = useStyles()

  return (
    <div>
      <PageHeader
        icon={<FontAwesomeIcon icon={faHandshake} className={classes.icon} />}
        title="Lista partnerów"
      />
      <PageContainer>
        <StoreKeyProvider storeKey="PartnersKey">
          <WithFilters
            config={{
              page: 1,
              paginate: 25,
              orderType: 'asc',
              order: 'name',
              inputs: {
                name: {
                  values: '',
                  relation: 'LIKE',
                },
                nip: {
                  value: '',
                  relation: 'LIKE',
                },
                city: {
                  value: '',
                  relation: 'LIKE',
                },
                street: {
                  value: '',
                  relation: 'LIKE',
                },
                state_id: {
                  value: '',
                  relation: 'WHERE',
                },
                postcode: {
                  value: '',
                  relation: 'LIKE',
                },
                price_list_id: {
                  value: '',
                  relation: 'WHERE',
                },
                active: {
                  value: 1,
                  relation: 'WHERE',
                },
              },
            }}
            endpoint="/partners"
            withActive={true}
          >
            <Card elevation={0} className={classes.bg}>
              <CardContent className={classes.contentSt}>
                <Button
                  className="absolute right-0 mr-4"
                  startIcon={<AddIcon />}
                  variant="contained"
                  color="primary"
                  onClick={() =>
                    dispatch(
                      openPopup({
                        component: 'PartnerFactory',
                        title: 'Dodaj partnera',
                        level: 1,
                        key: 'partner-factory-popup',
                        maxWidth: 'sm',
                      })
                    )
                  }
                >
                  <span className="mt-1">Dodaj partnera</span>
                </Button>
                {states && (
                  <DataInputs
                    className="pb-7 !important; bg-gray-700"
                    inputs={[
                      {
                        id: 'name',
                        label: 'Nazwa firmy',
                        placeholder: '',
                        type: 'text',
                      },
                      {
                        id: 'nip',
                        label: 'Nip',
                        placeholder: '',
                        type: 'text',
                      },
                      {
                        id: 'city',
                        label: 'Miasto',
                        placeholder: '',
                        type: 'text',
                      },
                      {
                        id: 'street',
                        label: 'Adres',
                        placeholder: '',
                        type: 'text',
                      },
                      {
                        id: 'active',
                        label: 'Aktywne',
                        placeholder: '',
                        type: 'select',
                        options: [
                          {
                            id: 1,
                            name: 'Tak',
                          },
                          {
                            id: 0,
                            name: 'Nie',
                          },
                        ],
                      },
                      {
                        id: 'price_list_id',
                        label: 'Cennik',
                        placeholder: '',
                        type: 'select',
                        options: priceLists,
                        config: {
                          key: 'id',
                          label: 'name',
                        },
                      },
                      {
                        id: 'state_id',
                        label: 'Województwo',
                        placeholder: '',
                        type: 'select',
                        options: states,
                      },
                      {
                        id: 'postcode',
                        label: 'Kod pocztowy',
                        placeholder: '',
                        type: 'text',
                      },
                    ]}
                  />
                )}
              </CardContent>
              <CardContent className={classes.content}>
                <DataTable
                  breakpoint={1687}
                  actions="PartnersTableActions"
                  rowStyles={{
                    name: {
                      maxWidth: '300px',
                    },
                  }}
                  labels={[
                    'Nazwa firmy',
                    'Nip',
                    'Miasto',
                    'Adres',
                    'Województwo',
                    'Kod pocztowy',
                    'Cennik',
                  ]}
                  keys={[
                    'name',
                    'nip',
                    'city',
                    'street',
                    'state_name',
                    'postcode',
                    'price_list_name',
                  ]}
                />
              </CardContent>
            </Card>
          </WithFilters>
        </StoreKeyProvider>
      </PageContainer>
    </div>
  )
}

export default Home
