import { useContext, useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { ValuationCalculatorContext } from '../../../../../../contexts/ValuationCalculatorContext'
import FormAutocomplete from '../../../../inputs/FormAutocomplete'
import { getThickness } from '../../../../../../store/valuationSlice'
import { useDispatch } from 'react-redux'

const ValuationCalculatorThicknessInput = () => {
  const {
    component,
    setComponent,
    recalculate,
    validate,
    readOnly,
    getExtrasItems,
    withoutBlocks,
    handleLoadingStack,
  } = useContext(ValuationCalculatorContext)
  const { t } = useTranslation()
  const [thickness, setThickness] = useState([])
  const dispatch = useDispatch()
  const firstRender = useRef(true)
  useEffect(() => {
    firstRender.current = false
  })
  useEffect(() => {
    firstRender.current = true
  }, [component.id])

  useEffect(async () => {
    if (component.thickness_id && thickness) {
      component.thickness_name = thickness.find(
        (element) => element.id == component.thickness_id
      )?.name
    } else {
      component.thickness_name = ''
    }
    setComponent(component)
  }, [component.thickness_id, thickness])

  useEffect(() => {
    if (!firstRender.current) {
      if (withoutBlocks) {
        getExtrasItems()
      } else {
        recalculate()
      }
    }
  }, [component.thickness_id])

  useEffect(() => {
    if (!firstRender.current) {
      if (thickness) {
        if (thickness.length == 1) {
          changeValue(thickness[0].id)
        } else if (component.thickness_id) {
          const exists = thickness.find(
            (element) => element.id == component.thickness_id
          )
          if (!exists) {
            changeValue('')
          }
        }
      }
    }
  }, [thickness])

  useEffect(async () => {
    if (component.color_id) {
      loadThickness()
    } else {
      setThickness([])
    }
    if (!firstRender.current) {
      changeValue('')
    }
  }, [component.color_id])

  const loadThickness = () => {
    handleLoadingStack(true)
    setThickness(null)
    const materialId = component.material_id
    const colorId = component.color_id
    dispatch(getThickness({ material_id: materialId, color_id: colorId })).then(
      (thickness) => {
        setThickness(thickness.payload)
        handleLoadingStack(false)
      }
    )
  }

  const changeValue = (value) => {
    component.thickness_id = value
    setComponent(component)
  }

  return (
    <>
      <FormAutocomplete
        variableName="thickness_id"
        disabled={readOnly || !component.color_id}
        helperText={
          validate && !component.thickness_id && t('main.validation.required')
        }
        value={component.thickness_id}
        handleChange={(l, v) => {
          changeValue(v)
        }}
        min="0"
        labelName={t('valuation_popup.data.input.thickness')}
        options={thickness}
      />
    </>
  )
}
ValuationCalculatorThicknessInput.propTypes = {}
export default ValuationCalculatorThicknessInput
