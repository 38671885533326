import store from '../store/store'
import { loadData } from '../store/filtersSlice'

export const refreshFilters = () => {
  let storeData = store.getState()
  let keys = Object.keys(storeData.filters.data)
  for (let i = 0; i < keys.length; i++) {
    store.dispatch(
      loadData({ endpoint: storeData.filters.data[keys[i]].path, key: keys[i] })
    )
  }
}

export const refreshFilter = (key) => {
  let storeData = store.getState()
  store.dispatch(
    loadData({ endpoint: storeData.filters.data[key].endpoint, key })
  )
}
