import { Grid, IconButton } from '@mui/material'
import { GetApp } from '@mui/icons-material'
import { green } from '@mui/material/colors'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import PersonIcon from '@mui/icons-material/Person'
import { useSelector } from 'react-redux'
import downloadFile from '../../../../lib/downloadFile'

const ExaminationsTableActions = ({ row }) => {
  const user = useSelector((state) => state.user.user)

  const download = () => {
    downloadFile({
      route: `examinations/${row.id}/download`,
      fileName: row.file_name,
    })
  }

  return (
    <Grid
      container
      alignItems="center"
      justifyContent="center"
      direction="row"
      spacing={1}
      className="table-actions"
    >
      {row.file_id && (
        <Grid item onClick={() => download()}>
          <IconButton className="p-0" title="Pobierz" size="small">
            <GetApp fontSize="small" style={{ color: green[500] }} />
          </IconButton>
        </Grid>
      )}
      {user && user.role_id == 1 && (
        <Grid item>
          <Link to={`/patient/${row.patient_id}/results`}>
            <IconButton className="p-0" title="Podgląd pacjenta" size="small">
              <PersonIcon fontSize="small" color="primary" />
            </IconButton>
          </Link>
        </Grid>
      )}
      {user && user.role_id == 2 && (
        <Grid item>
          <Link to={`/patient/${row.patient_id}`}>
            <IconButton className="p-0" title="Podgląd pacjenta" size="small">
              <PersonIcon fontSize="small" color="primary" />
            </IconButton>
          </Link>
        </Grid>
      )}
    </Grid>
  )
}

ExaminationsTableActions.propTypes = {
  row: PropTypes.object,
}

export default ExaminationsTableActions
