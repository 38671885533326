import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios'
import filtersBuilder from '../lib/filtersBuilder'

const buildFilter = (raw) => {
  let result = {}
  result = { ...raw, filters: filtersBuilder(raw.inputs) }
  delete result.inputs
  delete result.config
  return result
}

export const loadData = createAsyncThunk(
  'filters/loadData',
  async (payload, { getState, dispatch }) => {
    const ourRequest = axios.CancelToken.source() // <-- 1st step
    const id = Math.random()
    dispatch(cancelPreviousRequests({ url: payload.endpoint }))
    dispatch(
      addToRequestStack({ url: payload.endpoint, id, object: ourRequest })
    )
    const filters = getState().filters.filters[payload.key]
    const res = await axios.post(payload.endpoint, buildFilter(filters), {
      cancelToken: ourRequest.token,
    })
    if (res) {
      const data = res.data
      data.endpoint = payload.endpoint
      return { key: payload.key, data }
    }
  }
)

export const getFilter = createAsyncThunk(
  'filters/getFilter',
  async (payload, { getState }) => {
    const filters = getState().filters.filters[payload.key]
    return buildFilter(filters)
  }
)

export const filtersSlice = createSlice({
  name: 'filters',
  initialState: {
    filters: {
      PartnersKey: {},
      OrdersKey: {},
      CustomOrdersKey: {},
      EventsKey: {},
      ContractsKey: {},
      AdministratorsKey: {},
      ExaminationsKey: {},
      MaterialsKey: {},
      ColorsKey: {},
      MaterialFinishingPreviewKey: {},
      PriceListsKey: {},
      MaterialFinishingPricingPreviewKey: {},
      MaterialColorsPreviewKey: {},
      MaterialPricingPreviewKey: {},
      PartnerEmployeesPreview: {},
      PartnerValuesPreviewKey: {},
      PatientsListKey: {},
      PatientEventsKey: {},
      ValuationsKey: {},
      UserValuationsKey: {},
      UsersKey: {},
      PartnerOrdersPreviewKey: {},
      PartnerAddressesPreview: {},
    },
    data: {
      PartnersKey: [],
      OrdersKey: [],
      CustomOrdersKey: [],
      EventsKey: [],
      ContractsKey: [],
      AdministratorsKey: [],
      ExaminationsKey: [],
      MaterialsKey: [],
      ColorsKey: [],
      MaterialFinishingPreviewKey: [],
      PriceListsKey: [],
      MaterialFinishingPricingPreviewKey: [],
      MaterialColorsPreviewKey: [],
      MaterialPricingPreviewKey: [],
      PartnerEmployeesPreview: [],
      PartnerValuesPreviewKey: [],
      PatientsListKey: [],
      PatientEventsKey: [],
      ValuationsKey: [],
      UserValuationsKey: [],
      UsersKey: [],
      PartnerOrdersPreviewKey: [],
      PartnerAddressesPreview: [],
    },
    requestStack: {},
  },
  reducers: {
    loadFilterByKey: (state, action) => {
      Object.assign(
        state.filters[action.payload.key],
        JSON.parse(action.payload.filter)
      )
    },
    changeProperty: (state, action) => {
      state.filters[action.payload.key][action.payload.property] =
        action.payload.value
    },
    cancelPreviousRequests: (state, data) => {
      let requests = state.requestStack[data.payload.url]
      if (requests) {
        for (let id in requests) {
          requests[id].cancel()
          if (
            state.requestStack[data.payload.url] &&
            state.requestStack[data.payload.url][id]
          ) {
            delete state.requestStack[data.payload.url][id]
          }
        }
      }
    },
    addToRequestStack: (state, data) => {
      if (!state.requestStack[data.payload.url]) {
        state.requestStack[data.payload.url] = {}
      }
      state.requestStack[data.payload.url][data.payload.id] =
        data.payload.object
    },
    changeInputs: (state, action) => {
      let copy = JSON.parse(
        JSON.stringify(state.filters[action.payload.key].inputs)
      )
      Object.keys(copy).forEach((key) => {
        copy[key].value = action.payload.values[key]
      })
      state.filters[action.payload.key].inputs = copy
    },
    changeOrder: (state, action) => {
      const key = action.payload.orderKey
      if (state.filters[action.payload.key].order === key) {
        state.filters[action.payload.key].orderType =
          state.filters[action.payload.key].orderType === 'asc' ? 'desc' : 'asc'
      } else {
        state.filters[action.payload.key].order = key
        state.filters[action.payload.key].orderType = 'asc'
      }
    },
    buildInitFilter: (state, action) => {
      const config = action.payload.config
      Object.assign(state.filters[action.payload.key], {
        page: config.page || 1,
        paginate: config.paginate || 10,
        orderType: config.orderType || 'asc',
        order: config.order || 'id',
        inputs: config.inputs || {},
        config,
      })
    },
    resetFilter: (state, action) => {
      const config =
        state.filters[action.payload.key] &&
        state.filters[action.payload.key].config
      if (config) {
        Object.assign(state.filters[action.payload.key], {
          page: config.page || 1,
          paginate: config.paginate || 10,
          orderType: config.orderType || 'asc',
          order: config.order || 'id',
          inputs: config.inputs || {},
        })
      }
    },
  },
  extraReducers: {
    [loadData.fulfilled]: (state, action) => {
      state.data[action.payload.key] = action.payload.data
    },
  },
})

export const {
  loadFilterByKey,
  changeFilters,
  buildInitFilter,
  changeProperty,
  changeOrder,
  changeInputs,
  resetFilter,
  cancelPreviousRequests,
  addToRequestStack,
} = filtersSlice.actions

export default filtersSlice.reducer
