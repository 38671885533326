import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
// import setAuthToken from '../lib/setAuthToken'
import axios from 'axios'

export const loadLanguages = createAsyncThunk(
  'language/loadLanguages',
  async (data, { dispatch }) => {
    const res = await axios.get('/languages')
    if (res.status == 200) {
      dispatch(setLanguages(res.data))
      return res.data
    }
  }
)

export const languageSlice = createSlice({
  name: 'language',
  initialState: {
    languages: [],
  },
  reducers: {
    setLanguages: (state, action) => {
      state.languages = action.payload
    },
    extraReducers: (builder) => {
      builder.addCase(loadLanguages.fulfilled, (state, action) => {
        state.languages = action.payload
      })
    },
  },
})

export const { setLanguages } = languageSlice.actions

export default languageSlice.reducer
