import { Grid, DialogActions, Button, DialogContent } from '@mui/material'
import SaveIcon from '@mui/icons-material/Save'
import axios from 'axios'
import { handleOpenAlert } from '../../../../lib/handleAlert'
import { closePopup } from '../../../../store/popupsSlice'
import { refreshFilter } from '../../../../lib/handleFilters'
import { useDispatch } from 'react-redux'
import ReactQuill from 'react-quill'
import { useState } from 'react'
import PropTypes from 'prop-types'

const NotesFactory = ({ comment, id }) => {
  const contractId = id
  const dispatch = useDispatch()

  const [value, setValue] = useState(comment)

  const submit = () => {
    axios
      .post(`/contracts/${contractId}/comment/update`, {
        comment: value,
      })
      .then((res) => {
        if (res.status == 200) {
          dispatch(closePopup('note-factory-popup'))
          refreshFilter('EventsKey')
        }
        handleOpenAlert({
          title: 'Sukces!',
          subtitle: contractId ? 'Edytowano notatkę' : 'Dodano notatkę',
          type: 'success',
        })
      })
      .catch((err) => {
        handleOpenAlert(err.request.status)
      })
  }

  return (
    <>
      <DialogContent dividers={true}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <ReactQuill value={value} onChange={setValue} />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={submit}
          startIcon={<SaveIcon />}
          variant="contained"
          color="primary"
          type="submit"
        >
          <span className="mt-1">Zapisz</span>
        </Button>
      </DialogActions>
    </>
  )
}

NotesFactory.propTypes = {
  comment: PropTypes.string,
  id: PropTypes.number,
}

export default NotesFactory
