import PageHeader from 'components/organisms/layout/PageHeader'
import { Search } from '@mui/icons-material'
import { Card, CardHeader, CardContent, Typography, Grid } from '@mui/material'
import { useParams } from 'react-router'
import { faUsers } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import makeStyles from '@mui/styles/makeStyles'
import PageContainer from 'components/organisms/layout/PageContainer'
// import React, { useState, useEffect } from 'react'
import { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
// import { handleOpenAlert } from 'components/organisms/lib/handleAlert'
import axios from 'axios'
import LoadingCard from 'components/atoms/LoadingCard'
import ValuationTable from 'components/molecules/valuations/ValuationTable'

const useStyles = makeStyles((theme) => ({
  fontAwesome: {
    position: 'absolute',
    fontSize: '16rem',
    right: '20%',
    margin: 'auto 0',
    top: '50%',
    transform: 'translateY(-50%);',
    height: '80%',
    color: theme.palette.primary.light,
    overflow: 'hidden',
    opacity: '0.2',
  },
  customCard: {
    position: 'relative',
  },
  custom: {
    width: '100%',
    maxWidth: '50% !important',
  },
  bg: {
    background: 'transparent',
    overflow: 'unset',
  },
  content: {
    padding: '24px',
    background: '#fff',
    marginBottom: '24px',
    borderRadius: '2px',
    boxShadow:
      '0px 3px 1px -2px rgba(0,0,0,0.2),0px 2px 2px 0px rgba(0,0,0,0.14),0px 1px 5px 0px rgba(0,0,0,0.12)',
  },
  contentSt: {
    background: '#fff',
    marginBottom: '24px',
    borderRadius: '2px',
    boxShadow:
      '0px 3px 1px -2px rgba(0,0,0,0.2),0px 2px 2px 0px rgba(0,0,0,0.14),0px 1px 5px 0px rgba(0,0,0,0.12)',
    position: 'relative',
  },
}))

const UserPreview = () => {
  const { id } = useParams()
  const [user, setUser] = useState(null)
  const history = useHistory()

  useEffect(() => {
    if (id) {
      loadUser(id)
    }
  }, [id])

  const loadUser = (id) => {
    axios
      .post(`/users/${id}`)
      .then((res) => {
        if (res.status == 200) {
          setUser(res.data)
        }
      })
      .catch(() => {
        // handleOpenAlert({
        //   title: 'Błąd!',
        //   subtitle: `Nie znaleziono handlowca`,
        //   type: 'warning',
        // })
        history.goBack()
      })
  }

  const classes = useStyles()

  return (
    <>
      <PageHeader
        icon={<Search fontSize="medium" />}
        title="Podgląd użytkownika"
      />
      {user ? (
        <PageContainer>
          <Card className={classes.customCard} elevation={2} square>
            <FontAwesomeIcon className={classes.fontAwesome} icon={faUsers} />
            <CardHeader
              className="text-custom_grey"
              title={`Podstawowe informacje`}
            ></CardHeader>
            <CardContent>
              <div>
                <Card
                  elevation={0}
                  className="flex flex-col pt-0 text-black lg:w-full md:w-full p-4 whitespace-nowrap"
                >
                  <Grid
                    className="z-10"
                    container
                    rowSpacing={1}
                    columnSpacing={{ xs: 1, sm: 2, md: 3 }}
                  >
                    <Grid item xs={12} md={6} lg={3}>
                      <Typography
                        className="flex items-center gap-1 text-custom_grey"
                        variant="subtitle1"
                      >
                        <p className="flex font-semibold text-custom_grey">
                          Imię:{' '}
                        </p>
                        {user.firstname}
                      </Typography>
                      <Typography
                        className="flex items-center gap-1 text-custom_grey"
                        variant="subtitle1"
                      >
                        <p className="flex font-semibold text-custom_grey">
                          Nazwisko:{' '}
                        </p>
                        {user.lastname}
                      </Typography>
                    </Grid>
                    <Grid item xs={12} md={6} lg={3}>
                      <Typography
                        className="flex items-center gap-1 text-custom_grey"
                        variant="subtitle1"
                      >
                        <p className="flex font-semibold text-custom_grey">
                          E-mail:{' '}
                        </p>
                        {user.email}
                      </Typography>
                      <Typography
                        className="flex items-center gap-1 text-custom_grey"
                        variant="subtitle1"
                      >
                        <p className="flex font-semibold text-custom_grey">
                          Nr. tel.:{' '}
                        </p>
                        {user.phone_number}
                      </Typography>
                    </Grid>
                  </Grid>
                </Card>
              </div>
            </CardContent>
          </Card>
          <ValuationTable
            endpoint={`/users/${id}/valuations`}
            customKey="UserValuationsKey"
            excludeFilters={['user_name', 'number']}
            title="Lista wycen"
            actionValues={{
              partner_id: user.id,
            }}
          />
        </PageContainer>
      ) : (
        <LoadingCard />
      )}
    </>
  )
}

UserPreview.propTypes = {}

export default UserPreview
