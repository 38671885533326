import React, { useState } from 'react'
import { Formik } from 'formik'
import * as Yup from 'yup'
import { Link, useHistory } from 'react-router-dom'
import { Typography } from '@mui/material'
import FormikControl from '../../molecules/FormikControl'
import { Card } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import LogoAmbit from '../../../images/logo-ambit.png'
// import { useDispatch } from 'react-redux'
// import { setToken, loadUser } from '../../../store/userSlice'
import { handleOpenAlert } from '../../../lib/handleAlert'
import axios from 'axios'
import LoadingButton from '@mui/lab/LoadingButton'
// import { useHistory } from 'react-router-dom'
// import { loginUser } from '../../../store/userSlice'
import LanguagePicker from '../translate/LanguagePicker'
import { useTranslation } from 'react-i18next'

const useStyles = makeStyles((theme) => ({
  nested: {
    padding: theme.spacing(10),
  },
  listItem: {
    color: theme.palette.white.main,
  },
  logo: {
    maxWidth: '123px',
  },
  paperFront: {
    position: 'relative',
    maxWidth: '540px',
    maxHeight: '450px',
    width: '100',
    height: '100%',
    boxShadow: '-55px 40px 2px 0px rgb(218, 218, 218)',
    [theme.breakpoints.down('md')]: {
      width: '100%',
      boxShadow: 'unset',
      maxHeight: '474px',
    },
  },

  formBg: {
    height: '100vh',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    gap: '24px',
    backgroundColor: theme.palette.secondary.mainNd,
    padding: theme.spacing(4),
    [theme.breakpoints.down('md')]: {
      padding: theme.spacing(2),
    },
  },
  form: {
    height: '100%',
    width: '100%',
    maxWidth: '600px',
    maxHeight: '490px',
    display: 'flex',
    gap: theme.spacing(4),
    flexDirection: 'column',
    justifyContent: 'center',
    padding: theme.spacing(4),
    [theme.breakpoints.down('md')]: {
      maxWidth: 'unset',
    },
  },
  languagePicker: {
    position: 'absolute',
    top: '5px',
    right: '5px',
  },
  btn: {
    background: theme.palette.primary.main,
    width: '100%',
    marginTop: '8px',
    [theme.breakpoints.down('md')]: {
      marginTop: 'unset',
    },
  },
}))

const PasswordReset = () => {
  const classes = useStyles()
  const history = useHistory()
  const [loading, setLoading] = useState(false)
  const { t } = useTranslation()

  return (
    <>
      <Formik
        initialValues={{ email: '' }}
        validationSchema={Yup.object({
          email: Yup.string()
            .email(t('main.validation.correct_mail'))
            .required(t('main.validation.required')),
        })}
        onSubmit={async (values) => {
          setLoading(true)
          axios
            .post('/auth/password/reset', {
              email: values.email,
            })
            .then(async (res) => {
              if (res.status == 200) {
                handleOpenAlert({
                  title: t('main.alert.success_title'),
                  subtitle: t('password_reset.first_step.info'),
                })
                setLoading(false)
                history.push('/login')
              }
            })
            .catch(() => {
              setLoading(false)
            })
        }}
      >
        {(formik) => (
          <form className={classes.formBg} onSubmit={formik.handleSubmit}>
            <Card className={classes.paperFront} sx={{ minWidth: 'unset' }}>
              <LanguagePicker className={classes.languagePicker} />
              <div className={classes.form}>
                {/* <div className="gap-7 flex flex-wrap flex-col"> */}
                <div className="flex items-center gap-6 sm:justify-center">
                  <Link to="/login">
                    <img className={classes.logo} src={LogoAmbit} alt="logo" />
                  </Link>
                  <h1 className="text-left text-custom_blue_dark text-2xl">
                    {t('password_reset.first_step.title')}
                  </h1>
                </div>
                <Typography>
                  {t('password_reset.first_step.content')}
                </Typography>
                <div className=" flex gap-4 flex-col justify-center w-9/12 mx-auto">
                  <FormikControl
                    variableName="email"
                    handleChange={formik.handleChange}
                    labelName="E-mail"
                    formik={formik}
                    type="email"
                    variant="standard"
                  />
                </div>
                {/* </div> */}
                <div className=" flex justify-end gap-8 flex-wrap sm:justify-center sm:gap-2">
                  <LoadingButton
                    className={classes.btn}
                    color="primary"
                    variant="contained"
                    loading={loading}
                    type="submit"
                    size="large"
                  >
                    <span className="mt-1">
                      {t('password_reset.first_step.send')}
                    </span>
                  </LoadingButton>
                </div>
              </div>
            </Card>
          </form>
        )}
      </Formik>
    </>
  )
}

export default PasswordReset
