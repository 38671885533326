import DataTable from '../../organisms/dataTable/DataTable'
import { Card, CardContent, CardHeader } from '@mui/material'
import { makeStyles } from '@mui/styles'
import StoreKeyProvider from '../../providers/StoreKeyProvider'
import WithFilters from '../../hocs/WithFilters'
import DataInputs from '../../organisms/dataTable/DataInputs'
import PropTypes from 'prop-types'
import React, { useEffect, useReducer } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Button } from '@mui/material'
import { openPopup } from '../../../store/popupsSlice'
import AddIcon from '@mui/icons-material/Add'
import axios from 'axios'
import ShowAttachmentCell from '../../organisms/dataTable/cells/ShowAttachmentCell'
import { useTranslation } from 'react-i18next'
import { cloneDeep } from 'lodash'
import ShowWarningCell from 'components/organisms/dataTable/cells/ShowWarningCell'

const useStyles = makeStyles(() => ({
  icon: {
    width: '24px !important',
    height: '24px',
  },
  bg: {
    background: 'transparent',
    overflow: 'unset',
  },
  content: {
    padding: '24px',
    background: '#fff',
    marginBottom: '24px',
    borderRadius: '2px',
    boxShadow:
      '0px 3px 1px -2px rgba(0,0,0,0.2),0px 2px 2px 0px rgba(0,0,0,0.14),0px 1px 5px 0px rgba(0,0,0,0.12)',
  },
  contentSt: {
    background: '#fff',
    marginBottom: '24px',
    borderRadius: '2px',
    boxShadow:
      '0px 3px 1px -2px rgba(0,0,0,0.2),0px 2px 2px 0px rgba(0,0,0,0.14),0px 1px 5px 0px rgba(0,0,0,0.12)',
    position: 'relative',
  },
  contentPreview: {
    background: '#fff',
    padding: 'unset',
    color: 'rgba(0, 0, 0, 0.6)',
  },
}))

const inputReducer = (state, data) => {
  let oldState = JSON.parse(JSON.stringify(state))
  if (data.type === 1) {
    const index = oldState.findIndex((state) => state.id == data.id)
    if (index !== -1) oldState[index].options = data.options
  } else if (data.type === 2) {
    const index = oldState.findIndex((state) => state.id == data.id)
    if (index !== -1) oldState.splice(index, 1)
  } else {
    return data
  }
  return oldState
}
const keyReducer = (state, data) => {
  return data
}
const labelReducer = (state, data) => {
  return data
}
const setValueReducer = (state, data) => {
  let newData = JSON.parse(JSON.stringify(state))
  newData[data.key].value = data.value
  return newData
}
const ValuationTable = ({
  endpoint,
  customKey = 'ValuationsKey',
  excludeFilters = [],
  actionValues,
  actions = 'ValuationsTableActions',
  showAdd,
  title,
  isOrders,
}) => {
  const { t } = useTranslation()

  const [keys, setKeys] = useReducer(keyReducer, [
    'is_custom',
    'file_count',
    'name',
    'number',
    'partner_name',
    'user_name',
    'summary_net',
    'sale_summary_net',
    'created_at',
    'status_name',
  ])

  const [inputs, changeInputs] = useReducer(inputReducer, [
    {
      id: 'name',
      label: t('valuation.filter.name'),
      placeholder: '',
      type: 'text',
    },
    {
      id: 'number',
      label: t('valuation.filter.order_number'),
      placeholder: '',
      type: 'text',
    },
    {
      id: 'partner_name',
      label: 'Partner',
      placeholder: '',
      type: 'text',
    },
    {
      id: 'user_name',
      label: 'Użytkownik',
      placeholder: '',
      type: 'text',
    },
    {
      id: 'summary_net',
      label: 'Suma netto',
      placeholder: '',
      type: 'number-between',
    },
    {
      id: 'created_at',
      label: t('valuation.filter.created_at'),
      placeholder: '',
      type: 'date-between',
    },
    {
      id: 'status_id',
      label: 'Status',
      placeholder: '',
      type: 'select',
      options: [],
      config: {
        key: 'id',
        label: 'name',
      },
    },
    {
      id: 'sale_summary_net',
      label: t('valuation.filter.price_after_overlay'),
      placeholder: '',
      type: 'number-between',
    },
    {
      id: 'custom',
      label: 'Specjalne',
      placeholder: '',
      type: 'select',
      options: [
        {
          id: 1,
          name: 'Tak',
        },
        {
          id: 0,
          name: 'Nie',
        },
      ],
    },
  ])

  const [withfiltersInputs, setWithFiltersInputs] = useReducer(
    setValueReducer,
    {
      name: {
        value: '',
        relation: 'LIKE',
      },
      number: {
        value: '',
        relation: 'LIKE',
      },
      partner_name: {
        value: '',
        relation: 'LIKE',
      },
      user_name: {
        value: '',
        relation: 'LIKE',
      },
      min_summary_net: {
        value: null,
        relation: 'MIN',
      },
      max_summary_net: {
        value: null,
        relation: 'MAX',
      },
      min_sale_summary_net: {
        value: null,
        relation: 'MIN',
      },
      max_sale_summary_net: {
        value: null,
        relation: 'MAX',
      },
      min_created_at: {
        value: null,
        preffix: 'valuations',
        relation: 'MIN',
      },
      max_created_at: {
        value: null,
        preffix: 'valuations',
        relation: 'MAX',
      },
      status_id: {
        value: '',
        relation: 'WHERE',
      },
      custom: {
        value: '',
        relation: 'WHERE',
      },
    }
  )
  const user = useSelector((state) => state.user.user)

  useEffect(() => {
    let k = keys
    let l = labels
    const inputsClone = cloneDeep(inputs)
    for (let i = 0; i < excludeFilters.length; i++) {
      let index
      if ((index = k.indexOf(excludeFilters[i])) !== -1) {
        l.splice(index, 1)
        k.splice(index, 1)
      }
      if (
        (index = inputsClone.findIndex((e) => e.id == excludeFilters[i])) !== -1
      ) {
        inputsClone.splice(index, 1)
      }
    }
    changeInputs(inputsClone)
    setKeys(k)
    setLabels(l)
  }, [excludeFilters])

  useEffect(() => {
    axios
      .post(`/lookups/${isOrders ? 'order_statuses' : 'valuation_statuses'}`)
      .then((res) => {
        if (res.status == 200) {
          changeInputs({
            type: 1,
            id: 'status_id',
            options: res.data,
          })
        }
      })
    if (!isOrders && user.role_id == 1) {
      // setWithFiltersInputs({ key: 'status_id', value: '2' })
    }
  }, [])

  const classes = useStyles()
  const dispatch = useDispatch()

  const [labels, setLabels] = useReducer(labelReducer, [
    '',
    '',
    t('valuation.table.name'),
    'Numer zamówienia',
    'Partner',
    'Użytkownik',
    t('valuation.table.net_sum'),
    t('valuation.table.price_after_overlay'),
    t('valuation.table.created_at'),
    'Status',
  ])

  return (
    <>
      <StoreKeyProvider storeKey={customKey}>
        <WithFilters
          config={{
            page: 1,
            paginate: 25,
            orderType: 'desc',
            order: 'created_at',
            inputs: withfiltersInputs,
          }}
          endpoint={endpoint}
        >
          <Card elevation={0} className={classes.bg}>
            <CardContent className={classes.contentSt}>
              {showAdd && (
                <Button
                  className="absolute right-0 mr-4 valuation-button"
                  startIcon={<AddIcon />}
                  variant="contained"
                  color="primary"
                  onClick={() =>
                    dispatch(
                      openPopup({
                        component: 'ValuationFactory',
                        title: showAdd,
                        level: 1,
                        key: 'valuation-factory-popup',
                        maxWidth: 'xl',
                        values: { ...actionValues, filterKey: customKey },
                      })
                    )
                  }
                >
                  <span className="mt-1">{showAdd}</span>
                </Button>
              )}
              <CardHeader title={title} className={classes.contentPreview} />
              <DataInputs inputs={inputs} />
            </CardContent>
            <CardContent className={classes.content}>
              <DataTable
                rowStyles={{
                  is_custom: {
                    width: '30px',
                  },
                  file_count: {
                    width: '30px',
                  },
                  partner_name: {
                    maxWidth: '350px',
                  },
                  name: {
                    maxWidth: '350px',
                  },
                }}
                rowComponents={{
                  file_count: ShowAttachmentCell,
                  is_custom: ShowWarningCell,
                }}
                breakpoint={1851}
                actions={actions}
                labels={labels}
                keys={keys}
              />
            </CardContent>
          </Card>
        </WithFilters>
      </StoreKeyProvider>
    </>
  )
}
ValuationTable.propTypes = {
  endpoint: PropTypes.string,
  customKey: PropTypes.string,
  excludeFilters: PropTypes.array,
  actionValues: PropTypes.any,
  actions: PropTypes.string,
  title: PropTypes.string,
  showAdd: PropTypes.string,
  isOrders: PropTypes.bool,
}

export default ValuationTable
