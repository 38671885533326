import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import ValuationOrderDetailsBlockRow from './ValuationOrderDetailsBlockRow'
import React, { useEffect, useState } from 'react'
import ValuationOrderDetailsExtraRow from './ValuationOrderDetailsExtraRow'

const ValuationOrderDetails = ({ components = [], currencyAcronym }) => {
  const { t } = useTranslation()
  const [blocks, setBlocks] = useState([])
  const [extras, setExtras] = useState([])

  useEffect(() => {
    const blocks = []
    for (const component of components) {
      if (
        component.toShow &&
        (!component.without_blocks || !component.universal)
      ) {
        blocks.push(component)
      }
    }
    setBlocks(blocks)
  }, [components])

  useEffect(() => {
    const extras = []
    for (const component of components) {
      if (component.toShow && Object.keys(component.extras).length) {
        for (const key in component.extras) {
          const extra = component.extras[key]
          if (!extra.service && extra.id) {
            extras.push({ ...extra, windowsillsQuantity: component.quantity })
          }
        }
      }
    }
    setExtras(extras)
  }, [components])

  return (
    <div id="valuation-order-details-box">
      <Typography variant="h6" component="div">
        {t('Windowsills')}:
      </Typography>
      {blocks.length > 0 ? (
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell
                style={{
                  width: `10%`,
                  whiteSpace: 'nowrap',
                }}
              >
                Lp.
              </TableCell>
              <TableCell
                style={{
                  width: `35%`,
                  whiteSpace: 'nowrap',
                }}
              >
                {t('valuation_popup.data.table.name')}
              </TableCell>
              <TableCell
                style={{
                  width: `15%`,
                  whiteSpace: 'nowrap',
                }}
              >
                {t('valuation_popup.data.table.quantity')}
              </TableCell>
              <TableCell
                style={{
                  width: `20%`,
                  whiteSpace: 'nowrap',
                }}
              >
                {`${t('Price per piece')} (${currencyAcronym})`}
              </TableCell>
              <TableCell
                style={{
                  width: `20%`,
                  whiteSpace: 'nowrap',
                }}
              >
                {`${t('Net sum')} ${currencyAcronym}`}
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {blocks.map((block, index) => (
              <ValuationOrderDetailsBlockRow
                key={index}
                block={block}
                lp={index + 1}
              />
            ))}
          </TableBody>
        </Table>
      ) : (
        <div
          style={{
            textAlign: 'center',
            color: 'grey',
            fontSize: '20px',
            height: '100%',
          }}
        >
          {t('main.no_data')}
        </div>
      )}
      <Typography
        variant="h6"
        component="div"
        style={{
          marginTop: '12px',
        }}
      >
        {t('Finishes')}:
      </Typography>
      {extras.length > 0 ? (
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell
                style={{
                  width: `10%`,
                  whiteSpace: 'nowrap',
                }}
              >
                Lp.
              </TableCell>
              <TableCell
                style={{
                  width: `35%`,
                  whiteSpace: 'nowrap',
                }}
              >
                {t('valuation_popup.data.table.name')}
              </TableCell>
              <TableCell
                style={{
                  width: `15%`,
                  whiteSpace: 'nowrap',
                }}
              >
                {t('valuation_popup.data.table.quantity')}
              </TableCell>
              <TableCell
                style={{
                  width: `20%`,
                  whiteSpace: 'nowrap',
                }}
              >
                {`${t('Price per piece')} (${currencyAcronym})`}
              </TableCell>
              <TableCell
                style={{
                  width: `20%`,
                  whiteSpace: 'nowrap',
                }}
              >
                {`${t('Net sum')} ${currencyAcronym}`}
              </TableCell>
            </TableRow>
          </TableHead>
          {extras.length > 0 ? (
            <TableBody>
              {extras.map((extra, index) => (
                <ValuationOrderDetailsExtraRow
                  key={index}
                  extra={extra}
                  lp={index + 1}
                  windowsills
                />
              ))}
            </TableBody>
          ) : (
            <div
              style={{
                textAlign: 'center',
                color: 'grey',
                fontSize: '20px',
                height: '100%',
              }}
            >
              {t('main.no_data')}
            </div>
          )}
        </Table>
      ) : (
        <div
          style={{
            textAlign: 'center',
            color: 'grey',
            fontSize: '20px',
            height: '100%',
          }}
        >
          {t('main.no_data')}
        </div>
      )}
    </div>
  )
}

ValuationOrderDetails.propTypes = {
  components: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
  currencyAcronym: PropTypes.string,
}

export default ValuationOrderDetails
