import PageHeader from 'components/organisms/layout/PageHeader'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faMoneyBillWave } from '@fortawesome/pro-solid-svg-icons'
import makeStyles from '@mui/styles/makeStyles'
import PageContainer from 'components/organisms/layout/PageContainer'
import ValuationTable from 'components/molecules/valuations/ValuationTable'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'

const useStyles = makeStyles(() => ({
  icon: {
    width: '24px !important',
    height: '24px',
  },
}))

const Valuations = () => {
  const classes = useStyles()
  const { t } = useTranslation()
  const user = useSelector((state) => state.user.user)

  const getToExclude = () => {
    let toExclude = ['number', 'is_custom']
    if (user.role_id == 2) {
      toExclude.push('partner_name')
      toExclude.push('partner_id')
      toExclude.push('user_name')
      toExclude.push('user_id')
    }
    return toExclude
  }

  return (
    <div>
      <PageHeader
        icon={
          <FontAwesomeIcon icon={faMoneyBillWave} className={classes.icon} />
        }
        title={t('valuation.title')}
      />
      <PageContainer>
        <ValuationTable
          endpoint={
            user.role_id == 1
              ? '/valuations'
              : `/partners/${user.partner_id}/valuations`
          }
          showAdd={t('valuation.actions.add_valuation')}
          excludeFilters={getToExclude()}
          actionValues={{}}
        />
      </PageContainer>
    </div>
  )
}

export default Valuations
