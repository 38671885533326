// import PropTypes from 'prop-types'
import { useFormik } from 'formik'
import { Grid, DialogActions, Button, DialogContent } from '@mui/material'
import regex from '../../../../regex'
import FormikControl from '../../../molecules/FormikControl'
// import FormikSelect from '../../../molecules/FormikSelect'
import SaveIcon from '@mui/icons-material/Save'
import * as Yup from 'yup'
import axios from 'axios'
import { handleOpenAlert } from '../../../../lib/handleAlert'
import { closePopup } from '../../../../store/popupsSlice'
import { useDispatch } from 'react-redux'
import { refreshFilter } from '../../../../lib/handleFilters'

// import { useEffect, useState } from 'react'

let lastCheckedEmail = {
  email: null,
  status: false,
}

async function checkEmail(email) {
  if (lastCheckedEmail['email'] != email && regex.email.test(email)) {
    lastCheckedEmail['email'] = email
    let res = await axios.post('/users/email/check', { email })
    lastCheckedEmail['status'] = !res.data.exist
    return !res.data.exist
  } else {
    return lastCheckedEmail['status']
  }
}

const phone_numberRegExp = /^(\+[0-9]{1,})?[ ]?([0-9]{3}[- ]?){3}$/

const EmployeeFactory = ({ ...values }) => {
  const dispatch = useDispatch()

  const doctor_id = values.id

  const initEmail = values.id && values.email

  const formik = useFormik({
    initialValues: {
      email: values && values.email,
      phone_number: values && values.phone_number,
      firstname: values && values.firstname,
      lastname: values && values.lastname,
    },
    enableReinitialize: false,
    validationSchema: Yup.object({
      email: Yup.string()
        .email('E-mail niepoprawny')
        .required('Pole wymagane')
        .test('email-unique', 'Podany email jest zajęty', (value) =>
          value && value.length > 2 && value != initEmail
            ? checkEmail(value)
            : true
        ),
      firstname: Yup.string().required('Pole wymagane'),
      lastname: Yup.string().required('Pole wymagane'),
      phone_number: Yup.string()
        .matches(phone_numberRegExp, 'Numer telefonu jest niepoprawny')
        .required('Pole wymagane'),
    }),
    onSubmit: async (values) => {
      await axios
        .post(doctor_id ? `/doctors/${doctor_id}/edit` : '/doctors/store', {
          ...values,
        })
        .then((res) => {
          if (res.status == 200) {
            dispatch(closePopup('doctor-factory-popup'))
            refreshFilter('DoctorsKey')
          }
          handleOpenAlert({
            title: 'Sukces!',
            subtitle: 'Dodano nowego lekarza',
            type: 'success',
          })
        })
        .catch((err) => {
          handleOpenAlert(err.request)
        })
    },
  })

  return (
    <>
      <form onSubmit={formik.handleSubmit} onReset={formik.handleReset}>
        <DialogContent dividers={true}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Grid container spacing={2}>
                <Grid item md={6} xs={12}>
                  <FormikControl
                    variableName="type"
                    handleChange={formik.handleChange}
                    labelName="Typ"
                    formik={formik}
                  />
                </Grid>
                <Grid item md={6} xs={12}>
                  <FormikControl
                    variableName="unit"
                    handleChange={formik.handleChange}
                    labelName="Jednostka"
                    formik={formik}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Grid container spacing={2}>
                <Grid item md={6} xs={12}>
                  <FormikControl
                    variableName="width"
                    handleChange={formik.handleChange}
                    labelName="Szerokość"
                    formik={formik}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button
            startIcon={<SaveIcon />}
            variant="contained"
            color="primary"
            type="submit"
          >
            <span className="mt-1">Zapisz</span>
          </Button>
        </DialogActions>
      </form>
    </>
  )
}

export default EmployeeFactory
