import makeStyles from '@mui/styles/makeStyles'
import FormikControl from '../../molecules/FormikControl'
import { useFormik } from 'formik'
import { Card, Paper, Typography } from '@mui/material'
import * as Yup from 'yup'
import { useEffect, useState } from 'react'
import { useParams } from 'react-router'
import axios from 'axios'
import loginSetData from '../../../lib/loginSetData'
import LoadingButton from '@mui/lab/LoadingButton'
import { Link, useHistory } from 'react-router-dom'
import LogoAmbit from '../../../images/logo-ambit.png'
import LanguagePicker from '../translate/LanguagePicker'
import { useTranslation } from 'react-i18next'

const useStyles = makeStyles((theme) => ({
  loginMain: {
    background: 'unset !important',
    boxShadow: 'unset !important',
  },
  nested: {
    padding: theme.spacing(10),
  },
  listItem: {
    color: theme.palette.white.main,
  },
  logo: {
    maxWidth: '160px',
    width: 'fit-content',
    margin: '0 auto',
    [theme.breakpoints.down('md')]: {
      width: 'auto',
    },
  },
  paperBack: {
    background: theme.palette.primary.light,
    position: 'absolute',
    minHeight: '650px',
    minWidth: '530px',
    margin: '0px 0px 50px 55px',
    maxWidth: '650px',
    maxHeight: '650px',
    [theme.breakpoints.down('md')]: {
      display: 'none',
    },
  },

  error: {
    color: theme.palette.error.main,
  },

  paperFront: {
    position: 'relative',
    width: '440px',
    minWidth: '100%',
    height: '100%',
    boxShadow: '-55px 40px 2px 0px rgb(218, 218, 218)',
    [theme.breakpoints.down('md')]: {
      width: '100%',
      boxShadow: 'unset',
    },
  },

  formBg: {
    height: '100vh',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    gap: '16px',
    backgroundColor: theme.palette.secondary.mainNd,
    padding: theme.spacing(4),
    [theme.breakpoints.down('md')]: {
      padding: theme.spacing(2),
    },
  },
  languagePicker: {
    display: 'flex',
    width: '100%',
    justifyContent: 'flex-end',
    margin: '-1.5rem 0 0 1.5rem',
  },
  form: {
    height: '100%',
    width: '100%',
    maxWidth: '600px',
    display: 'flex',
    gap: '18px',
    flexDirection: 'column',
    justifyContent: 'center',
    padding: theme.spacing(4),
    [theme.breakpoints.down('md')]: {
      maxWidth: 'unset',
    },
  },
  btn: {
    background: theme.palette.primary.main,
    width: '100%',
    marginTop: '8px',
  },
}))

const Register = () => {
  const classes = useStyles()
  const { code } = useParams()
  const [loading, setLoading] = useState(false)
  const history = useHistory()
  const { t } = useTranslation()
  const formik = useFormik({
    initialValues: {
      password: '',
      passwordConfirmation: '',
      email: '',
      firstname: '',
      lastname: '',
    },
    validationSchema: Yup.object({
      password: Yup.string()
        .required(t('main.validation.required'))
        .min('8', t('main.validation.min', { length: 8 })),
      passwordConfirmation: Yup.string()
        .required(t('main.validation.required'))
        .oneOf(
          [Yup.ref('password'), null],
          t('main.validation.min', { length: 8 })
        )
        .min('8', t('main.validation.password_exact', { length: 8 })),
    }),
    onSubmit: (values) => {
      setLoading(true)
      axios
        .post('/auth/register', {
          code: decodeURIComponent(code),
          password: values.password,
        })
        .then(async (res) => {
          if (res.status == 200) {
            await loginSetData(res.data, t)
          }
        })
        .finally(() => {
          setLoading(false)
        })
    },
  })
  useEffect(() => {
    if (code && formik) {
      checkValidate(code, formik)
    }
  }, [code])

  const checkValidate = (code, formik) => {
    axios
      .post('/auth/code/check', { code: decodeURIComponent(code) })
      .then((res) => {
        if (res.data) {
          formik.setFieldValue('firstname', res.data.firstname)
          formik.setFieldValue('lastname', res.data.lastname)
          formik.setFieldValue('email', res.data.email)
        }
      })
      .catch(() => {
        history.push('/login')
      })
  }

  return (
    <form className={classes.formBg} onSubmit={formik.handleSubmit}>
      <Paper className={classes.loginMain}>
        <Card className={classes.paperFront} sx={{ minWidth: 'unset' }}>
          <div className={classes.form}>
            <LanguagePicker className={classes.languagePicker} />
            <Link to="/login">
              <img className={classes.logo} src={LogoAmbit} alt="logo" />
            </Link>
            <div>
              <Typography
                variant="h2"
                className="text-custom_red_main text-center"
              >
                {t('Register')}
              </Typography>
              <div className=" flex gap-4 flex-col justify-center w-9/12 mx-auto">
                <FormikControl
                  variableName="firstname"
                  handleChange={formik.handleChange}
                  labelName={t('Firstname')}
                  formik={formik}
                  disabled={true}
                />
                <FormikControl
                  variableName="lastname"
                  handleChange={formik.handleChange}
                  labelName={t('Lastname')}
                  formik={formik}
                  disabled={true}
                />
                <FormikControl
                  variableName="email"
                  handleChange={formik.handleChange}
                  labelName="E-mail"
                  formik={formik}
                  disabled={true}
                  type="email"
                />
                <FormikControl
                  variableName="password"
                  handleChange={formik.handleChange}
                  labelName={t('Password')}
                  formik={formik}
                  type="password"
                  inputProps={{
                    autoComplete: 'new-password',
                  }}
                />
                <FormikControl
                  variableName="passwordConfirmation"
                  handleChange={formik.handleChange}
                  labelName={t('Repeat password')}
                  formik={formik}
                  type="password"
                  inputProps={{
                    autoComplete: 'new-password',
                  }}
                />
              </div>
            </div>
            <div className=" flex justify-end gap-8 flex-wrap sm:justify-center sm:gap-2">
              <LoadingButton
                className={classes.btn}
                color="primary"
                variant="contained"
                type="submit"
                size="large"
                loading={loading}
              >
                <span className="mt-1"> {t('Register')}</span>
              </LoadingButton>
            </div>
          </div>
        </Card>
      </Paper>
    </form>
  )
}

export default Register
