import React, { memo } from 'react'
import PropTypes from 'prop-types'
// import getDeepValue from '../../../../lib/deepUtils'
import { useTranslation } from 'react-i18next'
import NumberInput from 'components/molecules/NumberInput'
import { makeStyles } from '@mui/styles'
// import ValuationCalculatorControl from './inputs/ValuationCalculatorControl'
//eslint-disable-next-line
const useStyles = makeStyles((theme) => ({
  flex: {
    display: 'flex',
    flexDirection: 'column',
  },
}))
const ValuationSummaryRowQuantityCell = ({
  component,
  setQuantity,
  readOnly,
  validate,
  edit,
}) => {
  const { t } = useTranslation()
  const classes = useStyles()
  const extras = component.extras || {}
  return (
    <>
      {component.without_blocks ? (
        <div className={classes.flex}>
          {Object.keys(extras).map((key) => (
            <span key={extras[key].id}>
              {extras[key].quantity} {t('qty.')}
            </span>
          ))}
        </div>
      ) : (
        <NumberInput
          name="quantity"
          helperText={t('main.validation.incorrect_value')}
          max="1000"
          min="1"
          disabled={readOnly || component.without_blocks || edit}
          error={
            validate &&
            (component.quantity < 1 || !Number.isInteger(+component.quantity))
          }
          handleChange={(value) => {
            setQuantity(value)
          }}
          value={component.quantity}
        />
      )}
    </>
  )
}

ValuationSummaryRowQuantityCell.propTypes = {
  component: PropTypes.object,
  setQuantity: PropTypes.func,
  readOnly: PropTypes.bool,
  validate: PropTypes.bool,
  edit: PropTypes.bool,
}

export default memo(ValuationSummaryRowQuantityCell)
