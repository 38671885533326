import StoreKeyContext from '../../contexts/StoreKeyContext'
import PropTypes from 'prop-types'

const StoreKeyProvider = ({ storeKey, children }) => (
  <StoreKeyContext.Provider value={storeKey}>
    {children}
  </StoreKeyContext.Provider>
)

StoreKeyProvider.propTypes = {
  storeKey: PropTypes.string,
  children: PropTypes.any,
}

export default StoreKeyProvider
