import { useEffect, useRef, useState } from 'react'
import {
  TextField,
  InputAdornment,
  List,
  Card,
  FormControl,
  ListItem,
} from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import axios from 'axios'
import SearchIcon from '@mui/icons-material/Search'
import PropTypes from 'prop-types'
import resolveFromObject from '../../lib/resolveFromObject'
import LoadingText from '../atoms/LoadingText'

const useStyles = makeStyles((theme) => ({
  root: {
    position: 'relative',
    width: '100%',
  },
  listContainer: {
    position: 'absolute',
    zIndex: 2,
    top: '100%',
    left: 0,
    width: '100%',
    backgroundColor: theme.palette.white.light,
  },
  extra: {
    display: 'flex;',
    flexDirection: 'column',
    flexWrap: 'wrap',
    alignContent: 'center',
    borderTop: '0.3px dotted lightgrey',
    fontSize: '0.7em',
    width: '100vw',
    padding: 'unset !important',
  },
  extraMain: {
    display: 'flex',
    flexWrap: 'wrap',
    flexDirection: 'column',
  },
  searchBar: {
    minWidth: '400px',
    [theme.breakpoints.down('custom_valuations_th')]: {
      minWidth: 'unset',
    },
  },
}))

const Search = ({
  emitValue,
  disabled,
  error,
  wholeObject = false,
  config = { key: 'id', label: 'name', separator: ' ' },
  url,
  minWidth = 'auto',
}) => {
  const classes = useStyles()

  const [value, setValue] = useState('')
  const [timeoutValue, setTimeoutValue] = useState(null)
  const [results, setResults] = useState([])
  const [show, _setShow] = useState(false)
  const [searching, setSearching] = useState(false)

  const mounted = useRef(false)

  useEffect(() => {
    mounted.current = true

    return () => {
      mounted.current = false
    }
  }, [])

  const setShow = (value) => {
    setTimeout(() => {
      if (mounted.current) {
        _setShow(value)
      }
    }, 250)
  }

  const search = (value) => {
    axios.post(url, { value }).then((res) => {
      setSearching(false)
      setResults(res.data)
    })
  }
  const handleChange = (result) => {
    setValue('')
    setResults([])
    emitValue(wholeObject ? result : result[config.key])
  }

  const changeValue = (value) => {
    setSearching(true)
    setValue(value)
    if (value.length >= 2) {
      if (timeoutValue) {
        clearTimeout(timeoutValue)
      }
      setTimeoutValue(
        setTimeout(() => {
          search(value)
        }, 800)
      )
    } else {
      setResults([])
    }
  }

  return (
    <>
      <FormControl
        fullWidth
        className={classes.root}
        error={error}
        style={{ minWidth: minWidth }}
      >
        <TextField
          className={classes.searchBar}
          size="small"
          error={error}
          disabled={disabled}
          // label={label}
          placeholder="Szukaj"
          autoComplete="off"
          value={value}
          onChange={(e) => changeValue(e.target.value)}
          onFocus={() => setShow(true)}
          onBlur={() => setShow(false)}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon fontSize="small" />
              </InputAdornment>
            ),
          }}
        />
        {show &&
          (results && results.length > 0 ? (
            <Card className={classes.listContainer} elevation={4}>
              <List className={classes.extraMain}>
                {results.map((result) => (
                  <>
                    <ListItem
                      style={{
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        borderBottom: '1px solid lightgray',
                      }}
                      key={`${result[config.key]}`}
                      button
                      onClick={() => {
                        handleChange(result)
                      }}
                    >
                      {resolveFromObject(
                        result,
                        config.label,
                        config.separator
                      )}
                      {config.extraObject && result[config.extraObject.key] && (
                        <div>
                          {result[config.extraObject.key].map((r, index) => (
                            <div
                              className={classes.extra}
                              key={`${index}-${result[config.key]}`}
                            >
                              {resolveFromObject(
                                r,
                                config.extraObject.label,
                                config.separator
                              )}
                            </div>
                          ))}
                        </div>
                      )}
                    </ListItem>
                  </>
                ))}
              </List>
            </Card>
          ) : (
            <Card className={classes.listContainer} elevation={4}>
              <ListItem>
                {value.length > 0 ? (
                  searching ? (
                    <LoadingText text="Wyszukiwanie" />
                  ) : (
                    'Brak wyników'
                  )
                ) : (
                  'Podaj szukaną frazę'
                )}
              </ListItem>
            </Card>
          ))}
      </FormControl>
    </>
  )
}

Search.propTypes = {
  emitValue: PropTypes.func,
  wholeObject: PropTypes.bool,
  disabled: PropTypes.bool,
  error: PropTypes.bool,
  helperText: PropTypes.string,
  url: PropTypes.string,
  redirectLink: PropTypes.string,
  config: PropTypes.object,
  minWidth: PropTypes.string,
}

export default Search
