import PropTypes from 'prop-types'

const NavGroup = ({ children, allowRoles, role }) => {
  return allowRoles.includes(role) ? <>{children}</> : <></>
}

NavGroup.propTypes = {
  allowRoles: PropTypes.array,
  role: PropTypes.number,
  children: PropTypes.any,
}

export default NavGroup
