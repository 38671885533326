import PropTypes from 'prop-types'
import { Card } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    color: theme.palette.white.main,
  },
}))

const LoginLayout = ({ children }) => {
  const classes = useStyles()

  return (
    <div id="wrapper">
      <main className="loginMain">
        <Card className={classes.root}>{children}</Card>
      </main>
    </div>
  )
}

LoginLayout.propTypes = {
  children: PropTypes.any,
}

export default LoginLayout
