import { TableCell, TableRow } from '@mui/material'

const ColorItemListHeaderForm = () => {
  return (
    <>
      <TableRow>
        <TableCell variant="head" style={{ width: '5%' }}>
          Lp.
        </TableCell>
        <TableCell variant="head" style={{ width: '50%' }}>
          Nazwa
        </TableCell>
        <TableCell variant="head" style={{ width: '25%' }}>
          Kolor
        </TableCell>
        <TableCell align="right" variant="head" style={{ width: '20%' }} />
      </TableRow>
    </>
  )
}
export default ColorItemListHeaderForm
