import { TableCell, TableRow } from '@mui/material'
import React from 'react'
import PropTypes from 'prop-types'
import UnitEnum from 'features/Unit/Enum/UnitEnum'

const MaterialPricingListHeaderForm = ({ material }) => {
  return (
    <>
      <TableRow>
        <TableCell
          variant="head"
          style={{
            width: `5%`,
          }}
        >
          Lp.
        </TableCell>
        <TableCell
          variant="head"
          style={{
            width: `15%`,
          }}
        >
          Kolor
        </TableCell>
        <TableCell
          variant="head"
          style={{
            width: '10%',
          }}
        >
          {material.unit_id === UnitEnum.SQUARE_METERS
            ? 'Grubość'
            : 'Szerokość'}
        </TableCell>
        <TableCell
          variant="head"
          style={{
            width: `10%`,
          }}
        >
          Procent ceny głównej
        </TableCell>
        {material.unit_id === UnitEnum.LINEAR_METERS && (
          <>
            <TableCell
              variant="head"
              style={{
                width: `15%`,
              }}
            >
              Cena hurtowa
            </TableCell>
            <TableCell
              variant="head"
              style={{
                width: `15%`,
              }}
            >
              Zwiększ cenę detaliczną o
            </TableCell>
          </>
        )}
        <TableCell
          variant="head"
          style={{
            width: `15%`,
          }}
        >
          Cena detaliczna
        </TableCell>
        <TableCell
          variant="head"
          style={{
            width: `15%`,
          }}
        >
          Akcja
        </TableCell>
      </TableRow>
    </>
  )
}
MaterialPricingListHeaderForm.propTypes = {
  material: PropTypes.object.isRequired,
}
export default MaterialPricingListHeaderForm
