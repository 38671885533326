import { Grid, IconButton } from '@mui/material'
import { Delete, Download, Edit } from '@mui/icons-material'
import { useDispatch, useSelector } from 'react-redux'
import PropTypes from 'prop-types'
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye'
import { openPopup } from '../../../../store/popupsSlice'
import axios from 'axios'
import AttachMoneyIcon from '@mui/icons-material/AttachMoney'
import { useContext, useState } from 'react'
import Confirmation from '../../popups/Confirmation'
import { handleOpenAlert } from '../../../../lib/handleAlert'
import { refreshFilter } from '../../../../lib/handleFilters'
import { useTranslation } from 'react-i18next'
import StoreKeyContext from '../../../../contexts/StoreKeyContext'
import downloadFile from '../../../../lib/downloadFile'

const ValuationsTableActions = ({ row }) => {
  const storeKey = useContext(StoreKeyContext)
  const dispatch = useDispatch()
  const user = useSelector((state) => state.user.user)
  const [confirmation, setConfirmation] = useState(false)
  const { t } = useTranslation()

  const downloadPdf = () => {
    downloadFile({
      route: `/valuations/${row.id}/generate`,
      fileName: `${t('valuation.download.valuation')}${row.id}.pdf`,
    })
  }

  return (
    <Grid
      container
      alignItems="center"
      justifyContent="center"
      direction="row"
      spacing={1}
      className="table-actions"
    >
      {confirmation && (
        <Confirmation
          level={5}
          maxWidth="xs"
          icon="Warning"
          title={t('valuation.alert.are_you_sure')}
          subtitle={t('valuation.alert.this_cannot_be_undone')}
          reject={() => {
            setConfirmation(false)
          }}
          success={() => {
            axios
              .post(`/valuations/${row.id}/delete`)
              .then(() => {
                handleOpenAlert({
                  title: t('main.alert.success_title'),
                  subtitle: t('valuation.alert.valuation_deleted'),
                  type: 'success',
                })
                refreshFilter(storeKey)
                setConfirmation(false)
              })
              .catch((err) => {
                handleOpenAlert(err.request)
              })
          }}
        />
      )}
      <Grid item className="valuation-button">
        <IconButton
          className="p-0"
          title={t('valuation_popup.data.valuation_preview')}
          size="small"
          onClick={() =>
            dispatch(
              openPopup({
                component: 'ValuationFactory',
                title: t('valuation_popup.data.valuation_preview'),
                level: 1,
                maxWidth: 'xl',
                values: {
                  ...row,
                  readOnly: true,
                },
                key: 'valuation-markup-factory-popup',
              })
            )
          }
        >
          <RemoveRedEyeIcon fontSize="small" color="primary" />
        </IconButton>
      </Grid>
      <>
        {(user.role_id == 1 ? [2, 3] : [1, 3]).includes(row.status_id) && (
          <Grid item className="valuation-button">
            <IconButton
              className="p-0"
              title={t('valuation.action_title.edit_valuation')}
              size="small"
              onClick={() =>
                dispatch(
                  openPopup({
                    component: 'ValuationFactory',
                    title:
                      user.role_id == 1
                        ? `${t('valuation.actions.edit_valuation')} ${
                            row.partner_name
                          }`
                        : t('valuation.action_title.edit_valuation'),
                    level: 1,
                    maxWidth: 'xl',
                    values: {
                      ...row,
                    },
                    key: 'valuation-factory-popup',
                  })
                )
              }
            >
              <Edit fontSize="small" />
            </IconButton>
          </Grid>
        )}
        {row.user_id == user.id && row.status_id != 2 && (
          <Grid item>
            <IconButton
              className="p-0"
              title={t('valuation.action_title.edit_overlay')}
              size="small"
              onClick={() =>
                dispatch(
                  openPopup({
                    component: 'ValuationMarkupFactory',
                    title: `${t('valuation.actions.edit_valuation')} ${
                      row.partner_name
                    }`,
                    level: 1,
                    maxWidth: 'xl',
                    values: {
                      ...row,
                    },
                    key: 'valuation-markup-factory-popup',
                  })
                )
              }
            >
              <AttachMoneyIcon fontSize="small" />
            </IconButton>
          </Grid>
        )}
        {row.status_id == 1 && (
          <Grid item>
            <IconButton
              title={t('valuation.action_title.delete_valuation')}
              size="small"
              onClick={() => setConfirmation(true)}
            >
              <Delete fontSize="small" color="error" />
            </IconButton>
          </Grid>
        )}
        <Grid item>
          <IconButton
            className="p-0"
            title={t('valuation.action_title.download_offer')}
            size="small"
            onClick={() => {
              downloadPdf()
            }}
          >
            <Download fontSize="small" />
          </IconButton>
        </Grid>
      </>
    </Grid>
  )
}

ValuationsTableActions.propTypes = {
  row: PropTypes.object,
}

export default ValuationsTableActions
