import { Grid, IconButton, TextField } from '@mui/material'
import { useState } from 'react'
import PropTypes from 'prop-types'
import Search from './Search'
import resolveFromObject from '../../lib/resolveFromObject'
import { Clear } from '@mui/icons-material'

const SearchSelect = ({
  emitValue,
  url,
  error = false,
  config = { key: 'id', label: 'name', separator: ' ' },
  label = null,
  edit = null,
}) => {
  // const [value, _setValue] = useState('')
  const [selectedObject, _setSelectedObject] = useState(null)

  const setSelectedObject = async (object) => {
    if (object) {
      await _setSelectedObject(object)
      setValue(
        resolveFromObject(object, config.label, config.separator),
        object[config.key]
      )
    } else {
      await setValue(null, null)
      _setSelectedObject(null)
    }
  }
  const setValue = (value, id) => {
    emitValue(id, value)
  }

  return (
    <Grid item xs={12}>
      {selectedObject || edit ? (
        <Grid className="text-center" container item xs={12}>
          <Grid md={11} item xs={9}>
            <TextField
              style={{
                width: `100%`,
              }}
              disabled={true}
              label={label}
              value={
                (edit && edit.name) ||
                resolveFromObject(
                  selectedObject,
                  config.label,
                  config.separator
                )
              }
              type="text"
              variant="outlined"
              size="small"
            />
          </Grid>
          <Grid md={1} item xs={3}>
            <IconButton
              className="p-2 m-0"
              color="secondary"
              component="span"
              onClick={() => {
                setSelectedObject(null)
              }}
              size="large"
            >
              <Clear />
            </IconButton>
          </Grid>
        </Grid>
      ) : (
        <Search
          config={config}
          label={label}
          error={error}
          emitValue={(value) => {
            setSelectedObject(value)
          }}
          wholeObject={true}
          url={url}
        />
      )}
    </Grid>
  )
}

SearchSelect.propTypes = {
  emitValue: PropTypes.func,
  url: PropTypes.string,
  config: PropTypes.object,
  label: PropTypes.string,
  error: PropTypes.bool,
  edit: PropTypes.object,
}

export default SearchSelect
