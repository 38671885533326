import React from 'react'
import makeStyles from '@mui/styles/makeStyles'
import PropTypes from 'prop-types'
import ListItemButton from '@mui/material/ListItemButton'
import { Link } from 'react-router-dom'
import ListItemIcon from '@mui/material/ListItemIcon'
import ListItemText from '@mui/material/ListItemText'
import NavGroup from '../../NavGroup'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useSelector } from 'react-redux'
import { faHandshake } from '@fortawesome/pro-solid-svg-icons'

// import Article from '@mui/icons-material/Article'

const useStyles = makeStyles((theme) => ({
  nested: {
    paddingLeft: theme.spacing(4),
  },
  fontSize: {
    fontSize: '0.875rem',
  },
  listItem: {
    minWidth: '38px',
    '&.MuiListItemButton-root:hover': {
      backgroundColor: 'red',
    },
    '&.Mui-selected:active': {
      background: 'rgba(0, 0, 0, 0.9)',
    },
    color: 'rgba(0, 0, 0, 0.6)',
  },
}))

const MESNavigationItems = function ({ location }) {
  const classes = useStyles()
  const user = useSelector((state) => state.user.user)

  return (
    <>
      <NavGroup role={user.role_id} allowRoles={[1]}>
        <Link to="/mes/orders">
          <ListItemButton selected={location[1] == 'orders'}>
            <ListItemIcon className={classes.listItem}>
              <FontAwesomeIcon icon={faHandshake} />
            </ListItemIcon>
            <ListItemText primary="Zamówienia" />
          </ListItemButton>
        </Link>
      </NavGroup>
    </>
  )
}

MESNavigationItems.propTypes = {
  location: PropTypes.array,
}

export default MESNavigationItems
