import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
// import setAuthToken from '../lib/setAuthToken'
import axios from 'axios'
import cookies from 'js-cookie'
import roleIdToPrefix from '../lib/roleIdToPrefix'
import UserRoleEnum from '../features/UserRole/Enums/UserRoleEnum'

const goodbyeRedirect = {
  // 0: '/goodbye',
  1: '/login',
  2: '/login'
}

const welcomeRedirect = {
  1: '/orders',
  2: '/home'
}

// eslint-disable-next-line
const domain = process.env.REACT_APP_BASE_COOKIE_DOMAIN

export const loadUser = createAsyncThunk(
  'user/loadUser',
  async (data, { dispatch, rejectWithValue }) => {
    const token = cookies.get('x-access-token', { domain })
    try {
      if (token) {
        await dispatch(setToken(token))
        const res = await axios.post('/auth/user', null, {
          headers: { Authorization: 'Bearer ' + token, ignoreAlerts: true }
        })
        if (res.status == 200) {
          dispatch(setUser(res.data))
          return res.data
        } else {
          dispatch(logout())
          return rejectWithValue(res.data)
        }
      } else {
        dispatch(logout())
        return rejectWithValue()
      }
    } catch (err) {
      dispatch(logout())
      return rejectWithValue('test2')
    }
  }
)

export const userSlice = createSlice({
  name: 'user',
  initialState: {
    user: null,
    isAuthenticated: false,
    loaded: false,
    token: null
  },
  reducers: {
    setUser: (state, action) => {
      state.user = action.payload
      let role = action.payload.role_id
      if (!role) {
        state.user.role_id = 0
        role = 0
      }
      state.isAuthenticated = true
      state.loaded = true
      cookies.set('x-role', role)
      // eslint-disable-next-line no-undef
      axios.defaults.baseURL = `${process.env.REACT_APP_BASE_API_URL}${roleIdToPrefix[role]}`
      if (
        !state.user.permissions.includes('viewB2B') &&
        !state.user.permissions.includes('view MES') &&
        state.user.role_id !== UserRoleEnum.PARTNER
      ) {
        userSlice.caseReducers.logout(state)
      } else if (
        !state.user.permissions.includes('viewB2B') &&
        state.user.permissions.includes('view MES')
      ) {
        // eslint-disable-next-line no-undef
        window.location = process.env.REACT_APP_BASE_MES_URL
      }
      if (
        window.location.pathname.includes('login') ||
        // window.location.pathname.includes('goodbye') ||
        window.location.pathname.includes('register') ||
        window.location.pathname.includes('password/reset')
      ) {
        window.location = welcomeRedirect[role]
          ? welcomeRedirect[role]
          : '/patients'
      }
    },
    setToken: (state, action) => {
      axios.defaults.headers.common[
        'Authorization'
        ] = `Bearer ${action.payload}`
      state.token = action.payload
      cookies.set('x-access-token', action.payload, { domain })
    },
    logout: (state) => {
      const role = cookies.get('x-role')
      delete axios.defaults.headers.common['Authorization']
      cookies.remove('x-access-token', { domain })
      cookies.remove('x-access-token')
      cookies.remove('x-role')
      state.token = null
      state.user = null
      state.isAuthenticated = false
      state.loaded = true
      if (
        !window.location.pathname.includes('login') &&
        !window.location.pathname.includes('register') &&
        // !window.location.pathname.includes('goodbye') &&
        !window.location.pathname.includes('password/reset')
      ) {
        window.location = goodbyeRedirect[role]
          ? goodbyeRedirect[role]
          : '/login'
      }
    },
    extraReducers: (builder) => {
      builder.addCase(loadUser.fulfilled, (state, action) => {
        state.isAuthenticated = true
        state.user = action.payload
        state.loaded = true
        if (!action.payload.role_id) {
          state.user.role_id = 0
        }
      })
      builder.addCase(loadUser.rejected, (state) => {
        state.isAuthenticated = false
        state.loaded = true
        state.user = null
        cookies.remove('x-access-token', { domain })
        cookies.remove('x-access-token')
        cookies.remove('x-role')
      })
    }
  }
})

export const { loginUser, setToken, logout, setUser } = userSlice.actions

export default userSlice.reducer
