import React from 'react'
import makeStyles from '@mui/styles/makeStyles'
import List from '@mui/material/List'
import B2BNavigationItems from './B2BNavigationItems'
import { useSelector } from 'react-redux'
import MESNavigationItems from './MESNavigationItems.js'
// import Article from '@mui/icons-material/Article'

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    maxWidth: 360,
    backgroundColor: theme.palette.white.light,
    borderBottom: '1px solid #ABAFB3',
    padding: 'unset !important',
    color: 'rgba(0, 0, 0, 0.6)',
  },
}))

export default function Navigation() {
  const classes = useStyles()
  const location = window.location.pathname.split('/').slice(1)
  const panelId = useSelector((state) => state.main.panelId)

  return (
    <List
      component="nav"
      aria-labelledby="nested-list-subheader"
      className={classes.root}
    >
      {panelId == 1 ? (
        <B2BNavigationItems location={location} />
      ) : (
        <MESNavigationItems location={location} />
      )}
    </List>
  )
}
