import { useContext, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import StoreKeyContext from '../../../contexts/StoreKeyContext'
import { useFormik } from 'formik'
import FormikControl from '../../molecules/FormikControl'
import FormikSelect from '../../molecules/FormikSelect'
import FormikDatePicker from '../../molecules/FormikDatePicker'
import {
  createYupSchema,
  createInitialValues,
} from '../../../lib/formikCreator'
import { changeInputs, resetFilter } from '../../../store/filtersSlice'
import { Button, Grid } from '@mui/material'
import * as yup from 'yup'
import PropTypes from 'prop-types'
import makeStyles from '@mui/styles/makeStyles'
import { FilterList, Clear } from '@mui/icons-material'
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown'
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp'
import { useState } from 'react'
import eventBus from '../../../lib/eventBus'
import { useTranslation } from 'react-i18next'

const useStyles = makeStyles((theme) => ({
  inputs: {
    display: 'flex',
    flex: '1 1 auto',
    width: '100%',
    justifyContent: 'flex-start',
    gap: theme.spacing(2),
  },
  btns: {
    display: 'flex',
    width: 'auto',
    justifyContent: 'flex-end',
    gap: theme.spacing(2),
    padding: `${theme.spacing(3)} 0`,
  },
  btn: {
    color: '#000',
    width: 'auto',
    display: 'flex',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    marginBottom: '16px',
  },
  selectBetween: {
    display: 'flex !important',
    gap: theme.spacing(2),
  },
  fontSize: {
    fontSize: '0.875rem',
  },
}))

const DataInputs = ({ inputs }) => {
  const dispatch = useDispatch()
  const { t } = useTranslation()

  const storeKey = useContext(StoreKeyContext)
  const filters = useSelector((state) => state.filters.filters[storeKey])

  const formik = useFormik({
    enableReinitialize: false,
    initialValues: {},
    validationSchema: yup.object().shape(inputs.reduce(createYupSchema, {})),
    onSubmit: (values) => {
      dispatch(changeInputs({ key: storeKey, values }))
    },
    onReset: (values) => {
      dispatch(resetFilter({ key: storeKey }))
      eventBus.dispatch('newFilterValues', { ...values })
    },
  })

  const handleChange = async (key, value) => {
    await formik.setFieldValue(key, value)
    let temp = JSON.parse(JSON.stringify(formik.values))
    temp[key] = value
    eventBus.dispatch('newFilterValues', { ...temp })
  }

  useEffect(() => {
    eventBus.on('forceFilterValue', ({ key, value, filter }) => {
      if (filter == storeKey) formik.setFieldValue(key, value)
    })

    return eventBus.remove('forceFilterValue')
  }, [])

  useEffect(() => {
    filters &&
      filters.inputs &&
      formik &&
      formik.setValues(createInitialValues(filters.inputs))
  }, [filters])

  const classes = useStyles()
  const [set, isSet] = useState(true)
  return (
    <>
      <div>
        <Button
          className={classes.btn}
          variant="text"
          endIcon={set ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}
          onClick={() => isSet(!set)}
        >
          <span className="mt-1">{t('main.filter.title')}</span>
        </Button>
        {set && (
          <form
            onSubmit={formik.handleSubmit}
            onReset={formik.handleReset}
            className="filter-inputs"
          >
            <Grid container spacing={2} item xs={12}>
              {inputs.map((input) => {
                return input.type == 'select' ? (
                  <Grid
                    key={input.id}
                    item
                    lg={input.size * 3 || 3}
                    md={input.size * 4 || 4}
                    xs={12}
                  >
                    <FormikSelect
                      className={classes.fontSize}
                      labelName={input.label}
                      variableName={input.id}
                      value={formik.values[input.id]}
                      formik={formik}
                      options={input.options}
                      id={input.id + '_filter'}
                      disabled={input.disabled}
                      handleChange={handleChange}
                      config={
                        input.config
                          ? input.config
                          : { key: 'id', label: 'name' }
                      }
                      size="small"
                    />
                  </Grid>
                ) : input.type == 'date' ? (
                  <Grid
                    key={input.id}
                    item
                    lg={input.size * 3 || 3}
                    md={input.size * 4 || 4}
                    xs={12}
                  >
                    <FormikDatePicker
                      className={classes.fontSize}
                      labelName={input.label}
                      variableName={input.id}
                      handleChange={formik.handleChange}
                      formik={formik}
                      id={input.id + '_filter'}
                      size="small"
                    />
                  </Grid>
                ) : input.type == 'date-between' ? (
                  <Grid
                    key={input.id}
                    item
                    lg={input.size * 3 || 3}
                    md={input.size * 4 || 4}
                    xs={12}
                    className={classes.selectBetween}
                  >
                    <FormikDatePicker
                      className={classes.fontSize}
                      labelName={input.label + t('main.filter.from')}
                      variableName={`min_${input.id}`}
                      handleChange={formik.handleChange}
                      formik={formik}
                      id={'min_' + input.id + '_filter'}
                      size="small"
                    />
                    <FormikDatePicker
                      className={classes.fontSize}
                      labelName={input.label + t('main.filter.to')}
                      variableName={`max_${input.id}`}
                      handleChange={formik.handleChange}
                      formik={formik}
                      id={'max_' + input.id + '_filter'}
                      size="small"
                    />
                  </Grid>
                ) : input.type == 'number-between' ? (
                  <Grid
                    key={input.id}
                    item
                    lg={input.size * 3 || 3}
                    md={input.size * 4 || 4}
                    xs={12}
                    className={classes.selectBetween}
                  >
                    <FormikControl
                      className={classes.fontSize}
                      labelName={input.label + t('main.filter.from')}
                      variableName={`min_${input.id}`}
                      type="number"
                      id={'min_' + input.id + '_filter'}
                      handleChange={formik.handleChange}
                      formik={formik}
                      size="small"
                    />
                    <FormikControl
                      className={classes.fontSize}
                      labelName={input.label + t('main.filter.to')}
                      variableName={`max_${input.id}`}
                      type="number"
                      id={'max_' + input.id + '_filter'}
                      handleChange={formik.handleChange}
                      formik={formik}
                      size="small"
                    />
                  </Grid>
                ) : (
                  <Grid
                    key={input.id}
                    item
                    lg={input.size * 3 || 3}
                    md={input.size * 4 || 4}
                    xs={12}
                  >
                    <FormikControl
                      className={classes.fontSize}
                      variableName={input.id}
                      type={input.type}
                      id={input.id + '_filter'}
                      handleChange={formik.handleChange}
                      labelName={input.label}
                      formik={formik}
                      size="small"
                    />
                  </Grid>
                )
              })}
            </Grid>
            <div className={classes.btns}>
              <Button variant="contained" type="reset" startIcon={<Clear />}>
                <span className="mt-1">{t('main.button.clear')}</span>
              </Button>
              <Button
                variant="contained"
                color="primary"
                type="submit"
                startIcon={<FilterList />}
              >
                <span className="mt-1">{t('main.button.filter')}</span>
              </Button>
            </div>
          </form>
        )}
      </div>
    </>
  )
}

DataInputs.propTypes = {
  inputs: PropTypes.array,
}

export default DataInputs
