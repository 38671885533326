import React, { useState } from 'react'
import PropTypes from 'prop-types'
import {
  Grid,
  DialogActions,
  Button,
  Typography,
  Dialog,
  DialogTitle,
  DialogContent,
} from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import { Check, Block, Warning, Help } from '@mui/icons-material'
import LoadingButton from '@mui/lab/LoadingButton'
import { useTranslation } from 'react-i18next'

const useStyles = makeStyles((theme) => ({
  root: {
    textAlign: 'center',
  },
  icon: {
    background: theme.palette.background.default,
    borderRadius: '1em',
    padding: theme.spacing(2),
    fontSize: '5em',
  },
  dialogWrapper: {
    padding: theme.spacing(2),
  },
  dialogActions: {
    justifyContent: 'space-between',
  },
  dialogContent: {
    overflowY: 'unset',
  },
}))

const icons = {
  Warning: Warning,
  Help: Help,
}

const Confirmation = ({
  icon,
  title,
  subtitle,
  success,
  reject,
  maxWidth,
  dialogTitle = '',
  showDialogTitle = true,
  level,
}) => {
  const classes = useStyles()
  const [loading, setLoading] = useState(false)
  const { t } = useTranslation()
  const initSuccess = () => {
    setLoading(true)
    try {
      success()
    } catch (e) {
      setLoading(false)
    }
  }
  return (
    <Dialog
      open={true}
      maxWidth={maxWidth}
      classes={{ paper: classes.dialogWrapper }}
      style={{ zIndex: 1300 + level }}
      fullWidth={true}
      disableEscapeKeyDown={true}
    >
      {showDialogTitle && (
        <DialogTitle>
          <Typography variant="h3" component="div" align="center">
            {dialogTitle || t('main.alert.warning')}
          </Typography>
        </DialogTitle>
      )}
      <DialogContent className={classes.dialogContent}>
        <Grid container spacing={3} className={classes.root}>
          <Grid item xs={12}>
            {React.createElement(icons[icon], {
              className: classes.icon,
            })}
          </Grid>
          <Grid item xs={12}>
            <Typography variant="h5">{title}</Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography>{subtitle}</Typography>
          </Grid>
          <Grid item xs={12}>
            <DialogActions className={classes.dialogActions}>
              <LoadingButton
                startIcon={<Block />}
                variant="contained"
                color="white"
                loading={loading}
                onClick={reject}
              >
                <span className="mt-1">{t('main.alert.no')}</span>
              </LoadingButton>
              <LoadingButton
                startIcon={<Check />}
                variant="contained"
                color="primary"
                onClick={initSuccess}
                loading={loading}
                loadingPosition="start"
              >
                <span className="mt-1">{t('main.alert.yes')}</span>
              </LoadingButton>
            </DialogActions>
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  )
}

Confirmation.propTypes = {
  icon: PropTypes.string,
  title: PropTypes.string,
  subtitle: PropTypes.string,
  dialogTitle: PropTypes.string,
  showDialogTitle: PropTypes.bool,
  success: PropTypes.func,
  reject: PropTypes.func,
  level: PropTypes.number,
  maxWidth: PropTypes.string,
}

export default Confirmation
