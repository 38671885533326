import PropTypes from 'prop-types'
import SaveIcon from '@mui/icons-material/Save'
import LoadingButton from '@mui/lab/LoadingButton'
import { useSelector } from 'react-redux'
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart'
import { Checkbox, Grid } from '@mui/material'
import AttachMoneyIcon from '@mui/icons-material/AttachMoney'
import { useTranslation } from 'react-i18next'
import { Done } from '@mui/icons-material'

const ValuationButtons = ({
  loading = false,
  universal = true,
  statusId = null,
  ownerId,
  onSubmit,
  formik,
}) => {
  const user = useSelector((state) => state.user.user)
  const { t } = useTranslation()
  const submit = (status, markup = false) => {
    if (formik.values.send) {
      status = universal ? 4 : 2
    }
    onSubmit(status, markup)
  }
  return (
    <>
      <Grid
        className="flex items-center justify-end flex-wrap"
        gap={2}
        container
        item
      >
        {(!statusId || statusId == 1) && user.role_id == 2 && (
          <>
            <Grid
              item
              style={{
                border: '2px solid #bc2b2a',
                borderRadius: '4px',
                padding: '0 10px',
                width: 'fit-content',
                textAlign: 'center',
              }}
            >
              <label
                style={{
                  fontSize: '15px',
                  fontWeight: 'bold',
                }}
              >
                {universal
                  ? t('valuation_popup.data.button.ordering')
                  : t('Send to verify')}
              </label>
              <Checkbox
                size="large"
                color="primary"
                checked={Boolean(formik.values.send)}
                onChange={(e) => {
                  formik.setFieldValue('send', e.target.checked)
                }}
              />
            </Grid>
            {(universal || statusId > 2) && (
              <LoadingButton
                startIcon={<AttachMoneyIcon />}
                variant="contained"
                color="primary"
                type="button"
                disabled={formik.values.send}
                onClick={() => {
                  submit(1, true)
                }}
                loading={loading}
                loadingPosition="start"
              >
                {t('valuation_popup.data.button.save_and_add_overlay')}
              </LoadingButton>
            )}
          </>
        )}
        {((user.role_id == 1 && [4].includes(statusId)) ||
          (user.role_id == 2 && (!statusId || [1].includes(statusId)))) && (
          <LoadingButton
            startIcon={<SaveIcon />}
            variant="contained"
            color="primary"
            type="button"
            onClick={() => {
              submit(statusId || 1)
            }}
            loading={loading}
            loadingPosition="start"
          >
            <span className="mt-1">
              {formik.values.send
                ? t('valuation_popup.data.button.send')
                : t('valuation_popup.data.button.save')}
            </span>
          </LoadingButton>
        )}
        {(statusId == 3 ||
          ((!statusId || (statusId == 1 && ownerId == user.id)) &&
            user.role_id == 1)) && (
          <LoadingButton
            startIcon={<ShoppingCartIcon />}
            variant="contained"
            color="primary"
            type="button"
            onClick={() => {
              submit(4)
            }}
            loading={loading}
            loadingPosition="start"
          >
            <span className="mt-1">
              {t('valuation_popup.data.button.order')}
            </span>
          </LoadingButton>
        )}
        {statusId == 2 && user.role_id == 1 && (
          <LoadingButton
            startIcon={<Done />}
            variant="contained"
            color="primary"
            type="button"
            onClick={() => {
              submit(3)
            }}
            loading={loading}
            loadingPosition="start"
          >
            <span className="mt-1">{t('Accept')}</span>
          </LoadingButton>
        )}
      </Grid>
    </>
  )
}
ValuationButtons.propTypes = {
  loading: PropTypes.bool,
  universal: PropTypes.bool,
  statusId: PropTypes.number,
  ownerId: PropTypes.number,
  onSubmit: PropTypes.func,
  formik: PropTypes.object,
}

export default ValuationButtons
