import {
  changeDownloadProgress,
  handleCloseDownload,
  handleOpenDownload,
} from './handleDownload'
import axios from 'axios'
import downloader from './downloader'

export default function downloadFile({ route, fileName }) {
  handleOpenDownload(fileName)
  axios
    .post(route, null, {
      responseType: 'blob',
      headers: {
        Accept: '',
      },
      onDownloadProgress: (progressEvent) => {
        changeDownloadProgress(
          fileName,
          Math.round((progressEvent.loaded * 100) / progressEvent.total)
        )
      },
    })
    .then((res) => {
      downloader({
        data: res.data,
        name: fileName,
      })
      handleCloseDownload(fileName)
    })
    .catch(() => {
      handleCloseDownload(fileName)
    })
}
