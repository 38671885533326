import PropTypes from 'prop-types'
import React, { useEffect } from 'react'
import { useFormik } from 'formik'
import { refreshFilter } from '../../../../lib/handleFilters'
import eventBus from '../../../../lib/eventBus'
import regex from '../../../../regex'
import {
  Typography,
  Grid,
  DialogActions,
  DialogContent,
  InputAdornment,
  // CircularProgress,
} from '@mui/material'
import FormikControl from '../../../molecules/FormikControl'
// import FormikSelect from '../../../molecules/FormikSelect'
import SaveIcon from '@mui/icons-material/Save'
import * as Yup from 'yup'
import axios from 'axios'
import { handleOpenAlert } from '../../../../lib/handleAlert'
import { closePopup } from '../../../../store/popupsSlice'
import { useDispatch, useSelector } from 'react-redux'
import { useState } from 'react'
// import moment from 'moment'
// import FormikDatePicker from '../../../molecules/FormikDatePicker'
// import makeStyles from. '@mui/styles/makeStyles'
import LoadingButton from '@mui/lab/LoadingButton'
import SyncIcon from '@mui/icons-material/Sync'
import FormikSelect from '../../../molecules/FormikSelect'

const checkNipSumControl = (nip) => {
  let arrSteps = [6, 5, 7, 2, 3, 4, 5, 6, 7]
  let intSum = 0

  for (let i = 0; i < 9; i++) {
    intSum += arrSteps[i] * nip[i]
  }

  let int = intSum % 11
  let intControlNr = int === 10 ? 0 : int

  if (intControlNr == nip[9]) {
    return true
  }

  return false
}

const PartnerFactory = ({ ...values }) => {
  const partner_id = values.id

  const [lastCheckedNip, setlastCheckedNip] = useState({
    value: values.nip || '',
    avaiable: true,
    valid: false,
    validControlSum: true,
  })
  const [lastCheckedEmail, setlastCheckedEmail] = useState({
    email: values.email,
    exist: false,
  })
  const [timeoutValue, setTimeoutValue] = useState(null)
  const [states, setStates] = useState([])
  const [daysOfWeek, setDaysOfWeek] = useState([])
  const user = useSelector((state) => state.user.user)
  const languages = useSelector((state) => state.language.languages)

  const [priceLists, setPriceLists] = useState([])

  const doesExistEmail = (email) => {
    if (regex.email.test(email)) {
      if (lastCheckedEmail.value != email) {
        axios
          .post('/users/email/check', {
            email,
            user_id: values.user_id,
          })
          .then((res) => {
            setValidationLoading(false)
            setlastCheckedEmail({
              exist: res.data.exist,
              value: email,
            })
          })
      }
    } else {
      setlastCheckedEmail({
        exist: false,
        value: email,
      })
      setValidationLoading(false)
    }
  }

  const dispatch = useDispatch()

  const formik = useFormik({
    initialValues: {
      phone_number: '',
      email: '',
      firstname: '',
      lastname: '',
      nip: '',
      vat: 23,
      discount: '0',
      name: '',
      state_id: '',
      week_day_id: 1,
      lang_id: 1,
      postcode: '',
      city: '',
      id: '',
      street: '',
    },
    enableReinitialize: false,
    validationSchema: Yup.object({
      name: Yup.string().required('Pole wymagane'),
      state_id: Yup.number().required('Pole wymagane'),
      price_list_id: Yup.number().required('Pole wymagane'),
      vat: Yup.number().min(0).required('Pole wymagane'),
      postcode: Yup.string()
        .required('Pole wymagane')
        .matches(regex.postcode, 'Błędny format'),
      city: Yup.string().required('Pole wymagane'),
      street: Yup.string().required('Pole wymagane'),
      discount: Yup.number()
        .nullable()
        .min('0', 'Minimalny rabat 0%')
        .max('50', 'Maksymalny rabat 50%'),

      nip: Yup.string()
        .required('Pole wymagane')
        .nullable()
        .min('10', 'Nip musi zawierać 10 cyfr')
        .max('10', 'Nip musi zawierać 10 cyfr')
        .test('nip-check-exist', 'Podany nip jest zajęty', function () {
          return lastCheckedNip.avaiable
        })
        .test(
          'nip-check-sum-control',
          'Błędna suma kontrolna nipu',
          function () {
            return lastCheckedNip.validControlSum
          }
        ),

      firstname: Yup.string().when('id', {
        is: (id) => !id,
        then: Yup.string().required('Pole wymagane'),
      }),
      lastname: Yup.string().when('id', {
        is: (id) => !id,
        then: Yup.string().required('Pole wymagane'),
      }),
      lang_id: Yup.string().required('Pole wymagne'),
      email: Yup.string()
        .matches(regex.email, 'Błędny format emaila')
        .when('id', {
          is: (id) => !id,
          then: Yup.string().required('Pole wymagane'),
        })
        .test('email-unique', 'Podany email jest zajęty', function () {
          return !lastCheckedEmail.exist
        }),
      phone_number: Yup.string()
        .when('id', {
          is: (id) => !id,
          then: Yup.string().required('Pole wymagane'),
        })
        .matches(regex.phone, 'Numer telefonu jest niepoprawny'),
    }),
    onSubmit: async (values) => {
      if (!partner_id) {
        store(values)
        setLoading(true)
      } else {
        edit(values)
        setLoading(true)
      }
    },
  })

  const loadPriceLists = () => {
    return new Promise(function (resolve) {
      axios.get(`/price_lists`).then((res) => {
        if (res.status == 200) {
          resolve(res.data)
        }
      })
    })
  }
  const loadStates = () => {
    return new Promise(function (resolve) {
      axios.post(`/lookups/states`).then((res) => {
        if (res.status == 200) {
          resolve(res.data)
        }
      })
    })
  }
  const validNip = (nip) => {
    setValidationLoading(true)
    setlastCheckedNip({
      avaiable: true,
      valid: false,
      value: null,
      validControlSum: true,
    })
    const uninterceptedAxiosInstance = axios.create()
    uninterceptedAxiosInstance
      .post(`/gus`, { nip: nip })
      .then((res) => {
        formik.setFieldValue('name', res.data.name)
        formik.setFieldValue('state_id', res.data.state_id)
        formik.setFieldValue('postcode', res.data.postcode)
        formik.setFieldValue('city', res.data.city)
        formik.setFieldValue('street', res.data.street)
        setlastCheckedNip({
          avaiable: values.id == res.data.partner_id || !res.data.partner_id,
          valid: true,
          value: nip,
          validControlSum: true,
        })
        setValidationLoading(false)
      })
      .catch(async () => {
        setlastCheckedNip({
          avaiable: true,
          valid: false,
          value: nip,
          validControlSum: checkNipSumControl(nip),
        })
        setValidationLoading(false)
      })
  }

  const loadDaysOfWeek = () => {
    return new Promise(function (resolve) {
      axios.post('/lookups/week_days').then((response) => {
        resolve(response.data)
      })
    })
  }

  useEffect(async () => {
    setStates(await loadStates())
    setPriceLists(await loadPriceLists())
    setDaysOfWeek(await loadDaysOfWeek())
  }, [])

  useEffect(() => {
    setValidationLoading(true)
    if (timeoutValue) {
      clearTimeout(timeoutValue)
    }
    setTimeoutValue(
      setTimeout(() => {
        doesExistEmail(formik.values.email)
      }, 300)
    )
  }, [formik.values.email])

  useEffect(() => {
    if (!partner_id) {
      let nip = formik.values.nip
      nip = nip.replaceAll(/\D/g, '')
      formik.setFieldValue('nip', nip)
      if (/^[0-9]{10}$/.test(nip) && nip != lastCheckedNip.value) {
        validNip(nip)
      }
    }
  }, [formik.values.nip])

  useEffect(() => {
    if (partner_id) {
      axios.post(`/partners/${partner_id}`, null).then((res) => {
        formik.setValues({
          ...res.data,
        })
        validNip(res.data.nip)
      })
    }
  }, [partner_id])

  const store = (values) => {
    axios
      .post('/partners/store', {
        ...values,
      })
      .then((res) => {
        if (res.status == 200 || res.status == 201) {
          dispatch(closePopup('partner-factory-popup'))
          eventBus.dispatch('refreshPartner', { partner_id: res.data.id })

          refreshFilter('PartnersKey')
          setLoading(false)
        }
        handleOpenAlert({
          title: 'Sukces!',
          subtitle: 'Dodano nowego partnera',
        })
      })
      .catch((err) => {
        handleOpenAlert(err.request.status)
        setLoading(false)
      })
  }

  const edit = (values) => {
    axios
      .post(`/partners/${partner_id}/edit`, {
        ...values,
      })
      .then((res) => {
        if (res.status == 200 || res.status == 201) {
          dispatch(closePopup('partner-factory-popup'))
          refreshFilter('PartnersKey')
          eventBus.dispatch('loadPartner')
        }
        handleOpenAlert({
          title: 'Sukces!',
          subtitle: 'Edytowano partnera',
        })
      })
      .catch((err) => {
        handleOpenAlert(err.request.status)
        setLoading(false)
      })
  }

  const [loading, setLoading] = useState(false)
  const [validationLoading, setValidationLoading] = useState(false)

  // function handleClick() {
  //   setLoading(true)
  // }

  // const classes = styles()

  return (
    <>
      <form onSubmit={formik.handleSubmit} onReset={formik.handleReset}>
        <>
          <DialogContent dividers={true}>
            <Grid container spacing={3}>
              {!partner_id && (
                <Grid item xs={12}>
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <Typography variant="subtitle1" component="div">
                        Dane osobowe
                      </Typography>
                      {/* {loading && partner_id && (
                      <CircularProgress
                        className={classes.loading}
                        color="primary"
                        thickness={2}
                        size="4rem"
                      />
                    )} */}
                    </Grid>
                    <Grid item md={6} xs={12}>
                      <FormikControl
                        variableName="firstname"
                        handleChange={formik.handleChange}
                        labelName="Imię"
                        formik={formik}
                      />
                    </Grid>
                    <Grid item md={6} xs={12}>
                      <FormikControl
                        variableName="lastname"
                        handleChange={formik.handleChange}
                        labelName="Nazwisko"
                        formik={formik}
                      />
                    </Grid>
                    <Grid item md={6} xs={12}>
                      <FormikControl
                        variableName="nip"
                        handleChange={formik.handleChange}
                        labelName="Nip"
                        formik={formik}
                      />
                    </Grid>
                  </Grid>
                </Grid>
              )}
              <Grid item xs={12}>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <Typography variant="subtitle1" component="div">
                      Dane firmy
                      {lastCheckedNip.value === null && (
                        <SyncIcon className="rotate" title="Pobieranie" />
                      )}
                    </Typography>
                  </Grid>
                  <Grid item md={6} xs={12}>
                    <FormikControl
                      disabled={lastCheckedNip.valid && !!formik.values.name}
                      variableName="name"
                      handleChange={formik.handleChange}
                      labelName="Nazwa firmy"
                      formik={formik}
                    />
                  </Grid>
                  <Grid item md={6} xs={12}>
                    <FormikSelect
                      variableName="state_id"
                      value={formik.values['state_id']}
                      labelName="Województwo"
                      formik={formik}
                      disabled={
                        lastCheckedNip.valid && !!formik.values.state_id
                      }
                      options={states}
                    />
                  </Grid>
                  <Grid item md={6} xs={12}>
                    <FormikControl
                      disabled={
                        lastCheckedNip.valid && !!formik.values.postcode
                      }
                      variableName="postcode"
                      handleChange={formik.handleChange}
                      labelName="Kod pocztowy"
                      formik={formik}
                    />
                  </Grid>
                  <Grid item md={6} xs={12}>
                    <FormikControl
                      disabled={lastCheckedNip.valid && !!formik.values.city}
                      variableName="city"
                      handleChange={formik.handleChange}
                      labelName="Miasto"
                      formik={formik}
                    />
                  </Grid>
                  <Grid item md={6} xs={12}>
                    <FormikControl
                      disabled={lastCheckedNip.valid && !!formik.values.street}
                      variableName="street"
                      handleChange={formik.handleChange}
                      labelName="Ulica"
                      formik={formik}
                    />
                  </Grid>
                  <Grid item md={6} xs={12}>
                    <FormikSelect
                      variableName="lang_id"
                      value={formik.values['lang_id']}
                      labelName="Język"
                      formik={formik}
                      config={{
                        key: 'id',
                        label: 'name',
                      }}
                      options={languages || []}
                    />
                  </Grid>
                  <Grid item md={6} xs={12}>
                    <FormikSelect
                      variableName="week_day_id"
                      value={formik.values['week_day_id']}
                      labelName="Dzień dostawy"
                      formik={formik}
                      config={{
                        key: 'id',
                        label: 'name',
                      }}
                      options={daysOfWeek || []}
                    />
                  </Grid>
                  <Grid item md={6} xs={12}>
                    <FormikSelect
                      variableName="price_list_id"
                      value={formik.values['price_list_id']}
                      labelName="Cennik"
                      formik={formik}
                      config={{
                        key: 'id',
                        label: 'name',
                      }}
                      options={priceLists || []}
                    />
                  </Grid>
                  <Grid item md={6} xs={12}>
                    <FormikControl
                      variableName="vat"
                      handleChange={formik.handleChange}
                      labelName="Vat"
                      formik={formik}
                      type="number"
                      min="0"
                      value={formik.values.vat}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="start">%</InputAdornment>
                        ),
                      }}
                    />
                  </Grid>
                  {user.permissions &&
                    user.permissions.includes('setDiscount Partner') && (
                      <Grid item md={6} xs={12}>
                        <FormikControl
                          variableName="discount"
                          handleChange={formik.handleChange}
                          labelName="Rabat"
                          formik={formik}
                          type="number"
                          value={formik.values.discount}
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="start">
                                %
                              </InputAdornment>
                            ),
                          }}
                        />
                      </Grid>
                    )}
                </Grid>
              </Grid>

              {!partner_id && (
                <Grid item xs={12}>
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <Typography variant="subtitle1" component="div">
                        Dane kontaktowe
                      </Typography>
                    </Grid>
                    <Grid item md={6} xs={12}>
                      <FormikControl
                        type="tel"
                        variableName="phone_number"
                        handleChange={formik.handleChange}
                        labelName="Nr. Tel."
                        formik={formik}
                      />
                    </Grid>
                    <Grid item md={6} xs={12}>
                      <FormikControl
                        variableName="email"
                        handleChange={formik.handleChange}
                        labelName="E-mail"
                        formik={formik}
                      />
                    </Grid>
                  </Grid>
                </Grid>
              )}
            </Grid>
          </DialogContent>
          <DialogActions>
            <LoadingButton
              startIcon={<SaveIcon />}
              variant="contained"
              color="primary"
              type="submit"
              // onClick={setLoading}
              disabled={validationLoading}
              loading={loading}
              loadingPosition="start"
            >
              <span className="mt-1">Zapisz</span>
            </LoadingButton>
          </DialogActions>
        </>
      </form>
    </>
  )
}

PartnerFactory.propTypes = {
  values: PropTypes.object,
}

export default PartnerFactory
