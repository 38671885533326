import {
  openDownload,
  closeDownload,
  changeProgress,
} from '../store/downloadsSlice'
import store from '../store/store'

export const handleOpenDownload = (content) => {
  store.dispatch(openDownload({ title: content, key: content }))
}

export const handleCloseDownload = (key) => {
  store.dispatch(closeDownload(key))
}

export const changeDownloadProgress = (key, progress) => {
  store.dispatch(changeProgress({ key, progress }))
}
