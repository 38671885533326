import React from 'react'
import ReactDOM from 'react-dom'
import './index.css'
import './assets/main.scss'
import './assets/muiOverrides.scss'
import App from './App'
// import Head from './components/atoms/Head'
import { CssBaseline } from '@mui/material'
import { library } from '@fortawesome/fontawesome-svg-core'
import { fal } from '@fortawesome/pro-light-svg-icons'
import { fas } from '@fortawesome/pro-solid-svg-icons'
import { far } from '@fortawesome/pro-regular-svg-icons'
import { fad } from '@fortawesome/pro-duotone-svg-icons'
import './i18nextConf'

library.add(fal, fas, far, fad)

ReactDOM.render(
  <>
    {/* <Head /> */}
    <App />
    <CssBaseline />
  </>,
  document.getElementById('root')
)
