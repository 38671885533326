import { Button } from '@mui/material'
import PropTypes from 'prop-types'
import AttachFile from '@mui/icons-material/AttachFile'
import { styled } from '@mui/material/styles'

const VisuallyHiddenInput = styled('input')({
  clip: 'rect(0 0 0 0)',
  clipPath: 'inset(50%)',
  height: 1,
  overflow: 'hidden',
  position: 'absolute',
  bottom: 0,
  left: 0,
  whiteSpace: 'nowrap',
  width: 1,
})

const FormFileInput = ({
  label = 'Dodaj pliki',
  handleChange,
  accept = '*',
  multiple = true,
}) => {
  return (
    <Button
      component="label"
      role={undefined}
      variant="contained"
      tabIndex={-1}
      style={{
        zIndex: '2',
      }}
      startIcon={<AttachFile />}
    >
      {label}
      <VisuallyHiddenInput
        type="file"
        multiple={multiple}
        accept={accept}
        onChange={(e) => handleChange(e.target.files)}
      />
    </Button>
  )
}

FormFileInput.propTypes = {
  label: PropTypes.string,
  handleChange: PropTypes.func,
  accept: PropTypes.string,
  multiple: PropTypes.bool,
}

export default FormFileInput
