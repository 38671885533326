import React from 'react'
import { TableCell } from '@mui/material'
import PropTypes from 'prop-types'

const RowCell = ({ content, className, styles }) => {
  return (
    <TableCell className={className} style={styles}>
      {content}
    </TableCell>
  )
}

RowCell.propTypes = {
  content: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  className: PropTypes.any,
  styles: PropTypes.object,
}

export default RowCell
