import { TableCell, TableRow } from '@mui/material'
import React from 'react'

const MaterialFinishingPricingListHeaderForm = () => {
  return (
    <>
      <TableRow>
        <TableCell
          style={{
            width: `5%`,
            whiteSpace: 'nowrap',
          }}
        >
          Lp.
        </TableCell>
        <TableCell
          style={{
            width: `20%`,
            whiteSpace: 'nowrap',
          }}
        >
          Kolor
        </TableCell>
        <TableCell
          style={{
            width: `15%`,
            whiteSpace: 'nowrap',
          }}
        >
          Typ wykończenia
        </TableCell>
        <TableCell
          style={{
            width: `20%`,
            whiteSpace: 'nowrap',
          }}
        >
          Nazwa wykończenia
        </TableCell>
        <TableCell>Procent ceny głównej</TableCell>
        <TableCell
          style={{
            width: `15%`,
            whiteSpace: 'nowrap',
          }}
        >
          Cena
        </TableCell>
        <TableCell
          style={{
            width: `20%`,
            whiteSpace: 'nowrap',
          }}
        >
          Akcja
        </TableCell>
      </TableRow>
    </>
  )
}
export default MaterialFinishingPricingListHeaderForm
