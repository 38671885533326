import PropTypes from 'prop-types'
import { TableCell, TableRow } from '@mui/material'

const ValuationOrderDetailsExtraRow = ({ extra, lp }) => {
  return (
    <TableRow>
      <TableCell>{lp}</TableCell>
      <TableCell>{extra.name}</TableCell>
      <TableCell>{extra.quantity}</TableCell>
      <TableCell>{extra.discount_price_net.toFixed(2)}</TableCell>
      <TableCell>{extra.summary_discount_price_net.toFixed(2)}</TableCell>
    </TableRow>
  )
}

ValuationOrderDetailsExtraRow.propTypes = {
  extra: PropTypes.object,
  lp: PropTypes.number,
}
export default ValuationOrderDetailsExtraRow
