// import PropTypes from 'prop-types'
import { useFormik } from 'formik'
import {
  Grid,
  DialogActions,
  DialogContent,
  FormControlLabel,
  Checkbox,
} from '@mui/material'
import FormikControl from '../../../molecules/FormikControl'
// import FormikSelect from '../../../molecules/FormikSelect'
import SaveIcon from '@mui/icons-material/Save'
import * as Yup from 'yup'
import axios from 'axios'
import { handleOpenAlert } from '../../../../lib/handleAlert'
import { closePopup } from '../../../../store/popupsSlice'
import { useDispatch } from 'react-redux'
import FormikSelect from '../../../molecules/FormikSelect'
import { useEffect, useState } from 'react'
import { LoadingButton } from '@mui/lab'
import { refreshFilter } from '../../../../lib/handleFilters'
import FileInput from 'components/molecules/FileInput'
import createFormData from 'lib/createFormData'

// import { useEffect, useState } from 'react'

const MaterialFinishingPricingFactory = ({ ...values }) => {
  const [extraTypes, setExtraTypes] = useState([])
  const [fileEdit, setFileEdit] = useState(null)
  const dispatch = useDispatch()
  const [loading, setLoading] = useState(false)
  const materialId = values.material_id
  const editId = values.id

  useEffect(() => {
    axios.post('/lookups/extra_types').then((res) => {
      if (res.status == 200) {
        setExtraTypes(res.data)
      }
    })
  }, [])

  useEffect(() => {
    if (editId) {
      axios.get(`/materials/${materialId}/extras/${editId}`).then((res) => {
        if (res.status == 200) {
          formik.setValues({
            type_id: res.data.type_id,
            service: res.data.service,
            name: res.data.name,
            length: res.data.length,
          })
          if (res.data.file) {
            setFileEdit({
              file_id: res.data.file.id,
              file_name: res.data.file.name,
              file_hash: res.data.file.hash,
            })
          }
        }
      })
    }
  }, [editId])

  const formik = useFormik({
    initialValues: {
      type_id: null,
      name: null,
      service: 0,
      length: null,
    },
    enableReinitialize: false,
    validationSchema: Yup.object({
      type_id: Yup.number().required('Pole wymagane'),
      name: Yup.string().required('Pole wymagane'),
      length: Yup.number().min(0, 'Długość musi być dodatnia.'),
    }),
    onSubmit: async (v) => {
      setLoading(true)
      save(v)
    },
  })

  const save = (v) => {
    if (typeof v.image == 'string') {
      delete v.image
    }
    const form = createFormData({
      ...v,
    })
    axios[editId ? 'put' : 'post'](
      `/materials/${materialId}/extras/${editId || 'store'}`,
      form,
      {
        'Content-Type': 'multipart/form-data',
      }
    )
      .then((res) => {
        if (res.status == 200 || res.status == 201) {
          dispatch(closePopup(values.popupKey))
          refreshFilter('MaterialFinishingPreviewKey')
          handleOpenAlert({
            title: 'Sukces!',
            subtitle: `${editId ? 'Zaktualizowano' : 'Dodano'} wykończenie`,
          })
        }
      })
      .catch((err) => {
        handleOpenAlert(err.request.status)
      })
      .finally(() => {
        setLoading(false)
      })
  }

  useEffect(() => {
    if (formik.values.type_id != 2) {
      formik.setFieldValue('length', '')
    }
  }, [formik.values.type_id])

  return (
    <>
      <form onSubmit={formik.handleSubmit} onReset={formik.handleReset}>
        <DialogContent dividers={true}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Grid container spacing={2}>
                <Grid item md={6} xs={12}>
                  <FormikControl
                    variableName="name"
                    labelName="Nazwa"
                    formik={formik}
                  />
                </Grid>
                <Grid item md={6} xs={12}>
                  <FormikSelect
                    variableName="type_id"
                    value={formik.values.type_id}
                    labelName="Typ wykończenia"
                    formik={formik}
                    options={extraTypes}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Grid container spacing={2}>
                <Grid item md={6} xs={12}>
                  <FormikControl
                    variableName="length"
                    type="number"
                    disabled={formik.values.type_id != 2}
                    labelName="Długość"
                    formik={formik}
                  />
                </Grid>
                <Grid item md={6} xs={12}>
                  <FileInput
                    formik={formik}
                    edit={fileEdit}
                    availableExtensions={['jpg', 'bmp', 'png', 'gif', 'svg']}
                    delEdit={(id) => {
                      setFileEdit(null)
                      formik.setFieldValue('file_to_del', id)
                    }}
                    downloadRoute={(file) => {
                      return editId ? `/files/${file.file_hash}/download` : null
                    }}
                    labelName="image"
                    label="Kliknij aby dodać zdjęcie"
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <LoadingButton
            startIcon={<SaveIcon />}
            loading={loading}
            variant="contained"
            color="primary"
            type="submit"
          >
            <span className="mt-1">Zapisz</span>
          </LoadingButton>
        </DialogActions>
      </form>
    </>
  )
}

export default MaterialFinishingPricingFactory
