import { MenuItem, Select } from '@mui/material'
import { useTranslation } from 'react-i18next'
import i18next from 'i18next'
import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'

const LanguagePicker = ({
  className,
  inputClassName,
  variant = 'outlined',
}) => {
  const { /*t,*/ i18n } = useTranslation()
  const languages = useSelector((state) => state.language.languages)
  const changeLanguage = (value) => {
    i18next.changeLanguage(value)
    localStorage.setItem('lang', value)
  }

  return (
    <>
      <div className={className}>
        <Select
          value={i18n.language}
          className={inputClassName}
          onChange={(e) => changeLanguage(e.target.value)}
          variant={variant}
          size="small"
          // label={t('language_picker.inputs.label')}
        >
          {languages.map((key, index) => (
            <MenuItem key={index} value={languages[index].acronym}>
              {languages[index].name}
            </MenuItem>
          ))}
        </Select>
      </div>
    </>
  )
}
LanguagePicker.propTypes = {
  className: PropTypes.string,
  inputClassName: PropTypes.string,
  variant: PropTypes.string,
}
export default LanguagePicker
