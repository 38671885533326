import CustomThemeProvider from './components/providers/CustomThemeProvider.jsx'
import { useEffect } from 'react'
import LocalizationProvider from '@mui/lab/LocalizationProvider'
import Routes from './components/organisms/Routes/Routes'
import { useHistory } from 'react-router-dom'
import DateAdapter from '@mui/lab/AdapterMoment'
import store from './store/store'
import { Provider } from 'react-redux'
import PopupsHub from './components/organisms/popups/PopupsHub.js'
import AlertsHub from './components/organisms/alerts/AlertsHub.js'
import axios from 'axios'
import RefreshToken from './components/hocs/RefreshToken'
import cookies from 'js-cookie'
import { handleOpenAlert } from './lib/handleAlert'
import DownloadsHub from './components/organisms/downloads/DownloadsHub.js'
import { useTranslation } from 'react-i18next'
import PWAPrompt from './components/organisms/popups/popupsComponents/PWAPrompt'
import PWAInstall from './hooks/PWA/PWAInstall'
import roleIdToPrefix from './lib/roleIdToPrefix'

// import Protected from './components/hocs/Protected.js'
// eslint-disable-next-line
let moment = require('moment')
// eslint-disable-next-line
require('moment/locale/pl')

moment.locale('pl')
// eslint-disable-next-line
axios.defaults.baseURL = process.env.REACT_APP_BASE_API_URL
axios.defaults.withCredentials = true

const App = () => {
  let history = useHistory()
  const { t, i18n } = useTranslation()
  const storeData = store.getState()
  const [acceptPWA, rejectPWA, showPWAPrompt] = PWAInstall()

  const handleErrorAlert = (response) => {
    if (response.status == 422) {
      handleOpenAlert({
        title: t('main.status.422.title'),
        subtitle: t('main.status.422.subtitle'),
        type: 'warning',
      })
    } else if (response.status == 500) {
      handleOpenAlert({
        title: t('main.status.500.title'),
        subtitle: t('main.status.500.subtitle'),
        type: 'error',
      })
    } else if (response.data.message) {
      handleOpenAlert({
        title: t('main.alert.warning'),
        subtitle: response.data.message,
        type: 'error',
      })
    }
  }

  useEffect(() => {
    let role = cookies.get('x-role')
    axios.defaults.headers['Content-Language'] =
      storeData.language.languages.find((e) => e.acronym == i18n.language)
        ?.id || 1
    if (role !== null && role !== undefined) {
      // eslint-disable-next-line
      axios.defaults.baseURL = `${process.env.REACT_APP_BASE_API_URL}${roleIdToPrefix[role]}`
    }
  })

  useEffect(() => {
    let isRefreshing = false
    let failedQueue = []

    const processQueue = (error, token = null) => {
      failedQueue.forEach((prom) => {
        if (error) {
          prom.reject(error)
        } else {
          prom.resolve(token)
        }
      })

      failedQueue = []
    }

    axios.interceptors.response.use(
      function (response) {
        // Any status code that lie within the range of 2xx cause this function to trigger
        // Do something with response data
        return response
      },
      async function (error) {
        let originalRequest = error.config

        if (
          error.response &&
          error.response.status !== 401 &&
          error.config.headers.ignoreAlerts === undefined
        ) {
          handleErrorAlert(error.response)
        }

        if (
          error.response &&
          error.response.status === 401 &&
          error.response.config.url !== '/auth/refresh-token' &&
          !originalRequest._retry
        ) {
          if (isRefreshing) {
            return new Promise(function (resolve, reject) {
              failedQueue.push({ resolve, reject })
            })
              .then((token) => {
                originalRequest.headers['Authorization'] = 'Bearer ' + token
                if (originalRequest.data !== undefined) {
                  originalRequest.data = JSON.parse(originalRequest.data)
                }
                return axios(originalRequest)
              })
              .catch((err) => {
                return Promise.reject(err)
              })
          }
          originalRequest._retry = true
          isRefreshing = true

          return new Promise(function (resolve, reject) {
            axios
              .get('/auth/refresh-token')
              .then(({ data }) => {
                if (originalRequest.data !== undefined) {
                  originalRequest.data = JSON.parse(originalRequest.data)
                }
                axios.defaults.headers.common['Authorization'] =
                  'Bearer ' + data.token
                originalRequest.headers['Authorization'] =
                  'Bearer ' + data.token
                processQueue(null, data.token)
                resolve(axios(originalRequest))
              })
              .catch((err) => {
                processQueue(err, null)
                reject(err)
              })
              .then(() => {
                isRefreshing = false
              })
          })
        } else if (error.response && error.response.status === 401) {
          handleOpenAlert({
            title: t('main.status.session.title'),
            subtitle: t('main.status.session.subtitle'),
            type: 'warning',
          })
          window.location.href = '/login'
        }
        return Promise.reject(error)
      }
    )
  })

  useEffect(() => {
    if (window.location.pathname == '/') {
      if (history) {
        history.push('/login')
      } else {
        window.location.href = '/login'
      }
    }
  }, [history])
  return (
    <>
      <Provider store={store}>
        <LocalizationProvider dateAdapter={DateAdapter}>
          <CustomThemeProvider>
            <PopupsHub />
            <AlertsHub />
            <DownloadsHub />
            <RefreshToken>
              {showPWAPrompt && (
                <PWAPrompt accept={acceptPWA} reject={rejectPWA} />
              )}
              <Routes />
            </RefreshToken>
          </CustomThemeProvider>
        </LocalizationProvider>
      </Provider>
    </>
  )
}

export default App
