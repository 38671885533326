import { useContext } from 'react'
import StoreKeyContext from '../../../contexts/StoreKeyContext'
import { TablePagination } from '@mui/material'
import { changeProperty } from '../../../store/filtersSlice'
import { useDispatch } from 'react-redux'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'

const DataTablePagination = ({ data, filters }) => {
  const dispatch = useDispatch()
  const { t } = useTranslation()

  const storeKey = useContext(StoreKeyContext)

  return (
    <>
      {Object.keys(filters) &&
        Object.keys(filters).length !== 0 &&
        Object.keys(data) &&
        Object.keys(data).length !== 0 && (
          <TablePagination
            labelDisplayedRows={({ from, to, count }) =>
              `${from}-${to} z ${count !== -1 ? count : `Więcej niż ${to}`}`
            }
            labelRowsPerPage={t('main.rows_per_page')}
            backIconButtonProps={{
              title: 'Poprzednia strona',
            }}
            nextIconButtonProps={{
              title: 'Następna strona',
            }}
            rowsPerPageOptions={[5, 10, 25, 50, 100]}
            component="div"
            count={data.total}
            rowsPerPage={filters.paginate}
            page={filters.page - 1}
            onPageChange={(e, page) =>
              dispatch(
                changeProperty({
                  key: storeKey,
                  property: 'page',
                  value: page + 1,
                })
              )
            }
            onRowsPerPageChange={(e) => {
              dispatch(
                changeProperty({
                  key: storeKey,
                  property: 'page',
                  value: 1,
                })
              )
              dispatch(
                changeProperty({
                  key: storeKey,
                  property: 'paginate',
                  value: e.target.value,
                })
              )
            }}
          />
        )}
    </>
  )
}

DataTablePagination.propTypes = {
  data: PropTypes.any,
  filters: PropTypes.object,
}

export default DataTablePagination
