import { useState, useEffect } from 'react'

const PWAInstall = () => {
  const [showPWAPrompt, setShowPWAPrompt] = useState(false)
  const [proposalEvent, setProposalEvent] = useState(null)

  useEffect(() => {
    window.addEventListener('beforeinstallprompt', (e) => {
      e.preventDefault()
      setProposalEvent(e)
    })
  }, [])

  useEffect(() => {
    if (proposalEvent) {
      setShowPWAPrompt(hasPWAQueryParam())
    }
  }, [proposalEvent])

  const hasPWAQueryParam = () => {
    const url = new URL(window.location.href)
    return url.searchParams.get('pwa') !== null
  }

  const acceptPWA = () => {
    proposalEvent.prompt()
    clearPWA()
  }

  const rejectPWA = () => {
    clearPWA()
  }
  const clearPWA = () => {
    clearQueryParams()
    setShowPWAPrompt(false)
  }

  const clearQueryParams = () => {
    window.history.pushState({}, document.title, window.location.pathname)
  }

  return [acceptPWA, rejectPWA, showPWAPrompt]
}
export default PWAInstall
