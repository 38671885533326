import React from 'react'
import PropTypes from 'prop-types'
import { IconButton, InputAdornment } from '@mui/material'
import { Clear } from '@mui/icons-material'
import Input from '../../Input/atoms/Input'

const ClearableInput = ({
  value,
  errors,
  changeHandler,
  label,
  type = 'text',
  suffix,
  InputProps,
}) => {
  return (
    <Input
      type={type}
      labelName={label}
      value={value}
      onChange={(v) => changeHandler(v)}
      message={errors}
      InputProps={{
        ...InputProps,
        endAdornment: (
          <>
            {suffix && (
              <InputAdornment position="start" className="mt-1">
                {suffix}
              </InputAdornment>
            )}
            <IconButton
              className="p-0"
              onClick={() => {
                changeHandler(0)
              }}
            >
              <Clear />
            </IconButton>
          </>
        ),
      }}
    />
  )
}

ClearableInput.propTypes = {
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  errors: PropTypes.object,
  changeHandler: PropTypes.func,
  label: PropTypes.string,
  suffix: PropTypes.string,
  type: PropTypes.string,
  InputProps: PropTypes.object,
}

export default ClearableInput
