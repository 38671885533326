import PropTypes from 'prop-types'
import React, { useEffect, useRef, useState } from 'react'
import VirtusoTableComponentsConfig from '../../../../features/Virtuoso/config/VirtusoTableComponentsConfig'
import { Grid, DialogActions, DialogContent, IconButton } from '@mui/material'
import SaveIcon from '@mui/icons-material/Save'
import axios from 'axios'
import LoadingButton from '@mui/lab/LoadingButton'
import { TableVirtuoso } from 'react-virtuoso'
import ColorItemListHeaderForm from '../../../modules/Color/molecules/ColorItemListHeaderForm'
import ColorItemListItemForm from '../../../modules/Color/molecules/ColorItemListItemForm'
import { Add, Delete } from '@mui/icons-material'
import Input from '../../../modules/Input/atoms/Input'
import { closePopup } from '../../../../store/popupsSlice'
import { refreshFilter } from '../../../../lib/handleFilters'
import { handleOpenAlert } from '../../../../lib/handleAlert'
import { useDispatch } from 'react-redux'
import NoContent from 'components/organisms/pages/NoContent'

ColorItemListItemForm.propTypes = { item: PropTypes.any }

const itemTemplate = {
  name: '',
  id: null,
}
const ColorItemsFactory = ({ popupKey, ...values }) => {
  const colorId = values.color_id
  const dispatch = useDispatch()
  const virtuoso = useRef(null)

  const [loading, setLoading] = useState(true)
  const [fetchingData, setFetchingData] = useState(true)
  const [items, _setItems] = useState([])
  const [searchValue, setSearchValue] = useState('')

  const [prefix, setPrefix] = useState('')

  useEffect(() => {
    setLoading(true)
    axios
      .get(`/colors/${colorId}/items`)
      .then((response) => {
        setItems(
          response.data.map((item) => ({
            ...item,
            key: item.id,
          }))
        )
        if (!response.data.length) {
          addItem()
        }
      })
      .finally(() => {
        setLoading(false)
      })
  }, [colorId])

  let filteredItems = items
  if (searchValue) {
    filteredItems = items.filter((item) => item.name.includes(searchValue))
  }

  const getItemIndex = (item) => {
    return items.findIndex((i) => i.key == item.key)
  }

  const addItem = () => {
    items.push({ ...itemTemplate, name: prefix, key: Math.random() })
    setItems(items)
    scrollBottom()
  }

  const setItems = (items) => {
    _setItems([...items])
  }

  const updateItem = (item) => {
    const index = getItemIndex(item)
    items[index] = item
    setItems(items)
  }

  const deleteItem = (item) => {
    const index = getItemIndex(item)
    items.splice(index, 1)
    setItems(items)
  }

  const scrollBottom = () => {
    const container = document.getElementById('virtuso-table-component')
    setTimeout(() => {
      container.scrollTo({
        top: container.scrollHeight,
        left: 0,
        behavior: 'smooth',
      })
    }, 5)
  }

  // const findDuplicateValues = (items) => {
  //   const duplicateValues = {}
  //   const sortedItems = items.sort((a, b) => (b.name > a.name ? -1 : 1))
  //   for (let i = 1; i < sortedItems.length; i++) {
  //     if (sortedItems[i].name === sortedItems[i - 1].name) {
  //       const value = sortedItems[i - 1].name
  //       if (!duplicateValues[value]) {
  //         duplicateValues[value] = new Set()
  //       }
  //       duplicateValues[value].add(i)
  //       duplicateValues[value].add(i - 1)
  //     }
  //   }
  //   return duplicateValues
  // }

  const sendToBackend = (items) => {
    setLoading(true)
    setFetchingData(true)
    axios
      .put(`/colors/${colorId}/items`, {
        items: items.map((item) => ({ name: item.name, id: item.id })),
      })
      .then((res) => {
        if (res.status == 200 || res.status == 201) {
          dispatch(closePopup(popupKey))

          refreshFilter('ColorsKey')
          handleOpenAlert({
            title: 'Sukces!',
            subtitle: `Zaktualizowano barwy`,
            type: 'success',
          })
        }
      })
      .catch((err) => {
        let errors = err.response.data.errors
        let key = Object.keys(errors)[0]
        let index = key.split('.')[1]
        virtuoso.current.scrollToIndex({
          index,
          align: 'start',
          behavior: 'smooth',
        })
        let el = document.querySelector(`[data-index="${index}"]`)
        if (el) {
          el.className = 'error-background'
          setTimeout(() => {
            el.className = ''
          }, 3000)
        }
        handleOpenAlert({
          title: 'Pole posiada zduplikowaną wartość',
          type: 'warning',
        })
        return err
      })
      .finally(() => {
        setLoading(false)
        setFetchingData(false)
      })
  }

  const save = (e) => {
    e.preventDefault()
    const filteredItems = items.filter((item) => item.name.length)
    sendToBackend(filteredItems)
  }

  return (
    <>
      <form onSubmit={save}>
        <>
          <DialogContent dividers={true}>
            <Grid
              style={{ gridTemplateRows: 'auto auto 100%', display: 'grid' }}
              container
              spacing={3}
            >
              <Grid item xs={12}>
                <Input labelName="Prefix" onChange={setPrefix} value={prefix} />
              </Grid>{' '}
              <Grid item xs={12}>
                <Input
                  labelName="Szukaj..."
                  onChange={setSearchValue}
                  value={searchValue}
                />
              </Grid>
              <Grid style={{ height: '50vh' }} item xs={12}>
                {items.length ? (
                  <TableVirtuoso
                    data={filteredItems}
                    ref={virtuoso}
                    components={VirtusoTableComponentsConfig}
                    style={{ height: '100%' }}
                    fixedHeaderContent={() => (
                      <ColorItemListHeaderForm
                        style={{ backgroundColor: '#fff' }}
                      />
                    )}
                    itemContent={(index, item) => (
                      <ColorItemListItemForm
                        item={item}
                        index={index}
                        updateItem={updateItem}
                        action={
                          <div>
                            {items.length > 1 && (
                              <IconButton
                                color="error"
                                component="span"
                                size="large"
                                onClick={() => {
                                  deleteItem(item)
                                }}
                              >
                                <Delete />
                              </IconButton>
                            )}
                            {items.length - 1 == index && (
                              <IconButton
                                component="span"
                                size="large"
                                onClick={() => {
                                  addItem()
                                }}
                              >
                                <Add />
                              </IconButton>
                            )}
                          </div>
                        }
                      />
                    )}
                  />
                ) : (
                  <NoContent loading={fetchingData} />
                )}
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <LoadingButton
              startIcon={<Add />}
              color="primary"
              variant="contained"
              type="button"
              onClick={addItem}
              size="large"
              className="mr-2"
            >
              <span className="mt-1">Dodaj</span>
            </LoadingButton>
            <LoadingButton
              startIcon={<SaveIcon />}
              variant="contained"
              color="primary"
              type="submit"
              loading={loading}
              loadingPosition="start"
            >
              <span className="mt-1">Zapisz</span>
            </LoadingButton>
          </DialogActions>
        </>
      </form>
    </>
  )
}

ColorItemsFactory.propTypes = {
  popupKey: PropTypes.string,
  values: PropTypes.object,
}

export default ColorItemsFactory
