import { createSlice } from '@reduxjs/toolkit'
import { handleCloseAlert } from '../lib/handleAlert'

export const alertsSlice = createSlice({
  name: 'alerts',
  initialState: {
    alerts: {},
  },
  reducers: {
    openAlert: (state, action) => {
      const { title, subtitle, type, key } = action.payload
      Object.assign(
        (state.alerts[key] = {
          key,
          title,
          subtitle,
          type,
          timeout: setTimeout(() => {
            handleCloseAlert(key)
          }, 5000),
        })
      )
    },
    closeAlert: (state, action) => {
      const key = action.payload
      delete state.alerts[key]
    },
  },
})

export const { openAlert, closeAlert } = alertsSlice.actions

export default alertsSlice.reducer
