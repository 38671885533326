import { AppBar, Toolbar, Grid, IconButton, Typography } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import { Menu } from '@mui/icons-material'
// import SearchRedirect from '../../molecules/SearchRedirect'
// import { openPopup } from '../../../store/popupsSlice'
import { logout } from '../../../../store/userSlice'
import { useSelector, useDispatch } from 'react-redux'
import { changeBurgerExpanded } from '../../../../store/layoutSlice'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPowerOff } from '@fortawesome/pro-solid-svg-icons'
// import DownloadIcon from '@mui/icons-material/Download'
// import downloader from '../../../lib/downloader'
import { useHistory } from 'react-router-dom'
import axios from 'axios'
import Search from '../../../molecules/Search'

const useStyles = makeStyles((theme) => ({
  redIcon: {
    color: theme.palette.primary.main,
    fontSize: '20px',
  },
  burgerIcon: {
    marginRight: theme.spacing(2),
    [theme.breakpoints.down('md')]: {
      padding: 'unset',
      marginRight: '5px',
      marginLeft: '5px',
    },
  },
  [theme.breakpoints.up('md')]: {
    burgerIcon: {
      display: 'none',
    },
  },
  header: {
    justifyContent: 'flex-end',
    [theme.breakpoints.down('md')]: {
      padding: 'unset',
      marginRight: 'unset',
    },
    minHeight: 74,
  },
  logo: {
    filter: 'brightness(0) invert(1)',
  },
}))

const Header = () => {
  const dispatch = useDispatch()
  const classes = useStyles()
  const history = useHistory()
  const mobileOpen = useSelector((state) => state.layout.burgerExpanded)
  const user = useSelector((state) => state.user.user)

  return (
    <AppBar position="sticky" color="transparent">
      <Toolbar className={classes.header}>
        <Grid
          container
          alignItems="center"
          className="header-btn justify-between"
        >
          <Grid item>
            <Grid container alignItems="center">
              <>
                <Grid item>
                  <IconButton
                    className={classes.burgerIcon}
                    color="primary"
                    onClick={() =>
                      dispatch(changeBurgerExpanded({ value: !mobileOpen }))
                    }
                    size="large"
                  >
                    <Menu />
                  </IconButton>
                </Grid>
                {user.role_id == 1 && (
                  <Grid item>
                    <Search
                      config={{
                        label: 'name+nip',
                        key: 'id',
                        extraObject: {
                          key: 'users',
                          label: 'firstname+lastname+email',
                        },
                      }}
                      // minWidth="400px"
                      url="/partners/search"
                      emitValue={(id) => {
                        history.push(`/partners/${id}/employees`)
                      }}
                    />
                  </Grid>
                )}
              </>
            </Grid>
          </Grid>
          <Grid item sm></Grid>
          <Grid item className="flex items-center gap-4">
            {/* {user.role_id == 1 && <HeaderSwitchPanel />} */}
            <Grid
              item
              className="w-full flex justify-end flex-wrap items-center"
            >
              <Typography className="pt-1">
                {`${user.firstname}`} {`${user.lastname}`}
              </Typography>
              <Grid item>
                <IconButton
                  title="Wyloguj się"
                  className={classes.redIcon}
                  onClick={() => {
                    axios.post('/auth/logout').then(() => {
                      dispatch(logout())
                    })
                  }}
                  size="large"
                >
                  {<FontAwesomeIcon icon={faPowerOff} />}
                </IconButton>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Toolbar>
    </AppBar>
  )
}

export default Header
