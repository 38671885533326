const ItemsValidation = () => {
  const transformYupErrorsIntoObject = (error) => {
    const errorsPerKey = {}

    error.inner.forEach((err) => {
      errorsPerKey[err.path] = err.message
    })

    return errorsPerKey
  }

  const validateItems = (itemRules, items) => {
    const errors = {}
    return new Promise((resolve, reject) => {
      for (const item of items) {
        try {
          itemRules.validateSync(item, { abortEarly: false })
        } catch (error) {
          errors[item.key] = transformYupErrorsIntoObject(error)
        }
      }
      if (Object.keys(errors).length) {
        return reject(errors)
      } else {
        return resolve(true)
      }
    })
  }

  return [validateItems]
}
export default ItemsValidation
