import PageContainer from 'components/organisms/layout/PageContainer'
import DataTable from 'components/organisms/dataTable/DataTable'
import StoreKeyProvider from 'components/providers/StoreKeyProvider'
import WithFilters from 'components/hocs/WithFilters'
import { Button, Card, CardContent, CardHeader } from '@mui/material'
import { useEffect, useState } from 'react'
import DataInputs from 'components/organisms/dataTable/DataInputs'
// import axios from 'axios'
import PropTypes from 'prop-types'
import eventBus from 'lib/eventBus'
import makeStyles from '@mui/styles/makeStyles'
import { Add } from '@mui/icons-material'
import { openPopup } from 'store/popupsSlice'
import { useDispatch } from 'react-redux'
import ShowMainCell from 'components/organisms/dataTable/cells/ShowMainCell'
import axios from 'axios'

const useStyles = makeStyles((theme) => ({
  fontAwesome: {
    position: 'absolute',
    fontSize: '16rem',
    right: '20%',
    margin: 'auto 0',
    top: '50%',
    transform: 'translateY(-50%);',
    height: '80%',
    color: theme.palette.primary.light,
    overflow: 'hidden',
    opacity: '0.5',
  },
  customCard: {
    position: 'relative',
  },
  custom: {
    width: '100%',
    maxWidth: '50% !important',
  },
  bg: {
    background: 'transparent',
    overflow: 'unset',
  },
  content: {
    padding: '24px',
    background: '#fff',
    marginBottom: '24px',
    borderRadius: '2px',
    boxShadow:
      '0px 3px 1px -2px rgba(0,0,0,0.2),0px 2px 2px 0px rgba(0,0,0,0.14),0px 1px 5px 0px rgba(0,0,0,0.12)',
  },
  contentSt: {
    background: '#fff',
    marginBottom: '24px',
    borderRadius: '2px',
    boxShadow:
      '0px 3px 1px -2px rgba(0,0,0,0.2),0px 2px 2px 0px rgba(0,0,0,0.14),0px 1px 5px 0px rgba(0,0,0,0.12)',
  },
  contentPreview: {
    background: '#fff',
    padding: 'unset',
    color: 'rgba(0, 0, 0, 0.6)',
  },
}))

const PartnerAddressesPreview = ({ partnerId }) => {
  const dispatch = useDispatch()

  // const [genders, setGenders] = useState([])
  // const [medical_care, setMecicalCare] = useState([])
  // // const [medicalFacilities, setMedicalFacilityName] = useState([])
  // const [admins, setPwz] = useState([])
  const classes = useStyles()

  const [events, setEvents] = useState()
  const [states, setStates] = useState([])

  useEffect(() => {
    axios.post('/lookups/states', null).then((res) => {
      setStates(res.data)
    })
  }, [])

  useEffect(() => {
    eventBus.on('newFilterValues', async (data) => {
      await setEvents(data.type_id)
    })

    return eventBus.remove('newFilterValues')
  }, [])

  useEffect(() => {
    eventBus.dispatch('forceFilterValue', {
      key: 'partner_id',
      value: null,
      filter: 'PartnerAddressesKey',
    })
  }, [events])

  return (
    <>
      <PageContainer>
        <StoreKeyProvider storeKey="PartnerAddressesPreview">
          <WithFilters
            config={{
              page: 1,
              paginate: 25,
              orderType: 'asc',
              order: 'city',
              inputs: {
                name: {
                  value: '',
                  relation: 'LIKE',
                },
                city: {
                  value: '',
                  relation: 'LIKE',
                },
                street: {
                  value: null,
                  relation: 'LIKE',
                },
                postcode: {
                  value: null,
                  relation: 'LIKE',
                },
                state_id: {
                  value: '',
                  relation: 'WHERE',
                },
                active: {
                  value: 1,
                  relation: 'WHERE',
                },
              },
            }}
            endpoint={`/partners/${partnerId}/addresses/filter`}
          >
            <Card elevation={0} className={classes.bg}>
              <CardContent className={classes.contentSt}>
                <CardHeader
                  className={classes.contentPreview}
                  title="Lista adresów"
                  action={
                    <Button
                      startIcon={<Add />}
                      variant="contained"
                      color="primary"
                      onClick={() =>
                        dispatch(
                          openPopup({
                            component: 'PartnerAddressFactory',
                            title: 'Dodaj adres',
                            level: 1,
                            maxWidth: 'sm',
                            key: 'partner-adddress-popup',
                            values: {
                              partner_id: partnerId,
                            },
                          })
                        )
                      }
                    >
                      <span className="mt-1">Dodaj adres</span>
                    </Button>
                  }
                />
                <DataInputs
                  inputs={[
                    {
                      id: 'name',
                      label: 'Nazwa',
                      placeholder: '',
                      type: 'text',
                    },
                    {
                      id: 'city',
                      label: 'Miasto',
                      placeholder: '',
                      type: 'text',
                    },
                    {
                      id: 'street',
                      label: 'Ulica',
                      placeholder: '',
                      type: 'text',
                    },
                    {
                      id: 'postcode',
                      label: 'Kod pocztowy',
                      placeholder: '',
                      type: 'text',
                    },
                    {
                      id: 'state_id',
                      label: 'Województwo',
                      placeholder: '',
                      type: 'select',
                      options: states,
                    },
                    {
                      id: 'phone_number',
                      label: 'Numer telefonu',
                      placeholder: '',
                      type: 'text',
                    },
                    {
                      id: 'email',
                      label: 'Email',
                      placeholder: '',
                      type: 'text',
                    },
                    {
                      id: 'active',
                      label: 'Aktywne',
                      placeholder: '',
                      type: 'select',
                      options: [
                        {
                          id: 1,
                          name: 'Tak',
                        },
                        {
                          id: 0,
                          name: 'Nie',
                        },
                      ],
                    },
                  ]}
                />
              </CardContent>
              <CardContent className={classes.content}>
                <DataTable
                  breakpoint={1400}
                  actions="PartnerAddressPreviewActions"
                  rowComponents={{
                    main: ShowMainCell,
                  }}
                  labels={[
                    'Główny',
                    'Nazwa',
                    'Miasto',
                    'Ulica',
                    'Kod pocztowy',
                    'Województwo',
                    'Numer telefonu',
                    'Email',
                  ]}
                  keys={[
                    'main',
                    'name',
                    'city',
                    'street',
                    'postcode',
                    'state_name',
                    'phone_number',
                    'email',
                  ]}
                />
              </CardContent>
            </Card>
          </WithFilters>
        </StoreKeyProvider>
      </PageContainer>
    </>
  )
}

PartnerAddressesPreview.propTypes = {
  partnerId: PropTypes.number,
}

export default PartnerAddressesPreview
