import { Grid, IconButton } from '@mui/material'
import { Delete, Edit } from '@mui/icons-material'
import { useDispatch, useSelector } from 'react-redux'
import PropTypes from 'prop-types'
import { handleOpenAlert } from '../../../../lib/handleAlert'
import { refreshFilter } from '../../../../lib/handleFilters'
import { openPopup } from '../../../../store/popupsSlice'
import Confirmation from '../../popups/Confirmation'
import { useState } from 'react'
import axios from 'axios'

const MaterialColorsPreviewActions = ({ row }) => {
  const dispatch = useDispatch()
  const user = useSelector((state) => state.user.user)
  const [confirmation, setConfirmation] = useState(false)

  return (
    <Grid
      container
      alignItems="center"
      justifyContent="center"
      direction="row"
      spacing={1}
      className="table-actions"
    >
      {confirmation && (
        <Confirmation
          level={5}
          maxWidth="xs"
          icon="Warning"
          title={`Czy na pewno chcesz usunąć kolor: ${row.name}?`}
          subtitle={`Tej operacji nie można cofnąć.`}
          reject={() => {
            setConfirmation(false)
          }}
          success={() => {
            axios
              .delete(`/materials/${row.material_id}/colors/${row.color_id}`)
              .then(() => {
                handleOpenAlert({
                  title: 'Sukces!',
                  subtitle: `Usunięto kolor ${row.name}`,
                  type: 'success',
                })
                refreshFilter('MaterialColorsPreviewKey')
              })
              .finally(() => {
                setConfirmation(false)
              })
          }}
        />
      )}

      {user.permissions && user.permissions.includes('edit MaterialColor') && (
        <IconButton
          title="Edytuj kolor"
          size="small"
          onClick={() =>
            dispatch(
              openPopup({
                component: 'MaterialColorFactory',
                title: 'Edytuj kolor',
                level: 1,
                maxWidth: 'sm',
                values: {
                  color_id: row.color_id,
                  material_id: row.material_id,
                },
                key: 'material-color-factory-popup',
              })
            )
          }
        >
          <Edit fontSize="small" />
        </IconButton>
      )}
      {user.permissions && user.permissions.includes('remove MaterialColor') && (
        <IconButton
          title="Usuń kolor"
          size="small"
          onClick={() => setConfirmation(true)}
        >
          <Delete fontSize="small" />
        </IconButton>
      )}
    </Grid>
  )
}

MaterialColorsPreviewActions.propTypes = {
  row: PropTypes.object,
}

export default MaterialColorsPreviewActions
