import PropTypes from 'prop-types'
import { useEffect, useState } from 'react'
import axios from 'axios'
import Confirmation from '../Confirmation'
import {
  DialogActions,
  DialogContent,
  Table,
  TableBody,
  TableCell,
  FormHelperText,
  TableRow,
  TableHead,
  Select,
  FormControl,
  MenuItem,
  Button,
} from '@mui/material'
import { useDispatch } from 'react-redux'
import { handleOpenAlert } from '../../../../lib/handleAlert'
import { closePopup } from '../../../../store/popupsSlice'
import { refreshFilter } from '../../../../lib/handleFilters'

const parseComponentsToSend = (components, local) => {
  let result = JSON.parse(JSON.stringify(components))
  for (let i = 0; i < local.length; i++) {
    const temp = local[i]
    result[temp.typeId][temp.arrIndex].event_id = temp.eventId
  }
  return result
}

const getName = (examinations, type, examinationId) => {
  let exams = examinations[type]
  for (let i = 0; i < exams.length; i++) {
    if (exams[i].id == examinationId) {
      return exams[i].name
    }
  }
}

const initParseComponents = (components, examinations) => {
  let arr = []
  let typesKeys = Object.keys(components)
  for (let i = 0; i < typesKeys.length; i++) {
    let comps = components[typesKeys[i]]
    for (let j = 0; j < comps.length; j++) {
      let comp = comps[j]
      comp.name = getName(examinations, typesKeys[i], comp.examination_id)
      if (comp.toEvent) {
        arr.push({ ...comp, eventId: '', arrIndex: j, typeId: typesKeys[i] })
      }
    }
  }
  return arr
}

// eslint-disable-next-line
const EventsFactory = ({ components, data, examinations }) => {
  const dispatch = useDispatch()

  const [lComponents, _setLComponents] = useState()
  const [sweetAlert, setSweetAlert] = useState(false)
  const [validate, setValidate] = useState(false)

  useEffect(async () => {
    await setContractTypes(await loadContractTypes())
    _setLComponents(
      initParseComponents(JSON.parse(JSON.stringify(components)), examinations)
    )
  }, [])

  const setLComponentEventType = (index, value) => {
    let temp = [...lComponents]
    temp[index].eventId = value
    _setLComponents(temp)
  }

  const [contractTypes, setContractTypes] = useState([])

  const loadContractTypes = () => {
    return new Promise(function (resolve) {
      axios.post('/lookups/contract_types', null).then((res) => {
        resolve(res.data)
      })
    })
  }

  const submit = () => {
    setValidate(true)
    let elements
    setTimeout(() => {
      elements = document.querySelectorAll(
        '.Mui-error .MuiOutlinedInput-notchedOutline'
      )
    }, 100)
    setTimeout(() => {
      if (elements.length == 0) {
        setSweetAlert(true)
      }
    }, 200)
  }

  const finalize = () => {
    axios
      .post(`/contracts/${data.id}/finish`, {
        ...data,
        components: parseComponentsToSend(data.components, lComponents),
      })
      .then((res) => {
        if (res.status == 200) {
          dispatch(closePopup('contract-factory-popup'))
          dispatch(closePopup('events-factory-popup'))
          refreshFilter('ContractsKey')
        }
        handleOpenAlert({
          title: 'Sukces!',
          subtitle: 'Sfinalizowano zlecenie!',
          type: 'success',
        })
      })
      .catch((err) => {
        handleOpenAlert(err.request.status)
      })
  }

  return (
    <>
      <DialogContent dividers={true}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Badanie</TableCell>
              <TableCell>Zdarzenie</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {lComponents &&
              lComponents.map((component, index) => (
                <TableRow key={component.id}>
                  <TableCell>
                    {component.name} (
                    {component.tooth1
                      ? `${component.tooth1}, ${component.price_gross}zł`
                      : `${component.price_gross}zł`}
                    )
                  </TableCell>
                  <TableCell>
                    <FormControl
                      fullWidth
                      error={validate && component.eventId == ''}
                    >
                      <Select
                        value={component.eventId}
                        onChange={(e) =>
                          setLComponentEventType(index, e.target.value)
                        }
                      >
                        {contractTypes &&
                          contractTypes.map((option) => (
                            <MenuItem key={option.id} value={option.id}>
                              {option.name}
                            </MenuItem>
                          ))}
                      </Select>
                      {validate && component.eventId == '' && (
                        <FormHelperText>
                          Typ zdarzenia jest wymagany
                        </FormHelperText>
                      )}
                    </FormControl>
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </DialogContent>
      <DialogActions>
        <Button
          variant="contained"
          color="primary"
          type="submit"
          onClick={() => submit()}
        >
          <span className="mt-1">Zapisz</span>
        </Button>
      </DialogActions>
      {sweetAlert && (
        <Confirmation
          level={5}
          maxWidth="xs"
          icon="Warning"
          title={`Czy na pewno chcesz dodać zlecenie?`}
          subtitle={`Tej operacji nie można cofnąć.`}
          reject={() => {
            setSweetAlert(false)
          }}
          success={async () => finalize()}
        />
      )}
    </>
  )
}

EventsFactory.propTypes = {
  components: PropTypes.object,
  data: PropTypes.object,
  examinations: PropTypes.object,
}

export default EventsFactory
