import { Grid, IconButton } from '@mui/material'
import { Edit, Delete, Email } from '@mui/icons-material'
import { useDispatch, useSelector } from 'react-redux'
import PropTypes from 'prop-types'
import Confirmation from '../../popups/Confirmation'
import { handleOpenAlert } from '../../../../lib/handleAlert'
import { openPopup } from '../../../../store/popupsSlice'
import { refreshFilter } from '../../../../lib/handleFilters'
import { useState } from 'react'
import axios from 'axios'
import RestoreFromTrashIcon from '@mui/icons-material/RestoreFromTrash'

const PartnerEmployeePreviewActions = ({ row }) => {
  const dispatch = useDispatch()
  const [confirmation, setConfirmation] = useState(false)
  const user = useSelector((state) => state.user.user)

  const sendInvitation = () => {
    axios.post(`/users/${row.id}/invitation/resend`).then(() => {
      handleOpenAlert({
        title: 'Sukces!',
        subtitle: `Wysłano zaproszenie do pracownika: ${row.firstname} ${row.lastname}`,
        type: 'success',
      })
    })
  }

  return (
    <Grid
      container
      alignItems="center"
      justifyContent="center"
      direction="row"
      spacing={1}
      className="table-actions"
    >
      {confirmation && (
        <Confirmation
          level={5}
          maxWidth="xs"
          icon="Warning"
          title={`Czy na pewno chcesz ${
            row.active ? 'usunąć' : 'przywrócić'
          } pracownika: ${row.firstname} ${row.lastname}?`}
          subtitle={`Tej operacji nie można cofnąć.`}
          reject={() => {
            setConfirmation(false)
          }}
          success={() => {
            axios
              .post(
                `/partners/${row.partner_id}/users/${row.id}/${
                  row.active ? 'delete' : 'restore'
                }`
              )
              .then(() => {
                handleOpenAlert({
                  title: 'Sukces!',
                  subtitle: `${
                    row.active ? 'Usunięto' : 'Przywrócono'
                  } pracownika: ${row.firstname} ${row.lastname}`,
                  type: 'success',
                })
                refreshFilter('PartnerEmployeesPreview')
                setConfirmation(false)
              })
            // .catch((err) => {
            //   handleOpenAlert(err.request)
            // })
          }}
        />
      )}
      {!!row.active && (
        <>
          <Grid item>
            <IconButton
              title={'Wyślij zaproszenie'}
              size="small"
              onClick={() => sendInvitation()}
            >
              <Email />
            </IconButton>
          </Grid>
          {user.permissions && user.permissions.includes('edit User') && (
            <>
              <Grid item>
                <IconButton
                  title="Edytuj pracownika"
                  size="small"
                  onClick={() =>
                    dispatch(
                      openPopup({
                        component: 'EmployeeFactory',
                        title: 'Edytuj Pracownika',
                        level: 1,
                        maxWidth: 'sm',
                        values: {
                          partner_id: row.partner_id,
                          id: row.id,
                        },
                        key: 'employee-factory-popup',
                      })
                    )
                  }
                >
                  <Edit fontSize="small" />
                </IconButton>
              </Grid>
            </>
          )}
        </>
      )}
      {user.permissions && user.permissions.includes('remove User') && (
        <Grid item>
          <IconButton
            title={`${row.active ? 'Przywróć' : 'Usuń'}`}
            size="small"
            onClick={() => setConfirmation(true)}
          >
            {row.active ? (
              <Delete fontSize="small" color="error" />
            ) : (
              <RestoreFromTrashIcon fontSize="small" color="success" />
            )}
          </IconButton>
        </Grid>
      )}
    </Grid>
  )
}

PartnerEmployeePreviewActions.propTypes = {
  row: PropTypes.object,
}

export default PartnerEmployeePreviewActions
