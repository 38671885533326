import PageHeader from '../../layout/PageHeader'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBoxOpen } from '@fortawesome/pro-solid-svg-icons'
import makeStyles from '@mui/styles/makeStyles'
import PageContainer from '../../layout/PageContainer'
// import AddIcon from '@mui/icons-material/Add'
// import { useDispatch, useSelector } from 'react-redux'
// import { openPopup } from '../../../store/popupsSlice'

const useStyles = makeStyles(() => ({
  icon: {
    width: '24px !important',
    height: '24px',
  },
}))

const Orders = () => {
  const classes = useStyles()

  return (
    <div>
      <PageHeader
        icon={<FontAwesomeIcon icon={faBoxOpen} className={classes.icon} />}
        title="Lista zamówień"
      />
      <PageContainer></PageContainer>
    </div>
  )
}

export default Orders
