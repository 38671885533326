import React, { useEffect, useState } from 'react'
import {
  DialogActions,
  DialogContent,
  Grid,
  InputAdornment,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
} from '@mui/material'
import PropTypes from 'prop-types'
import FormikControlDeep from '../../../molecules/FormikControlDeep'
import getDeepValue from '../../../../lib/deepUtils'
import SaveIcon from '@mui/icons-material/Save'
import * as Yup from 'yup'
import { LoadingButton } from '@mui/lab'
import { useFormik } from 'formik'
import axios from 'axios'
import LoadingCard from '../../../atoms/LoadingCard'
import { useDispatch } from 'react-redux'
import { closePopup } from '../../../../store/popupsSlice'
import { refreshFilter } from '../../../../lib/handleFilters'
import { handleOpenAlert } from '../../../../lib/handleAlert'
import { useTranslation } from 'react-i18next'

const ValuationMarkupFactory = ({ ...values }) => {
  const [timeoutValues, _setTimeoutValues] = useState([])
  const [markup, _setMarkup] = useState([])
  const [loading, setLoading] = useState(false)
  const valuationId = values.id
  const dispatch = useDispatch()
  const { t } = useTranslation()

  const formik = useFormik({
    initialValues: {},
    enableReinitialize: false,
    validationSchema: Yup.object({
      components: Yup.array().of(
        Yup.object()
          .required(t('main.validation.required'))
          .shape({
            sale_value_net: Yup.number().when(
              'value_net',
              (value_net, schema) => {
                return schema.test({
                  test: (sale_value_net) => value_net <= sale_value_net,
                  message: t('main.validation.price_after_overlay'),
                })
              }
            ),
            markup: Yup.number().min(
              0,
              t('main.validation.min', { length: 0 })
            ),
          })
      ),
    }),
    onSubmit: async (values) => {
      setLoading(true)
      let data = []
      for (let i = 0; i < values.components.length; i++) {
        data.push({
          component_id: values.components[i].id,
          sale_value_net: values.components[i].sale_value_net,
        })
      }
      axios
        .post(`/valuations/${valuationId}/setSale`, { components: data })
        .then((res) => {
          if (res.status == 200 || res.status == 201) {
            dispatch(closePopup('valuation-markup-factory-popup'))
            // eventBus.dispatch('refreshPatient', { partner_id: res.data.id })

            refreshFilter(values.filterKey || 'ValuationsKey')
            setLoading(false)
          }
          handleOpenAlert({
            title: t('main.alert.success_title'),
            subtitle: t('valuation.alert.overlay_success'),
          })
        })
        .catch(() => {
          setLoading(false)
        })
    },
  })

  const setMarkup = (value) => {
    _setMarkup(value)
    if (value !== '') {
      let components = formik.values.components
      for (let i = 0; i < components.length; i++) {
        components[i].markup = value
        setTimeoutValues(i, components[i].value_net, value)
      }
      formik.setFieldValue('components', components)
    }
  }

  const buildValuation = () => {
    axios
      .post(
        values.status_id == 4
          ? `/orders/${valuationId}`
          : `/valuations/${valuationId}`,
        {}
      )
      .then((response) => {
        let data = []
        for (let i = 0; i < response.data.components.length; i++) {
          let component = response.data.components[i]
          component.length_concat = ''
          for (let i = 0; i < component.blocks.length; i++) {
            component.length_concat += component.blocks[i].length
            component.length_concat += 'cm'
            if (component.blocks.length != i + 1) {
              component.length_concat += ', '
            }
          }
          setTimeoutValues(
            i,
            component.value_net,
            null,
            component.sale_value_net
          )
          data.push(component)
        }
        formik.setFieldValue('components', data)
      })
  }

  useEffect(() => {
    if (valuationId) {
      buildValuation()
    }
  }, [valuationId])

  const pathPrefix = 'components'

  const recalculate = (index, value_net, markup = 0, markupSummary = 0) => {
    if (markupSummary) {
      formik.setFieldValue(
        `${pathPrefix}[${index}].markup`,
        ((markupSummary / value_net - 1) * 100).toFixed(2)
      )
    } else {
      formik.setFieldValue(
        `${pathPrefix}[${index}].sale_value_net`,
        (value_net * (1 + markup / 100)).toFixed(2)
      )
    }
  }

  const setMarkupSummary = async (index, value) => {
    await formik.setFieldValue(`${pathPrefix}[${index}].sale_value_net`, value)
    setTimeoutValues(
      index,
      formik.values.components[index].value_net,
      null,
      value
    )
  }

  const setTimeoutValues = (
    index,
    value_net,
    markup = 0,
    markupSummary = 0
  ) => {
    if (timeoutValues[index]) {
      clearTimeout(timeoutValues[index])
    }
    let tv = timeoutValues
    tv[index] = setTimeout(() => {
      recalculate(index, value_net, markup, markupSummary)
    }, 500)
    _setTimeoutValues(tv)
  }

  const setComponentMarkup = (index, value) => {
    if (markup && value != markup) {
      setMarkup('')
    }
    formik.setFieldValue(`${pathPrefix}[${index}].markup`, value)
    setTimeoutValues(index, formik.values.components[index].value_net, value)
  }

  // useEffect(() => {
  //   for (let i = 0; i < formik.values.components.length; i++) {
  //     setTimeoutValues(
  //       i,
  //       formik.values.components[i].value_net,
  //       formik.values.components[i].markup
  //     )
  //   }
  // }, [formik.values.components.length])

  return (
    <>
      <form onSubmit={formik.handleSubmit} onReset={formik.handleReset}>
        <DialogContent dividers={true}>
          {formik.values.components && formik.values.components.length > 0 ? (
            <Table size="small">
              <TableHead>
                <TableRow>
                  <TableCell
                    style={{
                      width: `5%`,
                      whiteSpace: 'nowrap',
                    }}
                  >
                    Lp.
                  </TableCell>
                  <TableCell
                    style={{
                      width: `30%`,
                      whiteSpace: 'nowrap',
                    }}
                  >
                    {t('valuation_markup_popup.data.table.name')}
                  </TableCell>
                  <TableCell
                    style={{
                      width: `10%`,
                      whiteSpace: 'nowrap',
                    }}
                  >
                    {t('valuation_markup_popup.data.table.quantity')}
                  </TableCell>
                  <TableCell
                    style={{
                      width: `5%`,
                      whiteSpace: 'nowrap',
                    }}
                  >
                    {t('Net price')}
                  </TableCell>
                  <TableCell
                    style={{
                      width: `10%`,
                      whiteSpace: 'nowrap',
                    }}
                  >
                    {t('valuation_markup_popup.data.table.overlay')}
                  </TableCell>
                  <TableCell
                    style={{
                      width: `10%`,
                      whiteSpace: 'nowrap',
                    }}
                  >
                    {t('valuation_markup_popup.data.table.price_after_overlay')}
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {formik.values.components.map((component, index) => (
                  <TableRow key={index}>
                    <TableCell xs={3}>{index + 1}</TableCell>
                    <TableCell xs={3}>
                      <p className="font-bold">{component.name}</p>
                      <>
                        {t('valuation_markup_popup.data.table.width')}
                        {component.width}cm
                        <div sx={{ minWidth: '10%' }}>
                          {t('valuation_markup_popup.data.table.length')}{' '}
                          {component.length_concat}
                        </div>
                      </>
                    </TableCell>
                    <TableCell xs={3}>{component.quantity}</TableCell>
                    <TableCell xs={3}>
                      {parseFloat(component.value_net).toFixed(2)} zł
                    </TableCell>
                    <TableCell xs={3}>
                      <FormikControlDeep
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="start">%</InputAdornment>
                          ),
                        }}
                        size="size"
                        variableName={`${pathPrefix}[${index}].markup`}
                        min={0}
                        max={1000}
                        handleChange={(v) => {
                          setComponentMarkup(index, v)
                        }}
                        type="number"
                        formik={formik}
                      />
                    </TableCell>
                    <TableCell xs={3}>
                      <FormikControlDeep
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="start">zł</InputAdornment>
                          ),
                        }}
                        size="size"
                        variableName={`${pathPrefix}[${index}].sale_value_net`}
                        handleChange={(v) => {
                          setMarkupSummary(index, v)
                        }}
                        forceError={
                          getDeepValue(
                            formik.values,
                            `${pathPrefix}[${index}].sale_value_net`
                          ) <= 0
                        }
                        helperText={'Błędna cena'}
                        type="number"
                        formik={formik}
                      />
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          ) : (
            <LoadingCard fullHeight={true} />
          )}
        </DialogContent>
        <DialogActions>
          {
            <Grid item md={12} xs={12}>
              <TextField
                name="markup"
                type="number"
                value={markup}
                onChange={(e) => {
                  setMarkup(e.target.value)
                }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="start">%</InputAdornment>
                  ),
                }}
                label={t('valuation_markup_popup.data.input.overlay')}
                formik={formik}
              />
            </Grid>
          }
          <LoadingButton
            startIcon={<SaveIcon />}
            variant="contained"
            color="primary"
            type="submit"
            // onClick={setLoading}
            loading={loading}
            loadingPosition="start"
          >
            <span className="mt-1">
              {t('valuation_markup_popup.data.button.save')}
            </span>
          </LoadingButton>
        </DialogActions>
      </form>
    </>
  )
}

ValuationMarkupFactory.propTypes = {
  components: PropTypes.array,
  editComponent: PropTypes.func,
  delComponent: PropTypes.func,
  editingComponentIndex: PropTypes.number,
  pathPrefix: PropTypes.string,
  formik: PropTypes.object,
  minDiscount: PropTypes.number,
}

export default ValuationMarkupFactory
