import React, { useEffect, useState } from 'react'
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material'
import PropTypes from 'prop-types'
import { useFormik } from 'formik'
import axios from 'axios'
import LoadingCard from '../../../atoms/LoadingCard'
import makeStyles from '@mui/styles/makeStyles'
import { Clear } from '@mui/icons-material'
import { closePopup } from '../../../../store/popupsSlice'
import { useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'

const useStyles = makeStyles((theme) => ({
  dialogWrapper: {
    padding: 0,
    maxWidth: 'unset',
  },
  footer: {
    display: 'flex',
    justifyContent: 'flex-start',
    margin: '0px auto 0px 0px',
    alignItems: 'center',
    gap: theme.spacing(2),
  },
}))

const ValuationMarkupFactoryPreview = ({ ...values }) => {
  const [timeoutValues, _setTimeoutValues] = useState([])
  //   const [markup, _setMarkup] = useState([])
  const valuationId = values.id
  const scroll = 'paper'
  const dispatch = useDispatch()
  const classes = useStyles()
  const popupKey = values.popupKey
  const { t } = useTranslation()

  const formik = useFormik({
    initialValues: {},
    enableReinitialize: false,
  })

  const buildValuation = () => {
    axios
      .post(
        values.status_id == 4
          ? `/orders/${valuationId}`
          : `/valuations/${valuationId}`,
        {}
      )
      .then((response) => {
        let data = []
        for (let i = 0; i < response.data.components.length; i++) {
          let component = response.data.components[i]
          component.length_concat = ''
          for (let i = 0; i < component.blocks.length; i++) {
            component.length_concat += component.blocks[i].length
            component.length_concat += 'cm'
            if (component.blocks.length != i + 1) {
              component.length_concat += ', '
            }
          }
          setTimeoutValues(
            i,
            component.value_net,
            null,
            component.sale_value_net
          )
          data.push(component)
        }
        formik.setFieldValue('components', data)
      })
  }

  useEffect(() => {
    if (valuationId) {
      buildValuation()
    }
  }, [valuationId])

  const pathPrefix = 'components'

  const recalculate = (index, value_net, markup = 0, markupSummary = 0) => {
    if (markupSummary) {
      formik.setFieldValue(
        `${pathPrefix}[${index}].markup`,
        ((markupSummary / value_net - 1) * 100).toFixed(2)
      )
    } else {
      formik.setFieldValue(
        `${pathPrefix}[${index}].sale_value_net`,
        (value_net * (1 + markup / 100)).toFixed(2)
      )
    }
  }

  const setTimeoutValues = (
    index,
    value_net,
    markup = 0,
    markupSummary = 0
  ) => {
    if (timeoutValues[index]) {
      clearTimeout(timeoutValues[index])
    }
    let tv = timeoutValues
    tv[index] = setTimeout(() => {
      recalculate(index, value_net, markup, markupSummary)
    }, 500)
    _setTimeoutValues(tv)
  }

  return (
    <>
      <Dialog
        fullScreen
        open={true}
        classes={{ paper: classes.dialogWrapper }}
        fullWidth={true}
        style={{ zIndex: 1300 }}
        scroll={scroll}
        disableEscapeKeyDown={true}
      >
        <DialogTitle>
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}
          >
            <Typography variant="h6" component="div">
              {t('valuation_popup.data.valuation_preview')}
            </Typography>
            <Clear
              className="cursor-pointer"
              onClick={() => dispatch(closePopup(popupKey))}
            />
          </div>
        </DialogTitle>
        <DialogContent dividers={scroll == 'paper'}>
          {formik.values.components && formik.values.components.length > 0 ? (
            <Table size="small">
              <TableHead>
                <TableRow>
                  <TableCell
                    style={{
                      width: `2%`,
                      whiteSpace: 'nowrap',
                    }}
                  >
                    Lp.
                  </TableCell>
                  <TableCell
                    style={{
                      width: `25%`,
                      whiteSpace: 'nowrap',
                    }}
                  >
                    Nazwa
                  </TableCell>
                  <TableCell
                    style={{
                      width: `10%`,
                      whiteSpace: 'nowrap',
                    }}
                  >
                    Szerokość
                  </TableCell>
                  <TableCell
                    style={{
                      width: `10%`,
                      whiteSpace: 'nowrap',
                    }}
                  >
                    Długość
                  </TableCell>
                  <TableCell
                    style={{
                      width: `5%`,
                      whiteSpace: 'nowrap',
                    }}
                  >
                    Sztuki
                  </TableCell>
                  <TableCell
                    style={{
                      width: `5%`,
                      whiteSpace: 'nowrap',
                    }}
                  >
                    Rabat
                  </TableCell>
                  <TableCell
                    style={{
                      width: `10%`,
                      whiteSpace: 'nowrap',
                    }}
                  >
                    Cena po rabacie
                  </TableCell>
                  <TableCell
                    style={{
                      width: `5%`,
                      whiteSpace: 'nowrap',
                    }}
                  >
                    Narzut
                  </TableCell>
                  <TableCell
                    style={{
                      width: `5%`,
                      whiteSpace: 'nowrap',
                    }}
                  >
                    Cena po narzucie
                  </TableCell>
                  <TableCell
                    style={{
                      width: `5%`,
                      whiteSpace: 'nowrap',
                    }}
                  >
                    Cena za prapet
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {formik.values.components.map((component, index) => (
                  <TableRow key={index}>
                    <TableCell xs={3}>{index + 1}</TableCell>
                    <TableCell xs={3}>{component.name}</TableCell>
                    <TableCell xs={3}>{component.width} cm</TableCell>
                    <TableCell xs={3}>{component.length_concat}</TableCell>
                    <TableCell xs={3}>{component.quantity}</TableCell>
                    <TableCell xs={3}>
                      {parseFloat(component.discount).toFixed(2)} %
                    </TableCell>
                    <TableCell xs={3}>{component.value_net} zł</TableCell>
                    <TableCell xs={3}>
                      {parseFloat(
                        (component.sale_value_net / component.value_net - 1) *
                          100
                      ).toFixed(2)}
                      %
                    </TableCell>
                    <TableCell xs={3}>{component.sale_value_net} zł</TableCell>
                    <TableCell xs={3}>{component.base_value_net} zł</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          ) : (
            <LoadingCard fullHeight={true} />
          )}
        </DialogContent>
        <DialogActions>
          {
            <Grid className={classes.footer}>
              <Grid item>
                <Typography sx={{ fontWeight: 'bold', width: '200px' }}>
                  {`Cena dla partnera:`}
                  <p className="text-green-600 flex">{`${values.summary_net} zł`}</p>
                </Typography>
              </Grid>
              <Grid item>
                <Typography sx={{ fontWeight: 'bold', width: '200px' }}>
                  {`Cena po narzucie:`}
                  <p className="text-green-600 flex">{`${values.sale_summary_net} zł`}</p>
                </Typography>
              </Grid>
            </Grid>
          }
        </DialogActions>
      </Dialog>
    </>
  )
}

ValuationMarkupFactoryPreview.propTypes = {
  components: PropTypes.array,
  editComponent: PropTypes.func,
  delComponent: PropTypes.func,
  editingComponentIndex: PropTypes.number,
  pathPrefix: PropTypes.string,
  formik: PropTypes.object,
  minDiscount: PropTypes.number,
  disableInput: PropTypes.bool,
  popupKey: PropTypes.string,
}

export default ValuationMarkupFactoryPreview
