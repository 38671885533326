// import AddIcon from '@mui/icons-material/Add'

import PropTypes from 'prop-types'
import AttachFileIcon from '@mui/icons-material/AttachFile'

const ShowAttachmentCell = ({ row, keyName }) => {
  return <span spacing={1}>{!!row[keyName] && <AttachFileIcon />}</span>
}

ShowAttachmentCell.propTypes = {
  row: PropTypes.object,
  keyName: PropTypes.string,
}

export default ShowAttachmentCell
