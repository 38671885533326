import {
  // Select,
  FormControl,
  // InputLabel,
  // MenuItem,
  FormHelperText,
  Autocomplete,
  TextField,
} from '@mui/material'
import resolveFromObject from '../../lib/resolveFromObject'
import PropTypes from 'prop-types'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

const FormikSelect = ({
  variableName,
  options,
  labelName,
  formik,
  value,
  helperText,
  handleChange,
  disabled = false,
  config = { key: 'id', label: 'name', separator: ' ' },
  variant = 'outlined',
  textFieldProps,
  // ...props,
}) => {
  const [displayValue, setDisplayValue] = useState(null)
  const { t } = useTranslation()

  useEffect(() => {
    if ((value || value === 0) && options) {
      for (let i = 0; i < options.length; i++) {
        let option = options[i]
        if (option[config.key] == value) {
          setDisplayValue({
            id: value,
            label: resolveFromObject(option, config.label, config.separator),
          })
        }
      }
    } else {
      setDisplayValue(null)
    }
  }, [value, options])

  const localHandleChange = (e, v) => {
    let value = v && (v.id || v.id === 0) ? v.id : ''
    if (handleChange) {
      handleChange(variableName, value)
    } else {
      formik.setFieldValue(variableName, value)
    }
  }

  return (
    <FormControl
      fullWidth
      error={
        formik &&
        formik.touched[variableName] &&
        Boolean(formik.errors[variableName])
      }
    >
      {typeof options == 'object' && config && (
        <Autocomplete
          size="small"
          value={displayValue}
          onChange={(e, v) => localHandleChange(e, v)}
          id={variableName}
          name={variableName}
          variant={variant}
          disabled={disabled}
          loading={!disabled && !options}
          loadingText={t('main.loading')}
          getOptionLabel={(option) => option.label}
          isOptionEqualToValue={(option, value) =>
            option[config.key] === value[config.key]
          }
          openOnFocus={true}
          noOptionsText={t('main.no_data')}
          options={
            options && options.length > 0
              ? options.map((option) => ({
                  id: option[config.key],
                  label: resolveFromObject(
                    option,
                    config.label,
                    config.separator
                  ),
                }))
              : []
          }
          renderInput={(props) => (
            <TextField
              size="small"
              {...props}
              autoComplete="off"
              error={
                formik &&
                formik.touched[variableName] &&
                Boolean(formik.errors[variableName])
              }
              {...textFieldProps}
              label={labelName}
            />
          )}
        />
      )}
      <FormHelperText>
        {helperText}
        {formik && formik.touched[variableName] && formik.errors[variableName]}
      </FormHelperText>
    </FormControl>
  )
}

FormikSelect.propTypes = {
  formik: PropTypes.object,
  variableName: PropTypes.string,
  labelName: PropTypes.string,
  handleChange: PropTypes.func,
  type: PropTypes.string,
  variant: PropTypes.string,
  options: PropTypes.array,
  config: PropTypes.object,
  disabled: PropTypes.bool,
  params: PropTypes.any,
  textFieldProps: PropTypes.any,
  value: PropTypes.any,
  helperText: PropTypes.string,
}

export default FormikSelect
