import React, { useEffect, useState } from 'react'
import PageHeader from '../../layout/PageHeader'
import Article from '@mui/icons-material/Article'
import PageContainer from '../../layout/PageContainer'
import { Button, Grid, MenuItem, Paper, Select, TextField } from '@mui/material'
import ReportPartnersPreview from '../../../molecules/reports/ReportPartnersPreview'
import { faSortAmountUpAlt } from '@fortawesome/pro-solid-svg-icons'
import { faSortAmountDown } from '@fortawesome/pro-solid-svg-icons'
import DatePicker from '@mui/lab/DatePicker'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import makeStyles from '@mui/styles/makeStyles'
import axios from 'axios'
import FormikSelect from '../../../molecules/FormikSelect'
import LoadingCard from '../../../atoms/LoadingCard'

const useStyles = makeStyles(() => ({
  button: {
    borderBottomLeftRadius: '0',
    borderTopLeftRadius: '0',
    minWidth: '32px',
  },
  menuItem: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  menuItemImg: {
    maxWidth: '50%',
    maxHeight: '100px',
  },
}))

const options = [
  {
    label: 'Ilość',
    type: 'count',
    color: '#323232',
  },
  {
    label: 'Kwota',
    type: 'summary_price',
    color: 'rgba(255, 171, 0, 0.9)',
  },
]

const Reports = () => {
  const classes = useStyles()

  const [loaded, setLoaded] = useState(false)
  const [selected, setSelected] = useState(0)
  const [orderType, setOrderType] = useState('desc')
  const [dateStart, setDateStart] = useState(null)
  const [statusId, setStatusId] = useState(null)
  const [dateEnd, setDateEnd] = useState(null)
  const [name, setName] = useState(null)
  const [data, setData] = useState(null)
  const [statusOptions, setStatusOptions] = useState([])

  useEffect(async () => {
    const data = (await axios.post('/lookups/valuation_order_statuses')).data
    setStatusOptions(data)
    const statusId = data.at(-1).id
    setStatusId(statusId)
    const date = new Date()
    const dateStart = `${date.getFullYear()}-${
      date.getMonth() > 9 ? date.getMonth() + 1 : `0${date.getMonth() + 1}`
    }-01`
    setDateStart(dateStart)
    await filter({
      date_end: dateEnd,
      date_start: dateStart,
      name: name,
      status_id: statusId,
    })
    setLoaded(true)
  }, [])

  const filter = async (data) => {
    const response = await axios.post('/partners/report', {
      type: options[selected].type,
      orderType,
      ...data,
    })
    setData(response.data)
  }

  return (
    <>
      <PageHeader icon={<Article fontSize="medium" />} title="Raporty" />
      {/* <Select
        size="small"
        value={selected}
        classes={{ select: classes.menuItem }}
        onChange={(e) => setSelected(e.target.value)}
      >
        {options &&
          options.map((option, index) => (
            <MenuItem key={index} value={index} className={classes.menuItem}>
              <img className={classes.menuItemImg} src="./wykonczenie.png" />
              <div>{option.label}</div>
            </MenuItem>
          ))}
      </Select> */}
      {loaded ? (
        <PageContainer>
          <Grid item xs={12}>
            <Paper
              style={{
                padding: '16px',
                display: 'flex',
                flexDirection: 'column',
                gap: '10px',
              }}
            >
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                }}
              >
                <div
                  style={{
                    width: '80%',
                    display: 'grid',
                    gap: '10px',
                    gridTemplateColumns: '1fr 1fr 1fr 1fr',
                  }}
                >
                  <TextField
                    style={{
                      width: '100%',
                    }}
                    label="Nazwa"
                    size="small"
                    value={name}
                    onInput={(e) => {
                      setName(e.target.value)
                    }}
                  />
                  <FormikSelect
                    labelName="Status"
                    size="small"
                    options={statusOptions}
                    value={statusId}
                    handleChange={(n, v) => setStatusId(v)}
                  />
                  <DatePicker
                    label="Data od"
                    value={dateStart}
                    onChange={(value) => {
                      setDateStart(value ? value.format('YYYY-MM-DD') : '')
                    }}
                    // open={open}
                    // onOpen={() => setOpen(true)}
                    // onClose={() => setOpen(false)}
                    mask="__.__.____"
                    renderInput={(params) => (
                      <TextField {...params} size="small" />
                    )}
                  />
                  <DatePicker
                    label="Data do"
                    value={dateEnd}
                    onChange={(value) => {
                      setDateEnd(value ? value.format('YYYY-MM-DD') : '')
                    }}
                    // open={open}
                    // onOpen={() => setOpen(true)}
                    // onClose={() => setOpen(false)}
                    mask="__.__.____"
                    renderInput={(params) => (
                      <TextField {...params} size="small" />
                    )}
                  />
                </div>
                <div className="flex">
                  <Select
                    size="small"
                    value={selected}
                    onChange={(e) => setSelected(e.target.value)}
                  >
                    {options &&
                      options.map((option, index) => (
                        <MenuItem key={index} value={index}>
                          {option.label}
                        </MenuItem>
                      ))}
                  </Select>
                  <Button
                    className={classes.button}
                    onClick={() => {
                      setOrderType(orderType == 'asc' ? 'desc' : 'asc')
                    }}
                  >
                    {orderType === 'asc' ? (
                      <FontAwesomeIcon icon={faSortAmountDown} />
                    ) : (
                      <FontAwesomeIcon icon={faSortAmountUpAlt} />
                    )}
                  </Button>
                </div>
              </div>
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'flex-end',
                }}
              >
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() =>
                    filter({
                      date_end: dateEnd,
                      date_start: dateStart,
                      name: name,
                      status_id: statusId,
                    })
                  }
                >
                  <span className="mt-1">Filtruj</span>
                </Button>
              </div>
            </Paper>
          </Grid>
          <Grid item xs={12}>
            <Paper>
              <ReportPartnersPreview
                label={options[selected].label}
                color={options[selected].color}
                data={data}
              />
            </Paper>
          </Grid>
        </PageContainer>
      ) : (
        <LoadingCard />
      )}
    </>
  )
}

export default Reports
