import { Card, CardHeader, CardContent, Button } from '@mui/material'
import StoreKeyProvider from 'components/providers/StoreKeyProvider'
import WithFilters from 'components/hocs/WithFilters'
import DataTable from 'components/organisms/dataTable/DataTable'
import PropTypes from 'prop-types'
import DataInputs from 'components/organisms/dataTable/DataInputs'
import { useEffect, useState } from 'react'
import eventBus from 'lib/eventBus'
import { useDispatch } from 'react-redux'
import { openPopup } from 'store/popupsSlice'
import makeStyles from '@mui/styles/makeStyles'
import { getFilter } from 'store/filtersSlice'
import axios from 'axios'
import { AttachMoney } from '@mui/icons-material'
const useStyles = makeStyles(() => ({
  bg: {
    background: 'transparent',
    overflow: 'unset',
  },
  content: {
    padding: '24px',
    background: '#fff',
    marginBottom: '24px',
    borderRadius: '2px',
    boxShadow:
      '0px 3px 1px -2px rgba(0,0,0,0.2),0px 2px 2px 0px rgba(0,0,0,0.14),0px 1px 5px 0px rgba(0,0,0,0.12)',
  },
  contentSt: {
    background: '#fff',
    marginBottom: '24px',
    borderRadius: '2px',
    boxShadow:
      '0px 3px 1px -2px rgba(0,0,0,0.2),0px 2px 2px 0px rgba(0,0,0,0.14),0px 1px 5px 0px rgba(0,0,0,0.12)',
  },
  contentPreview: {
    background: '#fff',
    padding: 'unset',
    color: 'rgba(0, 0, 0, 0.6)',
  },
}))

const MaterialPricingPreview = ({ materialId, priceListId }) => {
  const dispatch = useDispatch()
  const classes = useStyles()

  const [colors, setColors] = useState([])
  const [thickness, setThickness] = useState([])
  const [pricing, setPricing] = useState()

  useEffect(() => {
    axios.post(`/materials/${materialId}/colors/filter`, null).then((res) => {
      setColors(res.data)
    }),
      axios.post(`/materials/${materialId}/thickness`, null).then((res) => {
        setThickness(res.data)
      })
  }, [])

  useEffect(() => {
    eventBus.on('newFilterValues', async (data) => {
      await setPricing(data.type_id)
    })

    return eventBus.remove('newFilterValues')
  }, [])

  useEffect(() => {
    eventBus.dispatch('forceFilterValue', {
      key: 'material_id',
      value: null,
      filter: 'MaterialsKey',
    })
  }, [pricing])

  return (
    <div>
      <StoreKeyProvider storeKey="MaterialPricingPreviewKey">
        <WithFilters
          config={{
            page: 1,
            paginate: 25,
            orderType: 'asc',
            order: 'color_name',
            inputs: {
              color_id: {
                value: '',
                relation: 'WHERE',
              },
              thickness_id: {
                value: '',
                relation: 'WHERE',
              },
              height: {
                value: '',
                relation: 'LIKE',
              },
              min_width: {
                value: null,
                relation: 'MIN',
              },
              max_width: {
                value: null,
                relation: 'MAX',
              },
              min_price_net: {
                value: null,
                relation: 'MIN',
              },
              max_price_net: {
                value: null,
                relation: 'MAX',
              },
              // active: {
              //   value: 1,
              //   relation: 'WHERE',
              // },
            },
          }}
          endpoint={`/price_lists/${priceListId}/materials/${materialId}/prices`}
        >
          <Card elevation={0} className={classes.bg}>
            <CardContent className={classes.contentSt}>
              <CardHeader
                className={classes.contentPreview}
                title="Cennik"
                action={
                  <div>
                    <Button
                      startIcon={<AttachMoney />}
                      variant="contained"
                      color="primary"
                      onClick={async () => {
                        let filters = await dispatch(
                          getFilter({ key: 'MaterialPricingPreviewKey' })
                        )
                        dispatch(
                          openPopup({
                            component: 'MaterialPricingFactory',
                            title: `Edytuj Cennik`,
                            level: 1,
                            key: 'pricing-factory-popup',
                            maxWidth: 'sm',
                            values: {
                              id: materialId,
                              priceListId,
                              filterKey: 'MaterialPricingPreviewKey',
                              filters: filters ? filters.payload : null,
                            },
                          })
                        )
                      }}
                    >
                      <span className="mt-1">Edytuj Cennik</span>
                    </Button>
                  </div>
                }
              />
              {colors && thickness && (
                <DataInputs
                  inputs={[
                    {
                      id: 'color_id',
                      label: 'Kolor',
                      placeholder: '',
                      config: {
                        key: 'color_id',
                        label: 'name',
                      },
                      type: 'select',
                      options: colors,
                    },
                    {
                      id: 'thickness_id',
                      label: 'Grubość',
                      placeholder: '',
                      type: 'select',
                      options: thickness,
                    },
                    {
                      id: 'width',
                      label: 'Szerokość',
                      placeholder: '',
                      type: 'number-between',
                    },
                    {
                      id: 'price_net',
                      label: 'Cena',
                      placeholder: '',
                      type: 'number-between',
                    },
                    // {
                    //   id: 'active',
                    //   label: 'Aktywne',
                    //   placeholder: '',
                    //   type: 'select',
                    //   options: [
                    //     {
                    //       id: 1,
                    //       name: 'Tak',
                    //     },
                    //     {
                    //       id: 0,
                    //       name: 'Nie',
                    //     },
                    //   ],
                    // },
                  ]}
                />
              )}
            </CardContent>
            <CardContent className={classes.content}>
              <DataTable
                breakpoint={1200}
                labels={[
                  'Kolor',
                  'Grubość',
                  'Szerokość',
                  'Cena',
                  'Główna cena',
                ]}
                keys={[
                  'color_name',
                  'thickness_name',
                  'width',
                  'price_net',
                  'main_price_net',
                ]}
              />
            </CardContent>
          </Card>
        </WithFilters>
      </StoreKeyProvider>
    </div>
  )
}

MaterialPricingPreview.propTypes = {
  priceListId: PropTypes.oneOfType(PropTypes.string, PropTypes.number),
  materialId: PropTypes.string,
}

export default MaterialPricingPreview
