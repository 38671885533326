import { Grid, IconButton } from '@mui/material'
import { useDispatch, useSelector } from 'react-redux'
import PropTypes from 'prop-types'
import { openPopup } from '../../../../store/popupsSlice'
import { Delete, Edit } from '@mui/icons-material'
import axios from 'axios'
import Confirmation from '../../popups/Confirmation'
import { handleOpenAlert } from '../../../../lib/handleAlert'
import { refreshFilter } from '../../../../lib/handleFilters'
import { useState } from 'react'
import RestoreFromTrashIcon from '@mui/icons-material/RestoreFromTrash'
import PaletteIcon from '@mui/icons-material/Palette'
const ColorsTableActions = ({ row }) => {
  const dispatch = useDispatch()
  const user = useSelector((state) => state.user.user)
  const [confirmation, setConfirmation] = useState(false)

  return (
    <Grid
      container
      alignItems="center"
      justifyContent="center"
      direction="row"
      spacing={1}
      className="table-actions"
    >
      {confirmation && (
        <Confirmation
          level={5}
          maxWidth="xs"
          icon="Warning"
          title={`Czy na pewno chcesz ${
            row.active ? 'usunąć' : 'przywrócić'
          } kolor: ${row.name}?`}
          subtitle={`Tej operacji nie można cofnąć.`}
          reject={() => {
            setConfirmation(false)
          }}
          success={() => {
            axios[row.active ? 'delete' : 'patch'](
              `/colors/${row.id}${row.active ? '' : '/restore'}`
            ).then(() => {
              handleOpenAlert({
                title: 'Sukces!',
                subtitle: `${row.active ? 'Usunięto' : 'Przywrócono'} kolor ${
                  row.name
                }`,
                type: 'success',
              })
              refreshFilter('ColorsKey')
              setConfirmation(false)
            })
          }}
        />
      )}
      {!!row.active &&
        user.permissions &&
        user.permissions.includes('edit Color') && (
          <IconButton
            title="Edytuj kolor"
            size="small"
            onClick={() =>
              dispatch(
                openPopup({
                  component: 'ColorFactory',
                  title: 'Edytuj kolor',
                  level: 1,
                  maxWidth: 'sm',
                  values: {
                    ...row,
                  },
                  key: 'color-factory-popup',
                })
              )
            }
          >
            <Edit fontSize="small" />
          </IconButton>
        )}
      {row.active === 1 &&
        row.group === 1 &&
        user.permissions &&
        user.permissions.includes('edit Color') && (
          <IconButton
            title="Edytuj barwy"
            size="small"
            onClick={() =>
              dispatch(
                openPopup({
                  component: 'ColorItemsFactory',
                  title: 'Edytuj dostępne barwy',
                  level: 1,
                  maxWidth: 'sm',
                  values: {
                    color_id: row.id,
                  },
                  key: 'color-items-factory-popup',
                })
              )
            }
          >
            <PaletteIcon fontSize="small" />
          </IconButton>
        )}
      {user.permissions &&
        ((row.active && user.permissions.includes('remove Color')) ||
          (!row.active && user.permissions.includes('restore Color'))) && (
          <Grid className="p-0" item>
            <IconButton
              className="p-0"
              title={`${row.active ? 'Przywróć' : 'Usuń'} kolor`}
              size="small"
              onClick={() => setConfirmation(true)}
            >
              {row.active ? (
                <Delete fontSize="small" color="error" />
              ) : (
                <RestoreFromTrashIcon fontSize="small" color="success" />
              )}
            </IconButton>
          </Grid>
        )}
    </Grid>
  )
}

ColorsTableActions.propTypes = {
  row: PropTypes.object,
}

export default ColorsTableActions
