import PropTypes from 'prop-types'
import React, { useEffect } from 'react'
import { useFormik } from 'formik'
import { refreshFilter } from '../../../../lib/handleFilters'
import { Grid, DialogActions, DialogContent } from '@mui/material'
import FormikControl from '../../../molecules/FormikControl'
import SaveIcon from '@mui/icons-material/Save'
import * as Yup from 'yup'
import axios from 'axios'
import { handleOpenAlert } from '../../../../lib/handleAlert'
import { closePopup } from '../../../../store/popupsSlice'
import { useDispatch } from 'react-redux'
import { useState } from 'react'
import LoadingButton from '@mui/lab/LoadingButton'
import Switch from '@mui/material/Switch'
import FormControlLabel from '@mui/material/FormControlLabel'

const ColorFactory = ({ ...values }) => {
  const dispatch = useDispatch()

  const editId = values.id

  const formik = useFormik({
    initialValues: {
      name: null,
      group: false,
    },
    enableReinitialize: false,
    validationSchema: Yup.object({
      name: Yup.string().required('Pole wymagane'),
    }),
    onSubmit: async (values) => {
      setLoading(true)
      save(values)
    },
  })

  useEffect(() => {
    if (editId) {
      axios.get(`/colors/${editId}`).then((response) => {
        formik.setValues({
          name: response.data.name,
          group: response.data.group,
        })
      })
    }
  }, [editId])

  const save = (values) => {
    axios[editId ? 'put' : 'post'](`/colors${editId ? `/${editId}` : ''}`, {
      ...values,
    })
      .then((res) => {
        if (res.status == 200 || res.status == 201) {
          dispatch(closePopup('color-factory-popup'))

          refreshFilter('ColorsKey')
        }
        handleOpenAlert({
          title: 'Sukces!',
          subtitle: `${editId ? 'Dodano' : 'Zaktualizowano'} kolor`,
          type: 'success',
        })
      })
      .catch((err) => {
        handleOpenAlert(err.request.status)
      })
      .finally(() => {
        setLoading(false)
      })
  }

  const [loading, setLoading] = useState(false)

  return (
    <>
      <form onSubmit={formik.handleSubmit} onReset={formik.handleReset}>
        <>
          <DialogContent dividers={true}>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <FormikControl
                      variableName="name"
                      handleChange={formik.handleChange}
                      labelName="Nazwa koloru"
                      formik={formik}
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <FormControlLabel
                      control={
                        <Switch
                          color="primary"
                          checked={Boolean(formik.values.group)}
                          onChange={(e) => {
                            formik.setFieldValue('group', e.target.checked)
                          }}
                        />
                      }
                      label="Grupa"
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <LoadingButton
              startIcon={<SaveIcon />}
              variant="contained"
              color="primary"
              type="submit"
              // onClick={setLoading}
              loading={loading}
              loadingPosition="start"
            >
              <span className="mt-1">Zapisz</span>
            </LoadingButton>
          </DialogActions>
        </>
      </form>
    </>
  )
}

ColorFactory.propTypes = {
  values: PropTypes.object,
}

export default ColorFactory
