import React from 'react'
import makeStyles from '@mui/styles/makeStyles'
import PropTypes from 'prop-types'
import ListItemButton from '@mui/material/ListItemButton'
import { Link } from 'react-router-dom'
import ListItemIcon from '@mui/material/ListItemIcon'
import ListItemText from '@mui/material/ListItemText'
import NavGroup from '../../NavGroup'
import RequestQuoteIcon from '@mui/icons-material/RequestQuote'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faForklift, faUsers } from '@fortawesome/pro-solid-svg-icons'
import { faMoneyBillWave } from '@fortawesome/pro-solid-svg-icons'
import { faBoxOpen } from '@fortawesome/pro-solid-svg-icons'
import ConstructionIcon from '@mui/icons-material/Construction'
import { useSelector } from 'react-redux'
import { faHandshake } from '@fortawesome/pro-solid-svg-icons'
import { faHome } from '@fortawesome/pro-solid-svg-icons'
import EqualizerIcon from '@mui/icons-material/Equalizer'
import { useTranslation } from 'react-i18next'
import ColorLensIcon from '@mui/icons-material/ColorLens'
// import Article from '@mui/icons-material/Article'

const useStyles = makeStyles((theme) => ({
  nested: {
    paddingLeft: theme.spacing(4),
  },
  fontSize: {
    fontSize: '0.875rem',
  },
  listItem: {
    minWidth: '38px',
    '&.MuiListItemButton-root:hover': {
      backgroundColor: 'red',
    },
    '&.Mui-selected:active': {
      background: 'rgba(0, 0, 0, 0.9)',
    },
    color: 'rgba(0, 0, 0, 0.6)',
  },
}))

const B2BNavigationItems = function ({ location }) {
  const classes = useStyles()
  const user = useSelector((state) => state.user.user)
  const { t } = useTranslation()

  const redirectToMes = () => {
    // eslint-disable-next-line
    const mesUrl = process.env.REACT_APP_BASE_MES_URL
    window.open(`${mesUrl}/orders`, '_blank')
  }

  return (
    <>
      <NavGroup role={user.role_id} allowRoles={[1]}>
        <Link to="/partners">
          <ListItemButton selected={location[0] == 'partners'}>
            <ListItemIcon className={classes.listItem}>
              <FontAwesomeIcon icon={faHandshake} />
            </ListItemIcon>
            <ListItemText primary="Partnerzy" />
          </ListItemButton>
        </Link>
      </NavGroup>
      {user.permissions && user.permissions.includes('viewAdmins User') && (
        <NavGroup role={user.role_id} allowRoles={[1]}>
          <Link to="/users">
            <ListItemButton selected={location[0] == 'users'}>
              <ListItemIcon className={classes.listItem}>
                <FontAwesomeIcon icon={faUsers} />
              </ListItemIcon>
              <ListItemText primary="Użytkownicy" />
            </ListItemButton>
          </Link>
        </NavGroup>
      )}
      <NavGroup role={user.role_id} allowRoles={[1]}>
        <Link to="/materials">
          <ListItemButton selected={location[0] == 'materials'}>
            <ListItemIcon className={classes.listItem}>
              <ConstructionIcon />
            </ListItemIcon>
            <ListItemText primary="Materiały" />
          </ListItemButton>
        </Link>
      </NavGroup>
      <NavGroup role={user.role_id} allowRoles={[1]}>
        <Link to="/colors">
          <ListItemButton selected={location[0] == 'colors'}>
            <ListItemIcon className={classes.listItem}>
              <ColorLensIcon />
            </ListItemIcon>
            <ListItemText primary="Kolory" />
          </ListItemButton>
        </Link>
      </NavGroup>
      <NavGroup role={user.role_id} allowRoles={[2]}>
        <Link to="/home">
          <ListItemButton selected={location[0] == 'home'}>
            <ListItemIcon className={classes.listItem}>
              <FontAwesomeIcon icon={faHome} />
            </ListItemIcon>
            <ListItemText primary={t('home.page')} />
          </ListItemButton>
        </Link>
      </NavGroup>
      <NavGroup role={user.role_id} allowRoles={[2]}>
        <Link to="/valuations">
          <ListItemButton selected={location[0] == 'valuations'}>
            <ListItemIcon className={classes.listItem}>
              <FontAwesomeIcon icon={faMoneyBillWave} />
            </ListItemIcon>
            <ListItemText primary={t('valuation.page')} />
          </ListItemButton>
        </Link>
      </NavGroup>
      <NavGroup role={user.role_id} allowRoles={[1]}>
        <Link to="/orders/custom">
          <ListItemButton
            selected={location[0] == 'orders' && location[1] == 'custom'}
          >
            <ListItemIcon className={classes.listItem}>
              <FontAwesomeIcon icon={faMoneyBillWave} />
            </ListItemIcon>
            <ListItemText primary="Zamówienia niestandardowe" />
          </ListItemButton>
        </Link>
      </NavGroup>
      <NavGroup role={user.role_id} allowRoles={[1, 2]}>
        <Link to="/orders">
          <ListItemButton selected={location[0] == 'orders' && !location[1]}>
            <ListItemIcon className={classes.listItem}>
              <FontAwesomeIcon icon={faBoxOpen} />
            </ListItemIcon>
            <ListItemText
              className={classes.fontSize}
              primary={t('order.page')}
            />
          </ListItemButton>
        </Link>
      </NavGroup>
      {user.permissions && user.permissions.includes('view PriceList') && (
        <NavGroup role={user.role_id} allowRoles={[1]}>
          <Link to="/price_lists">
            <ListItemButton selected={location[0] == 'price_lists'}>
              <ListItemIcon className={classes.listItem}>
                <RequestQuoteIcon />
              </ListItemIcon>
              <ListItemText primary="Cenniki" />
            </ListItemButton>
          </Link>
        </NavGroup>
      )}
      {user.permissions && user.permissions.includes('view report') && (
        <NavGroup role={user.role_id} allowRoles={[1]}>
          <Link to="/reports">
            <ListItemButton
              selected={
                location[0] == 'reports' || location[0] == 'administrator'
              }
            >
              <ListItemIcon className={classes.listItem}>
                <EqualizerIcon />
              </ListItemIcon>
              <ListItemText primary="Raporty" />
            </ListItemButton>
          </Link>
        </NavGroup>
      )}
      {user.permissions && user.permissions.includes('view MES') && (
        <NavGroup role={user.role_id} allowRoles={[1]}>
          <ListItemButton onClick={redirectToMes}>
            <ListItemIcon className={classes.listItem}>
              <FontAwesomeIcon icon={faForklift} />
            </ListItemIcon>
            <ListItemText className={classes.fontSize} primary="Magazyn" />
          </ListItemButton>
        </NavGroup>
      )}
    </>
  )
}

B2BNavigationItems.propTypes = {
  location: PropTypes.array,
}

export default B2BNavigationItems
