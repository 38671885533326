import { useContext, useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { ValuationCalculatorContext } from '../../../../../../contexts/ValuationCalculatorContext'
import FormAutocomplete from '../../../../inputs/FormAutocomplete'
import { useDispatch } from 'react-redux'
import { getMaterials } from '../../../../../../store/valuationSlice'

const ValuationCalculatorMaterialInput = () => {
  const {
    component,
    setComponent,
    setImage,
    validate,
    readOnly,
    setMaterialImage,
    handleLoadingStack,
    setMaterialMaxLength,
    getExtrasItems,
    partnerId,
  } = useContext(ValuationCalculatorContext)
  const { t } = useTranslation()
  const [materials, _setMaterials] = useState([])
  const [currentMaterial, setCurrentMaterial] = useState('')
  const firstRender = useRef(true)
  useEffect(() => {
    firstRender.current = false
  })
  useEffect(() => {
    firstRender.current = true
  }, [component.id])

  const dispatch = useDispatch()
  useEffect(() => {
    let maxLength = null
    if (component.material_id && materials) {
      const material = materials.find(
        (element) => element.id == component.material_id
      )
      if (material) {
        component.material_name = material.name
        component.unit_id = material.unit_id
        component.unit_name = material.unit_name
        setImage(material.image)
        setMaterialImage(material.image)
        setCurrentMaterial(material)
        maxLength = material.max_length * 1000
      }
    } else {
      component.material_name = ''
      setCurrentMaterial('')
      setImage('')
    }
    setMaterialMaxLength(maxLength)
    setComponent(component)
  }, [component.material_id, materials])

  useEffect(() => {
    if (!component.color_id) {
      setImage(currentMaterial?.image || '')
    }
  }, [component.color_id])

  useEffect(() => {
    if (!firstRender.current) {
      getExtrasItems()
    }
  }, [component.material_id])

  useEffect(async () => {
    if (component.location_id) {
      loadMaterials()
    } else {
      setMaterials([])
    }
    if (!firstRender.current) {
      changeValue('')
    }
  }, [component.location_id])

  const changeValue = (value) => {
    if (value) {
      component.material_id = value[0].id
      component.unit_name = value[0].unit_name
      component.unit_id = value[0].unit_id
    } else {
      component.material_id = null
      component.unit_name = null
      component.unit_id = null
    }
    setComponent(component)
  }

  const setMaterials = (materials) => {
    let result = []
    if (materials) {
      for (let i = 0; i < materials.length; i++) {
        if (readOnly || materials[i].active) {
          result.push(materials[i])
        }
      }
      _setMaterials(result)
    } else {
      _setMaterials(materials)
    }
  }

  const loadMaterials = () => {
    setMaterials(null)
    handleLoadingStack(true)
    dispatch(
      getMaterials({
        location_id: component.location_id,
        partner_id: partnerId,
      })
    ).then((materials) => {
      setMaterials(materials.payload)
      handleLoadingStack(false)
    })
  }

  return (
    <>
      <FormAutocomplete
        variableName="material_id"
        handleChange={(l, v) => {
          changeValue(v)
        }}
        value={component.material_id}
        disabled={readOnly || !component.location_id}
        helperText={
          validate && !component.material_id && t('main.validation.required')
        }
        labelName={t('valuation_popup.data.input.material')}
        options={materials}
        returnObject={true}
      />
    </>
  )
}
ValuationCalculatorMaterialInput.propTypes = {}
export default ValuationCalculatorMaterialInput
