import React, { useMemo, useState } from 'react'
import { Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import PropTypes from 'prop-types'
import LoadingCard from '../../../../atoms/LoadingCard'
import ValuationSummaryRow from './ValuationSummaryRow'
import { useTranslation } from 'react-i18next'
import { toFixedNumber } from '../../../../../lib/numberFormatter'

const useStyles = makeStyles(() => ({
  cells: {
    minWidth: '38px',
    '&.MuiTableCell-root': {
      padding: '0px 16px !important',
    },
  },
  cellsNd: {
    minWidth: '38px',
    fontWeight: '700',
    '&.MuiTableCell-root': {
      padding: '8px 16px !important',
    },
  },
}))

const ValuationSummary = ({
  components = [],
  currentIndex,
  minDiscount,
  globalDiscount,
  delComponent,
  editComponent,
  clearGlobalDiscount,
  setComponentQuantity,
  currencyAcronym,
  validate,
  statusId,
  readOnly,
  setFieldValue,
  setFormValue,
}) => {
  const classes = useStyles()
  const [loading, setLoading] = useState(false)
  const [editingIndex, setEditingIndex] = useState(null)
  const [timeoutValues, _setTimeoutValues] = useState([])
  const { t } = useTranslation()

  const setTimeoutValues = (index, fn) => {
    if (timeoutValues[index]) {
      clearTimeout(timeoutValues[index])
    }
    let tv = timeoutValues
    tv[index] = setTimeout(() => {
      fn()
    }, 500)
    _setTimeoutValues(tv)
  }

  const setDiscount = async (index, value) => {
    await setFieldValue(index, 'discount', value)
    if (value != globalDiscount) {
      clearGlobalDiscount()
    }
  }

  const setQuantity = async (index, value) => {
    setComponentQuantity(components[index].id, value)
  }

  const setGlobalDiscount = (discount) => {
    if (![null, ''].includes(discount)) {
      for (let i = 0; i < components.length; i++) {
        setDiscount(i, discount)
      }
    }
  }
  const setDiscountSummary = async (index, value) => {
    const newDiscount = toFixedNumber({
      value: (1 - value / components[index].summary) * 100,
    })
    setFormValue(index, 'discount_summary', value)
    setTimeoutValues(index, () => {
      setFieldValue(index, 'discount', newDiscount)
    })
  }

  useMemo(() => {
    if (editingIndex !== null) {
      if (currentIndex != editingIndex) {
        setEditingIndex(null)
      }
    }
  }, [currentIndex])

  useMemo(() => setGlobalDiscount(globalDiscount), [globalDiscount])

  const deleteComponent = async (index) => {
    delComponent(index)
  }

  return (
    <div id="valuation-summary-box">
      {!loading ? (
        components &&
        (components.length > 0 ? (
          <Table size="small">
            <TableHead>
              <TableRow>
                <TableCell
                  className={classes.cells}
                  style={{
                    width: `2%`,
                    whiteSpace: 'nowrap',
                  }}
                >
                  Lp.
                </TableCell>
                <TableCell
                  className={classes.cells}
                  style={{
                    width: `30%`,
                    whiteSpace: 'nowrap',
                  }}
                >
                  {t('valuation_popup.data.table.name')}
                </TableCell>
                <TableCell
                  className={classes.cells}
                  style={{
                    width: `10%`,
                    whiteSpace: 'nowrap',
                  }}
                >
                  {t('valuation_popup.data.table.quantity')}
                </TableCell>
                <TableCell
                  className={classes.cells}
                  style={{
                    width: `5%`,
                    whiteSpace: 'nowrap',
                  }}
                >
                  {`${t('Net price')} (${currencyAcronym})`}
                </TableCell>
                <TableCell
                  className={classes.cells}
                  style={{
                    width: `10%`,
                    whiteSpace: 'nowrap',
                  }}
                >
                  {t('valuation_popup.data.table.discount')}
                </TableCell>
                <TableCell
                  className={classes.cells}
                  style={{
                    width: `5%`,
                    whiteSpace: 'nowrap',
                  }}
                >
                  {t('valuation_popup.data.table.after_discount')}{' '}
                  {currencyAcronym}{' '}
                </TableCell>
                <TableCell
                  className={classes.cells}
                  style={{
                    width: `5%`,
                    whiteSpace: 'nowrap',
                  }}
                >
                  {t('valuation_popup.data.table.actions')}
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {components.map((component, index) => (
                <ValuationSummaryRow
                  key={`${index}-${component.id}`}
                  setDiscountSummary={(v) => {
                    setDiscountSummary(index, v)
                  }}
                  setDiscount={(v) => {
                    setDiscount(index, v)
                  }}
                  setQuantity={(v) => {
                    setQuantity(index, v)
                  }}
                  readOnly={readOnly}
                  minDiscount={minDiscount}
                  validate={validate}
                  component={component}
                  lp={index + 1}
                  statusId={statusId}
                  edit={currentIndex == index}
                  delComponent={() => {
                    deleteComponent(index)
                  }}
                  editComponent={() => {
                    setEditingIndex(index)
                    editComponent(index)
                  }}
                />
              ))}
            </TableBody>
          </Table>
        ) : (
          <div
            style={{
              textAlign: 'center',
              color: 'grey',
              fontSize: '20px',
              height: '100%',
            }}
          >
            {t('main.no_data')}
          </div>
        ))
      ) : (
        <LoadingCard />
      )}
    </div>
  )
}

ValuationSummary.propTypes = {
  components: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
  editComponent: PropTypes.func,
  delComponent: PropTypes.func,
  currentIndex: PropTypes.number,
  pathPrefix: PropTypes.string,
  currencyAcronym: PropTypes.string,
  minDiscount: PropTypes.number,
  globalDiscount: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  clearGlobalDiscount: PropTypes.func,
  setComponentQuantity: PropTypes.func,
  validate: PropTypes.bool,
  statusId: PropTypes.number,
  readOnly: PropTypes.bool,
  setFieldValue: PropTypes.func,
  setFormValue: PropTypes.func,
}

export default ValuationSummary
