import { useEffect, useState } from 'react'
import axios from 'axios'
import MaterialPreview from './Preview/MaterialPreview'
import FormAutocomplete from 'components/molecules/inputs/FormAutocomplete'
import PageHeader from 'components/organisms/layout/PageHeader'
import { Search } from '@mui/icons-material'
import { makeStyles } from '@mui/styles'

const useStyles = makeStyles(() => ({
  priceListSelectContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    padding: '1rem 1rem 0 1rem',
  },
  priceListSelectControl: {
    width: '200px',
  },
  pricelistSelect: {
    backgroundColor: 'white',
  },
}))

const PriceListMaterialPreview = () => {
  const [priceLists, setPriceLists] = useState([])
  const [priceListId, _setPriceListId] = useState(null)

  const classes = useStyles()

  useEffect(() => {
    axios.get('/price_lists', null).then((res) => {
      setPriceLists(res.data)
      setPriceListId(sessionStorage.getItem('price_list_id') || res.data[0].id)
    })
  }, [])

  const setPriceListId = (v) => {
    sessionStorage.setItem('price_list_id', v)
    _setPriceListId(v)
  }

  return (
    <div>
      <>
        <PageHeader
          icon={<Search fontSize="medium" />}
          title="Podgląd materiału"
        />
        <div className={classes.priceListSelectContainer}>
          <FormAutocomplete
            value={priceListId}
            disableClearable={true}
            handleChange={(l, v) => {
              setPriceListId(v)
            }}
            textFieldProps={{
              className: classes.pricelistSelect,
            }}
            className={classes.priceListSelectControl}
            labelName="Cennik"
            options={priceLists}
          />
        </div>
        {priceListId && <MaterialPreview priceListId={priceListId} />}
      </>
    </div>
  )
}

export default PriceListMaterialPreview
