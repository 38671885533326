// import AddIcon from '@mui/icons-material/Add'
import PropTypes from 'prop-types'
import { WarningAmber } from '@mui/icons-material'
import { makeStyles } from '@mui/styles'
const useStyles = makeStyles(() => ({
  warning: {
    color: 'orange',
  },
}))
const ShowWarningCell = ({ row, keyName }) => {
  const classes = useStyles()
  return (
    <span spacing={1} className={classes.warning}>
      {!!row[keyName] && <WarningAmber />}
    </span>
  )
}

ShowWarningCell.propTypes = {
  row: PropTypes.object,
  keyName: PropTypes.string,
}

export default ShowWarningCell
