import React from 'react'
import { Link } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import { Paper, Drawer, Grid } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
// import { logout } from '../../../store/userSlice'
import { changeBurgerExpanded } from '../../../store/layoutSlice'
import { Clear } from '@mui/icons-material'
import Navigation from './Navigation/Navigation'
import LogoAmbit from '../../../images/logo-ambit.png'
import atComputers from '../../../images/PoweredByGrey.svg'
import LanguagePicker from '../translate/LanguagePicker'

const styles = makeStyles((theme) => ({
  SideMenu: {
    display: 'none',
    flexDirection: 'column',
    height: '100%',
    width: '200px',
    zIndex: 1101,
    position: 'relative',
    backgroundColor: theme.palette.white.light,
    [theme.breakpoints.up('md')]: {
      minWidth: '264px',
      display: 'flex',
    },
  },
  languagePickerInput: {
    borderColor: '#ABAFB3',
    padding: '4px 16px',
    width: '100%',
  },
  languagePicker: {
    width: '100%',
  },
  popUpHeader: {
    display: 'flex',
    alignItems: 'center',
    gap: theme.spacing(2),
    padding: theme.spacing(2),
    flexWrap: 'wrap',
    justifyContent: 'space-between',
    borderBottom: '1px solid #ABAFB3',
    background: theme.palette.primary.main,
  },
  drawerPaper: {
    backgroundColor: theme.palette.white.light,
    // color: theme.palette.white.main,
    maxWidth: '70%',
    width: '100%',
    ['@media (min-width:450px)']: {
      maxWidth: '320px',
    },
  },
  redIcon: {
    color: theme.palette.white.light,
  },
  logo: {
    // filter: 'brightness(0) invert(1)',
    maxWidth: '100px',
  },
}))

const SideMenu = () => {
  const dispatch = useDispatch()
  const classes = styles()
  const mobileOpen = useSelector((state) => state.layout.burgerExpanded)
  const user = useSelector((state) => state.user.user)

  const container = document.querySelector('body')

  return (
    <>
      <Paper square elevation={4} className={classes.SideMenu}>
        <Link
          to={user && user.role_id == 1 ? '/orders' : '/home'}
          style={
            {
              // padding: '22px 0 22px 0',
            }
          }
          className="hover:opacity-50 transition-all flex justify-start bg-custom_white_bg_definitive"
        >
          <img className={classes.logo} src={LogoAmbit} alt="logo" />
          <div className="flex items-start justify-end flex-col text-custom_black_bg font-light text-base pl-3 pb-2">
            <span className="">PRZEDSIĘBIORSTWO</span>
            <span className="">BUDOWLANE</span>
          </div>
        </Link>
        <Navigation />
        <Grid container item className="absolute bottom-0">
          <LanguagePicker
            inputClassName={classes.languagePickerInput}
            className={classes.languagePicker}
            variant="standard"
          />
          <a
            href="https://atcomp.pl/"
            target="_blank"
            className="hover:opacity-50 transition-all p-4 flex justify-center w-full"
            rel="noreferrer"
          >
            <img src={atComputers} alt="logo" />
          </a>
        </Grid>
      </Paper>
      <Drawer
        container={container}
        variant="temporary"
        anchor="left"
        open={mobileOpen}
        onClose={() => dispatch(changeBurgerExpanded({ value: false }))}
        onClick={() => dispatch(changeBurgerExpanded({ value: false }))}
        classes={{
          paper: classes.drawerPaper,
        }}
        ModalProps={{
          keepMounted: true,
        }}
      >
        <div className={classes.popUpHeader}>
          <div className="flex gap-3 items-center text-white">
            <Clear
              className="cursor-pointer"
              onClick={() => dispatch(changeBurgerExpanded({ value: false }))}
            />
            Menu
          </div>
        </div>
        <Navigation />
        <Grid container item className="absolute bottom-0">
          <a
            href="https://atcomp.pl/"
            target="_blank"
            className="hover:opacity-50 transition-all p-10 pb-1 flex justify-center w-full"
            rel="noreferrer"
          >
            <img src={atComputers} alt="logo" />
          </a>
        </Grid>
      </Drawer>
    </>
  )
}

export default SideMenu
