import PropTypes from 'prop-types'
import { TableCell } from '@mui/material'
import React, { memo, useState } from 'react'
import Autocomplete from 'components/modules/Input/atoms/Autocomplete'
import MaterialPricingListItemSquareMetersCellsForm from '../atoms/MaterialPricingListItemSquareMetersCellsForm'
import MaterialPricingListItemLinearMetersCellsForm from '../atoms/MaterialPricingListItemLinearMetersCellsForm'
import UnitEnum from '../../../../features/Unit/Enum/UnitEnum'
import ClearableInput from 'components/modules/Input/atoms/ClearableInput'
import {
  onIncreasePriceNetChange,
  onPriceNetChange,
  onWholesalePriceNetChange,
} from '../../../../features/MaterialPricing/materialPricesCalculation'

const MaterialPricingListItemForm = ({
  item,
  index,
  action,
  material,
  names,
  updateItem,
  colors = [],
  thickness = [],
  extras = {},
  errors = {},
}) => {
  const [handlerTimeout, setHandlerTimeout] = useState(null)
  const onFieldValueChange = (field, value) => {
    item[field] = value
    updateItem(item)
  }

  const changeHandler = (handler, value, field) => {
    if (handlerTimeout) {
      clearTimeout(handlerTimeout)
    }
    setHandlerTimeout(
      setTimeout(() => {
        updateValues(value, handler)
      }, 500)
    )
    onFieldValueChange(field, value)
  }
  const updateValues = (value, handler) => {
    const valuesToUpdate = handler(value, item)
    for (const field in valuesToUpdate) {
      onFieldValueChange(field, valuesToUpdate[field])
    }
  }

  const relatedCells =
    material.unit_id === UnitEnum.SQUARE_METERS
      ? MaterialPricingListItemSquareMetersCellsForm
      : MaterialPricingListItemLinearMetersCellsForm

  return (
    <>
      <TableCell>{index + 1}</TableCell>
      <TableCell>
        <Autocomplete
          labelName={'Kolor'}
          value={item.color_id}
          itemValue="color_id"
          onChange={(v) => onFieldValueChange('color_id', v)}
          options={colors}
        />
      </TableCell>
      {React.createElement(relatedCells, {
        onFieldValueChange,
        names,
        item,
        extras,
        errors,
        thickness,
      })}
      <TableCell>---</TableCell>
      {material && material.unit_id === UnitEnum.LINEAR_METERS && (
        <>
          <TableCell>
            <ClearableInput
              InputProps={{ id: `wholesale_price_net-${index}` }}
              label="Cena hurtowa"
              type="number"
              value={item.wholesale_price_net}
              errors={errors.wholesale_price_net}
              suffix="zł"
              changeHandler={(value) =>
                changeHandler(
                  onWholesalePriceNetChange,
                  value,
                  'wholesale_price_net'
                )
              }
            />
          </TableCell>
          <TableCell>
            <ClearableInput
              InputProps={{ id: `increase_price-${index}` }}
              label="Zwiększ cenę detaliczną o"
              type="number"
              value={item.increase_price_net}
              errors={errors.increase_price_net}
              suffix="%"
              changeHandler={(value) =>
                changeHandler(
                  onIncreasePriceNetChange,
                  value,
                  'increase_price_net'
                )
              }
            />
          </TableCell>
        </>
      )}
      <TableCell>
        <ClearableInput
          InputProps={{ id: `price_net-${index}` }}
          label="Cena detaliczna"
          type="number"
          value={item.price_net}
          errors={errors.price_net}
          suffix="zł"
          changeHandler={(value) =>
            changeHandler(onPriceNetChange, value, 'price_net')
          }
        />
      </TableCell>
      <TableCell>{action}</TableCell>
    </>
  )
}

MaterialPricingListItemForm.propTypes = {
  item: PropTypes.object.isRequired,
  index: PropTypes.number.isRequired,
  material: PropTypes.object.isRequired,
  action: PropTypes.element,
  colors: PropTypes.array,
  names: PropTypes.array,
  thickness: PropTypes.array,
  updateItem: PropTypes.func,
  errors: PropTypes.object,
  extras: PropTypes.object,
  finishing: PropTypes.bool,
}

export default memo(MaterialPricingListItemForm)
