import { Card, CardHeader, CardContent, Button } from '@mui/material'
import StoreKeyProvider from 'components/providers/StoreKeyProvider'
import WithFilters from 'components/hocs/WithFilters'
import DataTable from 'components/organisms/dataTable/DataTable'
import PropTypes from 'prop-types'
import DataInputs from 'components/organisms/dataTable/DataInputs'
import { useEffect, useState } from 'react'
import eventBus from 'lib/eventBus'
import { useDispatch, useSelector } from 'react-redux'
import { openPopup } from 'store/popupsSlice'
import makeStyles from '@mui/styles/makeStyles'
import axios from 'axios'
import AttachMoneyIcon from '@mui/icons-material/AttachMoney'
import { getFilter } from 'store/filtersSlice'

const useStyles = makeStyles((theme) => ({
  bg: {
    background: 'transparent',
    overflow: 'unset',
  },
  content: {
    padding: '24px',
    background: '#fff',
    marginBottom: '24px',
    borderRadius: '2px',
    boxShadow:
      '0px 3px 1px -2px rgba(0,0,0,0.2),0px 2px 2px 0px rgba(0,0,0,0.14),0px 1px 5px 0px rgba(0,0,0,0.12)',
  },
  contentSt: {
    background: '#fff',
    marginBottom: '24px',
    borderRadius: '2px',
    boxShadow:
      '0px 3px 1px -2px rgba(0,0,0,0.2),0px 2px 2px 0px rgba(0,0,0,0.14),0px 1px 5px 0px rgba(0,0,0,0.12)',
  },
  contentPreview: {
    background: '#fff',
    padding: 'unset',
    color: 'rgba(0, 0, 0, 0.6)',
    display: 'flex',
    flexWrap: 'wrap',
    [theme.breakpoints.down('custom_extras')]: {
      width: '100%',
    },
  },
}))

const MaterialFinishingPricingPreview = ({ materialId, priceListId }) => {
  const dispatch = useDispatch()
  const classes = useStyles()
  const [finishes, setFinish] = useState([])
  const [colors, setColors] = useState([])
  const user = useSelector((state) => state.user.user)

  const [chosenExaminationsType, setChosenExaminationsType] = useState()

  useEffect(() => {
    axios.post('/lookups/extra_types', null).then((res) => {
      setFinish(res.data)
    })
    axios.post(`/materials/${materialId}/colors/filter`, null).then((res) => {
      setColors(res.data)
    })
  }, [])

  useEffect(() => {
    eventBus.on('newFilterValues', async (data) => {
      await setChosenExaminationsType(data.type_id)
    })

    return eventBus.remove('newFilterValues')
  }, [])

  useEffect(() => {
    eventBus.dispatch('forceFilterValue', {
      key: 'examination_id',
      value: null,
      filter: 'ExaminationsKey',
    })
  }, [chosenExaminationsType])

  return (
    <div>
      <StoreKeyProvider storeKey="MaterialFinishingPricingPreviewKey">
        <WithFilters
          config={{
            page: 1,
            paginate: 25,
            orderType: 'desc',
            order: 'name',
            inputs: {
              color_id: {
                value: '',
                relation: 'WHERE',
              },
              type_id: {
                value: '',
                relation: 'WHERE',
              },
              name: {
                value: '',
                relation: 'LIKE',
              },
              min_price_net: {
                value: '',
                relation: 'MIN',
              },
              max_price_net: {
                value: '',
                relation: 'MAX',
              },
              min_length: {
                value: '',
                relation: 'MIN',
              },
              max_length: {
                value: '',
                relation: 'MAX',
              },
              // active: {
              //   value: 1,
              //   relation: 'WHERE',
              // },
            },
          }}
          endpoint={`/price_lists/${priceListId}/materials/${materialId}/extra_prices/filter`}
        >
          <Card elevation={0} className={classes.bg}>
            <CardContent className={classes.contentSt}>
              <CardHeader
                className={classes.contentPreview}
                title="Wykończenia cennik"
                action={
                  <>
                    <div className="flex flex-wrap side_menu_nd_w:flex-col side_menu_nd_w:gap-4">
                      {user.permissions &&
                        user.permissions.includes(
                          'setMaterialPrices Material'
                        ) && (
                          <Button
                            className="mr-4"
                            startIcon={<AttachMoneyIcon />}
                            variant="contained"
                            color="primary"
                            onClick={async () => {
                              let filters = await dispatch(
                                getFilter({ key: 'PricingPreviewKey' })
                              )
                              dispatch(
                                openPopup({
                                  component: 'MaterialPricingFactory',
                                  title: 'Edytuj ceny wykończeń',
                                  level: 1,
                                  key: 'factory-factory-popup',
                                  maxWidth: 'sm',
                                  values: {
                                    id: materialId,
                                    filters: filters,
                                    priceListId,
                                    filterKey:
                                      'MaterialFinishingPricingPreviewKey',
                                    finishing: true,
                                  },
                                })
                              )
                            }}
                          >
                            <span className="mt-1">Edytuj ceny wykończeń</span>
                          </Button>
                        )}
                    </div>
                  </>
                }
              />
              {finishes && (
                <DataInputs
                  inputs={[
                    {
                      id: 'color_id',
                      label: 'Kolor',
                      placeholder: '',
                      type: 'select',
                      config: {
                        key: 'color_id',
                        label: 'name',
                      },
                      options: colors,
                    },
                    {
                      id: 'type_id',
                      label: 'Typ wykończenia',
                      placeholder: '',
                      type: 'select',
                      config: {
                        key: 'id',
                        label: 'name',
                      },
                      options: finishes,
                    },
                    {
                      id: 'name',
                      label: 'Nazwa',
                      placeholder: '',
                      type: 'text',
                    },
                    {
                      id: 'price_net',
                      label: 'Cena',
                      placeholder: '',
                      type: 'number-between',
                    },
                    {
                      id: 'length',
                      label: 'Długość',
                      placeholder: '',
                      type: 'number-between',
                    },
                    // {
                    //   id: 'active',
                    //   label: 'Aktywne',
                    //   placeholder: '',
                    //   type: 'select',
                    //   options: [
                    //     {
                    //       id: 1,
                    //       name: 'Tak',
                    //     },
                    //     {
                    //       id: 0,
                    //       name: 'Nie',
                    //     },
                    //   ],
                    // },
                  ]}
                />
              )}
            </CardContent>
            <CardContent className={classes.content}>
              <DataTable
                breakpoint={1200}
                labels={[
                  'Kolor',
                  'Typ wykończenia',
                  'Nazwa',
                  'Cena',
                  'Długość',
                  'Główna cena',
                ]}
                keys={[
                  'color_name',
                  'type_name',
                  'name',
                  'price_net',
                  'length',
                  'main_price_net',
                ]}
              />
            </CardContent>
          </Card>
        </WithFilters>
      </StoreKeyProvider>
    </div>
  )
}

MaterialFinishingPricingPreview.propTypes = {
  materialId: PropTypes.string,
  priceListId: PropTypes.oneOfType(PropTypes.string, PropTypes.number),
}

export default MaterialFinishingPricingPreview
