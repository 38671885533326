import { Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material'
import PropTypes from 'prop-types'
import { useContext, useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { ValuationCalculatorContext } from '../../../../../../contexts/ValuationCalculatorContext'
// import { useState } from 'react'
import ValuationCalculatorLengthListItem from './ValuationCalculatorLengthListItem'

const ValuationCalculatorLengthList = ({ disabled = false }) => {
  const [headers, setHeaders] = useState([])
  const { t } = useTranslation()
  const { component, blocks, setBlocks, getExtrasItems, materialMaxLength } =
    useContext(ValuationCalculatorContext)
  const firstRender = useRef(true)
  useEffect(() => {
    firstRender.current = false
  })
  useEffect(() => {
    firstRender.current = true
  }, [component.id])

  useEffect(async () => {
    setHeaders([
      t('valuation_popup.data.table.length'),
      t('valuation_popup.data.table.connector'),
      t('Net price'),
      t('Price per m2/mb'),
      '',
    ])

    await initComponents()
  }, [component.id, component.material_id])

  const updateBlock = async (index, block) => {
    blocks[index] = block
    setBlocks(blocks)
  }

  const initComponents = async () => {
    let components = Object.assign([], blocks)
    for (let i = 0; i < components.length; i++) {
      if (
        !components[i].length &&
        !components[i].connector_id &&
        components.length > 1
      ) {
        components.splice(i, 1)
      }
    }
    if (components.length == 0) {
      addComponent()
    } else {
      setBlocks(components)
    }
  }
  const addComponent = () => {
    let b = blocks || []
    b.push({ price: 0, price_net: 0 })
    setBlocks(b)
  }
  const delComponent = (index) => {
    let b = blocks
    b.splice(index, 1)
    setBlocks(b)
    getExtrasItems()
  }

  return (
    <>
      {blocks && (
        <Table size="small">
          <TableHead>
            <TableRow>
              {headers &&
                headers.map((header, index) => (
                  <TableCell
                    key={index}
                    style={{
                      width: `${100 / headers.length}%`,
                      whiteSpace: 'nowrap',
                    }}
                  >
                    {header}
                  </TableCell>
                ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {blocks.map(
              (item, index) =>
                blocks[index] && (
                  <ValuationCalculatorLengthListItem
                    key={index}
                    delComponent={() => {
                      delComponent(index)
                    }}
                    addComponent={addComponent}
                    blocksCount={blocks.length}
                    changeValue={(block) => {
                      updateBlock(index, block)
                    }}
                    disabled={disabled}
                    item={item}
                    showAdd={
                      blocks.length - 1 == index &&
                      blocks[0].length < materialMaxLength
                    }
                    showDel={blocks.length > 1}
                    showConnector={index != 0}
                  />
                )
            )}
          </TableBody>
        </Table>
      )}
    </>
  )
}

ValuationCalculatorLengthList.propTypes = {
  disabled: PropTypes.bool,
}

export default ValuationCalculatorLengthList
