import { createSlice } from '@reduxjs/toolkit'

export const downloadsSlice = createSlice({
  name: 'downloads',
  initialState: {
    downloads: {},
  },
  reducers: {
    openDownload: (state, action) => {
      const { title, key } = action.payload
      Object.assign(
        (state.downloads[key] = {
          key,
          title,
          progress: 0,
        })
      )
    },
    closeDownload: (state, action) => {
      const key = action.payload
      delete state.downloads[key]
    },
    changeProgress: (state, action) => {
      const key = action.payload.key
      state.downloads[key].progress = action.payload.progress
    },
  },
})

export const { openDownload, closeDownload, changeProgress } =
  downloadsSlice.actions

export default downloadsSlice.reducer
