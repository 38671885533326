import * as yup from 'yup'

// https://github.com/jquense/yup/issues/559#issuecomment-518953000
export function createYupSchema(schema, config) {
  const { id, validationType, validations = [] } = config
  if (!yup[validationType]) {
    return schema
  }
  let validator = yup[validationType]()
  validations.forEach((validation) => {
    const { params, type } = validation
    if (!validator[type]) {
      return
    }
    validator = validator[type](...params)
  })
  schema[id] = validator
  return schema
}

export function createInitialValues(inputs) {
  let values = {}
  Object.keys(inputs).forEach((key) => {
    values[key] = inputs[key].value
  })
  return values
}
