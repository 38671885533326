import PropTypes from 'prop-types'
import React, { useEffect } from 'react'
import { useFormik } from 'formik'
import { refreshFilter } from '../../../../lib/handleFilters'
import eventBus from '../../../../lib/eventBus'
import regex from '../../../../regex'
import {
  Grid,
  DialogActions,
  DialogContent,
  // CircularProgress,
} from '@mui/material'
import FormikControl from '../../../molecules/FormikControl'
// import FormikSelect from '../../../molecules/FormikSelect'
import SaveIcon from '@mui/icons-material/Save'
import * as Yup from 'yup'
import axios from 'axios'
import { handleOpenAlert } from '../../../../lib/handleAlert'
import { closePopup } from '../../../../store/popupsSlice'
import { useDispatch } from 'react-redux'
import { useState } from 'react'
// import moment from 'moment'
// import FormikDatePicker from '../../../molecules/FormikDatePicker'
// import makeStyles from. '@mui/styles/makeStyles'
import LoadingButton from '@mui/lab/LoadingButton'
import FormikSelect from '../../../molecules/FormikSelect'
import FormAutocomplete from '../../../molecules/inputs/FormAutocomplete'

const PartnerAddressFactory = ({ ...values }) => {
  const partnerId = values.partner_id
  const addressId = values.id

  const [states, setStates] = useState([])
  const [daysOfWeek, setDaysOfWeek] = useState([])

  const dispatch = useDispatch()

  const formik = useFormik({
    initialValues: {
      phone_number: '',
      email: '',
      name: '',
      state_id: '',
      week_day_id: 1,
      postcode: '',
      city: '',
      id: '',
      street: '',
    },
    enableReinitialize: false,
    validationSchema: Yup.object().shape(
      {
        name: Yup.string().required('Pole wymagane'),
        state_id: Yup.number().required('Pole wymagane'),
        week_day_id: Yup.number().required('Pole wymagane'),
        postcode: Yup.string()
          .required('Pole wymagane')
          .matches(regex.postcode, 'Błędny format'),
        city: Yup.string().required('Pole wymagane'),
        street: Yup.string().required('Pole wymagane'),
        email: Yup.string()
          .when('phone_number', {
            is: (phone_number) => !phone_number,
            then: Yup.string().required('Pole wymagane'),
          })
          .matches(regex.email, 'Błędny format emaila'),
        phone_number: Yup.string()
          .when('email', {
            is: (email) => !email,
            then: Yup.string().required('Pole wymagane'),
          })
          .matches(regex.phone, 'Numer telefonu jest niepoprawny'),
      },
      [['email', 'phone_number']]
    ),
    onSubmit: async (values) => {
      setLoading(true)
      save(values)
    },
  })

  const loadDaysOfWeek = () => {
    return new Promise(function (resolve) {
      axios.post('/lookups/week_days').then((response) => {
        resolve(response.data)
      })
    })
  }

  const loadStates = () => {
    return new Promise(function (resolve) {
      axios.post(`/lookups/states`).then((res) => {
        if (res.status == 200) {
          resolve(res.data)
        }
      })
    })
  }

  useEffect(async () => {
    setStates(await loadStates())
    setDaysOfWeek(await loadDaysOfWeek())
  }, [])

  useEffect(() => {
    if (addressId) {
      axios
        .get(`/partners/${partnerId}/addresses/${addressId}`, null)
        .then((res) => {
          formik.setValues({
            ...res.data,
            email: res.data.email || '',
            phone_number: res.data.phone_number || '',
          })
        })
    }
  }, [addressId])

  const save = (values) => {
    axios[addressId ? 'put' : 'post'](
      `/partners/${partnerId}/addresses${addressId ? `/${addressId}` : ''}`,
      {
        ...values,
      }
    )
      .then((res) => {
        if (res.status == 200 || res.status == 201) {
          dispatch(closePopup('partner-adddress-popup'))
          eventBus.dispatch('loadPartner', { partnerId })

          refreshFilter('PartnerAddressesPreview')
        }
        handleOpenAlert({
          title: 'Sukces!',
          subtitle: `${addressId ? 'Zaktualizowano' : 'Dodano'} adres`,
        })
      })
      .catch((err) => {
        handleOpenAlert(err.request.status)
      })
      .finally(() => {
        setLoading(false)
      })
  }

  const [loading, setLoading] = useState(false)

  // function handleClick() {
  //   setLoading(true)
  // }

  // const classes = styles()

  return (
    <>
      <form onSubmit={formik.handleSubmit} onReset={formik.handleReset}>
        <>
          <DialogContent dividers={true}>
            <Grid container spacing={3}>
              <Grid item md={6} xs={12}>
                <FormikControl
                  variableName="name"
                  handleChange={formik.handleChange}
                  labelName="Nazwa"
                  formik={formik}
                />
              </Grid>
              <Grid item md={6} xs={12}>
                <FormikSelect
                  variableName="state_id"
                  value={formik.values['state_id']}
                  labelName="Województwo"
                  formik={formik}
                  options={states}
                />
              </Grid>
              <Grid item md={6} xs={12}>
                <FormikControl
                  variableName="postcode"
                  handleChange={formik.handleChange}
                  labelName="Kod pocztowy"
                  formik={formik}
                />
              </Grid>
              <Grid item md={6} xs={12}>
                <FormikControl
                  variableName="city"
                  handleChange={formik.handleChange}
                  labelName="Miasto"
                  formik={formik}
                />
              </Grid>
              <Grid item md={6} xs={12}>
                <FormikControl
                  variableName="street"
                  handleChange={formik.handleChange}
                  labelName="Ulica"
                  formik={formik}
                />
              </Grid>
              <Grid item md={6} xs={12}>
                <FormikControl
                  type="tel"
                  variableName="phone_number"
                  handleChange={formik.handleChange}
                  labelName="Nr. Tel."
                  formik={formik}
                />
              </Grid>
              <Grid item md={6} xs={12}>
                <FormikControl
                  variableName="email"
                  handleChange={formik.handleChange}
                  labelName="E-mail"
                  formik={formik}
                />
              </Grid>
              <Grid item md={6} xs={12}>
                <FormAutocomplete
                  handleChange={(l, v) => {
                    formik.setFieldValue('week_day_id', v)
                  }}
                  value={formik.values.week_day_id}
                  labelName="Dzień dostawy"
                  options={daysOfWeek}
                />
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <LoadingButton
              startIcon={<SaveIcon />}
              variant="contained"
              color="primary"
              loading={loading}
              type="submit"
              // onClick={setLoading}tart"
            >
              <span className="mt-1">Zapisz</span>
            </LoadingButton>
          </DialogActions>
        </>
      </form>
    </>
  )
}

PartnerAddressFactory.propTypes = {
  values: PropTypes.object,
}

export default PartnerAddressFactory
