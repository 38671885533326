import { Add, SquareFoot } from '@mui/icons-material'
import PropTypes from 'prop-types'
import { /*Button,*/ Button, Grid } from '@mui/material'
import { useContext, useEffect, useState } from 'react'
import CancelIcon from '@mui/icons-material/Cancel'
import ViewDayIcon from '@mui/icons-material/ViewDay'
import ValuationCalculatorWindowsill from './windowsill/ValuationCalculatorWindowsill'
// import ValuationCalculatorCustom from './ValuationCalculatorCustom'
import { LoadingButton } from '@mui/lab'
import { handleOpenAlert } from '../../../../lib/handleAlert'
import makeStyles from '@mui/styles/makeStyles'
import { useTranslation } from 'react-i18next'
import { ValuationCalculatorContext } from '../../../../contexts/ValuationCalculatorContext'
import LocationEnum from '../../../../lib/LocationEnum'
import ValuationCalculatorCustom from './custom/ValuationCalculatorCustom'
// import useWindowDimensions from '../../../../hooks/useWindowDimensions'
// import ValuationCalculatorLengthListCustomBox from '../../../atoms/ValuationCalculatorLengthListCustomBox'
const useStyles = makeStyles(() => ({
  hidden: {
    display: 'none',
  },
  buttonsGrid: {
    '&:empty': {
      border: 'none',
    },
    marginTop: '10px',
    width: 'auto',
    margin: '0 0 0 auto',
    background: 'white',
    border: '1px solid #bc2b2a',
    borderRadius: '4px',
    alignItems: 'center',
    padding: '0.5rem',
    gap: '1rem',
  },
}))
const ValuationCalculator = ({
  className = '',
  addComponent,
  endEditComponent,
  // breakpointWidth = 757,
}) => {
  const classes = useStyles()
  const { t } = useTranslation()

  const {
    component,
    loading,
    readOnly,
    edit,
    setValidate,
    recalculate,
    withoutBlocks,
    setWithoutBlocks,
    universal,
    blocks,
    extras,
  } = useContext(ValuationCalculatorContext)

  useEffect(() => {
    setValidate(false)
  }, [component.id])

  const endAndValidate = async (func) => {
    // setLoading(true)
    await setValidate(true)
    let elem = await document.querySelector(
      `#valuation-calculator-${universal ? 'windowsill' : 'custom'} .Mui-error`
    )
    if (!elem) {
      if (universal) {
        if (withoutBlocks) {
          const keys = Object.keys(extras)
          if (keys.length < 1) {
            handleOpenAlert({
              title: 'Brak wybranych wykończeń!',
              type: 'warning',
            })
            return
          }
        } else {
          for (let i = 0; i < blocks.length; i++) {
            if (!blocks[i].price) {
              recalculate()
              handleOpenAlert({
                title: 'Obliczanie części parapetów!',
                type: 'warning',
              })
              return
            }
          }
        }
      }
      func({
        ...component,
        extras: extras,
        without_blocks: withoutBlocks,
        blocks: withoutBlocks ? [] : blocks,
        description: withoutBlocks ? '' : component.description,
      })
    } else {
      // setLoading(false)
      setTimeout(
        () => elem.scrollIntoView({ behavior: 'smooth', block: 'end' }),
        50
      )
    }
  }

  return (
    <>
      <Grid item xs={12} className={className} style={{ positon: 'relative' }}>
        <>
          <ValuationCalculatorWindowsill
            className={!universal ? classes.hidden : undefined}
          />
          <ValuationCalculatorCustom
            className={universal ? classes.hidden : undefined}
          />
          <Grid
            container
            spacing={2}
            style={{
              position: 'sticky',
              bottom: '0',
              right: '0',
            }}
          >
            <Grid
              container
              spacing={2}
              alignItems="end"
              justifyContent="end"
              className={classes.buttonsGrid}
            >
              {universal && (
                <Button
                  onClick={() => setWithoutBlocks(!withoutBlocks)}
                  startIcon={<ViewDayIcon />}
                  disabled={
                    readOnly ||
                    !!loading ||
                    LocationEnum.groups[1].includes(component.location_id)
                  }
                  variant="contained"
                  color={withoutBlocks ? 'primary' : 'grey'}
                >
                  <span className="mt-1">{t('Only Finishing')}</span>
                </Button>
              )}
              {!readOnly &&
                (edit ? (
                  <Grid>
                    <LoadingButton
                      onClick={() => endAndValidate(endEditComponent)}
                      startIcon={<CancelIcon />}
                      loading={!!loading}
                      variant="contained"
                      color="primary"
                    >
                      <span className="mt-1">
                        {t('valuation_popup.data.button.finish_editing')}
                      </span>
                    </LoadingButton>
                  </Grid>
                ) : (
                  <Grid>
                    <LoadingButton
                      startIcon={<Add />}
                      loading={!!loading}
                      variant="contained"
                      color="primary"
                      type="button"
                      onClick={() => endAndValidate(addComponent)}
                      loadingPosition="start"
                    >
                      <span className="mt-1">
                        {universal
                          ? !withoutBlocks
                            ? t('valuation_popup.data.button.add_windowsill')
                            : t('Add finishes')
                          : t('Add product')}
                      </span>
                    </LoadingButton>
                  </Grid>
                ))}
            </Grid>
          </Grid>
        </>
      </Grid>
    </>
  )
}

ValuationCalculator.propTypes = {
  className: PropTypes.string,
  addComponent: PropTypes.func,
  universal: PropTypes.bool,
  endEditComponent: PropTypes.func,
  breakpointWidth: PropTypes.number,
}

export default ValuationCalculator
