import PageContainer from 'components/organisms/layout/PageContainer'
import DataTable from 'components/organisms/dataTable/DataTable'
import StoreKeyProvider from 'components/providers/StoreKeyProvider'
import WithFilters from 'components/hocs/WithFilters'
import { Button, Card, CardContent, CardHeader } from '@mui/material'
import { useEffect, useState } from 'react'
import DataInputs from 'components/organisms/dataTable/DataInputs'
// import axios from 'axios'
import PropTypes from 'prop-types'
import eventBus from 'lib/eventBus'
import makeStyles from '@mui/styles/makeStyles'
import { Add } from '@mui/icons-material'
import { openPopup } from 'store/popupsSlice'
import { useDispatch } from 'react-redux'

const useStyles = makeStyles((theme) => ({
  fontAwesome: {
    position: 'absolute',
    fontSize: '16rem',
    right: '20%',
    margin: 'auto 0',
    top: '50%',
    transform: 'translateY(-50%);',
    height: '80%',
    color: theme.palette.primary.light,
    overflow: 'hidden',
    opacity: '0.5',
  },
  customCard: {
    position: 'relative',
  },
  custom: {
    width: '100%',
    maxWidth: '50% !important',
  },
  bg: {
    background: 'transparent',
    overflow: 'unset',
  },
  content: {
    padding: '24px',
    background: '#fff',
    marginBottom: '24px',
    borderRadius: '2px',
    boxShadow:
      '0px 3px 1px -2px rgba(0,0,0,0.2),0px 2px 2px 0px rgba(0,0,0,0.14),0px 1px 5px 0px rgba(0,0,0,0.12)',
  },
  contentSt: {
    background: '#fff',
    marginBottom: '24px',
    borderRadius: '2px',
    boxShadow:
      '0px 3px 1px -2px rgba(0,0,0,0.2),0px 2px 2px 0px rgba(0,0,0,0.14),0px 1px 5px 0px rgba(0,0,0,0.12)',
  },
  contentPreview: {
    background: '#fff',
    padding: 'unset',
    color: 'rgba(0, 0, 0, 0.6)',
  },
}))

const PartnerEmployeesPreview = ({ partnerId, disabledAdd }) => {
  const dispatch = useDispatch()

  const classes = useStyles()

  const [events, setEvents] = useState()

  useEffect(() => {
    eventBus.on('newFilterValues', async (data) => {
      await setEvents(data.type_id)
    })

    return eventBus.remove('newFilterValues')
  }, [])

  useEffect(() => {
    eventBus.dispatch('forceFilterValue', {
      key: 'partner_id',
      value: null,
      filter: 'PartnersKey',
    })
  }, [events])

  return (
    <>
      <PageContainer>
        <StoreKeyProvider storeKey="PartnerEmployeesPreview">
          <WithFilters
            config={{
              page: 1,
              paginate: 25,
              orderType: 'desc',
              order: 'firstname',
              inputs: {
                firstname: {
                  value: '',
                  relation: 'LIKE',
                },
                lastname: {
                  value: '',
                  relation: 'LIKE',
                },
                phone_number: {
                  value: null,
                  relation: 'LIKE',
                },
                email: {
                  value: '',
                  relation: 'LIKE',
                },
                active: {
                  value: 1,
                  relation: 'WHERE',
                },
                role_id: {
                  value: '',
                  relation: 'WHERE',
                },
              },
            }}
            endpoint={`/partners/${partnerId}/users`}
          >
            <Card elevation={0} className={classes.bg}>
              <CardContent className={classes.contentSt}>
                <CardHeader
                  className={classes.contentPreview}
                  title="Lista pracowników"
                  action={
                    <Button
                      startIcon={<Add />}
                      variant="contained"
                      color="primary"
                      disabled={disabledAdd}
                      onClick={() =>
                        dispatch(
                          openPopup({
                            component: 'EmployeeFactory',
                            title: 'Dodaj pracownika',
                            level: 1,
                            maxWidth: 'sm',
                            key: 'employee-factory-popup',
                            values: {
                              partner_id: partnerId,
                            },
                          })
                        )
                      }
                    >
                      <span className="mt-1">Dodaj pracownika</span>
                    </Button>
                  }
                />
                <DataInputs
                  inputs={[
                    {
                      id: 'firstname',
                      label: 'Imię',
                      placeholder: '',
                      type: 'text',
                    },
                    {
                      id: 'lastname',
                      label: 'Nazwisko',
                      placeholder: '',
                      type: 'text',
                    },
                    {
                      id: 'phone_number',
                      label: 'Numer Telefonu',
                      placeholder: '',
                      type: 'text',
                    },
                    {
                      id: 'email',
                      label: 'Email',
                      placeholder: '',
                      size: 1,
                      type: 'text',
                    },
                    {
                      id: 'active',
                      label: 'Aktywne',
                      placeholder: '',
                      type: 'select',
                      options: [
                        {
                          id: 1,
                          name: 'Tak',
                        },
                        {
                          id: 0,
                          name: 'Nie',
                        },
                      ],
                    },
                  ]}
                />
              </CardContent>
              <CardContent className={classes.content}>
                <DataTable
                  breakpoint={1400}
                  actions="PartnerEmployeePreviewActions"
                  labels={['Imię', 'Nazwisko', 'Numer Telefonu', 'Email']}
                  keys={['firstname', 'lastname', 'phone_number', 'email']}
                />
              </CardContent>
            </Card>
          </WithFilters>
        </StoreKeyProvider>
      </PageContainer>
    </>
  )
}

PartnerEmployeesPreview.propTypes = {
  partnerId: PropTypes.number,
  disabledAdd: PropTypes.bool,
}

export default PartnerEmployeesPreview
