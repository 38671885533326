import { InputAdornment } from '@mui/material'
import { useContext } from 'react'
import { useTranslation } from 'react-i18next'
import { ValuationCalculatorContext } from '../../../../../../contexts/ValuationCalculatorContext'
import FormInput from '../../../../inputs/FormInput'

const ValuationCalculatorQuantityInput = () => {
  const { component, setComponent, validate, readOnly } = useContext(
    ValuationCalculatorContext
  )
  const { t } = useTranslation()

  const changeValue = (value) => {
    component.priceForProduct = value
    setComponent(component)
  }

  return (
    <>
      <FormInput
        name="priceForProduct"
        labelName={t('Net price')}
        disabled={readOnly}
        handleChange={(v) => {
          changeValue(v)
        }}
        helperText={
          validate &&
          (!component.priceForProduct || component.priceForProduct <= 0) &&
          t('main.validation.incorrect_price')
        }
        min={0}
        value={component.priceForProduct}
        type="number"
        InputProps={{
          endAdornment: <InputAdornment position="start">PLN</InputAdornment>,
        }}
      />
    </>
  )
}
ValuationCalculatorQuantityInput.propTypes = {}
export default ValuationCalculatorQuantityInput
