import React, { useMemo, memo } from 'react'
import { IconButton, TableCell, TableRow } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import PropTypes from 'prop-types'
import { Delete, Edit, RemoveRedEye } from '@mui/icons-material'
// import getDeepValue from '../../../../lib/deepUtils'
import { useSelector } from 'react-redux'
import NumberInput from '../../../NumberInput'
import { useTranslation } from 'react-i18next'
import ValuationSummaryRowNameCell from './ValuationSummaryRowNameCell'
import ValuationSummaryRowQuantityCell from './ValuationSummaryRowQuantityCell'
// import ValuationCalculatorControl from './inputs/ValuationCalculatorControl'
const useStyles = makeStyles((theme) => ({
  editedRow: {
    border: `3px solid ${theme.palette.primary.main}`,
  },
}))
//eslint-disable-next-line
const ValuationSummaryRow = ({
  component,
  lp,
  edit,
  setDiscountSummary,
  setDiscount,
  setQuantity,
  delComponent,
  editComponent,
  validate,
  minDiscount,
  readOnly,
}) => {
  const classes = useStyles()
  const user = useSelector((state) => state.user.user)
  const { t } = useTranslation()

  const isDisabledDiscount = useMemo(() => {
    return (
      user.discount === 0 ||
      minDiscount > user.discount ||
      component.discount > user.discount ||
      user.role_id == 2
    )
  }, [component])

  return (
    <TableRow className={edit ? classes.editedRow : ''}>
      <TableCell className={classes.cells} sx={{ minWidth: '2%' }}>
        {lp}
      </TableCell>
      <TableCell className={classes.cells} sx={{ minWidth: '40%' }}>
        <ValuationSummaryRowNameCell component={component} />
      </TableCell>
      <TableCell className={classes.cells} sx={{ minWidth: '5%' }}>
        <ValuationSummaryRowQuantityCell
          component={component}
          setQuantity={setQuantity}
          readOnly={readOnly}
          validate={validate}
          edit={edit}
        />
      </TableCell>
      <TableCell className={classes.cells} sx={{ minWidth: '5%' }}>
        {component.universal
          ? parseFloat(component.summary).toFixed(2)
          : component.summary || t('for verification')}
      </TableCell>
      <TableCell className={classes.cells} xs={3}>
        <NumberInput
          name="discount"
          error={
            validate &&
            !readOnly &&
            !isDisabledDiscount &&
            ((user.discount && component.discount > user.discount) ||
              component.discount < minDiscount)
          }
          helperText={t('main.validation.incorrect_value')}
          disabled={readOnly || isDisabledDiscount}
          handleChange={(value) => {
            setDiscount(value)
          }}
          min={minDiscount || 0}
          max={user.discount !== null ? user.discount : 50}
          value={component.discount}
        />
      </TableCell>
      <TableCell className={classes.cells} sx={{ minWidth: '5%' }}>
        {!component.universal && !component.summary && user.role_id === 2 ? (
          t('for verification')
        ) : (
          <NumberInput
            name="discount_summary"
            max={component.summary}
            min={(component.summary * ((100 - user.discount) / 100))?.toFixed(
              2
            )}
            error={
              validate &&
              (parseFloat(component.discount_summary) >
                parseFloat(component.summary) ||
                component.discount_summary <= 0)
            }
            disabled={readOnly || isDisabledDiscount}
            helperText={t('main.validation.incorrect_value')}
            handleChange={(value) => {
              setDiscountSummary(value)
            }}
            value={component.discount_summary}
            // value={components[index].quantity}
          />
        )}
      </TableCell>
      <TableCell className={classes.cells} sx={{ minWidth: '3%' }}>
        {!readOnly ? (
          !edit && (
            <>
              <IconButton
                color="primary"
                component="span"
                size="large"
                onClick={() => {
                  editComponent()
                }}
              >
                <Edit />
              </IconButton>

              <IconButton
                color="primary"
                component="span"
                size="large"
                onClick={() => {
                  delComponent()
                }}
              >
                <Delete />
              </IconButton>
            </>
          )
        ) : (
          <IconButton
            color="primary"
            component="span"
            size="large"
            onClick={() => {
              editComponent()
            }}
          >
            <RemoveRedEye />
          </IconButton>
        )}
      </TableCell>
    </TableRow>
  )
}

ValuationSummaryRow.propTypes = {
  edit: PropTypes.bool,
  component: PropTypes.object,
  lp: PropTypes.number,
  setDiscountSummary: PropTypes.func,
  setDiscount: PropTypes.func,
  setQuantity: PropTypes.func,
  editComponent: PropTypes.func,
  delComponent: PropTypes.func,
  validate: PropTypes.bool,
  minDiscount: PropTypes.number,
  readOnly: PropTypes.bool,
}

export default memo(ValuationSummaryRow)
