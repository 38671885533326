import { Grid, IconButton } from '@mui/material'
import { Delete } from '@mui/icons-material'
import { useSelector } from 'react-redux'
import PropTypes from 'prop-types'
import { useState } from 'react'
import Confirmation from '../../popups/Confirmation'
import { handleOpenAlert } from '../../../../lib/handleAlert'
import { refreshFilter } from '../../../../lib/handleFilters'
import RestoreFromTrashIcon from '@mui/icons-material/RestoreFromTrash'
import axios from 'axios'
import SwitchAccessShortcutIcon from '@mui/icons-material/SwitchAccessShortcut'
import eventBus from '../../../../lib/eventBus'

const PriceListsActions = ({ row }) => {
  const user = useSelector((state) => state.user.user)
  const [confirmation, setConfirmation] = useState(false)

  const setMain = () => {
    axios.post(`/price_lists/${row.id}/set_main`).then(() => {
      handleOpenAlert({
        title: 'Sukces!',
        subtitle: `Zmieniono główny cennik`,
        type: 'success',
      })
      refreshFilter('PriceListsKey')
    })
  }

  return (
    <Grid
      container
      alignItems="center"
      justifyContent="center"
      direction="row"
      spacing={1}
      className="table-actions"
    >
      {confirmation && (
        <Confirmation
          level={5}
          maxWidth="xs"
          icon="Warning"
          title={`Czy na pewno chcesz ${
            row.active ? 'usunąć' : 'przywrócić'
          } cennik: ${row.name}?`}
          subtitle={`Tej operacji nie można cofnąć.`}
          reject={() => {
            setConfirmation(false)
          }}
          success={() => {
            axios[row.active ? 'delete' : 'patch'](
              `/price_lists/${row.id}${row.active ? '' : '/active'}`
            )
              .then(() => {
                handleOpenAlert({
                  title: 'Sukces!',
                  subtitle: `${
                    row.active ? 'Usunięto' : 'Przywrócono'
                  } cennik ${row.name}`,
                  type: 'success',
                })
                refreshFilter('PriceListsKey')
              })
              .finally(() => {
                setConfirmation(false)
              })
          }}
        />
      )}

      {!row.main &&
        !!row.active &&
        user &&
        user.permissions &&
        user.permissions.includes('setMain PriceList') && (
          <IconButton
            className="p-0"
            title={`Ustaw jako główny cennik`}
            size="small"
            onClick={() => setMain()}
          >
            <SwitchAccessShortcutIcon fontSize="small" color="success" />
          </IconButton>
        )}
      {user &&
        user.permissions &&
        user.permissions.includes('remove PriceList') && (
          <IconButton
            className="p-0"
            title={`${row.active ? 'Przywróć' : 'Usuń'} cennik`}
            size="small"
            onClick={() => setConfirmation(true)}
          >
            {row.active ? (
              <Delete fontSize="small" color="error" />
            ) : (
              <RestoreFromTrashIcon fontSize="small" color="success" />
            )}
          </IconButton>
        )}
    </Grid>
  )
}

PriceListsActions.propTypes = {
  row: PropTypes.object,
}

export default PriceListsActions
