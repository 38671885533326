import PageHeader from '../../../layout/PageHeader'
import ColorLensIcon from '@mui/icons-material/Construction'
import DataTable from '../../../dataTable/DataTable'
// import { useSelector } from 'react-redux'
import { Card, CardContent, Button } from '@mui/material'
import AddIcon from '@mui/icons-material/Add'
import PageContainer from '../../../layout/PageContainer'
import StoreKeyProvider from '../../../../providers/StoreKeyProvider'
import WithFilters from '../../../../hocs/WithFilters'
import DataInputs from '../../../dataTable/DataInputs'
import { useDispatch, useSelector } from 'react-redux'
import { openPopup } from '../../../../../store/popupsSlice'
import makeStyles from '@mui/styles/makeStyles'

const useStyles = makeStyles(() => ({
  bg: {
    background: 'transparent',
    overflow: 'unset',
  },
  content: {
    padding: '24px',
    background: '#fff',
    marginBottom: '24px',
    borderRadius: '2px',
    boxShadow:
      '0px 3px 1px -2px rgba(0,0,0,0.2),0px 2px 2px 0px rgba(0,0,0,0.14),0px 1px 5px 0px rgba(0,0,0,0.12)',
  },
  contentSt: {
    background: '#fff',
    marginBottom: '24px',
    borderRadius: '2px',
    boxShadow:
      '0px 3px 1px -2px rgba(0,0,0,0.2),0px 2px 2px 0px rgba(0,0,0,0.14),0px 1px 5px 0px rgba(0,0,0,0.12)',
    position: 'relative',
  },
}))

const Colors = () => {
  const user = useSelector((state) => state.user.user)
  const dispatch = useDispatch()
  const classes = useStyles()
  const yesNoOptions = [
    {
      id: 1,
      name: 'Tak',
    },
    {
      id: 0,
      name: 'Nie',
    },
  ]

  return (
    <div>
      <>
        <PageHeader
          icon={<ColorLensIcon fontSize="medium" />}
          title="Lista kolorów"
        />
      </>
      <PageContainer>
        <StoreKeyProvider storeKey="ColorsKey">
          <WithFilters
            config={{
              page: 1,
              paginate: 25,
              orderType: 'desc',
              order: 'name',
              inputs: {
                name: {
                  value: '',
                  relation: 'LIKE',
                },
                group: {
                  value: '',
                  relation: 'WHERE',
                },
                active: {
                  value: 1,
                  relation: 'WHERE',
                },
              },
            }}
            endpoint="/colors/filter"
          >
            <Card elevation={0} className={classes.bg}>
              <CardContent className={classes.contentSt}>
                {user.permissions && user.permissions.includes('store Color') && (
                  <Button
                    className="absolute right-0 mr-4"
                    startIcon={<AddIcon />}
                    variant="contained"
                    color="primary"
                    onClick={() =>
                      dispatch(
                        openPopup({
                          component: 'ColorFactory',
                          title: 'Dodaj kolor',
                          level: 1,
                          key: 'color-factory-popup',
                          maxWidth: 'sm',
                        })
                      )
                    }
                  >
                    <span className="mt-1">Dodaj kolor </span>
                  </Button>
                )}
                <DataInputs
                  inputs={[
                    {
                      id: 'name',
                      label: 'Nazwa',
                      placeholder: '',
                      type: 'text',
                    },
                    {
                      id: 'group',
                      label: 'Grupa',
                      placeholder: '',
                      type: 'select',
                      options: yesNoOptions,
                    },
                    {
                      id: 'active',
                      label: 'Aktywne',
                      placeholder: '',
                      type: 'select',
                      options: yesNoOptions,
                    },
                  ]}
                />
              </CardContent>
              <CardContent className={classes.content}>
                <DataTable
                  breakpoint={1484}
                  actions="ColorsTableActions"
                  labels={['Nazwa']}
                  keys={['name']}
                />
              </CardContent>
            </Card>
          </WithFilters>
        </StoreKeyProvider>
      </PageContainer>
    </div>
  )
}

export default Colors
