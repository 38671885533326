import { Grid, IconButton } from '@mui/material'
import {
  Download,
  Edit,
  PrecisionManufacturing,
  RemoveRedEye,
  Launch,
} from '@mui/icons-material'
import { useDispatch, useSelector } from 'react-redux'
import PropTypes from 'prop-types'
import { openPopup } from '../../../../store/popupsSlice'
import axios from 'axios'
import { handleOpenAlert } from '../../../../lib/handleAlert'
import { Check, Description } from '@mui/icons-material'
import React, { useContext, useState } from 'react'
import Confirmation from '../../popups/Confirmation'
import { refreshFilter } from '../../../../lib/handleFilters'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheckDouble, faTimes } from '@fortawesome/pro-solid-svg-icons'
import StoreKeyContext from '../../../../contexts/StoreKeyContext'
import MoveOrderToProductionConfirmation from '../../../../features/Order/Molecules/MoveOrderToProductionConfirmation'
import downloadFile from '../../../../lib/downloadFile'

const OrderTableAction = ({ row }) => {
  const storeKey = useContext(StoreKeyContext)
  const dispatch = useDispatch()
  const user = useSelector((state) => state.user.user)
  const [confirmation, _setConfirmation] = useState(null)
  const [confirmationLoading, setConfirmationLoading] = useState(false)

  const production_order_id = row.production_order_id
  const order_id = row.id
  const redirectToMes = () => {
    // eslint-disable-next-line
    const mesUrl = process.env.REACT_APP_BASE_MES_URL
    window.open(`${mesUrl}/orders/${production_order_id}`, '_blank')
  }

  const setConfirmation = (bool) => {
    _setConfirmation(bool)
    if (!bool) {
      setConfirmationLoading(false)
    }
  }

  const downloadPdf = (type) => {
    let preffix = type == 1 ? 'zamówienie' : 'zlecenie_partner'
    downloadFile({
      route: `/orders/${row.id}/generate/${type}`,
      fileName: `${preffix}_${row.id}.pdf`,
    })
  }

  const moveOrderToProduction = (method) => {
    setConfirmationLoading(true)
    axios
      .post(
        `orders/${order_id}/${
          method == 'manual' ? 'start_production' : 'production_orders'
        }`
      )
      .then(() => {
        handleOpenAlert({
          title: 'Sukces!',
          subtitle: `Przekazano do realizacji`,
          type: 'success',
        })
        setTimeout(() => {
          refreshFilter(storeKey)
          setConfirmation(null)
        }, 200)
      })
      .finally(() => {
        setConfirmation(null)
      })
  }

  return (
    <Grid
      container
      alignItems="center"
      justifyContent="center"
      direction="row"
      spacing={1}
      className="table-actions"
    >
      {/* {user.role_id == 1 && user.permissions.includes('edit User') && ( */}
      <>
        {confirmation === 'start_production' &&
          user.role_id == 1 &&
          row.status_id == 4 && (
            <MoveOrderToProductionConfirmation
              loading={confirmationLoading}
              showProduction={!row.manual_execution}
              result={async (result) => {
                if (result === 'cancel') {
                  setConfirmation(false)
                } else {
                  moveOrderToProduction(result)
                }
              }}
            />
          )}
        {confirmation === 'complete' &&
          user.role_id == 1 &&
          row.status_id == 5 && (
            <Confirmation
              level={5}
              maxWidth="xs"
              icon="Warning"
              title={`Czy na pewno chcesz oznaczyć jako "zrealizowane"?`}
              subtitle={`Tej operacji nie można cofnąć.`}
              reject={() => {
                setConfirmation(null)
              }}
              success={() => {
                axios
                  .post(`orders/${order_id}/complete`)
                  .then(() => {
                    handleOpenAlert({
                      title: 'Sukces!',
                      subtitle: `Oznaczono jako zrealizowane`,
                      type: 'success',
                    })
                    setTimeout(() => {
                      refreshFilter(storeKey)
                      setConfirmation(null)
                    }, 200)
                  })
                  .finally(() => {
                    setConfirmation(null)
                  })
              }}
            />
          )}
        {confirmation === 'store_in_erp' && user.role_id == 1 && (
          <Confirmation
            level={5}
            maxWidth="xs"
            icon="Warning"
            title={`Czy na pewno chcesz utworzyć dokument w ERP?`}
            subtitle={`Tej operacji nie można cofnąć.`}
            reject={() => {
              setConfirmation(null)
            }}
            success={() => {
              axios
                .post(`orders/${order_id}/erp_documents`)
                .then(() => {
                  handleOpenAlert({
                    title: 'Sukces!',
                    subtitle: `Utworzono dokument`,
                    type: 'success',
                  })
                  setTimeout(() => {
                    refreshFilter(storeKey)
                    setConfirmation(null)
                  }, 200)
                })
                .finally(() => {
                  setConfirmation(null)
                })
            }}
          />
        )}
        {confirmation === 'cancel' && user.role_id == 1 && row.status_id == 4 && (
          <Confirmation
            level={5}
            maxWidth="xs"
            icon="Warning"
            title={`Czy na pewno chcesz anulować zamówienie?`}
            subtitle={`Tej operacji nie można cofnąć.`}
            reject={() => {
              setConfirmation(null)
            }}
            success={() => {
              axios
                .post(`orders/${order_id}/cancel`)
                .then(() => {
                  handleOpenAlert({
                    title: 'Sukces!',
                    subtitle: `Anulowano zamówienie`,
                    type: 'success',
                  })
                  setTimeout(() => {
                    refreshFilter(storeKey)
                    setConfirmation(null)
                  }, 200)
                })
                .finally(() => {
                  setConfirmation(null)
                })
            }}
          />
        )}
        {user.role_id == 1 && !row.production_order_id && row.status_id == 5 && (
          <Grid item>
            <IconButton
              className="p-0"
              title="Oznacz jako zrealizowane"
              size="small"
              onClick={() => setConfirmation('complete')}
            >
              <FontAwesomeIcon
                className="text-custom_red_main"
                icon={faCheckDouble}
              />
            </IconButton>
          </Grid>
        )}
        {Boolean(row.production_order_id) && user.role_id === 1 && (
          <Grid item>
            <IconButton
              className="p-0"
              title="Podgląd produkcji"
              size="small"
              onClick={redirectToMes}
            >
              <Launch fontSize="small" />
            </IconButton>
          </Grid>
        )}
        {user.role_id == 1 && row.status_id == 4 && (
          <Grid item>
            <IconButton
              className="p-0"
              title="Anuluj zamówienie"
              size="small"
              onClick={() => setConfirmation('cancel')}
            >
              <FontAwesomeIcon
                className="text-custom_red_main"
                icon={faTimes}
              />
            </IconButton>
          </Grid>
        )}
        {user.role_id == 1 && row.status_id == 4 && (
          <>
            <Grid item>
              <IconButton
                className="p-0"
                title={'Oznacz jako "W realizacji"'}
                size="small"
                onClick={() => setConfirmation('start_production')}
              >
                <Check fontSize="small" color="primary" />
              </IconButton>
            </Grid>
          </>
        )}
        {user.role_id == 1 &&
          row.status_id == 6 &&
          Boolean(row.production_order_id) &&
          !row.erp_id && (
            <Grid item>
              <IconButton
                className="p-0"
                title="Zapisz dokument w ERP"
                size="small"
                onClick={() => setConfirmation('store_in_erp')}
              >
                <Description fontSize="small" color="primary" />
              </IconButton>
            </Grid>
          )}
        {row.status_id == 4 && user.role_id == 1 && (
          <Grid item className="valuation-button">
            <IconButton
              className="p-0"
              title="Edytuj zamówienie"
              size="small"
              onClick={() =>
                dispatch(
                  openPopup({
                    component: 'ValuationFactory',
                    title: `Edytuj zamówienie dla: ${row.partner_name}`,
                    level: 1,
                    maxWidth: 'xl',
                    values: {
                      ...row,
                      filterKey: storeKey,
                    },
                    key: 'valuation-factory-popup',
                  })
                )
              }
            >
              <Edit fontSize="small" />
            </IconButton>
          </Grid>
        )}
        {((user.role_id == 1 && [4, 5, 6].includes(row.status_id)) ||
          (!user.role_id == 1 && row.status_id == 6)) && (
          <Grid item>
            <IconButton
              className="p-0"
              title="Pobierz zamówienie"
              size="small"
              onClick={() => {
                downloadPdf(1)
              }}
            >
              <Download fontSize="small" />
            </IconButton>
          </Grid>
        )}
        {user.role_id == 1 && [4, 5, 6].includes(row.status_id) && (
          <Grid item>
            <IconButton
              className="p-0"
              title="Pobierz dane do produkcji"
              size="small"
              onClick={() => {
                downloadPdf(2)
              }}
            >
              <PrecisionManufacturing fontSize="small" />
            </IconButton>
          </Grid>
        )}
        <Grid item className="valuation-button">
          <IconButton
            className="p-0"
            title="Podgląd zamówienia"
            size="small"
            onClick={() =>
              dispatch(
                openPopup({
                  component: 'ValuationFactory',
                  title: `Podgląd zamówienia`,
                  level: 1,
                  maxWidth: 'xl',
                  values: {
                    ...row,
                    readOnly: true,
                  },
                  key: 'valuation-markup-factory-popup',
                })
              )
            }
          >
            <RemoveRedEye fontSize="small" color="primary" />
          </IconButton>
        </Grid>
      </>
      {/* )} */}
    </Grid>
  )
}

OrderTableAction.propTypes = {
  row: PropTypes.object,
}

export default OrderTableAction
