// import React from 'react'
import PropTypes from 'prop-types'
import AlertMui from '@mui/material/Alert'
import AlertTitle from '@mui/material/AlertTitle'
import { Paper } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
// import { AlertTitle, Stack } from '@mui/lab'

const useStyles = makeStyles(() => ({
  root: {
    minWidth: '250px',
  },
}))

const Alert = ({ title, subtitle, type }) => {
  const classes = useStyles()
  return (
    // <Stack sx={{ width: '100%' }} spacing={2}>
    <Paper elevation={2} className={classes.root}>
      <AlertMui severity={type}>
        <AlertTitle>{title}</AlertTitle>
        {subtitle}
      </AlertMui>
    </Paper>
  )
}

Alert.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
  type: PropTypes.string,
}

export default Alert
