import PropTypes from 'prop-types'
import React, { useEffect } from 'react'
import { useFormik } from 'formik'
import ValuationSummary from '../../../molecules/valuations/calculator/summary/ValuationSummary'
import createFormData from '../../../../lib/createFormData'
import CalculateIcon from '@mui/icons-material/Calculate'
import {
  Typography,
  Grid,
  DialogActions,
  DialogContent,
  Paper,
  Dialog,
  DialogTitle,
  InputAdornment,
  Button,
  Tabs,
  Tab,
} from '@mui/material'
import { Clear, SquareFoot } from '@mui/icons-material'
import EditIcon from '@mui/icons-material/Edit'
import { useDispatch, useSelector } from 'react-redux'
import * as Yup from 'yup'
import { useState } from 'react'
import makeStyles from '@mui/styles/makeStyles'
import ValuationCalculator from '../../../molecules/valuations/calculator/ValuationCalculator'
import { handleOpenAlert } from '../../../../lib/handleAlert'
import { closePopup, openPopup } from '../../../../store/popupsSlice'
import axios from 'axios'
import { refreshFilter } from '../../../../lib/handleFilters'
import ValuationMainForm from '../../../molecules/valuations/calculator/ValuationMainForm'
import LoadingCard from '../../../atoms/LoadingCard'
import ValuationButtons from '../../../molecules/valuations/calculator/footer/ValuationButtons'
import NumberInput from '../../../molecules/NumberInput'
import { useTranslation } from 'react-i18next'
import ValuationCalculatorContextProvider from '../../../../contexts/ValuationCalculatorContext'
import { cloneDeep } from 'lodash'
import { clear } from '../../../../store/valuationSlice'
import LocationEnum from '../../../../lib/LocationEnum'
import buildValuationComponentName from 'features/Valuation/functions/buildValuationComponentName'
import ValuationOrderDetails from '../../../molecules/valuations/calculator/orderDetails/ValuationOrderDetails'
import { toFixedNumber } from '../../../../lib/numberFormatter'
import buildFile from '../../../../lib/buildFile'

const useStyles = makeStyles((theme) => ({
  footerSt: {
    display: 'flex',
    alignItems: 'center',
    gap: theme.spacing(2),
  },
  [theme.breakpoints.down('custom_valuations_st')]: {
    footerSt: {
      flexWrap: 'wrap',
    },
  },
  footer: {
    display: 'flex',
    justifyContent: 'flex-start',
    margin: '0px auto 0px 0px',
    alignItems: 'center',
    gap: theme.spacing(2),
  },
  addParapet: {
    display: 'flex',
    alignItems: 'center',
    gap: theme.spacing(2),
  },
  container: {
    padding: '24px 16px',
    margin: '20px 0px',
    [theme.breakpoints.down('lg')]: {
      margin: '10px 0px',
    },
  },
  containerNd: {
    padding: '24px 16px',
    margin: '20px 0px',
    overflow: 'auto',
    [theme.breakpoints.down('lg')]: {
      margin: '10px 0px',
      overflow: 'unset',
      height: 'unset',
    },
  },
  root: {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr',
    gridTemplateRows: 'auto',
    gap: '24px',
    minHeight: '100%',
    height: 'fit-content',
    [theme.breakpoints.down('lg')]: {
      gridTemplateColumns: 'auto',
      gridTemplateRows: '1fr 1fr',
      gap: '0px',
    },
    [theme.breakpoints.down('custom_valuations_nd')]: {
      display: 'unset',
    },
  },
  hide: {
    maxHeight: '0',
    overflowY: 'hidden',
    transition: 'all  0.7s ease-in',
    opacity: 0,
  },
  flexEnd: {
    display: 'flex',
    gap: '1rem',
    justifyContent: 'flex-end',
  },
  dialogWrapper: {
    padding: 0,
    maxWidth: 'unset',
  },
  hidden: {
    display: 'none',
  },
}))

//eslint-disable-next-line
const ValuationFactory = ({ breakpointWidth = 757, ...values }) => {
  const user = useSelector((state) => state.user.user)
  const valuationId = values.id
  const partnerId = user.partner_id ? user.partner_id : values.partner_id
  const userId = user.partner_id ? user.id : null
  const popupKey = values.popupKey
  const scroll = 'paper'
  const dispatch = useDispatch()
  const classes = useStyles()
  const [loading, setLoading] = useState(false)
  const { t } = useTranslation()

  const [discount, setDiscount] = useState(null)
  const [componentInitValues, setComponentInitValues] = useState({
    toShow: false,
    without_blocks: false,
    quantity: 1,
    extras: {},
    location_id: null,
    color_item_id: null,
    material_id: null,
    files: [],
  })
  const [componentIndex, _setComponentIndex] = useState(null)
  const [maxComponentIndex, setMaxComponentIndex] = useState(null)
  const [summary, _setSummary] = useState(0)
  const [ownerId, setOwnerId] = useState(null)
  const [initLoaded, setInitLoaded] = useState(false)
  const [selectedGroupNum, setSelectedGroupNum] = useState(null)
  const [showCalculator, setShowCalculator] = useState(false)
  const [statusId, setStatusId] = useState(null)
  const [universal, setUniversal] = useState(true)
  const [minDiscount, setMinDiscount] = useState(0)
  const [currencyAcronym, setCurrencyAcronym] = useState('PLN')
  const [validate, setValidate] = useState(false)
  const [maxDiscount, setMaxDiscount] = useState(0)
  const [tab, setTab] = useState(+localStorage.getItem('valuationPreviewTab'))
  const [builtComponents, setBuiltComponents] = useState([])
  const readOnly = !!values.readOnly || statusId == 3

  const formik = useFormik({
    initialValues: {
      partner_id: partnerId || '',
      user_id: userId || '',
      name: '',
      address_id: null,
      show_name_on_pdf: false,
      discount: 0,
    },
    enableReinitialize: false,
    validationSchema: Yup.object({
      user_id: Yup.string().required('Pole wymagane'),
      address_id: Yup.string().required('Pole wymagane'),
      partner_id: Yup.number()
        .typeError('Pole wymagane')
        .required('Pole wymagane'),
      discount: Yup.number()
        .required('Pole wymagane')
        .max(99, 'Maksymalna zniżka nie może być większa niż 99%'),
    }),
    onSubmit: async (formValues) => {
      await setValidate(true)
      let elem = await document.querySelector('.Mui-error')
      if (!elem) {
        if (!validateComponentsCount()) {
          handleOpenAlert({
            title: 'Błąd!',
            subtitle: `Wymagany jest co najmniej 1 parapet`,
            type: 'warning',
          })
        } else if (
          !readOnly &&
          formik.values.components[componentIndex].toShow
        ) {
          handleOpenAlert({
            title: 'Błąd!',
            subtitle: `Należy zakończyć edycję parapetu`,
            type: 'warning',
          })
        } else {
          setLoading(true)
          store({ ...formValues, components: builtComponents })
        }
      }
    },
  })

  const isCalculatorDisabled = !(
    componentIndex !== null &&
    formik.values.user_id &&
    formik.values.address_id &&
    formik.values.components[componentIndex]
  )

  useEffect(() => {
    localStorage.setItem('valuationPreviewTab', tab)
  }, [tab])

  const calculateSummary = () => {
    let sum = 0
    for (let i = 0; i < builtComponents.length; i++) {
      sum += parseFloat(builtComponents[i].discount_summary)
    }
    _setSummary(sum)
  }

  useEffect(() => {
    calculateSummary()
  }, [builtComponents])

  const buildComponent = (componentData, extra) => {
    const component = cloneDeep(componentData)
    component.discount = extra
      ? extra.discount || minDiscount
      : component.discount || minDiscount
    const discountValue = 1 - component.discount / 100

    component.length_concat = ''
    component.quantity = component.quantity || 1

    if (component.universal) {
      //blocks
      let windowsillPriceNet = 0
      for (let i = 0; i < component.blocks.length; i++) {
        component.length_concat += component.blocks[i].length
        windowsillPriceNet += parseFloat(component.blocks[i].price)
        component.length_concat += 'mm'
        if (component.blocks.length !== i + 1) {
          component.length_concat += ', '
        }
      }
      //extras
      let extraSummaryPriceNet = 0
      let extrasKeys = Object.keys(component.extras)
      for (let i = 0; i < extrasKeys.length; i++) {
        let key = extrasKeys[i]
        const extra = component.extras[key]
        if (extra.quantity) {
          extra.discount_price_net = toFixedNumber({
            value: extra.price_net * discountValue,
          })
          extra.summary_price_net = toFixedNumber({
            value: extra.price_net * extra.quantity,
          })
          extra.summary_discount_price_net = toFixedNumber({
            value: extra.price_net * extra.quantity * discountValue,
          })
          if (extra.service) {
            windowsillPriceNet += parseFloat(extra.price_net * extra.quantity)
          } else {
            extraSummaryPriceNet += parseFloat(extra.price_net * extra.quantity)
          }
        }
      }

      component.windowsill_price_net = toFixedNumber({
        value: windowsillPriceNet,
      })
      component.windowsill_discount_price_net = toFixedNumber({
        value: component.windowsill_price_net * discountValue,
      })
      component.windowsill_summary_price_net = toFixedNumber({
        value: windowsillPriceNet * component.quantity,
      })
      component.windowsill_summary_discount_price_net = toFixedNumber({
        value: component.windowsill_summary_price_net * discountValue,
      })

      component.priceForProduct = extraSummaryPriceNet + windowsillPriceNet

      component.extra_summary_price_net = toFixedNumber({
        value: extraSummaryPriceNet,
      })
      component.extra_summary_discount_price_net = toFixedNumber({
        value: component.extra_summary_price_net * discountValue,
      })

      component.summary = toFixedNumber({
        value:
          component.windowsill_summary_price_net +
          component.extra_summary_price_net,
      })
    } else {
      component.summary = component.priceForProduct * component.quantity
      component.windowsill_discount_price_net = toFixedNumber({
        value: component.priceForProduct * discountValue,
      })
      component.windowsill_summary_discount_price_net = toFixedNumber({
        value: component.priceForProduct * discountValue * component.quantity,
      })
    }

    component.discount_summary = toFixedNumber({
      value: component.summary * discountValue,
    })
    component.name = buildValuationComponentName(component)
    return component
  }

  const buildComponents = () => {
    let array = []
    let formikComponents = formik.values.components
    if (formikComponents) {
      for (let i = 0; i < formikComponents.length; i++) {
        if (formikComponents[i] && formikComponents[i].toShow) {
          const component = buildComponent(
            formikComponents[i],
            builtComponents[i]
          )
          array.push(component)
        }
      }
    }
    setBuiltComponents(array)
  }

  useEffect(() => {
    if (!valuationId) {
      setInitLoaded(true)
    }
    return () => {
      dispatch(clear())
    }
  }, [])

  useEffect(() => {
    let components = formik.values.components || []
    let locationId = null
    for (let i = 0; i < components.length; i++) {
      if (components[i].toShow && i != componentIndex) {
        locationId = components[i].location_id
        break
      }
    }
    let groupKey = null
    for (const groupNum in LocationEnum.groups) {
      if (LocationEnum.groups[groupNum].includes(locationId) !== false) {
        groupKey = groupNum
        break
      }
    }
    if (componentIndex !== null) {
      buildComponents()
    }
    setSelectedGroupNum(groupKey)
  }, [componentIndex])

  const buildValuation = (initValues) => {
    return new Promise((resolve) => {
      axios
        .post(
          values.status_id >= 4
            ? `/orders/${valuationId}`
            : `/valuations/${valuationId}`,
          {}
        )
        .then((response) => {
          let universal = true
          let components = []
          let discount = 0
          let formValues = {}
          let data = response.data
          for (let i = 0; i < data.components.length; i++) {
            let c = data.components[i] //component from backend
            let component = {
              toShow: true,
              without_blocks: c.blocks.length == 0,
              thickness_id: c.thickness_id,
              material_id: c.material_id,
              color_item_id: c.color_item_id,
              color_id: c.color_id,
              universal: c.custom == 0,
              discount: parseFloat(c.discount).toFixed(2),
              quantity: c.quantity,
              unit_id: c.unit_id,
              id: c.id,
              summary: c.base_value_net,
              description: c.description,
              // width: c.width / 10,
              width: parseInt(c.width),
              name: c.name,
              location_id: c.location_id,
              files: c.files.map((file) => {
                return {
                  ...file,
                  id: file.file_id,
                  name: file.file_name,
                }
              }),
            }
            component.name = buildValuationComponentName(component)
            let blocks = []
            for (let j = 0; j < c.blocks.length; j++) {
              blocks.push({
                connector_id: c.blocks[j].connector_id,
                length: parseInt(c.blocks[j].length * 1000),
                price: parseFloat(c.blocks[j].price_net).toFixed(2),
              })
            }
            let extras = {}
            for (let j = 0; j < c.extras.length; j++) {
              const extra = c.extras[j]
              extras[extra.type_id] = {
                price_net: parseFloat(extra.price_net / extra.quantity).toFixed(
                  2
                ),
                type_id: extra.type_id,
                name: extra.name,
                quantity: extra.quantity,
                service: extra.service,
                id: extra.extra_id,
              }
            }
            component.blocks = blocks

            if (!component.universal) {
              universal = false
              component.priceForProduct = component.summary / component.quantity
              component.without_blocks = false
            }
            if (component.discount > discount) {
              discount = component.discount
            }
            component.extras = extras
            components.push(component)
          }
          if (!readOnly) {
            components.push(initValues)
          }
          formValues.components = components
          formValues.name = data.name
          formValues.address_id = data.address_id
          formValues.show_name_on_pdf = data.show_name_on_pdf
          formValues.user_id = data.partner_user_id
          formValues.partner_id = data.partner_id
          formValues.discount = data.discount
          formValues.description = data.description
          setStatusId(data.status_id)
          setOwnerId(data.user_id)
          formik.setValues(formValues)
          setMaxComponentIndex(components.length - 1)
          setComponentIndex(components.length - 1)
          setUniversal(universal)
          setMaxDiscount(discount)
          setInitLoaded(true)
          resolve(true)
        })
    })
  }
  const validateComponentsCount = () => {
    let components = formik.values.components
    for (let i = 0; i < components.length; i++) {
      if (components[i].toShow) {
        return true
      }
    }
    return false
  }

  const setComponentQuantity = (id, quantity) => {
    let componentsBuilt = cloneDeep(builtComponents)
    let components = cloneDeep(formik.values.components)
    const index = components.findIndex((v) => v.id == id)
    if (index !== -1) {
      components[index].quantity = quantity
      formik.setFieldValue('components', components)
      componentsBuilt[index] = buildComponent(
        components[index],
        componentsBuilt[index]
      )
      setBuiltComponents(componentsBuilt)
    }
  }

  const setComponentFieldValue = (index, field, value) => {
    let componentsBuilt = cloneDeep(builtComponents)
    let components = cloneDeep(formik.values.components)
    components[index][field] = value
    componentsBuilt[index][field] = value
    formik.setFieldValue('components', components)
    componentsBuilt[index] = buildComponent(
      components[index],
      componentsBuilt[index]
    )
    setBuiltComponents(componentsBuilt)
    calculateSummary()
  }

  const setFormFieldValue = (index, field, value) => {
    let components = cloneDeep(formik.values.components)
    components[index][field] = value
    formik.setFieldValue('components', components)
    builtComponents[index][field] = value
  }

  const setComponentIndex = (index) => {
    _setComponentIndex(index)
  }
  const editComponent = (index) => {
    let elem = document.getElementById('valuation-calculator')
    if (elem) {
      setTimeout(
        () => elem.scrollIntoView({ behavior: 'smooth', block: 'end' }),
        50
      )
    }
    setShowCalculator(true)
    setComponentIndex(index)
  }

  const delComponent = async (index) => {
    builtComponents.splice(index, 1)
    let components = formik.values.components
    components.splice(index, 1)
    formik.setFieldValue('components', components)
    if (index < componentIndex) {
      await setComponentIndex(componentIndex - 1)
    }
    await setMaxComponentIndex(maxComponentIndex - 1)
  }
  const addComponent = (component) => {
    let components = cloneDeep(formik.values.components)
    components[componentIndex] = {
      ...component,
      quantity: component.without_blocks ? 1 : component.quantity,
      universal,
      toShow: true,
    }
    components.push({
      ...componentInitValues,
      id: Math.random(),
      toShow: false,
    })
    let index = maxComponentIndex + 1
    formik.setFieldValue('components', components)
    setComponentIndex(index)
    setMaxComponentIndex(index)
    let elem =
      document.getElementById('valuation-summary-box') ||
      document.getElementById('valuation-order-details-box')
    setTimeout(
      () => elem.scrollIntoView({ behavior: 'smooth', block: 'end' }),
      50
    )
  }

  const endEditComponent = (component) => {
    let components = cloneDeep(formik.values.components)
    components[componentIndex] = {
      ...component,
      quantity: component.without_blocks ? 1 : component.quantity,
      toShow: true,
    }
    let index = maxComponentIndex
    formik.setFieldValue('components', components)
    setComponentIndex(index)
  }

  useEffect(async () => {
    let elem = await document.querySelector('.Mui-error')
    if (elem) {
      setTimeout(async () => {
        elem.scrollIntoView({ behavior: 'smooth', block: 'center' })
      }, 50)
    }
  }, [document.querySelector('.Mui-error')])

  useEffect(async () => {
    let partnerId = formik.values.partner_id
    if (partnerId) {
      axios.post(`/partners/${partnerId}`, null).then((response) => {
        setMinDiscount(response.data.discount)
        setCurrencyAcronym(response.data.currency_acronym)
        let initValues = {
          ...componentInitValues,
          location_id: values.location_id,
          material_id: values.material_id,
        }
        initValues.discount = response.data.discount
        setComponentInitValues({
          ...componentInitValues,
          discount: response.data.discount,
        })
        initValues.id = Math.random()

        if (valuationId) {
          buildValuation(initValues).then(() => {
            setShowCalculator(false)
          })
        } else {
          formik.setFieldValue('components', [initValues])
          setComponentIndex(0)
          setMaxComponentIndex(0)
          setOwnerId(user.id)
        }
        if (formik.values.user_id) {
          // setShowCalculator(true)
        }
      })
    }
  }, [formik.values.partner_id])

  const buildRequest = (formValues) => {
    let values = cloneDeep(formValues)
    for (let i = 0; i < values.components.length; i++) {
      const componentFiles = []
      for (const file of values.components[i].files) {
        componentFiles.push(buildFile(file))
      }

      if (values.components[i].universal) {
        values.components[i] = {
          ...values.components[i],
          files: componentFiles,
          width: values.components[i].width || null,
        }
        for (let j = 0; j < values.components[i].blocks.length; j++) {
          values.components[i].blocks[j].length /= 1000
        }
        let extrasKeys = Object.keys(values.components[i].extras)
        const extras = {}
        for (let j = 0; j < extrasKeys.length; j++) {
          let key = extrasKeys[j]
          if (values.components[i].extras[key].id) {
            extras[key] = {
              extra_id: values.components[i].extras[key].id,
              quantity: values.components[i].extras[key].quantity,
            }
          }
        }
        values.components[i].extras = extras
      } else {
        values.components[i] = {
          name: values.components[i].name,
          price: values.components[i].price,
          location_id: values.components[i].location_id,
          material_id: values.components[i].material_id,
          thickness_id: values.components[i].thickness_id,
          color_id: values.components[i].color_id,
          description: values.components[i].description,
          quantity: values.components[i].quantity,
          discount: values.components[i].discount,
          width: values.components[i].width,
          files: componentFiles,
          universal: values.components[i].universal,
          price_net: values.components[i].priceForProduct,
          without_blocks: false,
          blocks:
            user.role_id === 1
              ? values.components[i].blocks.map((block) => {
                  return {
                    length: block.length / 1000,
                  }
                })
              : [],
        }
      }
    }
    return values
  }
  const onSubmit = async (status_id, markup = false) => {
    formik.setFieldValue('markup', markup)
    formik.setFieldValue('status_id', status_id)
    formik.handleSubmit()
  }

  //eslint-disable-next-line
  const store = async (formValues) => {
    // for(let i = 0;i<values.compo)
    let data = await buildRequest(formValues)
    let formData = createFormData(data)
    axios
      .post(
        valuationId
          ? `/${statusId >= 4 ? 'orders' : 'valuations'}/${valuationId}/${
              formValues.status_id == 4 && statusId < 4 ? 'order' : 'edit'
            }`
          : '/valuations/store',
        formData,
        { 'Content-Type': 'multipart/form-data' }
      )
      .then((res) => {
        if (res.status == 200 || res.status == 201) {
          setLoading(false)
          setValidate(false)
          handleOpenAlert({
            title: 'Sukces!',
            subtitle: valuationId
              ? statusId >= 4
                ? t('valuation_popup.alert.order_updated')
                : `${t('valuation_popup.alert.valuation_updated')} ${
                    formValues.name || t('valuation_popup.defualt_name')
                  }`
              : t('valuation_popup.alert.new_valuation_added'),
          })
          if (formik.values.markup) {
            dispatch(
              openPopup({
                component: 'ValuationMarkupFactory',
                title: `${t(
                  'valuation_popup.alert.set_overlay_for_valuation'
                )} ${formValues.name || t('valuation_popup.defualt_name')}`,
                level: 1,
                maxWidth: 'xl',
                values: {
                  id: valuationId || res.data,
                },
                key: 'valuation-markup-factory-popup',
              })
            )
          } else if (
            user.role_id == 2 &&
            formValues.status_id == 4 &&
            window.location.pathname != '/orders'
          ) {
            window.location.href = '/orders'
          }
          refreshFilter(values.filterKey || 'ValuationsKey')
          dispatch(closePopup('valuation-factory-popup'))
        }
      })
      .catch(() => {
        setLoading(false)
        setValidate(false)
      })
  }

  return (
    <>
      <Dialog
        fullScreen
        open={true}
        classes={{ paper: classes.dialogWrapper }}
        fullWidth={true}
        style={{ zIndex: 1300 }}
        scroll={scroll}
        disableEscapeKeyDown={true}
      >
        <DialogTitle>
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}
          >
            <Typography variant="h6" component="div">
              {values.popupTitle || 'Dodaj wycenę'}
            </Typography>
            <Clear
              className="cursor-pointer"
              onClick={() => dispatch(closePopup(popupKey))}
            />
          </div>
        </DialogTitle>
        {!loading && formik && initLoaded ? (
          <DialogContent dividers={scroll === 'paper'}>
            <Grid item className={classes.root}>
              <Paper elevation={2} className={classes.container}>
                <Grid className={classes.flexEnd}>
                  {((!valuationId && Boolean(universal) && !showCalculator) ||
                    !universal) && (
                    <Button
                      disabled={
                        isCalculatorDisabled ||
                        (formik.values.components?.length > 1 && universal)
                      }
                      onClick={() => {
                        setUniversal(false)
                        setShowCalculator(!showCalculator)
                      }}
                      startIcon={
                        !showCalculator ? <SquareFoot /> : <EditIcon />
                      }
                      variant="contained"
                      color="primary"
                    >
                      <span className="mt-1">
                        {!showCalculator
                          ? t('valuation_popup.data.button.calculator_custom')
                          : t('valuation_popup.data.button.valuation_data')}
                      </span>
                    </Button>
                  )}
                  {((!valuationId && !universal && !showCalculator) ||
                    Boolean(universal)) && (
                    <Button
                      disabled={
                        isCalculatorDisabled ||
                        (!showCalculator &&
                          formik.values.components?.length > 1 &&
                          !universal)
                      }
                      onClick={() => {
                        if (!showCalculator) {
                          setUniversal(true)
                        }
                        setShowCalculator(!showCalculator)
                      }}
                      startIcon={
                        !showCalculator ? <CalculateIcon /> : <EditIcon />
                      }
                      variant="contained"
                      color="primary"
                    >
                      <span className="mt-1">
                        {!showCalculator
                          ? t('valuation_popup.data.button.standard_order')
                          : t('valuation_popup.data.button.valuation_data')}
                      </span>
                    </Button>
                  )}
                </Grid>
                <ValuationCalculatorContextProvider
                  currencyAcronym={currencyAcronym}
                  selectedGroupNum={selectedGroupNum}
                  showCalculator={showCalculator}
                  universal={universal}
                  readOnly={readOnly}
                  partnerId={formik.values.partner_id || partnerId}
                  edit={
                    formik.values.components &&
                    formik.values.components[componentIndex] &&
                    formik.values.components[componentIndex].toShow
                  }
                  initObject={
                    (formik.values.components &&
                      formik.values.components[componentIndex]) ||
                    {}
                  }
                >
                  <ValuationCalculator
                    readOnly={readOnly}
                    id="valuation-calculator"
                    className={!showCalculator ? classes.hidden : undefined}
                    addComponent={addComponent}
                    endEditComponent={endEditComponent}
                    universal={universal}
                    currencyAcronym={currencyAcronym}
                    pathPrefix={`components[${componentIndex}]`}
                    formik={formik}
                  />
                </ValuationCalculatorContextProvider>
                <ValuationMainForm
                  formik={formik}
                  partnerId={partnerId}
                  className={showCalculator ? classes.hidden : undefined}
                  userId={userId}
                  readOnly={readOnly}
                />
              </Paper>
              <Paper elevation={2} className={classes.containerNd}>
                {universal ? (
                  <Tabs
                    className="bg-white"
                    value={tab}
                    onChange={(_, value) => setTab(value)}
                    aria-label="nav tabs"
                  >
                    <Tab label={t('Preview')} value={0} />
                    <Tab label={t('Order details')} value={1} />
                  </Tabs>
                ) : (
                  <Typography variant="h6" component="div">
                    {t('Preview')}:
                  </Typography>
                )}
                {!universal || tab === 0 ? (
                  <ValuationSummary
                    components={!loading ? builtComponents : []}
                    editComponent={editComponent}
                    minDiscount={minDiscount || 0}
                    globalDiscount={discount}
                    readOnly={readOnly}
                    currencyAcronym={currencyAcronym}
                    clearGlobalDiscount={() => {
                      setDiscount('')
                    }}
                    currentIndex={componentIndex}
                    setComponentQuantity={setComponentQuantity}
                    statusId={statusId}
                    validate={validate}
                    delComponent={delComponent}
                    setFieldValue={setComponentFieldValue}
                    setFormValue={setFormFieldValue}
                  />
                ) : (
                  <ValuationOrderDetails
                    components={!loading ? builtComponents : []}
                    currencyAcronym={currencyAcronym}
                  />
                )}
              </Paper>
            </Grid>
          </DialogContent>
        ) : (
          <LoadingCard fullHeight={true} />
        )}
        <DialogActions className={classes.footerSt}>
          {
            <Grid className={classes.footer}>
              {!readOnly && user.role_id === 1 && (
                <Grid item>
                  <NumberInput
                    sx={{ minWidth: '110px' }}
                    name="discount"
                    value={discount}
                    disabled={
                      readOnly ||
                      user.discount === 0 ||
                      minDiscount > user.discount ||
                      maxDiscount > user.discount ||
                      user.role_id == 2
                    }
                    min={minDiscount || 0}
                    max={user.discount !== null ? user.discount : 99}
                    handleChange={(v) => {
                      setDiscount(v)
                    }}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="start">%</InputAdornment>
                      ),
                    }}
                    label="Rabat"
                  />
                </Grid>
              )}
              {!loading && formik && initLoaded && (
                <Grid item>
                  <Typography sx={{ fontWeight: 'bold', width: '300px' }}>
                    {`${t('valuation_popup.data.summary_net')}: ${parseFloat(
                      summary || 0
                    ).toFixed(2)} ${currencyAcronym}`}
                  </Typography>
                </Grid>
              )}
            </Grid>
          }
          {!values.readOnly && !loading && formik && initLoaded && (
            <ValuationButtons
              loading={loading}
              statusId={statusId}
              universal={universal}
              ownerId={ownerId}
              formik={formik}
              onSubmit={onSubmit}
            />
          )}
        </DialogActions>
      </Dialog>
    </>
  )
}

ValuationFactory.propTypes = {
  values: PropTypes.object,
  popupKey: PropTypes.string,
  breakpointWidth: PropTypes.number,
}

export default ValuationFactory
