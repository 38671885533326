import { Checkbox, FormControlLabel, Grid, Typography } from '@mui/material'
import FormikControl from '../../FormikControl'
import FormikSelect from '../../FormikSelect'
import SearchSelect from '../../SearchSelect'
import PropTypes from 'prop-types'
import { useEffect, useState } from 'react'
import axios from 'axios'
import ReactQuill from 'react-quill'
import { useTranslation } from 'react-i18next'

const ValuationMainForm = ({
  formik,
  partnerId,
  className = '',
  userId,
  readOnly,
}) => {
  const [users, setUsers] = useState(null)
  const [addresses, setAddresses] = useState(null)
  const { t } = useTranslation()

  const loadPartnerUsers = (id) => {
    return new Promise(function (resolve) {
      axios.post(`/partners/${id}/users`).then((res) => {
        if (res.status == 200) {
          resolve(res.data)
        }
      })
    })
  }

  useEffect(() => {
    if (formik.values.name?.length === 1) {
      formik.setFieldValue('show_name_on_pdf', true)
    } else if (!formik.values.name?.length) {
      formik.setFieldValue('show_name_on_pdf', false)
    }
  }, [formik.values.name])

  useEffect(async () => {
    const id = formik.values.partner_id
    if (id) {
      axios.post(`/partners/${id}/addresses/filter`).then((res) => {
        if (res.status == 200) {
          setAddresses(res.data)
          if (!formik.values.address_id) {
            for (const address of res.data) {
              if (address.main) {
                formik.setFieldValue('address_id', address.id)
              }
            }
          }
        }
      })
    }
    if (id && !userId) {
      let users = await loadPartnerUsers(id)
      setUsers(users)
      if (users.length == 1) {
        formik.setFieldValue('user_id', users[0].id)
      }
    } else {
      setUsers([])
    }
  }, [formik.values.partner_id])

  const setPartnerId = async (id) => {
    formik.setFieldValue('partner_id', id)
  }

  return (
    <>
      <Grid className={className}>
        <Grid
          container
          rowSpacing={1}
          spacing={3}
          style={{
            display: 'flex',
            justifyContent: 'column',
          }}
        >
          <Grid item xs={12}>
            <Typography variant="h6" component="div">
              {t('valuation_popup.data.title.valuation_data')}
            </Typography>
          </Grid>
          <Grid
            container
            item
            columnSpacing={1}
            rowSpacing={1}
            style={{
              display: 'flex',
              justifyContent: 'column',
            }}
          >
            {!partnerId && (
              <Grid item md={12} container xl={6}>
                <SearchSelect
                  url="/partners/search"
                  error={
                    formik.touched.partner_id && !!formik.errors.partner_id
                  }
                  helperText={
                    formik.touched.partner_id && formik.errors.partner_id
                  }
                  disabled={readOnly}
                  label="Partner"
                  edit={
                    formik.values.partner_id
                      ? {
                          name: formik.values.partner_name,
                          id: formik.values.partner_id,
                        }
                      : null
                  }
                  config={{
                    label: 'name+nip',
                    key: 'id',
                  }}
                  emitValue={async (id, value) => {
                    setPartnerId(id)
                    formik.setFieldValue('partner_name', value)
                  }}
                />
              </Grid>
            )}
            <Grid item container md={12} xl={partnerId ? 12 : 6}>
              <FormikSelect
                variableName="address_id"
                value={formik.values['address_id']}
                autocomplete="off"
                labelName={t('valuation_popup.data.input.address')}
                formik={formik}
                disabled={readOnly || !formik.values.partner_id}
                config={{
                  key: 'id',
                  label: (data) => {
                    return `${data.name} - ${data.city} ${data.street}`
                  },
                }}
                options={addresses}
              />
            </Grid>
            <Grid item container xs={12} md={12} lg={userId ? 12 : 6}>
              <FormikControl
                variableName="name"
                type="string"
                disabled={readOnly}
                handleChange={formik.handleChange}
                labelName={t('valuation_popup.data.input.valuation_name')}
                formik={formik}
              />
            </Grid>
            {!userId && (
              <Grid item container xs={12} md={12} lg={6}>
                <FormikSelect
                  variableName="user_id"
                  value={formik.values['user_id']}
                  labelName="Użytkownik"
                  formik={formik}
                  disabled={readOnly || !formik.values.partner_id}
                  config={{
                    key: 'id',
                    label: 'firstname+lastname',
                  }}
                  options={users}
                />
              </Grid>
            )}

            <Grid container item xs={12} md={12}>
              <FormControlLabel
                className="text-custom_blue_dark"
                control={
                  <Checkbox
                    className="toEvent"
                    color="primary"
                    checked={Boolean(formik.values.show_name_on_pdf)}
                  />
                }
                disabled={readOnly || !formik.values.partner_id}
                onChange={(e) => {
                  formik.setFieldValue('show_name_on_pdf', e.target.checked)
                }}
                label={t(
                  'valuation_popup.data.input.display_document_name_pdf'
                )}
              />
            </Grid>
            <Grid item container xs={12} md={12}>
              <Typography variant="h6" component="div">
                {t('valuation_popup.data.title.comment')}
              </Typography>
              <ReactQuill
                class="textarea"
                value={formik.values.description || null}
                readOnly={readOnly}
                style={{
                  width: '100%',
                }}
                onChange={(v) => {
                  if (v != '<p><br></p>') {
                    formik.setFieldValue('description', v)
                  }
                }}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  )
}
ValuationMainForm.propTypes = {
  formik: PropTypes.object,
  partnerId: PropTypes.number,
  className: PropTypes.string,
  userId: PropTypes.number,
  readOnly: PropTypes.bool,
}

export default ValuationMainForm
