import React from 'react'
import { TableCell, TableRow } from '@mui/material'
import PropTypes from 'prop-types'
import PartnersTableActions from './actions/PartnersTableActions'
import UsersTableActions from './actions/UsersTableActions'
import MaterialsTableActions from './actions/MaterialsTableActions'
import ValuationsTableActions from './actions/ValuationsTableActions'
import OrderTableActions from './actions/OrderTableActions'
import AdminsTableActions from './actions/AdminsTableActions'
import DefaultBox from './boxes/DefaultBox'
import PartnerValuesPreviewActions from './actions/PartnerValuesPreviewActions'
import PatientsListActions from './actions/PatientsListActions'
import PatientsDataActions from './actions/PatientsDataActions'
import ExaminationsTableActions from './actions/ExaminationsTableActions'
import ContractsTableActions from './actions/ContractsTableActions'
import EventsTableActions from './actions/EventsTableActions'
import PartnerEmployeePreviewActions from './actions/PartnerEmployeePreviewActions'
import PartnerAddressPreviewActions from './actions/PartnerAddressPreviewActions'
import MaterialColorsPreviewActions from './actions/MaterialColorsPreviewActions'
import MaterialFinishingPreviewActions from './actions/MaterialFinishingPreviewActions'
import makeStyles from '@mui/styles/makeStyles'
import { styled } from '@mui/material/styles'
import RowCell from './RowCell'
import ColorsTableActions from './actions/ColorsTableActions'
import PriceListsActions from './actions/PriceListsActions'

const components = {
  PartnersTableActions: PartnersTableActions,
  MaterialsTableActions: MaterialsTableActions,
  ValuationsTableActions: ValuationsTableActions,
  OrderTableActions: OrderTableActions,
  UsersTableActions: UsersTableActions,
  AdminsTableActions: AdminsTableActions,
  PatientsListActions: PatientsListActions,
  PatientsDataActions: PatientsDataActions,
  ExaminationsTableActions: ExaminationsTableActions,
  PartnerValuesPreviewActions: PartnerValuesPreviewActions,
  ContractsTableActions: ContractsTableActions,
  EventsTableActions: EventsTableActions,
  PartnerAddressPreviewActions: PartnerAddressPreviewActions,
  PartnerEmployeePreviewActions: PartnerEmployeePreviewActions,
  DefaultBox: DefaultBox,
  MaterialColorsPreviewActions: MaterialColorsPreviewActions,
  MaterialFinishingPreviewActions: MaterialFinishingPreviewActions,
  ColorsTableActions: ColorsTableActions,
  PriceListsActions: PriceListsActions,
}

const StyledTableRow = styled(TableRow)(() => ({
  '&:nth-of-type(even)': {
    backgroundColor: 'rgba(0, 0, 0, 0.02)',
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}))

const useStyles = makeStyles((theme) => ({
  cell: {
    padding: `${theme.spacing(2)} ${theme.spacing(1)}`,
    color: 'rgba(0, 0, 0, 0.7)',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
  },
}))

const Row = ({ cells, keys, actions, rowStyles = {}, rowComponents = {} }) => {
  const classes = useStyles()

  return (
    <StyledTableRow>
      {keys.map((key, i) => {
        return rowComponents[key] ? (
          <TableCell style={rowStyles[key] || {}}>
            {React.createElement(rowComponents[key], {
              row: cells,
              keyName: key,
            })}
          </TableCell>
        ) : (
          <RowCell
            key={i}
            className={classes.cell}
            content={cells[key]}
            styles={rowStyles[key] || {}}
          />
        )
      })}
      <TableCell className={classes.cell}>
        {actions && React.createElement(components[actions], { row: cells })}
      </TableCell>
    </StyledTableRow>
  )
}

Row.propTypes = {
  cells: PropTypes.object,
  rowComponents: PropTypes.object,
  keys: PropTypes.array,
  actions: PropTypes.string,
  rowStyles: PropTypes.any,
}

export default Row
