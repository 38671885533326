import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'
import { Route, Redirect } from 'react-router-dom'

const roleBounceRoute = {
  1: '/orders',
  2: '/home',
}

const RoleProtected = ({ allowRoles, children, ...rest }) => {
  const user = useSelector((state) => state.user.user)
  return (
    user &&
    (allowRoles.includes(user.role_id) ? (
      <Route {...rest}>{children}</Route>
    ) : (
      <Redirect to={roleBounceRoute[user.role_id]} />
    ))
  )
}

RoleProtected.propTypes = {
  children: PropTypes.any,
  allowRoles: PropTypes.array,
}

export default RoleProtected
