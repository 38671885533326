// import { Card, CardHeader, CardContent } from '@mui/material'
import PageContainer from '../../organisms/layout/PageContainer'
import PropTypes from 'prop-types'
import StoreKeyProvider from '../../providers/StoreKeyProvider'
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js'
import { Bar } from 'react-chartjs-2'
import { Paper } from '@mui/material'
import { useEffect, useState } from 'react'

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend)

export const options = {
  responsive: true,
  maintainAspectRatio: false,
  scales: {
    x: { display: false },
  },
  plugins: {
    legend: {
      // position: 'top' as const,
    },
    scales: {
      yAxes: [
        {
          display: false,
        },
      ],
    },
    // title: {
    //   display: true,
    //   text: 'Partnerzy',
    // },
  },
  tooltips: {
    callbacks: {
      label: function (tooltipItem) {
        return tooltipItem.yLabel
      },
    },
  },
}

const PartnerPreview = ({ label, color, data }) => {
  const [labels, setLabels] = useState([])
  const [datasets, setDatasets] = useState([])

  useEffect(() => {
    if (data) {
      setLabels(data.labels)
      setDatasets([
        {
          label: label,
          data: data.values,
          backgroundColor: color,
        },
      ])
    }
  }, [data])

  return (
    <>
      <PageContainer>
        <StoreKeyProvider storeKey="PatientsDataKey">
          <Paper className="shadow-none !important" elevation={0}>
            <Bar
              options={options}
              height={350}
              data={{
                labels,
                datasets,
              }}
            />
          </Paper>
        </StoreKeyProvider>
      </PageContainer>
    </>
  )
}

PartnerPreview.propTypes = {
  color: PropTypes.string,
  label: PropTypes.string,
  data: PropTypes.object,
}

export default PartnerPreview
