import React, { memo } from 'react'
import makeStyles from '@mui/styles/makeStyles'
import PropTypes from 'prop-types'
// import getDeepValue from '../../../../lib/deepUtils'
import { useTranslation } from 'react-i18next'
// import ValuationCalculatorControl from './inputs/ValuationCalculatorControl'
const useStyles = makeStyles((theme) => ({
  editedRow: {
    border: `3px solid ${theme.palette.primary.main}`,
  },
}))
//eslint-disable-next-line
const ValuationSummaryRowNameCell = ({ component }) => {
  const classes = useStyles()
  const { t } = useTranslation()

  return (
    <>
      <p className="font-bold">{component.name}</p>
      {component.universal && (
        <>
          {component.without_blocks ? (
            <>
              <div className={classes.cells} sx={{ minWidth: '10%' }}>
                {Object.keys(component.extras).map((key) => (
                  <p key={key}>{`${component.extras[key].name} - ${
                    component.extras[key].quantity
                  } ${t('qty.')}`}</p>
                ))}
              </div>
            </>
          ) : (
            <>
              {`${t('valuation_popup.data.table.content.width')} ${
                component.width
              }mm`}
              <div className={classes.cells} sx={{ minWidth: '10%' }}>
                {t('valuation_popup.data.table.content.length')}{' '}
                {component.length_concat}
              </div>
            </>
          )}
        </>
      )}
    </>
  )
}

ValuationSummaryRowNameCell.propTypes = {
  component: PropTypes.object,
}

export default memo(ValuationSummaryRowNameCell)
