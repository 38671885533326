import { Card, CardHeader, CardContent, Button } from '@mui/material'
import StoreKeyProvider from 'components/providers/StoreKeyProvider'
import WithFilters from 'components/hocs/WithFilters'
import DataTable from 'components/organisms/dataTable/DataTable'
import PropTypes from 'prop-types'
import DataInputs from 'components/organisms/dataTable/DataInputs'
import AddIcon from '@mui/icons-material/Add'
import { useDispatch, useSelector } from 'react-redux'
import { openPopup } from 'store/popupsSlice'
import makeStyles from '@mui/styles/makeStyles'
import ShowMainCell from 'components/organisms/dataTable/cells/ShowMainCell'
import ShowAttachmentCell from 'components/organisms/dataTable/cells/ShowAttachmentCell'

const useStyles = makeStyles((theme) => ({
  bg: {
    background: 'transparent',
    overflow: 'unset',
  },
  content: {
    padding: '24px',
    background: '#fff',
    marginBottom: '24px',
    borderRadius: '2px',
    boxShadow:
      '0px 3px 1px -2px rgba(0,0,0,0.2),0px 2px 2px 0px rgba(0,0,0,0.14),0px 1px 5px 0px rgba(0,0,0,0.12)',
  },
  contentSt: {
    background: '#fff',
    marginBottom: '24px',
    borderRadius: '2px',
    boxShadow:
      '0px 3px 1px -2px rgba(0,0,0,0.2),0px 2px 2px 0px rgba(0,0,0,0.14),0px 1px 5px 0px rgba(0,0,0,0.12)',
  },
  contentPreview: {
    background: '#fff',
    padding: 'unset',
    color: 'rgba(0, 0, 0, 0.6)',
    display: 'flex',
    flexWrap: 'wrap',
    [theme.breakpoints.down('custom_extras')]: {
      width: '100%',
    },
  },
}))

const MaterialColorsPreview = ({ materialId }) => {
  const dispatch = useDispatch()
  const classes = useStyles()
  const user = useSelector((state) => state.user.user)

  return (
    <div>
      <StoreKeyProvider storeKey="MaterialColorsPreviewKey">
        <WithFilters
          config={{
            page: 1,
            paginate: 25,
            orderType: 'desc',
            order: 'name',
            inputs: {
              name: {
                value: '',
                relation: 'LIKE',
              },
              // active: {
              //   value: 1,
              //   relation: 'WHERE',
              // },
            },
          }}
          endpoint={`/materials/${materialId}/colors/filter`}
        >
          <Card elevation={0} className={classes.bg}>
            <CardContent className={classes.contentSt}>
              <CardHeader
                className={classes.contentPreview}
                title="Kolory"
                action={
                  <>
                    <div className="flex flex-wrap side_menu_nd_w:flex-col side_menu_nd_w:gap-4">
                      {user.permissions &&
                        user.permissions.includes('store MaterialColor') && (
                          <Button
                            startIcon={<AddIcon />}
                            className="side_menu_nd_w:w-max"
                            variant="contained"
                            color="primary"
                            onClick={() =>
                              dispatch(
                                openPopup({
                                  component: 'MaterialColorFactory',
                                  title: 'Przypisz kolor',
                                  level: 1,
                                  key: 'material-color-factory-popup',
                                  maxWidth: 'sm',
                                  values: {
                                    material_id: materialId,
                                  },
                                })
                              )
                            }
                          >
                            <span className="mt-1">Przypisz kolor</span>
                          </Button>
                        )}
                    </div>
                  </>
                }
              />
              <DataInputs
                inputs={[
                  {
                    id: 'name',
                    label: 'Nazwa',
                    placeholder: '',
                    type: 'text',
                  },

                  // {
                  //   id: 'active',
                  //   label: 'Aktywne',
                  //   placeholder: '',
                  //   type: 'select',
                  //   options: [
                  //     {
                  //       id: 1,
                  //       name: 'Tak',
                  //     },
                  //     {
                  //       id: 0,
                  //       name: 'Nie',
                  //     },
                  //   ],
                  // },
                ]}
              />
            </CardContent>
            <CardContent className={classes.content}>
              <DataTable
                rowComponents={{
                  file_id: ShowAttachmentCell,
                  default: ShowMainCell,
                }}
                actions="MaterialColorsPreviewActions"
                breakpoint={1200}
                labels={['Domyślny', 'Plik', 'Nazwa']}
                keys={['default', 'file_id', 'name']}
              />
            </CardContent>
          </Card>
        </WithFilters>
      </StoreKeyProvider>
    </div>
  )
}

MaterialColorsPreview.propTypes = {
  materialId: PropTypes.string,
}

export default MaterialColorsPreview
