// import PropTypes from 'prop-types'
import { useFormik } from 'formik'
import { Typography, Grid, DialogActions, DialogContent } from '@mui/material'
import regex from '../../../../regex'
import FormikControl from '../../../molecules/FormikControl'
// import FormikSelect from '../../../molecules/FormikSelect'
import SaveIcon from '@mui/icons-material/Save'
import * as Yup from 'yup'
import axios from 'axios'
import { handleOpenAlert } from '../../../../lib/handleAlert'
import { closePopup } from '../../../../store/popupsSlice'
import { useDispatch } from 'react-redux'
import { refreshFilter } from '../../../../lib/handleFilters'
import LoadingButton from '@mui/lab/LoadingButton'
import { useEffect, useState } from 'react'

const EmployeeFactory = ({ ...values }) => {
  const dispatch = useDispatch()

  const user_id = values.id
  const partner_id = values.partner_id

  const [timeoutValue, setTimeoutValue] = useState(null)
  const [loading, setLoading] = useState(false)
  const [lastCheckedEmail, setlastCheckedEmail] = useState({
    email: values.email,
    exist: false,
  })

  const doesExistEmail = (email) => {
    if (regex.email.test(email)) {
      if (lastCheckedEmail.value != email) {
        axios
          .post('/users/email/check', {
            email,
            user_id,
          })
          .then((res) => {
            setlastCheckedEmail({
              exist: res.data.exist,
              value: email,
            })
          })
      }
    } else {
      setlastCheckedEmail({
        exist: false,
        value: email,
      })
    }
  }

  const formik = useFormik({
    initialValues: {
      email: values.email || '',
      phone_number: values.phone_number || '',
      firstname: values.firstname || '',
      lastname: values.lastname || '',
      id: values.id || '',
    },
    enableReinitialize: false,
    validationSchema: Yup.object({
      email: Yup.string()
        .email('E-mail niepoprawny')
        .when('id', {
          is: (id) => !id,
          then: Yup.string().required('Pole wymagane'),
        })
        .test('email-unique', 'Podany email jest zajęty', function () {
          return !lastCheckedEmail.exist
        }),
      firstname: Yup.string().required('Pole wymagane'),
      lastname: Yup.string().required('Pole wymagane'),
      phone_number: Yup.string()
        .matches(regex.phone, 'Numer telefonu jest niepoprawny')
        .required('Pole wymagane'),
    }),
    onSubmit: async (values) => {
      setLoading(true)
      await axios
        .post(
          user_id
            ? `/partners/${partner_id}/users/${user_id}/edit`
            : `/partners/${partner_id}/users/store`,
          {
            ...values,
          }
        )
        .then((res) => {
          if (res.status == 200 || res.status == 201) {
            dispatch(closePopup('employee-factory-popup'))
            refreshFilter('PartnerEmployeesPreview')
          }
          handleOpenAlert({
            title: 'Sukces!',
            subtitle: `${
              user_id ? 'Zaktualizowano' : 'Dodano nowego '
            } pracownika`,
            type: 'success',
          })
          setLoading(false)
        })
        .catch((err) => {
          setLoading(false)
          handleOpenAlert(err.request)
        })
    },
  })

  useEffect(() => {
    if (timeoutValue) {
      clearTimeout(timeoutValue)
    }
    setTimeoutValue(
      setTimeout(() => {
        doesExistEmail(formik.values.email)
      }, 300)
    )
  }, [formik.values.email])

  useEffect(() => {
    if (user_id) {
      axios
        .post(`/partners/${partner_id}/users/${user_id}`, null)
        .then((res) => {
          formik.setValues({
            ...res.data,
          })
        })
    }
  }, [user_id])

  return (
    <>
      <form onSubmit={formik.handleSubmit} onReset={formik.handleReset}>
        <DialogContent dividers={true}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Typography variant="subtitle1" component="div">
                    Dane osobowe
                  </Typography>
                </Grid>
                <Grid item md={6} xs={12}>
                  <FormikControl
                    variableName="firstname"
                    handleChange={formik.handleChange}
                    labelName="Imię"
                    formik={formik}
                  />
                </Grid>
                <Grid item md={6} xs={12}>
                  <FormikControl
                    variableName="lastname"
                    handleChange={formik.handleChange}
                    labelName="Nazwisko"
                    formik={formik}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Typography variant="subtitle1" component="div">
                    Dane kontaktowe
                  </Typography>
                </Grid>
                <Grid item md={6} xs={12}>
                  <FormikControl
                    type="tel"
                    variableName="phone_number"
                    handleChange={formik.handleChange}
                    labelName="Nr. Tel."
                    formik={formik}
                  />
                </Grid>
                <Grid item md={6} xs={12}>
                  <FormikControl
                    type="email"
                    variableName="email"
                    handleChange={formik.handleChange}
                    disabled={!!user_id}
                    labelName="E-mail"
                    formik={formik}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <LoadingButton
            startIcon={<SaveIcon />}
            variant="contained"
            color="primary"
            loading={loading}
            type="submit"
          >
            <span className="mt-1">Zapisz</span>
          </LoadingButton>
        </DialogActions>
      </form>
    </>
  )
}

export default EmployeeFactory
