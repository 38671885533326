import { Grid, IconButton } from '@mui/material'
import { Edit, Delete } from '@mui/icons-material'
import RestoreFromTrashIcon from '@mui/icons-material/RestoreFromTrash'
import PersonIcon from '@mui/icons-material/Person'
import { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Confirmation from '../../popups/Confirmation'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import { handleOpenAlert } from '../../../../lib/handleAlert'
import { openPopup } from '../../../../store/popupsSlice'
import { refreshFilter } from '../../../../lib/handleFilters'
import axios from 'axios'

const UsersTableActions = ({ row }) => {
  const dispatch = useDispatch()
  const [confirmation, setConfirmation] = useState(false)
  const user = useSelector((state) => state.user.user)

  return (
    <Grid
      container
      alignItems="center"
      justifyContent="center"
      direction="row"
      spacing={1}
      className="table-actions"
    >
      {confirmation && (
        <Confirmation
          level={5}
          maxWidth="xs"
          icon="Warning"
          title={`Czy na pewno chcesz ${
            row.active == 1 ? 'usunąć' : 'przywrócić'
          } uzytkownika?`}
          subtitle={`Tej operacji nie można cofnąć.`}
          reject={() => {
            setConfirmation(false)
          }}
          success={() => {
            axios
              .post(
                `/users/${row.id}/${row.active == 0 ? 'restore' : 'delete'}`
              )
              .then(() => {
                handleOpenAlert({
                  title: 'Sukces!',
                  subtitle: `${
                    row.active == 0 ? 'Przywrócono' : 'Usunięto'
                  } użytkownika`,
                  type: 'success',
                })
                refreshFilter('UsersKey')
                setConfirmation(false)
              })
              .catch((err) => {
                handleOpenAlert(err.request)
              })
          }}
        />
      )}
      {user.permissions && user.permissions.includes('orderDetails User') && (
        <Grid item>
          <Link to={`/user/${row.id}/results`}>
            <IconButton
              className="p-0"
              title="Podgląd użytkownika"
              size="small"
            >
              <PersonIcon fontSize="small" color="primary" />
            </IconButton>
          </Link>
        </Grid>
      )}
      {user.role_id == 1 &&
        user.permissions &&
        user.permissions.includes('edit User') && (
          <>
            <Grid item>
              <IconButton
                className="p-0"
                title="Edytuj uzytkownika"
                size="small"
                onClick={() =>
                  dispatch(
                    openPopup({
                      component: 'UserFactory',
                      title: 'Edytuj handlowca',
                      level: 1,
                      maxWidth: 'sm',
                      values: {
                        ...row,
                      },
                      key: 'user-factory-popup',
                    })
                  )
                }
              >
                <Edit fontSize="small" />
              </IconButton>
            </Grid>
          </>
        )}
      {user.role_id == 1 &&
        user.permissions &&
        user.permissions.includes('remove User') &&
        user.id != row.id &&
        row.active == 1 && (
          /*user.permissions &&
        user.permissions.includes('remove User') &&*/ <Grid item>
            <IconButton
              className="p-0"
              title="Usuń użytkownika"
              size="small"
              onClick={() => setConfirmation(true)}
            >
              <Delete fontSize="small" color="error" />
            </IconButton>
          </Grid>
        )}
      {user.role_id == 1 &&
        user.permissions &&
        user.permissions.includes('restore User') &&
        row.active == 0 && (
          /*user.permissions &&
        user.permissions.includes('remove User') &&*/ <Grid item>
            <IconButton
              className="p-0"
              title="Przywróć użytkownika"
              size="small"
              onClick={() => setConfirmation(true)}
            >
              <RestoreFromTrashIcon fontSize="small" color="success" />
            </IconButton>
          </Grid>
        )}
    </Grid>
  )
}

UsersTableActions.propTypes = {
  row: PropTypes.object,
}

export default UsersTableActions
