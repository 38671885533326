import { Grid, IconButton } from '@mui/material'
import {
  Edit,
  Delete,
  Check,
  AddComment,
  RateReview,
  Comment,
} from '@mui/icons-material'
// import AddIcon from '@mui/icons-material/Add'
import { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Confirmation from '../../popups/Confirmation'
import PropTypes from 'prop-types'
import { green } from '@mui/material/colors'
import { handleOpenAlert } from '../../../../lib/handleAlert'
import { openPopup } from '../../../../store/popupsSlice'
import { refreshFilter } from '../../../../lib/handleFilters'
import axios from 'axios'

const EventsTableActions = ({ row }) => {
  const user = useSelector((state) => state.user.user)

  const dispatch = useDispatch()
  const [confirmation, setConfirmation] = useState(false)

  return (
    <Grid
      container
      alignItems="center"
      justifyContent="center"
      direction="row"
      spacing={1}
      className="table-actions"
    >
      {/* {user.role_id == 1 && (
        <>
          <Grid item>
            <IconButton
              title="Dodaj zdarzenie"
              size="small"
              onClick={() =>
                dispatch(
                  openPopup({
                    component: 'ContractFactory',
                    title: 'Dodaj zdarzenie',
                    level: 1,
                    maxWidth: 'xl',
                    key: 'contract-factory-popup',
                    values: {
                      event: true,
                      ...row,
                    },
                  })
                )
              }
            >
              <AddIcon fontSize="small" />
            </IconButton>
          </Grid>
        </>
      )} */}
      {confirmation && (
        <Confirmation
          level={5}
          maxWidth="xs"
          icon="Warning"
          title={`Czy na pewno chcesz usunąć zlecenie?`}
          subtitle={`Tej operacji nie można cofnąć.`}
          reject={() => {
            setConfirmation(false)
          }}
          success={() => {
            axios
              .post(`/contracts/${row.id}/delete`)
              .then(() => {
                handleOpenAlert({
                  title: 'Sukces!',
                  subtitle: `Usunięto zlecenie`,
                  type: 'success',
                })
                refreshFilter('ContractsKey')
                setConfirmation(false)
              })
              .catch((err) => {
                handleOpenAlert(err.request)
              })
          }}
        />
      )}
      {row.status_id == 1 && (
        <>
          <Grid item>
            <IconButton
              className="p-0"
              title="Finalizuj zlecenie"
              size="small"
              onClick={() =>
                dispatch(
                  openPopup({
                    component: 'ContractFactory',
                    title: 'Finalizuj zlecenie',
                    level: 1,
                    maxWidth: 'xl',
                    values: {
                      ...row,
                      finalize: true,
                    },
                    key: 'contract-factory-popup',
                  })
                )
              }
            >
              <Check fontSize="small" style={{ color: green[500] }} />
            </IconButton>
          </Grid>
        </>
      )}
      {user.role_id == 1 && user.permissions.includes('edit Contract') && (
        <>
          <Grid item>
            <IconButton
              className="p-0"
              title="Edytuj zlecenie"
              size="small"
              onClick={() =>
                dispatch(
                  openPopup({
                    component: 'ContractFactory',
                    title: 'Edytuj zlecenie',
                    level: 1,
                    maxWidth: 'xl',
                    values: {
                      ...row,
                      patientEditable: true,
                    },
                    key: 'contract-factory-popup',
                  })
                )
              }
            >
              <Edit fontSize="small" />
            </IconButton>
          </Grid>
        </>
      )}
      {user.role_id == 1 && user.permissions.includes('remove Contract') && (
        <Grid item>
          <IconButton
            className="p-0"
            title="Usuń zlecenie"
            size="small"
            onClick={() => setConfirmation(true)}
          >
            <Delete fontSize="small" color="error" />
          </IconButton>
        </Grid>
      )}
      {row.comment && user.role_id === 1 && (
        <Grid item>
          <IconButton
            className="p-0"
            title="Podgląd notatki"
            size="small"
            onClick={() =>
              dispatch(
                openPopup({
                  component: 'NotesPreview',
                  title: 'Pokaż notatkę',
                  level: 1,
                  key: 'note-show-popup',
                  values: {
                    comment: row.comment,
                    id: row.id,
                  },
                })
              )
            }
          >
            <Comment fontSize="small" />
          </IconButton>
        </Grid>
      )}

      {user.role_id === 1 && (
        <>
          {row.comment !== null && row.comment !== undefined ? (
            <Grid item>
              <IconButton
                className="p-0"
                title="edytuj notatkę"
                size="small"
                onClick={() =>
                  dispatch(
                    openPopup({
                      component: 'NotesFactory',
                      title: 'Edytuj notatkę',
                      level: 1,
                      maxWidth: 'xl',
                      key: 'note-factory-popup',
                      values: {
                        comment: row.comment,
                        id: row.id,
                      },
                    })
                  )
                }
              >
                <RateReview fontSize="small" />
              </IconButton>
            </Grid>
          ) : (
            <Grid item>
              <IconButton
                className="p-0"
                title="Dodaj notatkę"
                size="small"
                onClick={() =>
                  dispatch(
                    openPopup({
                      component: 'NotesFactory',
                      title: 'Dodaj notatkę',
                      level: 1,
                      maxWidth: 'xl',
                      key: 'note-factory-popup',
                      values: {
                        comment: row.comment,
                        id: row.id,
                      },
                    })
                  )
                }
              >
                <AddComment fontSize="small" />
              </IconButton>
            </Grid>
          )}
        </>
      )}
    </Grid>
  )
}

EventsTableActions.propTypes = {
  row: PropTypes.object,
}

export default EventsTableActions
