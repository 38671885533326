import { createSlice } from '@reduxjs/toolkit'

export const layoutSlice = createSlice({
  name: 'layout',
  initialState: {
    burgerExpanded: false,
  },
  reducers: {
    changeBurgerExpanded: (state, action) => {
      state.burgerExpanded = action.payload.value
    },
  },
})

export const { changeBurgerExpanded } = layoutSlice.actions

export default layoutSlice.reducer
