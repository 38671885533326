import { Paper, Card, Typography } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import PropTypes from 'prop-types'

const useStyles = makeStyles((theme) => ({
  root: {
    // backgroundColor: theme.palette.primary.light,
  },
  pageHeader: {
    padding: '16px 24px',
    display: 'flex',
    backgroundColor: theme.palette.secondary.lightBlack,
    alignItems: 'center',
  },
  [theme.breakpoints.down('md')]: {
    pageHeader: {
      marginBottom: theme.spacing(4),
    },
  },
  pageIcon: {
    display: 'inline-block',
    padding: '11px',
    color: theme.palette.primary.main,
    overflow: 'inherit',
    pointerEvents: 'none',
    height: '46px',
    width: '46px',
  },
  pageTitle: {
    paddingLeft: theme.spacing(2),
    '& .MuiTypography-subtitle2': {
      opacity: '0.7',
    },
  },
}))

const PageHeader = ({ icon, title, subtitle }) => {
  const classes = useStyles()

  return (
    <Paper elevation={2} square className={classes.root}>
      <div className={classes.pageHeader}>
        <Card className={classes.pageIcon}>{icon}</Card>
        <div className={classes.pageTitle}>
          <Typography
            className="text-white text-xl"
            variant="h5"
            component="div"
          >
            {title}
          </Typography>
          <Typography
            className="text-white"
            variant="subtitle1"
            component="div"
          >
            {subtitle}
          </Typography>
        </div>
      </div>
    </Paper>
  )
}

PageHeader.propTypes = {
  icon: PropTypes.object,
  title: PropTypes.string,
  subtitle: PropTypes.string,
}

export default PageHeader
