export function createFormData(values) {
  var form = new FormData()
  return addValuesToForm(form, values)
}
function addValuesToForm(form, values) {
  for (let key in values) {
    form = unpack(form, values[key], key)
  }
  return form
}
function unpack(form, value, key) {
  if (value === null || value === undefined || value !== value) {
    form.set(key, '')
  } else if (typeof value == 'object') {
    if (value instanceof File) {
      form.append(key, value, value.name)
    } else {
      let keys = Object.keys(value)
      if (keys.length) {
        for (let i = 0; i < keys.length; i++) {
          let k = keys[i]
          // if (Number.isNaN(+k)) {
          //   form = unpack(form, value[k], `${key}[${k}]`)
          // } else {
          form = unpack(form, value[k], `${key}[${k}]`)
          // }
        }
      } else {
        form.append(key, [])
      }
    }
  } else {
    let v = typeof value == 'boolean' ? +value : value
    form.set(key, v)
  }
  return form
}

export default createFormData
