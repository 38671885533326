import PropTypes from 'prop-types'
import React, { useEffect } from 'react'
import { useFormik } from 'formik'
import { refreshFilter } from '../../../../lib/handleFilters'
import eventBus from '../../../../lib/eventBus'
import regex from '../../../../regex'
import {
  Typography,
  Grid,
  DialogActions,
  DialogContent,
  InputAdornment,
  // CircularProgress,
} from '@mui/material'
import FormikControl from '../../../molecules/FormikControl'
import SaveIcon from '@mui/icons-material/Save'
import * as Yup from 'yup'
import axios from 'axios'
import { handleOpenAlert } from '../../../../lib/handleAlert'
import { closePopup } from '../../../../store/popupsSlice'
import { useDispatch } from 'react-redux'
import { useState } from 'react'
import LoadingButton from '@mui/lab/LoadingButton'
import FormikSelect from '../../../molecules/FormikSelect'

const phone_numberRegExp = /^(\+[0-9]{1,})?[ ]?([0-9]{3}[- ]?){3}$/

const PartnerFactory = ({ ...values }) => {
  const user_id = values.id

  const [lastCheckedEmail, setlastCheckedEmail] = useState({
    email: values.email,
    exist: false,
  })
  const [timeoutValue, setTimeoutValue] = useState(null)
  const [roles, setRoles] = useState([])

  const dispatch = useDispatch()
  const formik = useFormik({
    initialValues: {
      phone_number: values.phone_number || '',
      email: values.email || '',
      firstname: values.firstname || '',
      lastname: values.lastname || '',
      discount: values.discount || '',
      role_id: values.role_id || '',
    },
    enableReinitialize: false,
    validationSchema: Yup.object({
      firstname: Yup.string().required('Pole wymagane'),
      lastname: Yup.string().required('Pole wymagane'),
      role_id: Yup.string().required('Pole wymagane'),
      discount: Yup.number()
        .nullable()
        .min('0', 'Minimalny rabat 0%')
        .max('50', 'Maksymalny rabat 50%'),

      email: Yup.string()
        .required('Pole wymagane')
        .email('Błędny format emaila')
        .when('active', {
          is: true,
          then: Yup.string().required('Pole wymagane'),
        })
        .test('email-unique', 'Podany email jest zajęty', function () {
          return !lastCheckedEmail.exist
        }),
      phone_number: Yup.string()
        .nullable()
        .matches(phone_numberRegExp, 'Numer telefonu jest niepoprawny'),
    }),
    onSubmit: async (values) => {
      if (!user_id) {
        store(values)
        setLoading(true)
      } else {
        edit(values)
        setLoading(true)
      }
    },
  })

  useEffect(() => {
    if (user_id) {
      axios.post(`/users/${user_id}`, null).then((res) => {
        formik.setValues({
          ...res.data,
        })
      })
    }
  }, [user_id])

  const loadRoles = () => {
    return new Promise(function (resolve) {
      axios.post(`/lookups/roles`).then((res) => {
        if (res.status == 200) {
          resolve(res.data)
        }
      })
    })
  }

  useEffect(async () => {
    setRoles(await loadRoles())
  }, [])

  useEffect(() => {
    if (timeoutValue) {
      clearTimeout(timeoutValue)
    }
    setTimeoutValue(
      setTimeout(() => {
        doesExistEmail(formik.values.email)
      }, 300)
    )
  }, [formik.values.email])

  const doesExistEmail = (email) => {
    if (regex.email.test(email)) {
      if (lastCheckedEmail.value != email) {
        axios
          .post('/users/email/check', {
            email,
            user_id: user_id,
          })
          .then((res) => {
            setlastCheckedEmail({
              exist: res.data.exist,
              value: email,
            })
          })
      }
    } else {
      setlastCheckedEmail({
        exist: false,
        value: email,
      })
    }
  }

  const store = (values) => {
    axios
      .post('/users/admins/store', {
        ...values,
      })
      .then((res) => {
        if (res.status == 200 || res.status == 201) {
          dispatch(closePopup('user-factory-popup'))
          eventBus.dispatch('refreshUser', { user_id: res.data.id })

          refreshFilter('UsersKey')
          setLoading(false)
        }
        handleOpenAlert({
          title: 'Sukces!',
          subtitle: 'Dodano nowego uzytkownika',
        })
      })
      .catch((err) => {
        handleOpenAlert(err.request.status)
        setLoading(false)
      })
  }

  const edit = (values) => {
    axios
      .post(`/users/${user_id}/edit`, {
        ...values,
      })
      .then((res) => {
        if (res.status == 200 || res.status == 201) {
          dispatch(closePopup('user-factory-popup'))
          refreshFilter('UsersKey')
          eventBus.dispatch('refreshUser', { user_id })
          setLoading(false)
        }
        handleOpenAlert({
          title: 'Sukces!',
          subtitle: 'Edytowano użytkownika',
        })
      })
      .catch((err) => {
        handleOpenAlert(err.request.status)
        setLoading(false)
      })
  }

  const [loading, setLoading] = useState(false)

  // function handleClick() {
  //   setLoading(true)
  // }

  // const classes = styles()

  return (
    <>
      <form onSubmit={formik.handleSubmit} onReset={formik.handleReset}>
        <>
          <DialogContent dividers={true}>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <Typography variant="subtitle1" component="div">
                      Dane osobowe
                    </Typography>
                    {/* {loading && user_id && (
                      <CircularProgress
                        className={classes.loading}
                        color="primary"
                        thickness={2}
                        size="4rem"
                      />
                    )} */}
                  </Grid>
                  <Grid item md={6} xs={12}>
                    <FormikControl
                      variableName="firstname"
                      handleChange={formik.handleChange}
                      labelName="Imię"
                      formik={formik}
                    />
                  </Grid>
                  <Grid item md={6} xs={12}>
                    <FormikControl
                      variableName="lastname"
                      handleChange={formik.handleChange}
                      labelName="Nazwisko"
                      formik={formik}
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <Typography variant="subtitle1" component="div">
                      Dane kontaktowe
                    </Typography>
                  </Grid>
                  <Grid item md={6} xs={12}>
                    <FormikControl
                      type="tel"
                      variableName="phone_number"
                      handleChange={formik.handleChange}
                      labelName="Nr. Tel."
                      formik={formik}
                    />
                  </Grid>
                  <Grid item md={6} xs={12}>
                    <FormikControl
                      variableName="email"
                      disabled={!!user_id}
                      handleChange={formik.handleChange}
                      labelName="E-mail"
                      formik={formik}
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <Grid container spacing={2}>
                  <Grid item xs={12} md={6} lg={6}>
                    <Typography
                      className="pb-4"
                      variant="subtitle1"
                      component="div"
                    >
                      Dane finansowe
                    </Typography>
                    <FormikControl
                      variableName="discount"
                      handleChange={formik.handleChange}
                      labelName="Rabat"
                      formik={formik}
                      type="number"
                      value={formik.values.discount}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="start">%</InputAdornment>
                        ),
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} md={6} lg={6}>
                    <Typography
                      className="pb-4"
                      variant="subtitle1"
                      component="div"
                    >
                      Rola
                    </Typography>
                    <FormikSelect
                      variableName="role_id"
                      value={formik.values['role_id']}
                      labelName="Rola"
                      formik={formik}
                      options={roles}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <LoadingButton
              startIcon={<SaveIcon />}
              variant="contained"
              color="primary"
              type="submit"
              // onClick={setLoading}
              loading={loading}
              loadingPosition="start"
            >
              <span className="mt-1">Zapisz</span>
            </LoadingButton>
          </DialogActions>
        </>
      </form>
    </>
  )
}

PartnerFactory.propTypes = {
  values: PropTypes.object,
}

export default PartnerFactory
