import { Card, CardHeader, CardContent, Button } from '@mui/material'
import StoreKeyProvider from 'components/providers/StoreKeyProvider'
import WithFilters from 'components/hocs/WithFilters'
import DataTable from 'components/organisms/dataTable/DataTable'
import DataInputs from 'components/organisms/dataTable/DataInputs'
import { useEffect, useState } from 'react'
import AddIcon from '@mui/icons-material/Add'
import { useDispatch, useSelector } from 'react-redux'
import { openPopup } from 'store/popupsSlice'
import makeStyles from '@mui/styles/makeStyles'
import axios from 'axios'
import RequestQuoteIcon from '@mui/icons-material/RequestQuote'
import PageHeader from 'components/organisms/layout/PageHeader'
import PageContainer from 'components/organisms/layout/PageContainer'

const useStyles = makeStyles((theme) => ({
  bg: {
    background: 'transparent',
    overflow: 'unset',
  },
  content: {
    padding: '24px',
    background: '#fff',
    marginBottom: '24px',
    borderRadius: '2px',
    boxShadow:
      '0px 3px 1px -2px rgba(0,0,0,0.2),0px 2px 2px 0px rgba(0,0,0,0.14),0px 1px 5px 0px rgba(0,0,0,0.12)',
  },
  contentPreview: {
    background: '#fff',
    padding: 'unset',
    color: 'rgba(0, 0, 0, 0.6)',
    display: 'flex',
    flexWrap: 'wrap',
    [theme.breakpoints.down('custom_extras')]: {
      width: '100%',
    },
  },
  contentSt: {
    background: '#fff',
    marginBottom: '24px',
    borderRadius: '2px',
    boxShadow:
      '0px 3px 1px -2px rgba(0,0,0,0.2),0px 2px 2px 0px rgba(0,0,0,0.14),0px 1px 5px 0px rgba(0,0,0,0.12)',
    position: 'relative',
  },
}))

const PriceLists = () => {
  const dispatch = useDispatch()
  const classes = useStyles()
  const [currencies, setCurrencies] = useState([])
  const user = useSelector((state) => state.user.user)

  useEffect(() => {
    axios.post('/lookups/currencies', null).then((res) => {
      setCurrencies(res.data)
    })
  }, [])

  return (
    <div>
      <PageHeader icon={<RequestQuoteIcon />} title="Cenniki" />
      <PageContainer>
        <StoreKeyProvider storeKey="PriceListsKey">
          <WithFilters
            config={{
              page: 1,
              paginate: 25,
              orderType: 'desc',
              order: 'name',
              inputs: {
                name: {
                  value: '',
                  relation: 'LIKE',
                },
                currency_id: {
                  value: '',
                  relation: 'WHERE',
                },
                active: {
                  value: 1,
                  relation: 'WHERE',
                },
              },
            }}
            endpoint={`/price_lists/filter`}
          >
            <Card elevation={0} className={classes.bg}>
              <CardContent className={classes.contentSt}>
                <CardHeader
                  className={classes.contentPreview}
                  title="Cenniki"
                  action={
                    <>
                      <div className="flex flex-wrap side_menu_nd_w:flex-col side_menu_nd_w:gap-4">
                        <Button
                          startIcon={<AddIcon />}
                          variant="contained"
                          color="primary"
                          onClick={() =>
                            dispatch(
                              openPopup({
                                component: 'PriceListFactory',
                                title: 'Dodaj cennik',
                                level: 1,
                                key: 'factory-price-list-popup',
                                maxWidth: 'sm',
                              })
                            )
                          }
                        >
                          <span className="mt-1">Dodaj cennik</span>
                        </Button>
                      </div>
                    </>
                  }
                />
                {currencies && (
                  <DataInputs
                    inputs={[
                      {
                        id: 'name',
                        label: 'Nazwa',
                        placeholder: '',
                        type: 'text',
                      },
                      {
                        id: 'currency_id',
                        label: 'Waluta',
                        placeholder: '',
                        type: 'select',
                        options: currencies,
                      },
                      {
                        id: 'active',
                        label: 'Aktywne',
                        placeholder: '',
                        type: 'select',
                        options: [
                          {
                            id: 1,
                            name: 'Tak',
                          },
                          {
                            id: 0,
                            name: 'Nie',
                          },
                        ],
                      },
                    ]}
                  />
                )}
              </CardContent>
              <CardContent className={classes.content}>
                <DataTable
                  actions="PriceListsActions"
                  breakpoint={1200}
                  labels={['Nazwa', 'Waluta']}
                  keys={['name', 'currency_name']}
                />
              </CardContent>
            </Card>
          </WithFilters>
        </StoreKeyProvider>
      </PageContainer>
    </div>
  )
}

PriceLists.propTypes = {}

export default PriceLists
