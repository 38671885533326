const resolveFromObject = (data, keys, separator = ' ') => {
  let result = ''
  if (typeof keys == 'function') {
    return keys(data)
  } else {
    const paths = keys.split('+')
    for (let i = 0; i < paths.length; i++) {
      if (paths[i].includes('"')) {
        result += paths[i].substr(1, paths[i].length - 2)
      } else {
        const path = paths[i].split('.')
        let actualData = { ...data }
        for (let j = 0; j < path.length; j++) {
          actualData = actualData[path[j]]
        }
        if (typeof actualData == 'string') {
          result += actualData.replace(/(\r\n|\n|\r)/gm, ' ')
        } else {
          result += actualData
        }
      }

      if (i !== paths.length - 1) {
        // eslint-disable-next-line
        if (typeof separator == 'object') {
          result += separator[i]
        } else {
          result += separator
        }
      }
    }
  }

  return result.trim()
}

export default resolveFromObject
