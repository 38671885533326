const filtersBuilder = (inputs) => {
  let result = {
    LIKE: {},
    MIN: {},
    MAX: {},
    WHERE: {},
  }

  let keys = Object.keys(inputs)
  for (let i = 0; i < keys.length; i++) {
    let input = inputs[keys[i]]
    let key = keys[i]
    if (key.includes('min_') || key.includes('max_')) {
      key = key.substring(4)
    }
    if ((input.value && input.value !== '') || input.value === 0) {
      Object.assign(result[input.relation], {
        [`${input.preffix ? `${input.preffix}.` : ''}${key}`]: input.value,
      })
    }
  }
  return result
}

export default filtersBuilder
