import { createContext, useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import axios from 'axios'
import { cloneDeep } from 'lodash'
import { omit } from 'lodash'
import LoadingCard from '../components/atoms/LoadingCard'
import { makeStyles } from '@mui/styles'

export const ValuationCalculatorContext = createContext({
  component: '',
  setComponent: () => {
    return undefined
  },
  blocks: [],
  setBlocks: () => {
    return undefined
  },
  image: '',
  setImage: () => {
    return undefined
  },
  setMaterialImage: () => {
    return undefined
  },
  setColorImage: () => {
    return undefined
  },
  validate: '',
  setValidate: () => {
    return undefined
  },
  extras: {},
  connectors: {},
  setExtras: () => {
    return undefined
  },
  maxLength: {},
  setMaterialMaxLength: () => {
    return undefined
  },
  fetchExtrasItemsTrigger: '',
  getExtrasItems: () => {
    return undefined
  },
  calculate: '',
  recalculate: () => {
    return undefined
  },
  widthError: '',
  discount: 0,
  loading: false,
  partnerId: null,
  universal: true,
  selectedGroupNum: null,
  readOnly: false,
  withoutBlocks: false,
  setWithoutBlocks: () => {
    return undefined
  },
  edit: false,
  handleLoadingStack: () => {
    return undefined
  },
  setWidthError: () => {
    return undefined
  },
})

const useStyles = makeStyles(() => ({
  hidden: {
    display: 'none',
  },
}))
const ValuationCalculatorContextProvider = ({
  children,
  initObject,
  showCalculator,
  currencyAcronym,
  selectedGroupNum,
  readOnly,
  universal,
  partnerId,
  discount,
  edit,
}) => {
  const classes = useStyles()
  const [component, _setComponent] = useState({})
  const [image, _setImage] = useState({
    type: 'img',
    value: null,
  })
  const [blocks, _setBlocks] = useState([])
  const [extras, _setExtras] = useState({})
  const [connectors, setConnectors] = useState({})
  const [calculate, setCalculate] = useState(0)
  const [widthError, setWidthError] = useState(null)
  const [loadingStack, setLoadingStack] = useState([])
  const [loading, _setLoading] = useState(false)
  const [validate, _setValidate] = useState(false)
  const [materialMaxLength, setMaterialMaxLength] = useState(false)
  const [materialImage, setMaterialImage] = useState(null)
  const [colorImage, setColorImage] = useState(null)
  const [loaded, setLoaded] = useState(false)
  const [withoutBlocks, setWithoutBlocks] = useState(false)
  const [fetchExtrasItemsTrigger, setFetchExtrasItemsTrigger] = useState(0)

  useEffect(() => {
    axios.post(`/lookups/connectors`).then((response) => {
      setConnectors(response.data)
    })
  }, [])

  useEffect(() => {
    setLoaded(false)
    setWithoutBlocks(initObject.without_blocks)
    setComponent(omit(initObject, ['blocks', 'extras']))
    setBlocks(initObject.blocks)
    setExtras(initObject.extras)
    if (!initObject.universal || !initObject.location_id) {
      setLoaded(true)
    }
  }, [initObject.id])

  const setComponent = (object) => {
    object = cloneDeep(object)
    _setComponent(object)
  }
  const setImage = (string, type = 'img') => {
    if (!component.color_id || string) {
      _setImage({ value: string, type })
    } else {
      _setImage({
        value: colorImage || materialImage,
        type: 'img',
      })
    }
  }
  const setLoading = (bool) => {
    _setLoading(bool)
  }
  const setBlocks = (array) => {
    array = cloneDeep(array)
    _setBlocks(array)
  }
  const setExtras = (object) => {
    object = cloneDeep(object)
    _setExtras(object)
  }
  const setValidate = (bool) => {
    _setValidate(bool)
  }

  const recalculate = () => {
    setCalculate(calculate + 1)
  }

  const handleLoadingStack = (add) => {
    let stack = loadingStack
    if (add) {
      stack.push(1)
    } else {
      stack.splice(0, 1)
    }
    if (stack.length) {
      setLoading(true)
    } else {
      if (!loaded) {
        setLoaded(true)
      }
      setLoading(false)
    }
    setLoadingStack(stack)
  }

  const getExtrasItems = () => {
    setFetchExtrasItemsTrigger(fetchExtrasItemsTrigger + 1)
  }

  const valuationCalculatorState = {
    component,
    setComponent,
    image,
    setImage,
    validate,
    setValidate,
    blocks,
    setBlocks,
    extras,
    setExtras,
    connectors,
    getExtrasItems,
    fetchExtrasItemsTrigger,
    calculate,
    withoutBlocks,
    setWithoutBlocks,
    recalculate,
    setMaterialImage,
    setColorImage,
    widthError,
    discount,
    loading,
    handleLoadingStack,
    setWidthError,
    currencyAcronym,
    selectedGroupNum,
    readOnly,
    partnerId,
    universal,
    edit,
    materialMaxLength,
    setMaterialMaxLength,
  }

  return (
    <ValuationCalculatorContext.Provider value={valuationCalculatorState}>
      <div className={showCalculator && loaded ? '' : classes.hidden}>
        {children}
      </div>
      <LoadingCard
        className={loaded || !showCalculator ? classes.hidden : ''}
      />
    </ValuationCalculatorContext.Provider>
  )
}

ValuationCalculatorContextProvider.propTypes = {
  children: PropTypes.object,
  currencyAcronym: PropTypes.string,
  selectedGroupNum: PropTypes.oneOfType(PropTypes.string, PropTypes.number),
  showCalculator: PropTypes.bool,
  universal: PropTypes.bool,
  discount: PropTypes.number,
  partnerId: PropTypes.oneOfType(PropTypes.number, PropTypes.string),
  initObject: PropTypes.object,
  readOnly: PropTypes.bool,
  edit: PropTypes.bool,
}

export default ValuationCalculatorContextProvider
