import { Grid, IconButton } from '@mui/material'
import { Edit, Delete } from '@mui/icons-material'
import Confirmation from '../../popups/Confirmation'
import { useState } from 'react'
import PersonIcon from '@mui/icons-material/Person'
import PropTypes from 'prop-types'
import { openPopup } from '../../../../store/popupsSlice'
import { Link } from 'react-router-dom'
import { handleOpenAlert } from '../../../../lib/handleAlert'
import { refreshFilter } from '../../../../lib/handleFilters'
import { useDispatch } from 'react-redux'
// import CalculateIcon from '@mui/icons-material/Calculate'
import axios from 'axios'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCalculator } from '@fortawesome/pro-solid-svg-icons'
import makeStyles from '@mui/styles/makeStyles'
import RestoreFromTrashIcon from '@mui/icons-material/RestoreFromTrash'

const useStyles = makeStyles(() => ({
  icon: {
    fontSize: '1.10rem',
    color: '#000',
  },
}))

const PartnersTableActions = ({ row }) => {
  const classes = useStyles()

  const [confirmation, setConfirmation] = useState(false)
  // const user = useSelector((state) => state.user.user)
  const dispatch = useDispatch()

  return (
    <Grid
      container
      alignItems="center"
      justifyContent="center"
      direction="row"
      spacing={1}
      className="table-actions"
    >
      {confirmation && (
        <Confirmation
          level={5}
          maxWidth="xs"
          icon="Warning"
          title={`Czy na pewno chcesz ${
            row.active == 1 ? 'usunąć' : 'przywrócić'
          } partnera ${row.name}?`}
          subtitle={`Tej operacji nie można cofnąć.`}
          reject={() => {
            setConfirmation(false)
          }}
          success={() => {
            axios
              .post(
                `/partners/${row.id}/${row.active == 0 ? 'restore' : 'remove'}`
              )
              .then(() => {
                handleOpenAlert({
                  title: 'Sukces!',
                  subtitle: `${
                    row.active == 0 ? 'Przywrócono' : 'Usunięto'
                  } partnera ${row.name}`,
                  type: 'success',
                })
                refreshFilter('PartnersKey')
                setConfirmation(false)
              })
              .catch((err) => {
                handleOpenAlert(err.request)
              })
          }}
        />
      )}
      <Grid item>
        <Link to={`/partners/${row.id}/employees`}>
          <IconButton className="p-0" size="small" title="Podgląd partnera">
            <PersonIcon fontSize="small" color="primary" />
          </IconButton>
        </Link>
      </Grid>
      <>
        <Grid item>
          <IconButton
            className="p-0"
            title="Edytuj partnera"
            onClick={() => {
              dispatch(
                openPopup({
                  component: 'PartnerFactory',
                  title: 'Edytuj partnera',
                  level: 1,
                  maxWidth: 'sm',
                  values: {
                    id: row.id,
                  },
                  key: 'partner-factory-popup',
                })
              )
            }}
            size="small"
          >
            <Edit fontSize="small" />
          </IconButton>
        </Grid>
        <Grid item>
          {row.active == 1 ? (
            <IconButton
              className="p-0"
              title="Usuń partnera"
              size="small"
              onClick={() => setConfirmation(true)}
            >
              <Delete fontSize="small" color="error" />
            </IconButton>
          ) : (
            <IconButton
              className="p-0"
              title="Przywróć partnera"
              size="small"
              onClick={() => setConfirmation(true)}
            >
              <RestoreFromTrashIcon fontSize="small" color="success" />
            </IconButton>
          )}
        </Grid>
        <Grid item className="valuation-button">
          <IconButton
            className="p-0"
            title="Wygeneruj wycenę"
            onClick={() =>
              dispatch(
                openPopup({
                  component: 'ValuationFactory',
                  title: `Dodaj wycenę dla: ${row.name}`,
                  level: 1,
                  maxWidth: 'xl',
                  key: 'valuation-factory-popup',
                  values: {
                    partner_id: row.id,
                  },
                })
              )
            }
          >
            <FontAwesomeIcon className={classes.icon} icon={faCalculator} />
          </IconButton>
        </Grid>
      </>
    </Grid>
  )
}

PartnersTableActions.propTypes = {
  row: PropTypes.object,
}

export default PartnersTableActions
