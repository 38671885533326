// import AddIcon from '@mui/icons-material/Add'
import PropTypes from 'prop-types'
import StarIcon from '@mui/icons-material/Star'

const ShowMainCell = ({ row, keyName }) => {
  return <span spacing={1}>{!!row[keyName] && <StarIcon />}</span>
}

ShowMainCell.propTypes = {
  row: PropTypes.object,
  keyName: PropTypes.string,
}

export default ShowMainCell
