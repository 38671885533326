import { Switch } from 'react-router-dom'
import { useSelector } from 'react-redux'
import DefaultLayout from '../layout/layouts/DefaultLayout.js'
import Partners from '../pages/B2B/Partners/Partners'
import Orders from '../pages/B2B/Valuations/Orders'
import Reports from '../pages/B2B/Reports'
import Materials from '../pages/B2B/Materials/Materials'
import Valuations from '../pages/B2B/Valuations/Valuations'
import PartnerPreview from '../pages/B2B/Partners/Preview/PartnerPreview'
import UserPreview from '../pages/B2B/Users/Preview/UserPreview'
import PriceListMaterialPreview from '../pages/B2B/Materials/PriceListMaterialPreview'
import Users from '../pages/B2B/Users/Users'
import RoleProtected from '../../molecules/RoleProtected'
import OutsideViewBox from '../dataTable/boxes/OutsideViewBox'
import Colors from '../pages/B2B/Colors/Colors'
import PriceLists from '../pages/B2B/PriceLists/PriceLists'
import CustomOrders from '../pages/B2B/Valuations/CustomOrders.js'
// import OutsideViewBox from './dataTable/boxes/OutsideViewBox'

const B2BRoutes = () => {
  const user = useSelector((state) => state.user.user)

  return (
    <Switch>
      <RoleProtected allowRoles={[1]} path="/partners/:id/:page">
        <DefaultLayout>
          <PartnerPreview />
        </DefaultLayout>
      </RoleProtected>
      <RoleProtected allowRoles={[1]} path="/partners">
        <DefaultLayout>
          <Partners />
        </DefaultLayout>
      </RoleProtected>
      {/* <RoleProtected allowRoles={[1]} path="/events">
          <DefaultLayout>
            <Events />
          </DefaultLayout>
        </RoleProtected> */}
      <RoleProtected allowRoles={[1]} path="/orders/custom">
        <DefaultLayout>
          <CustomOrders />
        </DefaultLayout>
      </RoleProtected>
      <RoleProtected allowRoles={[1, 2]} path="/orders">
        <DefaultLayout>
          <Orders />
        </DefaultLayout>
      </RoleProtected>
      {user &&
        user.permissions &&
        user.permissions.includes('orderDetails User') && (
          <RoleProtected allowRoles={[1]} path="/user/:id/:page">
            <DefaultLayout>
              <UserPreview />
            </DefaultLayout>
          </RoleProtected>
        )}
      <RoleProtected allowRoles={[1]} path="/materials/:id/:page">
        <DefaultLayout>
          <PriceListMaterialPreview />
        </DefaultLayout>
      </RoleProtected>

      {user && user.permissions && user.permissions.includes('view report') && (
        <RoleProtected allowRoles={[1]} path="/reports">
          <DefaultLayout>
            <Reports />
          </DefaultLayout>
        </RoleProtected>
      )}
      <RoleProtected allowRoles={[1]} path="/materials">
        <DefaultLayout>
          <Materials />
        </DefaultLayout>
      </RoleProtected>
      <RoleProtected allowRoles={[1]} path="/colors">
        <DefaultLayout>
          <Colors />
        </DefaultLayout>
      </RoleProtected>
      {user && user.permissions && user.permissions.includes('view PriceList') && (
        <RoleProtected allowRoles={[1]} path="/price_lists">
          <DefaultLayout>
            <PriceLists />
          </DefaultLayout>
        </RoleProtected>
      )}
      <RoleProtected allowRoles={[2]} path="/home">
        <DefaultLayout>
          <OutsideViewBox />
        </DefaultLayout>
      </RoleProtected>
      <RoleProtected allowRoles={[2]} path="/valuations">
        <DefaultLayout>
          <Valuations />
        </DefaultLayout>
      </RoleProtected>
      {/* <RoleProtected allowRoles={[2]} path="/partner/:id/">
          <DefaultLayout>
            <PartnerPreview />
          </DefaultLayout>
        </RoleProtected> */}
      <RoleProtected allowRoles={[1]} path="/users">
        <DefaultLayout>
          <Users />
        </DefaultLayout>
      </RoleProtected>
    </Switch>
  )
}

export default B2BRoutes
