import { useContext, useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { ValuationCalculatorContext } from '../../../../../../contexts/ValuationCalculatorContext'
import { InputAdornment } from '@mui/material'
import FormAutocomplete from 'components/molecules/inputs/FormAutocomplete'
import LocationEnum from '../../../../../../lib/LocationEnum'
import { getWidths } from '../../../../../../store/valuationSlice'
import { useDispatch } from 'react-redux'
import FormInput from 'components/molecules/inputs/FormInput'

const ValuationCalculatorWidthAutocomplete = () => {
  const {
    component,
    setComponent,
    recalculate,
    validate,
    withoutBlocks,
    handleLoadingStack,
    readOnly,
    widthError,
  } = useContext(ValuationCalculatorContext)
  const { t } = useTranslation()
  const firstRender = useRef(true)
  const [widths, setWidths] = useState([])
  const dispatch = useDispatch()

  useEffect(async () => {
    if (component.thickness_id) {
      loadWidths()
    } else {
      setWidths([])
    }
  }, [component.thickness_id])

  useEffect(() => {
    firstRender.current = false
  })

  useEffect(() => {
    firstRender.current = true
  }, [component.id])

  useEffect(() => {
    if (!firstRender.current && !withoutBlocks) {
      recalculate()
    }
  }, [component.width])

  const changeValue = (value) => {
    component.width = value
    setComponent(component)
  }

  const loadWidths = () => {
    setWidths([])
    handleLoadingStack(true)
    dispatch(
      getWidths({
        material_id: component.material_id,
        color_id: component.color_id,
        thickness_id: component.thickness_id,
      })
    ).then((materials) => {
      setWidths(materials.payload)
      handleLoadingStack(false)
    })
  }

  return (
    <>
      {component.location_id == LocationEnum.STONE ? (
        <FormInput
          name="width"
          value={component.width}
          handleChange={(v) => {
            changeValue(v)
          }}
          disabled={readOnly || !component.thickness_id}
          max="10000"
          labelName={t('valuation_popup.data.input.width')}
          min="1"
          helperText={
            (validate &&
              ((!component.width && t('main.validation.required')) ||
                (component.width < 50 &&
                  t('main.validation.min_value', { min: 50 })))) ||
            widthError
          }
          type="number"
          InputProps={{
            endAdornment: <InputAdornment position="start">mm</InputAdornment>,
          }}
        />
      ) : (
        <FormAutocomplete
          name="width"
          value={component.width}
          handleChange={(name, v) => {
            changeValue(v)
          }}
          disabled={readOnly || !component.thickness_id}
          max="10000"
          labelName={t('valuation_popup.data.input.width')}
          min="1"
          options={widths}
          freeSolo={component.location_id != LocationEnum.PCV}
          helperText={
            (validate &&
              ((!component.width && t('main.validation.required')) ||
                (component.width < 50 &&
                  t('main.validation.min_value', { min: 50 })))) ||
            widthError
          }
          config={{
            key: 'width',
            label: 'width',
            separator: ' ',
          }}
          type="number"
          InputProps={{
            endAdornment: <InputAdornment position="start">mm</InputAdornment>,
          }}
        />
      )}
    </>
  )
}
ValuationCalculatorWidthAutocomplete.propTypes = {}
export default ValuationCalculatorWidthAutocomplete
