import { Switch } from 'react-router-dom'
import { useSelector } from 'react-redux'
import DefaultLayout from '../layout/layouts/DefaultLayout.js'
import Orders from '../pages/MES/Orders'
import RoleProtected from '../../molecules/RoleProtected'
// import OutsideViewBox from './dataTable/boxes/OutsideViewBox'

const MESRoutes = () => {
  const user = useSelector((state) => state.user.user)
  return (
    <Switch>
      <RoleProtected allowRoles={[1]} path="/mes/orders">
        <DefaultLayout>
          <Orders />
        </DefaultLayout>
      </RoleProtected>
    </Switch>
  )
}

export default MESRoutes
