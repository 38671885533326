import { Grid, IconButton } from '@mui/material'
import { GetApp } from '@mui/icons-material'
import { green } from '@mui/material/colors'
import PropTypes from 'prop-types'

const PatientsDataActions = () => {
  return (
    <Grid
      container
      alignItems="center"
      justifyContent="center"
      direction="row"
      spacing={1}
      className="table-actions"
    >
      <Grid item>
        <IconButton title="Pobierz" size="small">
          <GetApp fontSize="small" style={{ color: green[500] }} />
        </IconButton>
      </Grid>
    </Grid>
  )
}

PatientsDataActions.propTypes = {
  row: PropTypes.object,
}

export default PatientsDataActions
