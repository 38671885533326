import PropTypes from 'prop-types'
import { useEffect, useState } from 'react'
import eventBus from 'lib/eventBus'
import ValuationTable from 'components/molecules/valuations/ValuationTable'

const PartnerOrdersPreview = ({ partnerId }) => {
  const [results, setResults] = useState()

  useEffect(() => {
    eventBus.on('newFilterValues', async (data) => {
      await setResults(data.type_id)
    })

    return eventBus.remove('newFilterValues')
  }, [])

  useEffect(() => {
    eventBus.dispatch('forceFilterValue', {
      key: 'partner_id',
      value: null,
      filter: 'PartnersKey',
    })
  }, [results])

  return (
    <>
      <ValuationTable
        endpoint={`/partners/${partnerId}/orders`}
        customKey="PartnerOrdersPreviewKey"
        actions="OrderTableActions"
        excludeFilters={['partner_name']}
        actionValues={{
          partner_id: partnerId,
        }}
        title="Lista zamówień"
        isOrders={true}
      />
    </>
  )
}

PartnerOrdersPreview.propTypes = {
  partnerId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
}

export default PartnerOrdersPreview
