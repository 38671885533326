import PropTypes from 'prop-types'
import React from 'react'
import { useFormik } from 'formik'
import { refreshFilter } from '../../../../lib/handleFilters'
import eventBus from '../../../../lib/eventBus'
import Confirmation from '../Confirmation'
import regex from '../../../../regex'
import {
  Typography,
  Grid,
  DialogActions,
  FormControlLabel,
  FormGroup,
  Checkbox,
  DialogContent,
  // CircularProgress,
} from '@mui/material'
import { openPopup } from '../../../../store/popupsSlice'
import FormikControl from '../../../molecules/FormikControl'
import FormikSelect from '../../../molecules/FormikSelect'
import SaveIcon from '@mui/icons-material/Save'
import * as Yup from 'yup'
import axios from 'axios'
import { handleOpenAlert } from '../../../../lib/handleAlert'
import { closePopup } from '../../../../store/popupsSlice'
import { useDispatch } from 'react-redux'
import { useEffect, useState } from 'react'
import moment from 'moment'
import FormikDatePicker from '../../../molecules/FormikDatePicker'
// import makeStyles from '@mui/styles/makeStyles'
import LoadingButton from '@mui/lab/LoadingButton'

// const styles = makeStyles(() => ({
//   loading: {
//     position: 'absolute',
//     left: '17%',
//     top: '4px',
//   },
// }))

const phone_numberRegExp = /^(\+[0-9]{1,})?[ ]?([0-9]{3}[- ]?){3}$/

function addZeroToNumber(number) {
  if (number < 10) {
    number = `0${number}`
  }
  return number
}

function getDateOfBirth(pesel) {
  let peselArray = pesel.split('')
  let multiplier = Math.floor(Number.parseInt(peselArray[2]) / 2) //3 i 4 cyfra mowi o miesiacu wartosci 0-12 -> (1900-1999) -> 20-32 -> (2000-2099) itd...
  let year =
    1900 + multiplier * 100 + Number.parseInt(peselArray[0] + peselArray[1]) //1 i 2 cyfra to dziesiatki i jednosci roku urodzenia
  let day = peselArray[4] + peselArray[5] //4 i 5 cyfra to dzien w miesiacu
  let month = addZeroToNumber(
    Number.parseInt(peselArray[2] + peselArray[3]) - multiplier * 20
  )
  return `${year}-${month}-${day}`
}

function isMan(pesel) {
  let peselArray = pesel.split('')
  return peselArray[9] % 2 == 1 //parzysta kobieta, nieparzysta mezczyzna
}

function validPesel(pesel) {
  let p = pesel.split('')
  let sum =
    1 * p[0] +
    3 * p[1] +
    7 * p[2] +
    9 * p[3] +
    1 * p[4] +
    3 * p[5] +
    7 * p[6] +
    9 * p[7] +
    1 * p[8] +
    3 * p[9] //wzor
  let lastDigit = 10 - (sum % 10) //wynik (10 - ostatnia cyfra liczby ze wzoru) musi byc taki sam jak ostatnia cyfra peselu
  return lastDigit % 10 == p[10]
}
let lastCheckedEmail = {
  email: null,
  status: false,
}

async function checkEmail(email) {
  if (lastCheckedEmail['email'] != email && regex.email.test(email)) {
    lastCheckedEmail['email'] = email
    let res = await axios.post('/patients/email/check', { email })
    lastCheckedEmail['status'] = !res.data.exist
    return !res.data.exist
  } else {
    return lastCheckedEmail['status']
  }
}
let lastCheckedPesel = {
  pesel: null,
  status: false,
}

async function checkPesel(pesel) {
  if (lastCheckedPesel['pesel'] != pesel) {
    lastCheckedPesel['pesel'] = pesel
    let res = await axios.post('/patients/pesel/check', { pesel })
    lastCheckedPesel['status'] = !res.data.exist
    return !res.data.exist
  } else {
    return lastCheckedPesel['status']
  }
}

const PatientFactory = ({ ...values }) => {
  const patient_id = values.id
  const [genders, setGenders] = useState()
  const [createContractAlert, setCreateContractAlert] = useState(false)
  const [fromContract, setFromContract] = useState(false)
  const [addedUser, setAddedUser] = useState(null)

  const initEmail = values.id && values.email

  useEffect(() => {
    axios.post('/lookups/genders', null).then((res) => {
      setGenders(res.data)
    })
    setFromContract(values.fromContract)
  }, [])

  const dispatch = useDispatch()

  const handleChangePesel = async (formik, value) => {
    if (value.length == 11 && validPesel(value)) {
      formik.setFieldValue('gender_id', isMan(value) ? 1 : 2)
      let date = moment(getDateOfBirth(value))
      formik.setFieldValue('birth_date', date)
    }

    formik.setFieldValue('pesel', value)
  }

  const handleChangeCheckBox = (formik, value, name) => {
    formik.setFieldValue(name, value)
  }

  const formik = useFormik({
    initialValues: {
      pesel: values.pesel || '',
      phone_number: values.phone_number || '',
      email: values.email || '',
      firstname: values.firstname || '',
      lastname: values.lastname || '',
      gender_id: values.gender_id || '',
      active: values.active,
      share_with_doctor: values.share_with_doctor,
      birth_date: values.birth_date || '',
    },
    enableReinitialize: false,
    validationSchema: Yup.object({
      pesel: Yup.string()
        .nullable()
        .min('11', 'PESEL musi zawierać 11 cyfr')
        .max('11', 'PESEL musi zawierać 11 cyfr')
        .test(
          'pesel-check',
          'Suma kontrolna peselu się nie zgadza',
          async function (value) {
            if (value) {
              if (validPesel(value)) {
                return true
              } else {
                return false
              }
            } else {
              return true
            }
          }
        )
        .test(
          'pesel-check-2',
          'Podany pesel jest zajęty',
          async function (value) {
            if (value && !patient_id) {
              if (validPesel(value)) {
                if (await checkPesel(value)) {
                  return true
                } else {
                  return false
                }
              } else {
                return true
              }
            } else {
              return true
            }
          }
        ),

      firstname: Yup.string().required('Pole wymagane'),
      active: Yup.boolean(),
      lastname: Yup.string().required('Pole wymagane'),
      birth_date: Yup.string().required('Pole wymagane'),
      email: Yup.string()
        .email('Błędny format emaila')
        .when('active', {
          is: true,
          then: Yup.string().required('Pole wymagane'),
        })
        .test('email-unique', 'Podany email jest zajęty', (value) =>
          value && value.length > 2 && value != initEmail
            ? checkEmail(value)
            : true
        ),
      phone_number: Yup.string().matches(
        phone_numberRegExp,
        'Numer telefonu jest niepoprawny'
      ),
      gender_id: Yup.string().required('Pole wymagane'),
    }),
    onSubmit: async (values) => {
      if (!patient_id) {
        store(values)
        setLoading(true)
      } else {
        edit(values)
        setLoading(true)
      }
    },
  })

  useEffect(() => {
    if (formik && values && values.pesel) {
      handleChangePesel(formik, values.pesel)
    }
  }, [])

  const store = (values) => {
    axios
      .post('/patients/store', {
        ...values,
        share_with_doctor: values.share_with_doctor || 0,
        active: values.active || 0,
        birth_date: moment(values.birth_date).format('YYYY-MM-DD'),
      })
      .then((res) => {
        if (res.status == 200 || res.status == 201) {
          if (!fromContract) {
            setCreateContractAlert(true)
          } else {
            dispatch(closePopup('patient-factory-popup'))
            eventBus.dispatch('refreshPatient', { patient_id: res.data.id })
          }

          setAddedUser(res.data)
          refreshFilter('PatientsKey')
          setLoading(false)
        }
        handleOpenAlert({
          title: 'Sukces!',
          subtitle: 'Dodano nowego pacjenta',
        })
      })
      .catch((err) => {
        handleOpenAlert(err.request.status)
        setLoading(false)
      })
  }

  const edit = (values) => {
    axios
      .post(`/patients/${patient_id}/edit`, {
        ...values,
        share_with_doctor: values.share_with_doctor || 0,
        active: values.active || 0,
        birth_date: moment(values.birth_date).format('YYYY-MM-DD'),
      })
      .then((res) => {
        if (res.status == 200 || res.status == 201) {
          dispatch(closePopup('patient-factory-popup'))
          refreshFilter('PatientsKey')
          eventBus.dispatch('refreshPatient', { patient_id })
          setLoading(false)
        }
        handleOpenAlert({
          title: 'Sukces!',
          subtitle: 'Edytowano pacjenta',
        })
      })
      .catch((err) => {
        handleOpenAlert(err.request.status)
        setLoading(false)
      })
  }

  const [loading, setLoading] = useState(false)

  // function handleClick() {
  //   setLoading(true)
  // }

  // const classes = styles()

  return (
    <>
      {createContractAlert && (
        <Confirmation
          level={5}
          maxWidth="xs"
          icon="Help"
          title={`Czy chcesz od razu dodać zlecenie?`}
          subtitle={`Zostaniesz przeniesiony do formularza dodawania zlecenia.`}
          reject={() => {
            setCreateContractAlert(false)
            setAddedUser(null)
            dispatch(closePopup('patient-factory-popup'))
          }}
          success={async () => {
            await dispatch(
              openPopup({
                component: 'ContractFactory',
                title: 'Dodawanie zlecenia',
                level: 2,
                maxWidth: 'xl',
                key: 'contract-factory-popup',
                values: {
                  patient_id: addedUser.id,
                  firstname: addedUser.firstname,
                  lastname: addedUser.lastname,
                },
              })
            )
            dispatch(closePopup('patient-factory-popup'))
          }}
        />
      )}

      <form onSubmit={formik.handleSubmit} onReset={formik.handleReset}>
        <>
          <DialogContent dividers={true}>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <Typography variant="subtitle1" component="div">
                      Dane osobowe
                    </Typography>
                    {/* {loading && patient_id && (
                      <CircularProgress
                        className={classes.loading}
                        color="primary"
                        thickness={2}
                        size="4rem"
                      />
                    )} */}
                  </Grid>
                  <Grid item md={6} xs={12}>
                    <FormikControl
                      variableName="pesel"
                      handleChange={(e) =>
                        handleChangePesel(formik, e.target.value)
                      }
                      forceError={
                        !formik.touched.pesel &&
                        validPesel(formik.values.pesel) &&
                        Boolean(checkPesel(formik.values.pesel))
                      }
                      forceHelper="Podany pesel jest zajęty"
                      labelName="PESEL"
                      formik={formik}
                    />
                  </Grid>
                  <Grid item md={6} xs={12}>
                    <FormikDatePicker
                      variableName="birth_date"
                      disabled={!!formik.values.pesel}
                      handleChange={formik.handleChange}
                      labelName="Data urodzenia"
                      formik={formik}
                    />
                  </Grid>
                  <Grid item md={6} xs={12}>
                    <FormikControl
                      variableName="firstname"
                      handleChange={formik.handleChange}
                      labelName="Imię"
                      formik={formik}
                    />
                  </Grid>
                  <Grid item md={6} xs={12}>
                    <FormikControl
                      variableName="lastname"
                      handleChange={formik.handleChange}
                      labelName="Nazwisko"
                      formik={formik}
                    />
                  </Grid>
                  {genders && (
                    <Grid item md={6} xs={12}>
                      <FormikSelect
                        variableName="gender_id"
                        disabled={!!formik.values.pesel}
                        value={formik.values['gender_id']}
                        labelName="Płeć"
                        formik={formik}
                        options={genders}
                      />
                    </Grid>
                  )}
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <Typography variant="subtitle1" component="div">
                      Dane kontaktowe
                    </Typography>
                  </Grid>
                  <Grid item md={6} xs={12}>
                    <FormikControl
                      type="tel"
                      variableName="phone_number"
                      handleChange={formik.handleChange}
                      labelName="Nr. Tel."
                      formik={formik}
                    />
                  </Grid>
                  <Grid item md={6} xs={12}>
                    <FormikControl
                      variableName="email"
                      handleChange={formik.handleChange}
                      labelName="E-mail"
                      formik={formik}
                    />
                  </Grid>
                </Grid>
                <Grid item xs={12}>
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <Typography variant="subtitle1" component="div">
                        Dane opcjonalne
                      </Typography>
                    </Grid>
                    <Grid item md={6} xs={12}>
                      <FormGroup>
                        <FormControlLabel
                          control={
                            <Checkbox
                              color="primary"
                              checked={Boolean(formik.values.active)}
                              onChange={(e) =>
                                handleChangeCheckBox(
                                  formik,
                                  e.target.checked,
                                  'active'
                                )
                              }
                            />
                          }
                          label="Chcę dostać i założyć profil"
                        />
                      </FormGroup>
                    </Grid>
                    <Grid item md={6} xs={12}>
                      <FormGroup>
                        <FormControlLabel
                          control={
                            <Checkbox
                              color="primary"
                              checked={Boolean(formik.values.share_with_doctor)}
                              onChange={(e) =>
                                handleChangeCheckBox(
                                  formik,
                                  e.target.checked,
                                  'share_with_doctor'
                                )
                              }
                            />
                          }
                          label="Chcę udostępnić lekarzowi"
                        />
                      </FormGroup>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <LoadingButton
              startIcon={<SaveIcon />}
              variant="contained"
              color="primary"
              type="submit"
              // onClick={setLoading}
              loading={loading}
              loadingPosition="start"
            >
              <span className="mt-1">Zapisz</span>
            </LoadingButton>
          </DialogActions>
        </>
      </form>
    </>
  )
}

PatientFactory.propTypes = {
  values: PropTypes.object,
}

export default PatientFactory
