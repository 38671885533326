import { toPath } from 'lodash'

export function getDeepValue(object, string) {
  let path = stringPathToArray(string)
  return getDeepValueByArray(object, path)
}

export function stringPathToArray(string) {
  return toPath(string)
}

export function getDeepValueByArray(object, arrayPath) {
  let searchValue = Object.assign({}, object)
  for (let i = 0; i < arrayPath.length; i++) {
    if (searchValue[arrayPath[i]]) {
      searchValue = searchValue[arrayPath[i]]
    } else {
      return null
    }
  }
  return searchValue
}

export default getDeepValue
