import { Card, CardHeader, CardContent, Button } from '@mui/material'
import StoreKeyProvider from 'components/providers/StoreKeyProvider'
import WithFilters from 'components/hocs/WithFilters'
import DataTable from 'components/organisms/dataTable/DataTable'
import PropTypes from 'prop-types'
import DataInputs from 'components/organisms/dataTable/DataInputs'
import { useEffect, useState } from 'react'
import eventBus from 'lib/eventBus'
import AddIcon from '@mui/icons-material/Add'
import { useDispatch, useSelector } from 'react-redux'
import { openPopup } from 'store/popupsSlice'
import makeStyles from '@mui/styles/makeStyles'
import axios from 'axios'
import ShowAttachmentCell from 'components/organisms/dataTable/cells/ShowAttachmentCell'

const useStyles = makeStyles((theme) => ({
  bg: {
    background: 'transparent',
    overflow: 'unset',
  },
  content: {
    padding: '24px',
    background: '#fff',
    marginBottom: '24px',
    borderRadius: '2px',
    boxShadow:
      '0px 3px 1px -2px rgba(0,0,0,0.2),0px 2px 2px 0px rgba(0,0,0,0.14),0px 1px 5px 0px rgba(0,0,0,0.12)',
  },
  contentSt: {
    background: '#fff',
    marginBottom: '24px',
    borderRadius: '2px',
    boxShadow:
      '0px 3px 1px -2px rgba(0,0,0,0.2),0px 2px 2px 0px rgba(0,0,0,0.14),0px 1px 5px 0px rgba(0,0,0,0.12)',
  },
  contentPreview: {
    background: '#fff',
    padding: 'unset',
    color: 'rgba(0, 0, 0, 0.6)',
    display: 'flex',
    flexWrap: 'wrap',
    [theme.breakpoints.down('custom_extras')]: {
      width: '100%',
    },
  },
}))

const MaterialFinishingPreview = ({ materialId }) => {
  const dispatch = useDispatch()
  const classes = useStyles()
  const [finishes, setFinish] = useState([])
  const user = useSelector((state) => state.user.user)

  const [chosenFinishingTypes, setChosenFinishingTypes] = useState()

  useEffect(() => {
    axios.post('/lookups/extra_types', null).then((res) => {
      setFinish(res.data)
    })
  }, [])

  useEffect(() => {
    eventBus.on('newFilterValues', async (data) => {
      await setChosenFinishingTypes(data.type_id)
    })

    return eventBus.remove('newFilterValues')
  }, [])

  useEffect(() => {
    eventBus.dispatch('forceFilterValue', {
      key: 'material_id',
      value: null,
      filter: 'MaterialFinishingPreviewKey',
    })
  }, [chosenFinishingTypes])

  return (
    <div>
      <StoreKeyProvider storeKey="MaterialFinishingPreviewKey">
        <WithFilters
          config={{
            page: 1,
            paginate: 25,
            orderType: 'desc',
            order: 'name',
            inputs: {
              name: {
                value: '',
                relation: 'LIKE',
              },
              type_id: {
                value: '',
                relation: 'WHERE',
              },
              min_length: {
                value: '',
                relation: 'MIN',
              },
              max_length: {
                value: '',
                relation: 'MAX',
              },
              active: {
                value: 1,
                relation: 'WHERE',
              },
            },
          }}
          endpoint={`/materials/${materialId}/extras/filter`}
        >
          <Card elevation={0} className={classes.bg}>
            <CardContent className={classes.contentSt}>
              <CardHeader
                className={classes.contentPreview}
                title="Wykończenia"
                action={
                  <>
                    <div className="flex flex-wrap side_menu_nd_w:flex-col side_menu_nd_w:gap-4">
                      {user.permissions &&
                        user.permissions.includes('store Extra') && (
                          <Button
                            startIcon={<AddIcon />}
                            variant="contained"
                            color="primary"
                            onClick={() =>
                              dispatch(
                                openPopup({
                                  component: 'MaterialFinishingPricingFactory',
                                  title: 'Dodaj wykończenie',
                                  level: 1,
                                  key: 'factory-finishing-popup',
                                  maxWidth: 'sm',
                                  values: {
                                    material_id: materialId,
                                    popupKey: 'factory-finishing-popup',
                                  },
                                })
                              )
                            }
                          >
                            <span className="mt-1">Dodaj wykończenia</span>
                          </Button>
                        )}
                    </div>
                  </>
                }
              />
              {finishes && (
                <DataInputs
                  inputs={[
                    {
                      id: 'name',
                      label: 'Nazwa',
                      placeholder: '',
                      type: 'text',
                    },
                    {
                      id: 'type_id',
                      label: 'Typ wykończenia',
                      placeholder: '',
                      type: 'select',
                      options: finishes,
                    },
                    {
                      id: 'length',
                      label: 'Długość',
                      placeholder: '',
                      type: 'number-between',
                    },
                    {
                      id: 'active',
                      label: 'Aktywne',
                      placeholder: '',
                      type: 'select',
                      options: [
                        {
                          id: 1,
                          name: 'Tak',
                        },
                        {
                          id: 0,
                          name: 'Nie',
                        },
                      ],
                    },
                  ]}
                />
              )}
            </CardContent>
            <CardContent className={classes.content}>
              <DataTable
                actions="MaterialFinishingPreviewActions"
                breakpoint={1200}
                rowComponents={{
                  file_id: ShowAttachmentCell,
                }}
                labels={['Plik', 'Nazwa', 'Typ wykończenia', 'Długość']}
                keys={['file_id', 'name', 'type_name', 'length']}
              />
            </CardContent>
          </Card>
        </WithFilters>
      </StoreKeyProvider>
    </div>
  )
}

MaterialFinishingPreview.propTypes = {
  materialId: PropTypes.string,
}

export default MaterialFinishingPreview
