// import React from 'react'
import PropTypes from 'prop-types'
import { Card, CardHeader, CardContent, LinearProgress } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
// import { AlertTitle, Stack } from '@mui/lab'

const useStyles = makeStyles(() => ({
  root: {
    minWidth: '250px',
  },
}))

const Alert = ({ title, progress }) => {
  const classes = useStyles()
  return (
    <Card elevation={2} className={classes.root}>
      <CardHeader title={title} />
      <CardContent>
        <LinearProgress variant="determinate" value={progress} />
      </CardContent>
    </Card>
  )
}

Alert.propTypes = {
  title: PropTypes.string,
  progress: PropTypes.number,
}

export default Alert
