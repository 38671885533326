import PropTypes from 'prop-types'
import { TableCell } from '@mui/material'
import Input from '../../Input/atoms/Input'

const ColorItemListItemForm = ({ item, index, updateItem, action }) => {
  const onFieldValueChange = (field, value) => {
    item[field] = value
    updateItem(item)
  }

  return (
    <>
      <TableCell>{index + 1}</TableCell>
      <TableCell>
        <Input
          onChange={(v) => onFieldValueChange('name', v)}
          value={item.name}
        />
      </TableCell>
      <TableCell>
        <Input
          type="color"
          onChange={(v) => onFieldValueChange('hex_color', v)}
          value={item.hex_color}
        />
      </TableCell>
      <TableCell align="right">{action}</TableCell>
    </>
  )
}

ColorItemListItemForm.propTypes = {
  item: PropTypes.object.isRequired,
  index: PropTypes.number.isRequired,
  updateItem: PropTypes.func.isRequired,
  action: PropTypes.element,
}

export default ColorItemListItemForm
