import { createSlice } from '@reduxjs/toolkit'
import eventBus from '../lib/eventBus'

export const popupsSlice = createSlice({
  name: 'popups',
  initialState: {
    popups: {},
  },
  reducers: {
    openPopup: (state, action) => {
      let { component, title, values, level, key, maxWidth } = action.payload
      if (!values) {
        values = {}
      }
      values.popupKey = key
      values.popupTitle = title
      Object.assign(
        (state.popups[key] = {
          component,
          title,
          values,
          level,
          maxWidth,
        })
      )
    },
    closePopup: (state, action) => {
      const key = action.payload
      delete state.popups[key]
      eventBus.dispatch('popupClosed', { key })
    },
  },
})

export const { openPopup, closePopup } = popupsSlice.actions

export default popupsSlice.reducer
