import { createSlice } from '@reduxjs/toolkit'

export const mainSlice = createSlice({
  name: 'main',
  initialState: {
    panelId: 1,
  },
  reducers: {
    changePanel: (state, action) => {
      localStorage.setItem('panelId', action.payload)
      state.panelId = action.payload
    },
  },
})

export const { changePanel, getPanelMainPage } = mainSlice.actions

export default mainSlice.reducer
