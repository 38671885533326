import React from 'react'
import {
  Select,
  FormControl,
  InputLabel,
  MenuItem,
  Button,
} from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import PropTypes from 'prop-types'
import { useDispatch } from 'react-redux'
// import { ArrowDropDown, ArrowDropUp } from '@mui/icons-material'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSortAmountUpAlt } from '@fortawesome/pro-solid-svg-icons'
import { faSortAmountDown } from '@fortawesome/pro-solid-svg-icons'
import { useContext } from 'react'
import StoreKeyContext from '../../../../contexts/StoreKeyContext'
import { changeOrder } from '../../../../store/filtersSlice'

const useStyles = makeStyles(() => ({
  root: {
    display: 'flex',
    flexDirection: 'row',
    maxHeight: '3em',
  },
  select: {
    borderBottomRightRadius: '0',
    borderTopRightRadius: '0',
    flex: '1 1 auto',
  },
  button: {
    borderBottomLeftRadius: '0',
    borderTopLeftRadius: '0',
    minWidth: '32px',
  },
}))

const DataTable = ({ labels, keys, filters }) => {
  const classes = useStyles()
  const dispatch = useDispatch()

  const storeKey = useContext(StoreKeyContext)

  const handleOrderChange = (key) => {
    dispatch(changeOrder({ key: storeKey, orderKey: key }))
  }

  return filters && labels && keys ? (
    <FormControl fullWidth className={classes.root}>
      <InputLabel>Sortowanie</InputLabel>

      <Select
        className={classes.select}
        value={filters.order || ''}
        onChange={(e) => handleOrderChange(e.target.value)}
        id={storeKey + 'sorter'}
        name={storeKey + 'sorter'}
        variant="outlined"
        label="Sortowanie"
      >
        {keys &&
          keys.length > 0 &&
          keys.map((key, index) => (
            <MenuItem key={index} value={key}>
              {labels[index]}
            </MenuItem>
          ))}
        {/* {keys && !keys['created_at'] && (
          <MenuItem value="created_at">Data utworzenia</MenuItem>
        )} */}
      </Select>
      <Button
        onClick={() => handleOrderChange(filters.order)}
        className={classes.button}
      >
        {filters.orderType === 'asc' ? (
          <FontAwesomeIcon icon={faSortAmountDown} />
        ) : (
          <FontAwesomeIcon icon={faSortAmountUpAlt} />
        )}
      </Button>
    </FormControl>
  ) : (
    <></>
  )
}

DataTable.propTypes = {
  labels: PropTypes.array,
  filters: PropTypes.object,
  keys: PropTypes.array,
}

export default DataTable
