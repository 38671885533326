export function buildFile(data) {
  const response = {
    id: null,
    file: null,
  }

  if (data instanceof File) {
    response.id = null
    response.file = data
  } else if (data) {
    response.id = data.id
    response.file = null
  }

  return response
}

export default buildFile
