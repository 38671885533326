import { Grid, IconButton } from '@mui/material'
import { Delete, Edit } from '@mui/icons-material'
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye'
import { useDispatch, useSelector } from 'react-redux'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import { openPopup } from '../../../../store/popupsSlice'
import axios from 'axios'
import Confirmation from '../../popups/Confirmation'
import { handleOpenAlert } from '../../../../lib/handleAlert'
import { refreshFilter } from '../../../../lib/handleFilters'
import { useState } from 'react'
import RestoreFromTrashIcon from '@mui/icons-material/RestoreFromTrash'

const MaterialsTableActions = ({ row }) => {
  const dispatch = useDispatch()
  const user = useSelector((state) => state.user.user)
  const [confirmation, setConfirmation] = useState(false)

  return (
    <Grid
      container
      alignItems="center"
      justifyContent="center"
      direction="row"
      spacing={1}
      className="table-actions"
    >
      {confirmation && (
        <Confirmation
          level={5}
          maxWidth="xs"
          icon="Warning"
          title={`Czy na pewno chcesz ${
            row.active ? 'usunąć' : 'przywrócić'
          } materiał: ${row.name}?`}
          subtitle={`Tej operacji nie można cofnąć.`}
          reject={() => {
            setConfirmation(false)
          }}
          success={() => {
            axios
              .post(`/materials/${row.id}/${row.active ? 'delete' : 'restore'}`)
              .then(() => {
                handleOpenAlert({
                  title: 'Sukces!',
                  subtitle: `${
                    row.active ? 'Usunięto' : 'Przywrócono'
                  } materiał ${row.name}`,
                  type: 'success',
                })
                refreshFilter('MaterialsKey')
              })
              .finally(() => {
                setConfirmation(false)
              })
          }}
        />
      )}
      <Grid className="p-0" item>
        <Link to={`/materials/${row.id}/prices`}>
          <IconButton className="p-0" title="Podgląd materiału" size="small">
            <RemoveRedEyeIcon fontSize="small" color="primary" />
          </IconButton>
        </Link>
      </Grid>
      {user.role_id == 1 &&
        user.permissions &&
        ((row.active && user.permissions.includes('remove Material')) ||
          (!row.active && user.permissions.includes('restore Material'))) && (
          <Grid className="p-0" item>
            <IconButton
              className="p-0"
              title={`${row.active ? 'Przywróć' : 'Usuń'} materiał`}
              size="small"
              onClick={() => setConfirmation(true)}
            >
              {row.active ? (
                <Delete fontSize="small" color="error" />
              ) : (
                <RestoreFromTrashIcon fontSize="small" color="success" />
              )}
            </IconButton>
          </Grid>
        )}
    </Grid>
  )
}

MaterialsTableActions.propTypes = {
  row: PropTypes.object,
}

export default MaterialsTableActions
