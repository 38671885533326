import { Add, Remove } from '@mui/icons-material'
import { IconButton, TableCell, TableRow, InputAdornment } from '@mui/material'
import axios from 'axios'
import PropTypes from 'prop-types'
import { memo, useContext, useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { ValuationCalculatorContext } from '../../../../../../contexts/ValuationCalculatorContext'
import FormAutocomplete from '../../../../inputs/FormAutocomplete'
import FormInput from '../../../../inputs/FormInput'
import LoadingText from '../../../../../atoms/LoadingText'

const ValuationCalculatorLengthListItem = ({
  item,
  showDel,
  delComponent,
  changeValue,
  addComponent,
  blocksCount,
  showAdd,
  showConnector,
  disabled,
}) => {
  const { t } = useTranslation()
  const calculateDiscount = (price) => {
    if (!price) {
      price = 0
    }
    return parseFloat(price).toFixed(2)
  }
  const [error, setError] = useState(null)
  const [timeoutCalc, setTimeoutCalc] = useState(null)
  const [cancelTokenSource, setCancelTokenSource] = useState(null)
  const {
    component,
    currencyAcronym,
    validate,
    partnerId,
    withoutBlocks,
    getExtrasItems,
    connectors,
    materialMaxLength,
    calculate,
    handleLoadingStack,
    setWidthError,
  } = useContext(ValuationCalculatorContext)

  const firstRender = useRef(true)
  useEffect(() => {
    firstRender.current = false
  })
  useEffect(() => {
    firstRender.current = true
  }, [component.id])

  useEffect(() => {
    if (!firstRender.current) {
      beginUpdatePrice()
      setError(null)
    }
  }, [calculate, item.length, blocksCount])

  const calculatePrice = (value) => {
    return new Promise(function (resolve) {
      handleLoadingStack(true)
      let materialId = component.material_id
      let colorId = component.color_id
      let thicknessId = component.thickness_id
      let width = component.width
      if (materialId && colorId && thicknessId && width > 0) {
        const source = axios.CancelToken.source()
        setCancelTokenSource(source)
        axios
          .post(
            `/blocks/calculate`,
            {
              material_id: materialId,
              thickness_id: thicknessId,
              partner_id: partnerId,
              length: value / 1000,
              color_id: colorId,
              width,
              blocks_count: blocksCount,
            },
            {
              cancelToken: source.token,
            }
          )
          .then(async (res) => {
            setCancelTokenSource(null)
            if (res.status === 200) {
              resolve(res.data)
            }
            setWidthError(null)
          })
          .catch((e) => {
            if (e.response && e.response.status === 422) {
              setCancelTokenSource(null)
              let errors = e.response.data.errors
              if (errors['length']) {
                resolve(errors['length'][0])
              } else {
                resolve({ price_net: 0, value_net: 0 })
              }
              if (errors['width']) {
                setWidthError(errors['width'][0])
              } else {
                setWidthError(null)
              }
            }
          })
          .finally(() => {
            handleLoadingStack(false)
          })
      } else {
        handleLoadingStack(false)
        resolve(0)
      }
    })
  }

  const beginUpdatePrice = async () => {
    if (timeoutCalc) {
      clearTimeout(timeoutCalc)
    }
    setTimeoutCalc(
      setTimeout(() => {
        updatePrice()
      }, 300)
    )
  }
  const updatePrice = async () => {
    if (cancelTokenSource) {
      cancelTokenSource.cancel()
    }
    const price = await getPrice(item.length)
    item.price = price.value_net
    item.price_net = price.price_net
    changeValue(item)
  }

  const getPrice = async (length) => {
    let price = null
    if (length > 0) {
      price = await calculatePrice(length)
    }
    if (price) {
      if (typeof price === 'string') {
        setError(price)
        price = {
          value_net: 0,
        }
      } else {
        setError(null)
      }
    } else {
      price = {
        value_net: 0,
      }
    }
    return price
  }

  useEffect(() => {
    if (item.price && !firstRender.current) {
      getExtrasItems()
    }
  }, [item.price /*item.connector_id*/])

  return (
    <>
      <TableRow>
        <TableCell xs={3}>
          <FormInput
            size="small"
            type="number"
            handleChange={(v) => {
              item.length = v
              changeValue(item)
            }}
            min="0"
            name="length"
            value={item.length}
            helperText={
              error ||
              (!withoutBlocks &&
                validate &&
                ((!item.length && t('main.validation.required')) ||
                  (item.length < 100 &&
                    t('main.validation.min_value', { min: 100 })) ||
                  (item.length == materialMaxLength &&
                    blocksCount > 1 &&
                    t('main.validation.max_value', {
                      max: materialMaxLength - 1,
                    }))))
            }
            error={true}
            labelName={t('valuation_popup.data.input.length')}
            disabled={disabled}
            InputProps={{
              endAdornment: (
                <InputAdornment position="start">mm</InputAdornment>
              ),
            }}
          />
        </TableCell>
        <TableCell xs={3}>
          {showConnector && (
            <FormAutocomplete
              size="small"
              value={item.connector_id}
              variableName="connector_id"
              helperText={
                error ||
                (!withoutBlocks &&
                  validate &&
                  !item.connector_id &&
                  t('main.validation.required'))
              }
              labelName={t('valuation_popup.data.input.connector')}
              handleChange={(label, v) => {
                item.connector_id = v
                changeValue(item)
              }}
              disabled={disabled}
              options={connectors}
            />
          )}
        </TableCell>
        <TableCell xs={3}>
          {item.price != null ? (
            `${calculateDiscount(item.price)} ${currencyAcronym}`
          ) : (
            <LoadingText text={t('valuation_popup.calculating')} />
          )}
        </TableCell>
        <TableCell xs={3}>
          {item.price_net === null ? (
            <LoadingText text={t('valuation_popup.calculating')} />
          ) : item.price_net ? (
            `${calculateDiscount(item.price_net)} ${currencyAcronym}${
              component.unit_name ? `/${component.unit_name}` : ''
            }`
          ) : (
            '---'
          )}
        </TableCell>
        <TableCell xs={3}>
          <div>
            {showAdd && (
              <IconButton
                color="primary"
                component="span"
                disabled={disabled}
                size="large"
                title={t('valuation_popup.data.button_title.add_another_block')}
                onClick={addComponent}
              >
                <Add />
              </IconButton>
            )}
            {showDel && (
              <IconButton
                color="primary"
                component="span"
                disabled={disabled}
                size="large"
                title={t('valuation_popup.delete_block')}
                onClick={delComponent}
              >
                <Remove />
              </IconButton>
            )}
          </div>
        </TableCell>
      </TableRow>
    </>
  )
}

ValuationCalculatorLengthListItem.propTypes = {
  item: PropTypes.object,
  delComponent: PropTypes.func,
  changeValue: PropTypes.func,
  addComponent: PropTypes.func,
  blocksCount: PropTypes.number,
  showConnector: PropTypes.bool,
  disabled: PropTypes.bool,
  showDel: PropTypes.bool,
  showAdd: PropTypes.bool,
}

export default memo(ValuationCalculatorLengthListItem)
