import React, { useContext } from 'react'
import { useTranslation } from 'react-i18next'
import { ValuationCalculatorContext } from '../../../../../contexts/ValuationCalculatorContext'
import { Chip, Grid, Typography } from '@mui/material'
import FormFileInput from '../../../inputs/FormFileInput'
import downloadFile from '../../../../../lib/downloadFile'

const ValuationCalculatorAttachments = () => {
  const { component, setComponent, readOnly } = useContext(
    ValuationCalculatorContext
  )
  const { t } = useTranslation()

  const changeValue = (files) => {
    component.files = [...component.files, ...files]
    setComponent(component)
  }

  const handleDelete = (index) => {
    component.files.splice(index, 1)
    setComponent(component)
  }

  const downloadAttachment = (index) => {
    const file = component.files[index]
    downloadFile({
      route: `/files/${file.file_hash}/download`,
      fileName: file.name,
    })
  }

  return (
    <>
      <Grid container sx={{ maxWidth: 'inherit' }}>
        <Grid item xs={12}>
          <Typography
            variant="h6"
            component="div"
            className="mb-5 mt-5 !important"
          >
            {t('valuation_popup.data.title.attachments')}
          </Typography>
        </Grid>
        <Grid
          sx={{ overfloWrap: 'anywhere' }}
          item
          xs={12}
          rowSpacing={4}
          columnSpacing={4}
          className="flex gap-2 flex-wrap"
          alignItems="center"
        >
          {!readOnly && (
            <FormFileInput
              key={component.id}
              accept="image/*"
              handleChange={(files) => {
                changeValue(files)
              }}
            />
          )}
          {component.files &&
            component.files.map((file, index) => (
              <Chip
                key={`${index}-${file.name}`}
                label={file.name}
                variant="outlined"
                onClick={() => downloadAttachment(index)}
                onDelete={readOnly ? null : () => handleDelete(index)}
              />
            ))}
        </Grid>
      </Grid>
    </>
  )
}
ValuationCalculatorAttachments.propTypes = {}
export default ValuationCalculatorAttachments
