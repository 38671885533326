import PageHeader from 'components/organisms/layout/PageHeader'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBoxOpen } from '@fortawesome/pro-solid-svg-icons'
import makeStyles from '@mui/styles/makeStyles'
import PageContainer from 'components/organisms/layout/PageContainer'
import ValuationTable from 'components/molecules/valuations/ValuationTable'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
// import AddIcon from '@mui/icons-material/Add'
// import { useDispatch, useSelector } from 'react-redux'
// import { openPopup } from '../../../store/popupsSlice'

const useStyles = makeStyles(() => ({
  icon: {
    width: '24px !important',
    height: '24px',
  },
  bg: {
    background: 'transparent',
    overflow: 'unset',
  },
  content: {
    padding: '24px',
    background: '#fff',
    marginBottom: '24px',
    borderRadius: '2px',
    boxShadow:
      '0px 3px 1px -2px rgba(0,0,0,0.2),0px 2px 2px 0px rgba(0,0,0,0.14),0px 1px 5px 0px rgba(0,0,0,0.12)',
  },
  contentSt: {
    background: '#fff',
    marginBottom: '24px',
    borderRadius: '2px',
    boxShadow:
      '0px 3px 1px -2px rgba(0,0,0,0.2),0px 2px 2px 0px rgba(0,0,0,0.14),0px 1px 5px 0px rgba(0,0,0,0.12)',
    position: 'relative',
  },
}))

const CustomOrders = () => {
  // const dispatch = useDispatch()
  // const user = useSelector((state) => state.user.user)
  const classes = useStyles()

  return (
    <div>
      <PageHeader
        icon={<FontAwesomeIcon icon={faBoxOpen} className={classes.icon} />}
        title="Lista zamówień niestandardowych do weryfikacji"
      />
      <PageContainer>
        <ValuationTable
          endpoint="/valuations/to_accept"
          showAdd={null}
          actions="ValuationsTableActions"
          excludeFilters={[
            'status_name',
            'status_id',
            'sale_summary_net',
            'summary_net',
          ]}
        />
      </PageContainer>
    </div>
  )
}

export default CustomOrders
