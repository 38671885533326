import {
  // Select,
  FormControl,
  // InputLabel,
  // MenuItem,
  FormHelperText,
  Select,
  MenuItem,
  InputLabel,
} from '@mui/material'
import PropTypes from 'prop-types'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { makeStyles } from '@mui/styles'
import noImage from './../../../images/no_image.png'

const useStyles = makeStyles(() => ({
  menuItem: {
    display: 'flex',
    alignItems: 'center',
    gap: '1rem',
    justifyContent: 'space-between',
  },
  menuItemImg: {
    maxWidth: '50%',
    objectFit: 'contain',
    maxHeight: '100px',
  },
}))

const FormSelectWithImg = ({
  variableName,
  options,
  labelName,
  value,
  error = true,
  helperText,
  handleChange,
  disabled = false,
  config = { key: 'id', label: 'name', separator: ' ' },
  // ...props,
}) => {
  const [displayValue, setDisplayValue] = useState('')
  const classes = useStyles()

  useEffect(() => {
    if ((value || value === 0) && options) {
      for (let i = 0; i < options.length; i++) {
        let option = options[i]
        if (option[config.key] == value) {
          setDisplayValue(option)
        }
      }
    } else {
      setDisplayValue('')
    }
  }, [value, options])

  const labelId = `form-select-img-label-${variableName}`

  const localHandleChange = (v) => {
    handleChange(variableName, v ? v[config.key] : '')
  }

  return (
    <FormControl fullWidth error={helperText && error}>
      <InputLabel id={labelId}>{labelName}</InputLabel>
      <Select
        size="small"
        labelId={labelId}
        id={variableName}
        name={variableName}
        value={value}
        disabled={disabled}
        classes={{ select: classes.menuItem }}
        renderValue={() => <span>{displayValue[config.label]}</span>}
        onChange={(e) => localHandleChange(e.target.value)}
        label={labelName}
      >
        <MenuItem className={classes.menuItem} value="">
          <div></div>
          <div>Brak</div>
        </MenuItem>
        {options &&
          options.map((option, index) => (
            <MenuItem key={index} value={option} className={classes.menuItem}>
              <img
                className={classes.menuItemImg}
                src={
                  option.image
                    ? `${
                        //eslint-disable-next-line
                           process.env.REACT_APP_BASE_BACKEND_URL}${option.image}`
                    : noImage
                }
              />
              <div>{option[config.label]}</div>
            </MenuItem>
          ))}
      </Select>
    </FormControl>
  )
}

FormSelectWithImg.propTypes = {
  variableName: PropTypes.string,
  labelName: PropTypes.string,
  handleChange: PropTypes.func,
  type: PropTypes.string,
  variant: PropTypes.string,
  className: PropTypes.string,
  options: PropTypes.array,
  config: PropTypes.object,
  disabled: PropTypes.bool,
  error: PropTypes.bool,
  params: PropTypes.any,
  textFieldProps: PropTypes.any,
  value: PropTypes.any,
  InputProps: PropTypes.object,
  helperText: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
}

export default FormSelectWithImg
